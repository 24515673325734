import React, { useContext, useEffect, lazy } from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { isCandor } from '../../utils/roles';
import { AuthContext } from '../../auth-context';
import { allowedUserType, USER_TYPES } from '../../utils/constants';
import {
  PharmacyOrdersPage,
  PharmacyDetailsPage,
  PharmaciesPage,
  PharmacyDetailsLayout,
  PharmacyOrderDetailsPage,
  PharmacyLayout,
} from '../../features/pharmacyDirectory/pages';
import { PharmacyStockPreview } from '../../features/pharmacyDirectory/components/PharmacyStockPreview';
import PatientsProvider from '../patient-management/PatientsProvider';
import ResetPasswordInput from '../login/auth-landing-pages/reset-password-input';
import PharmacyStockPreviewLegacy from '../PharmacyStockPage/PharmacyStockPreview';
import AuthLandingPageError from '../login/auth-landing-pages/auth-landing-page-error';
import AuthLandingPageSuccess from '../login/auth-landing-pages/auth-landing-page-success';
import ResetPasswordLinkError from '../login/auth-landing-pages/reset-password-link-error';

const { PHARMACIST, PATIENT } = USER_TYPES;

const WeightLossForm = lazy(() => import('../form/standard-forms/weight-loss-form'));
const HairLossForm = lazy(() => import('../form/standard-forms/hair-loss-form'));
const SleepForm = lazy(() => import('../form/standard-forms/sleep-form'));
const ColdSoreForm = lazy(() => import('../form/standard-forms/cold-sores-form'));
const HerpesForm = lazy(() => import('../form/standard-forms/herpes-form'));
const ContraceptivePillForm = lazy(() => import('../form/standard-forms/contraceptive-pill-form'));
const PurchaseHistory = lazy(() => import('../purchase-history/purchase-history-container'));
const AccountDetails = lazy(() => import('../account-details/main-section/AccountDetailsContainer'));
const MedicalInfo = lazy(() => import('../medical-info/medical-info-container'));
const Messages = lazy(() => import('../messenger/messages-selection'));
const Prescriptions = lazy(() => import('../prescriptions/Prescriptions'));
const PaymentSuccess = lazy(() => import('../landing-pages/payment-success'));
const AdminControl = lazy(() => import('../admin/AdminContainer'));
const PatientsPage = lazy(() => import('../patient-management/PatientsPage/PatientsPageContainer'));
const PatientPage = lazy(() => import('../patient-management/PatientPage/main-section/PatientPageContainer'));
const AssignedPatients = lazy(() => import('../patient-management/assigned-patients/assigned-patients-container'));
const ErrorPage = lazy(() => import('../layout/error-fallback'));
const PaymentContainer = lazy(() => import('../checkout/PaymentContainer'));
const AcneForm = lazy(() => import('../form/standard-forms/acne-form'));
const AgeingForm = lazy(() => import('../form/standard-forms/ageing-form'));
const SkincareForm = lazy(() => import('../form/standard-forms/skincare-form'));
const AllergiesForm = lazy(() => import('../form/standard-forms/allergies-form'));
const ErectileDysfunctionForm = lazy(() => import('../form/standard-forms/erectile-dysfunction-form'));
const EmailPreferences = lazy(() => import('../account-details/email-section/email-preferences'));
const MedicinalCannabisForm = lazy(() => import('../form/standard-forms/medicinal-cannabis-form'));
const UploadSupportingDocuments = lazy(() => import('../patient-documents/documents/upload-supporting-documents'));
const ConsultPaymentSuccess = lazy(() => import('../landing-pages/consult-payment-success'));
const BookingPaymentSuccess = lazy(() => import('../landing-pages/booking-payment-success'));
const SignAgreement = lazy(() => import('../patient-documents/agreements/sign-agreement'));
const ScriptPaymentSuccess = lazy(() => import('../landing-pages/script-payment-success'));
const CheckupForm = lazy(() => import('../form/other-forms/checkup-form'));
const Dashboard = lazy(() => import('../dashboard/dashboard'));
const Consults = lazy(() => import('../dashboard/consults/consults-container'));
const Catalogue = lazy(() => import('../catalogue/catalogue-container'));
const PharmacyStock = lazy(() => import('../PharmacyStockPage/PharmacyStock'));
const AuthLandingPageContainer = lazy(() => import('../login/auth-landing-pages/auth-landing-page-container'));
const AppointmentContainer = lazy(() => import('../appointments/appointments-container'));
const PendingAccount = lazy(() => import('../landing-pages/pending-account'));
const TgaDetailsContainer = lazy(() => import('../tga/tga-details-container'));
const MessagesContainer = lazy(() => import('../messenger/messages-container'));
const ChatListPresentation = lazy(() => import('../messenger/chat-list-presentation'));

// will redirect to dashboard if user is not allowed to access the page
const getRestriction = ({ isAllowed, component }) => (isAllowed ? component : <Navigate to="/" />);

// TODO: Add the userType conditions for the routes to avoid unauthorized access
// Refactor if needed, remove this comments if done

const AuthenticatedRouter = () => {
  const { userType } = useContext(AuthContext);
  const getAllowedUserTypes = (allowedUserTypesArray) => allowedUserType(allowedUserTypesArray, userType);

  useEffect(() => {
    window.addEventListener('vite:preloadError', () => {
      window?.reload?.();
    });
  }, []);

  return (
    <Routes>
      <Route
        path="/consults"
        element={getRestriction({ isAllowed: getAllowedUserTypes([PATIENT]), component: <Consults /> })}
      />
      <Route
        path="/pharmacy-stock"
        element={getRestriction({ isAllowed: getAllowedUserTypes([PHARMACIST]), component: <PharmacyStock /> })}
      />
      <Route
        path="/pharmacy/:pharmacyId/stock-levels"
        element={getRestriction({ isAllowed: isCandor(userType), component: <PharmacyStockPreviewLegacy /> })}
      />

      <Route path="/pharmacies">
        <Route
          index
          element={getRestriction({
            isAllowed: !getAllowedUserTypes([PHARMACIST]),
            component: <PharmaciesPage />,
          })}
        />
        <Route
          path=":pharmacyId"
          element={getRestriction({
            isAllowed: !getAllowedUserTypes([PATIENT]),
            component: <PharmacyDetailsLayout />,
          })}
        >
          <Route index element={<PharmacyDetailsPage />} />
          <Route
            path="stock-levels"
            element={getRestriction({ isAllowed: isCandor(userType), component: <PharmacyStockPreview /> })}
          />
          <Route path="orders">
            <Route index element={<PharmacyOrdersPage />} />
            <Route key="order-details" path=":orderId" element={<PharmacyOrderDetailsPage />} />
          </Route>
        </Route>
      </Route>
      {/* Redirect to pharmacies page - backwards compatibility */}
      <Route path="/pharmacy-directory" element={<Navigate to="/pharmacies" />} />

      <Route path="/checkup-form" element={<CheckupForm />} />
      <Route path="/upload-supporting-documents" element={<UploadSupportingDocuments />} />
      <Route path="/agreement" element={<SignAgreement />} />
      <Route path="/medicinal-cannabis-form" element={<MedicinalCannabisForm />} />
      <Route path="/hair-loss-form" element={<HairLossForm />} />
      <Route path="/erectile-dysfunction-form" element={<ErectileDysfunctionForm />} />
      <Route path="/contraceptive-pill-form" element={<ContraceptivePillForm />} />
      <Route path="/sleep-form" element={<SleepForm />} />
      <Route path="/cold-sores-form" element={<ColdSoreForm />} />
      <Route path="/herpes-form" element={<HerpesForm />} />
      <Route path="/acne-form" element={<AcneForm />} />
      <Route path="/allergies-form" element={<AllergiesForm />} />
      <Route path="/ageing-form" element={<AgeingForm />} />
      <Route path="/weight-loss-form" element={<WeightLossForm />} />
      <Route path="/skincare-form" element={<SkincareForm />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/purchase" element={<Prescriptions />} />
      <Route path="/account-details" element={<AccountDetails />} />
      <Route path="/catalogue" element={<Catalogue />} />
      <Route path="/medical-info" element={<MedicalInfo />} />
      <Route path="/messages" element={<Messages />} />
      <Route path="/messages/:id" element={<MessagesContainer />} />
      <Route path="/messages/list/pharmacists" element={<ChatListPresentation />} />
      <Route path="/messages/list/doctors" element={<ChatListPresentation />} />
      <Route path="/messages/list/patients" element={<ChatListPresentation />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/consult-payment-success" element={<ConsultPaymentSuccess />} />
      <Route path="/booking-payment-success" element={<BookingPaymentSuccess />} />
      <Route path="/script-payment-success" element={<ScriptPaymentSuccess />} />

      <Route
        element={
          <PatientsProvider>
            <Outlet />
          </PatientsProvider>
        }
      >
        <Route path="/patients" element={<PatientsPage />} />
        <Route path="/patients/:id" element={<PatientPage />} />
      </Route>
      <Route path="/assigned-patients" element={<AssignedPatients />} />
      <Route path="/payment-container" element={<PaymentContainer />} />
      <Route path="/admin-control" element={<AdminControl />} />

      <Route element={<PharmacyLayout />}>
        <Route path="orders" element={<PharmacyOrdersPage key="current" tab="current" />} />
        <Route path="/order-history" element={<PharmacyOrdersPage key="historic" tab="historic" />} />
      </Route>
      <Route path="/email-preferences" element={<EmailPreferences />} />
      <Route path="/error-fallback" element={<ErrorPage />} />
      <Route path="/auth-landing-page" element={<AuthLandingPageContainer />} />
      <Route path="/auth-landing-page-success" element={<AuthLandingPageSuccess />} />
      <Route path="/auth-landing-page-error" element={<AuthLandingPageError />} />
      <Route path="/reset-password-input" element={<ResetPasswordInput />} />
      <Route path="/reset-password-link-error" element={<ResetPasswordLinkError />} />
      <Route path="/purchase-history" element={<PurchaseHistory />} />
      <Route path="/pending-account" element={<PendingAccount />} />
      <Route
        path="/appointments"
        element={getRestriction({ isAllowed: getAllowedUserTypes([PATIENT]), component: <AppointmentContainer /> })}
      />
      <Route path="/tga" element={<TgaDetailsContainer />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
};

export default AuthenticatedRouter;
