import { httpsCallable } from 'firebase/functions';
import { ref, getDownloadURL } from 'firebase/storage';
import { captureException as sentryCaptureException } from '@sentry/react';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';

import { db, storage, functions } from '../../../../firebase-config';
import { getCatalogueId, SUPPORT_ID } from '../../../../utils/constants';

/**
 * @typedef {Object} Shipment
 * @property {string} user - User identifier
 * @property {string} name - Shipment name
 * @property {Array<any>} scriptsArray - Array of scripts
 */

/**
 * @typedef {Object} ScriptDataParams
 * @property {string|null} eScriptToken - The electronic script token, or null if not available
 * @property {number} index - The index of the script
 * @property {string|null} paperScriptURL - The paper script, or null if eScriptToken is provided
 * @property {string|null} tgaURL - The TGA URL, or null if not available
 */

/**
 * @typedef {Object} ScriptData
 * @property {number} index - The incremented index of the script
 * @property {string|null} eScriptToken - The electronic script token
 * @property {string|null} paperScript - The paper script, or null if eScriptToken is provided
 * @property {string|null} tga - The TGA URL
 */

/**
 * @typedef {Object} ScriptUserData
 * @property {string} prescriber - Prescriber's email
 * @property {string} tgaFile - TGA file type ('ap' or other)
 */

/**
 * @typedef {Object} HandleTgaFileParams
 * @property {ScriptData} scriptData - Script related data
 * @property {ScriptUserData} scriptUserData - User data related to script
 * @property {string} productId - ID of the product
 * @property {Object} initialResult - Initial result object
 * @property {Array<any>} catalogueList - List of catalogue items
 * @property {Shipment} shipment - Shipment information
 * @property {(message: string) => void} snackbar - Snackbar notification function
 */

const transferStorageFiles = httpsCallable(functions, 'transferStorageFiles');

const retryTgaTransfer = async ({ scriptData, productId, initialResult, shipment, snackbar }) => {
  try {
    const oldTgaPath = `patient_documents/${shipment.user}/tga/${productId}`;
    const newTgaPath = `pharmacy/${SUPPORT_ID}/tga/${shipment.user}/${productId}`;
    await transferStorageFiles({ oldPath: oldTgaPath, newPath: newTgaPath, action: 'copy' });
    const oldTgaUrl = await getDownloadURL(ref(storage, oldTgaPath));
    const updatedScriptData = { ...scriptData, tga: oldTgaUrl };

    return {
      scripts: [...initialResult.scripts, updatedScriptData],
    };
  } catch (error) {
    sentryCaptureException(error, {
      extra: { productId, shipment, failedAction: '2nd attempt to transferStorageFiles' },
    });
    snackbar(`An error was encountered during the retrieval of the file from the pharmacy. Error: ${error}`);
    return initialResult;
  }
};

// Handle standard TGA file retrieval process
const handleStandardTgaFile = async ({ scriptData, productId, initialResult, shipment, snackbar }) => {
  try {
    const tgaPath = `pharmacy/${SUPPORT_ID}/tga/${shipment.user}/${productId}`;
    const tgaUrl = await getDownloadURL(ref(storage, tgaPath));
    const updatedScriptData = { ...scriptData, tga: tgaUrl };

    return {
      scripts: [...initialResult.scripts, updatedScriptData],
    };
  } catch (error) {
    sentryCaptureException(error, {
      level: 'warning',
      extra: {
        issueIn: 'handleStandardTgaFile',
        productId,
        shipment,
      },
    });
    snackbar('Failed to transfer file from patient to pharmacy');
    return retryTgaTransfer({ scriptData, productId, initialResult, shipment, snackbar });
  }
};

const handleApFile = async ({ scriptData, prescriberEmail, productId, catalogueList, initialResult }) => {
  const doctorSnapShot = await getDocs(
    query(collection(db, 'doctors'), where('email', '==', prescriberEmail), limit(1)),
  );
  const doctorId = doctorSnapShot.docs[0]?.id;
  const { apRef } = catalogueList[getCatalogueId(productId)] || {};

  if (!doctorId || !apRef) {
    throw new Error('No doctor id or apRef found');
  }

  const tgaPath = `doctor/${doctorId}/prescriber_authorities/${apRef}.pdf`;
  const tgaUrl = await getDownloadURL(ref(storage, tgaPath));
  const updatedScriptData = { ...scriptData, tga: tgaUrl };

  return {
    scripts: [...initialResult.scripts, updatedScriptData],
  };
};

/**
 * Handles TGA file retrieval for medicinal cannabis forms
 * @param {HandleTgaFileParams} params - Parameters for handling TGA file
 * @returns {Promise<Object>} TGA file data
 */
export const handleTgaFile = async ({
  scriptData,
  scriptUserData,
  productId,
  initialResult,
  catalogueList,
  shipment,
  snackbar,
}) => {
  const { prescriber: prescriberEmail = '', tgaFile } = scriptUserData;
  try {
    if (tgaFile !== 'ap') {
      return await handleStandardTgaFile({ scriptData, productId, initialResult, shipment, snackbar });
    }

    return await handleApFile({ scriptData, prescriberEmail, productId, catalogueList, initialResult });
  } catch (error) {
    sentryCaptureException(error, {
      extra: { issueIn: 'handleTgaFile', productId, shipment, scriptUserData, prescriberEmail },
    });
    snackbar(`An error was encountered while retrieving the TGA file. Product ID: ${productId}`);

    return {};
  }
};
