import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Container, Typography, Link, Button, TextField } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PREFIX = 'EmailAuthPresentation';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  logo: `${PREFIX}-logo`,
  paper: `${PREFIX}-paper`,
  link: `${PREFIX}-link`,
  gridContainer: `${PREFIX}-gridContainer`,
  loginButton: `${PREFIX}-loginButton`,
  switchText: `${PREFIX}-switchText`,
  boldText: `${PREFIX}-boldText`,
  headingText: `${PREFIX}-headingText`,
  divider: `${PREFIX}-divider`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
};

const Root = styled('div')(() => ({
  [`& .${classes.input}`]: {
    display: 'flex',
    margin: '20px auto',
    width: 260,
  },

  [`& .${classes.button}`]: {
    margin: '40px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.logo}`]: {
    margin: '20px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.paper}`]: {
    padding: '40px 20px',
    width: '90%',
    maxWidth: '400px',
  },

  [`& .${classes.link}`]: {
    align: 'center',
    margin: 'auto',
  },

  [`& .${classes.gridContainer}`]: {
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '75vh',
  },

  [`& .${classes.loginButton}`]: {
    display: 'flex',
    margin: '15px auto',
    width: '260px',
  },

  [`& .${classes.switchText}`]: {
    fontWeight: 'bold',
    marginTop: 30,
  },

  [`& .${classes.boldText}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.headingText}`]: {
    marginBottom: 40,
  },

  [`& .${classes.divider}`]: {
    margin: '30px 0px',
  },

  [`& .${classes.cancelButton}`]: {
    margin: '-30px -20px',
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
    height: 20,
  },
}));

const EmailAuthPresentation = (props) => {
  const {
    handleModalClose,
    toggleHasAccount,
    setEmail,
    setPassword,
    handleLogin,
    handleSignUp,
    hasAccount,
    emailError,
    passwordError,
    handleForgottenPasswordButton,
    formContext,
    quickLogin,
    isSignupLoading,
  } = props;

  return (
    <Root>
      <Container maxWidth="md">
        <Grid container className={classes.gridContainer} spacing={0}>
          <Paper className={classes.paper}>
            {formContext && quickLogin && (
              <Grid className={classes.cancelButtonHolder}>
                <Button className={classes.cancelButton} onClick={handleModalClose}>
                  <CancelRoundedIcon color="primary" />
                </Button>
              </Grid>
            )}
            <form>
              {hasAccount ? (
                <>
                  <div>
                    <Typography className={classes.headingText} variant="h5" align="center">
                      Sign In
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Typography className={classes.headingText} variant="h5" align="center">
                      Create Account
                    </Typography>
                  </div>
                </>
              )}
              <div>
                <TextField
                  size="small"
                  type="text"
                  label="Email"
                  variant="outlined"
                  className={classes.input}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!(emailError === '')}
                  helperText={emailError}
                />
              </div>
              <div>
                <TextField
                  size="small"
                  type="password"
                  label="Password"
                  variant="outlined"
                  className={classes.input}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!(passwordError === '')}
                  helperText={passwordError}
                />
              </div>
              <div>
                {hasAccount ? (
                  <Grid sx={{ textAlign: 'center' }}>
                    <Link underline="none" href="#" onClick={handleForgottenPasswordButton} className={classes.link}>
                      Forgotten Password
                    </Link>
                    <br />
                    <Button className={classes.button} onClick={handleLogin} variant="contained" color="primary">
                      Sign in
                    </Button>
                    <Typography className={classes.switchText} variant="body1" align="center">
                      <Link underline="none" href="#" onClick={toggleHasAccount}>
                        I don&apos;t have an account
                      </Link>
                    </Typography>
                    <br />
                  </Grid>
                ) : (
                  <Grid>
                    <Button
                      className={classes.button}
                      onClick={handleSignUp}
                      variant="contained"
                      color="primary"
                      disabled={isSignupLoading}
                    >
                      {isSignupLoading ? 'Signing up...' : 'Sign up'}
                    </Button>
                    <Typography variant="caption" align="center" display="block">
                      By signing up you agree to the Candor Medical <br />
                      <Link underline="none" target="blank" href="https://www.candor.org/terms-and-conditions">
                        Terms & conditions
                      </Link>{' '}
                      and{' '}
                      <Link underline="none" target="blank" href="https://www.candor.org/privacy-policy">
                        Privacy Policy
                      </Link>
                    </Typography>
                    {(!formContext || quickLogin) && (
                      <Typography className={classes.switchText} variant="body1" align="center">
                        <Link underline="none" href="#" onClick={toggleHasAccount}>
                          I already have an account
                        </Link>
                      </Typography>
                    )}
                    <br />
                  </Grid>
                )}
              </div>
            </form>
          </Paper>
        </Grid>
      </Container>
    </Root>
  );
};

export default EmailAuthPresentation;
