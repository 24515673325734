import React from 'react';

/**
 * @typedef {Object} OrderDetailsContextValue
 * @property {ExtendedOrder} order
 * @property {boolean} loading
 * @property {string | null} error
 * @property {({isOpen, content, attemptToAttachToDialogue}: {isOpen: boolean, content: React.ReactNode, attemptToAttachToDialogue?: boolean}) => void} handleDialogue
 * @property {() => void} refreshOrder
 */
export const OrderDetailsContext = React.createContext({
  order: /** @type {ExtendedOrder | null} */ (null),
  loading: false,
  error: /** @type {string | null} */ (null),
  handleDialogue: (
    // eslint-disable-next-line no-unused-vars
    /** @type {{ isOpen: boolean, content: React.ReactNode, attemptToAttachToDialogue?: boolean }} */ props,
  ) => {},
  refreshOrder: () => {},
});
