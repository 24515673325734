import React from 'react';
import { useParams } from 'react-router-dom';
import { parseAsString, parseAsStringEnum, useQueryState } from 'nuqs';
import { collection, getDocs, query, where } from '@firebase/firestore';
import { CircularProgress, List, ListItem, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material';

import { db } from '../../../firebase-config';
import { useDocument } from '../../../hooks/useDocument';
import { capitalizeFirstLetterOfEachWord, formatCamelCase } from '../../../utils/constants';
import { usePharmacyContext } from '../hooks';
import { PHARMACY_TYPES } from '../constants';

/**
 * @typedef {Object} Pharmacy
 * @property {string[]} ordersAccepted - The orders accepted by the pharmacy.
 */

/**
 * @typedef {Object} CatalogueItem
 * @property {string} id - The catalogue item ID.
 * @property {string} name - The catalogue item name.
 * @property {string} item - The catalogue item item.
 * @property {string} form - The catalogue item form.
 * @property {number} stock - The catalogue item stock.
 */

const DEFAULT_STOCK = { inStock: [], outOfStock: [] };

const PREFIX = 'PharmacyStockPreview';

const classes = {
  box: `${PREFIX}-box`,
  listItem: `${PREFIX}-listItem`,
  circularHolder: `${PREFIX}-circularHolder`,
};

/**
 * @param {Object} props
 * @param {string} props.searchInput - The search input.
 * @param {CatalogueItem[]} props.catalogueItems - The catalogue items.
 * @param {string} props.healthCategory - The health category.
 * @returns {CatalogueItem[]} - The filtered catalogue items.
 */
const filterBySearchInput = ({ searchInput = '', catalogueItems = [], healthCategory }) =>
  catalogueItems
    .filter(
      ({ id, name, item, form }) =>
        `${name} ${item} ${id}`.toLowerCase().includes(searchInput.toLowerCase()) && form === healthCategory,
    )
    .sort((a, b) => a.name.localeCompare(b.name));

/**
 * @param {Object} props
 * @param {React.ReactNode} props.heading - The heading.
 * @param {boolean} props.shouldDisplay - Whether the heading should be displayed.
 * @returns {React.ReactNode} - The heading.
 */
const StockHeading = ({ heading, shouldDisplay }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <Typography variant="h6" textAlign="center" mt={2} mb={1}>
      {heading}
    </Typography>
  );
};

/**
 * @param {Object} props
 * @param {string} props.storeType - The store type.
 * @param {number} props.stock - The stock.
 */
const CatalogueItemSecondaryAction = ({ storeType, stock }) => {
  if (storeType === PHARMACY_TYPES.SUPPLIER) {
    return null;
  }

  return (
    <Typography color={stock > 0 ? 'primary' : 'secondary'} fontWeight={500}>
      {stock}
    </Typography>
  );
};

/**
 * @param {Object} props
 * @param {CatalogueItem} props.catalogueItem - The catalogue item.
 * @param {string} props.pharmacyId - The pharmacy ID.
 * @param {string} props.storeType - The store type.
 * @returns {React.ReactNode} - The catalogue item.
 */
const CatalogueItem = ({ catalogueItem, pharmacyId, storeType }) => {
  const label = `${catalogueItem.name} - ${catalogueItem.item}`;
  const stock = catalogueItem.stock[pharmacyId];

  return (
    <ListItem
      divider
      className={classes.listItem}
      secondaryAction={<CatalogueItemSecondaryAction storeType={storeType} stock={stock} />}
    >
      <Typography variant="body1">{label}</Typography>
    </ListItem>
  );
};

/**
 * @param {Object} props
 * @param {CatalogueItem} props.catalogueItem - The catalogue item.
 * @returns {string} - The catalogue item key.
 */
const getCatalogueItemKey = ({ catalogueItem }) => {
  const stockHash = Object.keys(catalogueItem.stock ?? {}).join('-');
  return `${catalogueItem.id}:${stockHash}`;
};

/**
 * @param {Object} props
 * @param {Object} props.filteredCatalogue - The filtered catalogue.
 * @param {string} props.pharmacyId - The pharmacy ID.
 * @param {string} props.stockCategory - The stock category.
 * @param {string} props.headingLabel - The heading label.
 * @param {string} props.storeType - The store type.
 * @returns {React.ReactNode} - The catalogue item list.
 */
const CatalogueItemList = ({ filteredCatalogue, pharmacyId, stockCategory, headingLabel, storeType }) => {
  if (!filteredCatalogue[stockCategory].length) {
    return <Typography textAlign="center">No Results Found</Typography>;
  }

  return (
    <div>
      <StockHeading heading={headingLabel} shouldDisplay={filteredCatalogue[stockCategory].length > 0} />
      <List dense>
        {filteredCatalogue[stockCategory].map((catalogueItem) => (
          <CatalogueItem
            catalogueItem={catalogueItem}
            key={getCatalogueItemKey({ catalogueItem })}
            pharmacyId={pharmacyId}
            storeType={storeType}
          />
        ))}
      </List>
    </div>
  );
};

/**
 * @param {Object} props
 * @param {boolean} props.shouldDisplay - Whether the no items available message should be displayed.
 * @returns {React.ReactNode} - The no items available message.
 */
const NoItemsAvailable = ({ shouldDisplay }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <Typography variant="body1" mt={2} textAlign="center">
      No Items Available For This Pharmacy
    </Typography>
  );
};

/**
 * @param {Object} props
 * @param {boolean} props.hasLoaded - Whether the catalogue has loaded.
 * @param {string} props.pharmacyId - The pharmacy ID.
 * @param {string} props.storeType - The store type.
 * @param {string} props.stockCategory - The stock category.
 * @param {Object} props.filteredCatalogue - The filtered catalogue.
 * @returns {React.ReactNode} - The stock display.
 */
const StockDisplay = ({ hasLoaded, pharmacyId, stockCategory, filteredCatalogue, storeType }) => {
  if (!hasLoaded) {
    return (
      <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
        <CircularProgress />
        <Typography>Loading Catalogue</Typography>
      </Stack>
    );
  }

  return (
    <div>
      <CatalogueItemList
        filteredCatalogue={filteredCatalogue}
        pharmacyId={pharmacyId}
        storeType={storeType}
        stockCategory={stockCategory}
        headingLabel={capitalizeFirstLetterOfEachWord(formatCamelCase(stockCategory))}
      />
      <NoItemsAvailable
        shouldDisplay={filteredCatalogue.inStock.length === 0 && filteredCatalogue.outOfStock.length === 0}
      />
    </div>
  );
};

/**
 * This is the child component for the pharmacy stock preview page.
 * @param {Object} props - The props for the pharmacy stock preview page.
 * @param {Pharmacy} props.pharmacy - The pharmacy to display.
 * @returns {React.ReactNode} - The pharmacy stock preview page.
 */
const PharmacyStockPreviewChild = ({ pharmacy }) => {
  const { pharmacyId } = useParams();

  const [healthCategory, setHealthCategory] = useQueryState(
    'healthCategory',
    parseAsStringEnum(pharmacy.ordersAccepted)
      .withOptions({ clearOnDefault: false })
      .withDefault(pharmacy.ordersAccepted[0]),
  );
  const [searchInput, setSearchInput] = useQueryState(
    'search',
    parseAsString.withOptions({ clearOnDefault: false }).withDefault(''),
  );
  const [stockCategory, setStockCategory] = useQueryState(
    'stockCategory',
    parseAsStringEnum(['inStock', 'outOfStock']).withOptions({ clearOnDefault: false }).withDefault('inStock'),
  );

  const [, pharmacyDocHasLoaded] = useDocument(`/orders/${pharmacyId}`);

  const [retrievedCatalogue, setRetrievedCatalogue] = React.useState(DEFAULT_STOCK);
  const [filteredCatalogue, setFilteredCatalogue] = React.useState(DEFAULT_STOCK);
  const [hasLoaded, setHasLoaded] = React.useState(false);

  React.useEffect(() => {
    setHasLoaded(false);
    const processSearch = () => {
      const { inStock, outOfStock } = retrievedCatalogue;
      if (inStock.length === 0 && outOfStock.length === 0) {
        return;
      }

      setFilteredCatalogue({
        inStock: filterBySearchInput({ searchInput, healthCategory, catalogueItems: inStock }),
        outOfStock: filterBySearchInput({ searchInput, healthCategory, catalogueItems: outOfStock }),
      });

      setHasLoaded(true);
    };
    processSearch();
  }, [healthCategory, retrievedCatalogue, searchInput]);

  React.useEffect(() => {
    setHasLoaded(false);

    const fetchCatalogue = async () => {
      const tempInStockCatalogue = (
        await getDocs(query(collection(db, 'catalogue'), where(`stock.${pharmacyId}`, '>', 0)))
      ).docs.map((doc) => doc.data());

      const tempOutOfStockCatalogue = (
        await getDocs(query(collection(db, 'catalogue'), where(`stock.${pharmacyId}`, '<=', 0)))
      ).docs.map((doc) => doc.data());

      setRetrievedCatalogue({ inStock: tempInStockCatalogue, outOfStock: tempOutOfStockCatalogue });
    };

    if (pharmacyDocHasLoaded) {
      fetchCatalogue();
    }
  }, [healthCategory, pharmacyDocHasLoaded, pharmacyId]);

  if (!pharmacy) {
    return null;
  }

  return (
    <Paper sx={{ padding: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Typography variant="h5">{capitalizeFirstLetterOfEachWord(healthCategory)}</Typography>

        <Select
          size="small"
          value={healthCategory}
          onChange={(e) => setHealthCategory(e.target.value)}
          sx={{ textTransform: 'capitalize' }}
        >
          {pharmacy.ordersAccepted.map((order) => (
            <MenuItem key={order} value={order} sx={{ textTransform: 'capitalize' }}>
              {order}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Stack direction="row" spacing={2}>
        <TextField
          placeholder="Search Catalogue"
          size="small"
          fullWidth
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Select size="small" value={stockCategory} onChange={(e) => setStockCategory(e.target.value)}>
          <MenuItem value="inStock">In Stock</MenuItem>
          <MenuItem value="outOfStock">Out of Stock</MenuItem>
        </Select>
      </Stack>

      <StockDisplay
        hasLoaded={hasLoaded}
        pharmacyId={pharmacy.id}
        stockCategory={stockCategory}
        filteredCatalogue={filteredCatalogue}
        storeType={pharmacy.type}
      />
    </Paper>
  );
};

export const PharmacyStockPreview = () => {
  const { pharmacy } = usePharmacyContext();

  if (!pharmacy) {
    return null;
  }

  return <PharmacyStockPreviewChild pharmacy={pharmacy} />;
};
