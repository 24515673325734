import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Typography, Button, CircularProgress, Paper, TextField } from '@mui/material';
import { FileUploadRounded, CancelRounded as CancelRoundedIcon } from '@mui/icons-material';

import { FILE_UPLOAD_ACCEPTED_FILES } from '../../../../utils/constants';

const PREFIX = 'UploadPatientDocument';

const classes = {
  paper: `${PREFIX}-paper`,
  fullWidthGrid: `${PREFIX}-fullWidthGrid`,
  gridContainer: `${PREFIX}-gridContainer`,
  input: `${PREFIX}-input`,
  paperHolder: `${PREFIX}-paperHolder`,
  fileNameHolder: `${PREFIX}-fileNameHolder`,
  heading: `${PREFIX}-heading`,
  changeFileButton: `${PREFIX}-changeFileButton`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  fileName: `${PREFIX}-fileName`,
  textField: `${PREFIX}-textField`,
  circular: `${PREFIX}-circular`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
  noRender: `${PREFIX}-noRender`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: 10,

  [`& .${classes.fullWidthGrid}`]: {
    margin: 10,
    position: 'relative',
    width: '100%',
  },

  [`& .${classes.input}`]: {
    display: 'none',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'auto',
    justifyContent: 'center',
  },

  [`& .${classes.fileNameHolder}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
    justifyContent: 'center',
  },

  [`& .${classes.heading}`]: {
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '50px',
    marginTop: '30px',
  },

  [`& .${classes.changeFileButton}`]: {
    margin: '20px 0px 0px 0px',
  },

  [`& .${classes.buttonIcon}`]: {
    margin: '0px 0px 2px 5px',
  },

  [`& .${classes.fileName}`]: {
    margin: '20px 0px 10px 0px',
  },

  [`& .${classes.textField}`]: {
    marginBottom: '20px',
    width: '80%',
  },

  [`& .${classes.circular}`]: {
    margin: '50px 0px 50px 0px',
  },

  [`& .${classes.cancelButton}`]: {
    margin: '-15px -20px',
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.cancelButtonHolder}`]: {
    height: 20,
  },

  [`& .${classes.noRender}`]: {
    display: 'none',
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    gap: 10,
    marginBottom: 20,
  },
}));

const getFileNameWithoutExtension = (fileNameWithoutExtension) => fileNameWithoutExtension?.trim() || '';

const getFileExtension = (fileName) => fileName?.substr(fileName?.lastIndexOf('.')) || '';

const getHelperText = ({ fileFound, trimmedFileName }) => {
  if (fileFound) {
    return 'Filename already exists';
  }
  if (!trimmedFileName) {
    return "Please don't leave it blank";
  }
  return ' ';
};

const getIsButtonDisabled = ({ fileFound, trimmedFileName, description, isError }) =>
  isError || fileFound || !trimmedFileName || description.trim() === '';

const UploadPatientDocumentPresentation = ({
  fileName,
  fileNameWithoutExtension,
  description,
  setDescription,
  fileFound,
  isLoading,
  handleClick,
  fileOnChange,
  fileNameOnChange,
  setCancelUpload,
  setExitUpload,
  exitPage,
  isError,
}) => {
  const trimmedFileName = getFileNameWithoutExtension(fileNameWithoutExtension);
  const fileExtension = getFileExtension(fileName);
  const helperText = getHelperText({ fileFound, trimmedFileName });
  const isButtonDisabled = getIsButtonDisabled({ fileFound, trimmedFileName, description, isError });

  return (
    <StyledPaper elevation={0}>
      <Grid size={{ xs: 12 }} className={classes.fullWidthGrid}>
        <Typography variant="h5" className={classes.heading}>
          Upload Document
        </Typography>
        <Grid size={{ xs: 12 }} className={classes.paperHolder}>
          {fileName && (
            <>
              <Grid size={{ xs: 12 }} className={classes.fileNameHolder}>
                <TextField
                  error={fileFound || !trimmedFileName}
                  helperText={helperText}
                  id="standard-required"
                  label="Filename"
                  value={fileNameWithoutExtension}
                  variant="standard"
                  onChange={({ target }) => fileNameOnChange(`${target.value}${fileExtension}`, target.value)}
                />
                <Typography variant="h6" align="center" className={classes.fileName}>
                  {fileExtension}
                </Typography>
              </Grid>
              <TextField
                className={classes.textField}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                placeholder="A description of the document is required"
                value={description}
                onChange={({ target }) => setDescription(target.value)}
                error={!description.trim()}
                helperText={!description.trim() ? 'Please provide a description' : ''}
              />
            </>
          )}
          <input
            accept={FILE_UPLOAD_ACCEPTED_FILES}
            className={classes.input}
            id="uploadDocument"
            type="file"
            onChange={fileOnChange}
          />
          <Grid className={classes.buttonContainer}>
            <label htmlFor="uploadDocument">
              <Button
                className={classes.changeFileButton}
                startIcon={
                  !fileName && <FileUploadRounded className={classes.buttonIcon} color="primary" fontSize="large" />
                }
                variant="outlined"
                component="span"
                color="primary"
                disabled={isLoading}
              >
                {!fileName ? 'Select Document' : 'Change'}
              </Button>
            </label>
            <Button
              disabled={isLoading}
              className={fileName ? classes.changeFileButton : classes.noRender}
              variant="outlined"
              component="span"
              color="primary"
              onClick={() => setCancelUpload(true)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
        <CircularProgress color="primary" className={!isLoading || !fileName ? classes.noRender : ''} />
        <Button
          disabled={isButtonDisabled}
          variant="contained"
          onClick={handleClick}
          className={isLoading || !fileName ? classes.noRender : ''}
        >
          Finish
        </Button>
        {isError && (
          <Typography color="secondary" variant="body1">
            Upload failed. Please try again.
          </Typography>
        )}
        <Grid className={classes.cancelButtonHolder}>
          <Button className={classes.cancelButton} onClick={() => (fileName ? setExitUpload(true) : exitPage())}>
            <CancelRoundedIcon color="primary" />
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

UploadPatientDocumentPresentation.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileNameWithoutExtension: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  fileFound: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  fileOnChange: PropTypes.func.isRequired,
  fileNameOnChange: PropTypes.func.isRequired,
  setExitUpload: PropTypes.func.isRequired,
  setCancelUpload: PropTypes.func.isRequired,
  exitPage: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
};

export default UploadPatientDocumentPresentation;
