import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';

import { CustomizableSnackbar } from '../../../components/layout';
import { PharmacyContext } from '../contexts';
import { usePharmacy } from '../hooks/usePharmacy';
import { useCatalogue } from '../hooks/useCatalogue';

/**
 * @param {Object} props
 * @param {string} [props.pharmacyId] - The pharmacy ID
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 */
export const PharmacyProvider = ({ pharmacyId: pharmacyIdFromProps = '', children }) => {
  const navigate = useNavigate();
  const { pharmacyId = pharmacyIdFromProps } = useParams();
  const { pharmacy, loading, error, refreshPharmacy } = usePharmacy(pharmacyId);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState(/** @type {React.ReactNode} */ (null));
  const [message, setMessage] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleGoBack = () => {
    navigate('/pharmacies');
  };

  const snackbar = React.useCallback(
    /**
     * @param {string} msg
     */
    (msg) => {
      setMessage(msg);
      setSnackbarOpen(true);
    },
    [],
  );

  const {
    data: catalogue,
    loading: catalogueLoading,
    error: catalogueError,
  } = useCatalogue({
    orderReceiverId: pharmacyId,
  });

  /**
   * @param {{isOpen: boolean, content: React.ReactNode}} props
   */
  const handleDialogue = React.useCallback(({ isOpen, content }) => {
    setIsDialogOpen(isOpen);
    setDialogContent(content);
  }, []);

  const value = React.useMemo(
    () => ({
      pharmacy,
      loading,
      error,
      refreshPharmacy,
      catalogue,
      catalogueLoading,
      catalogueError,
      handleDialogue,
      snackbar,
    }),
    [pharmacy, loading, error, refreshPharmacy, catalogue, catalogueLoading, catalogueError, handleDialogue, snackbar],
  );

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Typography>Error loading pharmacy</Typography>
      </Stack>
    );
  }

  if (!pharmacy) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%" gap={2}>
        <Typography>Pharmacy not found</Typography>
        <Button variant="text" onClick={handleGoBack}>
          Go to pharmacy directory
        </Button>
      </Stack>
    );
  }

  return (
    <PharmacyContext.Provider value={value}>
      {children}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogContent sx={{ width: '100%' }}>{dialogContent}</DialogContent>
      </Dialog>
      <CustomizableSnackbar message={message} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
    </PharmacyContext.Provider>
  );
};
