import React, { useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Badge, Drawer, ListItemText, MenuItem } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { Link, useLocation, useNavigate, NavLink } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { db } from '../../firebase-config';
import { AuthContext } from '../../auth-context';
import { FORMS, getValidForms, USER_TYPES, allowedUserType } from '../../utils/constants';

const { DOCTOR, NURSE, PHARMACIST, DIRECTOR, PATIENT, SUPPORT, SUPPLIER } = USER_TYPES;
const ACCOUNT_INFO = 'account-info';
const SECURITY = 'security';
const DOCUMENTS = 'documents';

const PREFIX = 'Sidebar';

const classes = {
  link: `${PREFIX}-link`,
  drawer: `${PREFIX}-drawer`,
  browser: `${PREFIX}-browser`,
  native: `${PREFIX}-native`,
  selected: `${PREFIX}-selected`,
  accordion: `${PREFIX}-accordion`,
};

const StyledDrawer = styled(Drawer)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
    padding: '15px 32px 15px 32px',
  },

  [`& .${classes.drawer}`]: {
    '@media (max-width: 1000px)': {
      width: 210,
    },
    width: 200,
  },

  [`& .${classes.browser}`]: {
    '@media (max-width: 1000px)': {
      display: 'none',
    },
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
  },

  [`& .${classes.native}`]: {
    '@media (min-width: 1001px)': {
      display: 'none',
    },
  },

  [`& .${classes.selected}`]: {
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
    backgroundColor: '#f5f5f5',
    padding: '15px 32px 15px 32px',
  },

  [`& .${classes.accordion}`]: {
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    padding: '0px 20px 0px 0px',
    margin: 0,
    height: 54,
  },
}));

/**
 * @param {object} props
 * @param {string} props.to - The URL to navigate to
 * @param {() => void} props.onClose - The function to call when the link is clicked
 * @param {React.ReactNode} props.children - The children to render inside the link
 * @returns {React.ReactNode} The NavigationLink component
 */
const NavigationLink = ({ to, onClose, children }) => (
  <NavLink to={to} style={{ textDecoration: 'none', color: '#0E6D81' }}>
    {({ isActive }) => (
      <MenuItem onClick={onClose} className={isActive ? classes.selected : classes.link}>
        {children}
      </MenuItem>
    )}
  </NavLink>
);

const Sidebar = ({ open, onClose, handleLogoutClose, from }) => {
  const isMounted = useRef(false);

  const path = useLocation();
  const navigate = useNavigate();
  const {
    user,
    userType,
    notifications,
    medicalPage,
    setAccountDetailsPage,
    accountDetailsPage,
    setMedicalPage,
    cataloguePage,
    setCataloguePage,
    setCatalogues,
    catalogues,
  } = useContext(AuthContext);
  const messagesNotifCount = Object.values(notifications ?? {}).reduce((acc, val) => acc + val, 0);

  const getAllowedUserTypes = React.useCallback(
    (allowedUserTypesArray) => allowedUserType(allowedUserTypesArray, userType),
    [userType],
  );

  useEffect(() => {
    isMounted.current = true;
    async function getData() {
      const docSnap = getAllowedUserTypes([SUPPLIER, PHARMACIST])
        ? (await getDoc(doc(db, 'orders', user.uid))).data()
        : (await getDoc(doc(db, 'patients', user.uid))).data();

      // get the treatments for the user
      // fixed the old code ternary error
      let tempTreatments = [];
      if (getAllowedUserTypes([PHARMACIST])) {
        tempTreatments = docSnap.ordersAccepted;
      } else if (getAllowedUserTypes([SUPPLIER])) {
        tempTreatments = getValidForms(docSnap.ordersAccepted);
      } else if (getAllowedUserTypes([DOCTOR, NURSE, DIRECTOR, SUPPORT])) {
        tempTreatments = [...FORMS];
      } else {
        // this is for patient userType
        tempTreatments = docSnap.catalogueItems
          ? Object.keys(docSnap.catalogueItems).filter((item) => docSnap.catalogueItems[item].length > 0)
          : [];
      }

      if (isMounted.current && tempTreatments?.length > 0) {
        setCatalogues(tempTreatments.sort());
        setCataloguePage(tempTreatments[0]);
      }
    }
    if (isMounted.current && user) {
      getData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [user, setCatalogues, setCataloguePage, getAllowedUserTypes]);

  const onClickSubMenu = (menu) => {
    if (menu === ACCOUNT_INFO || menu === SECURITY || menu === DOCUMENTS) {
      setAccountDetailsPage(menu);
      navigate('/account-details');
    } else if (menu === 'medical-info' || menu === 'conditions' || menu === 'concession cards') {
      setMedicalPage(menu);
      navigate('/medical-info');
    } else if (FORMS.includes(menu)) {
      setCataloguePage(menu);
      navigate('/catalogue');
    }
    if (from === 'nav') {
      onClose();
    }
  };

  const isTGAFilesLinkVisible = getAllowedUserTypes([NURSE, SUPPORT, DOCTOR, DIRECTOR, PHARMACIST, SUPPLIER]);

  return (
    <StyledDrawer
      open={from === 'nav' ? open : true}
      anchor="left"
      variant={from === 'nav' ? 'temporary' : 'permanent'}
      onClose={onClose}
    >
      <div className={classes.drawer}>
        <NavigationLink to="/dashboard" onClose={onClose}>
          <ListItemText primary="Dashboard" />
        </NavigationLink>
        {getAllowedUserTypes([PATIENT]) && (
          <NavigationLink to="/consults" onClose={onClose}>
            <ListItemText primary="Consults" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([PATIENT]) && (
          <NavigationLink to="/purchase" onClose={onClose}>
            <ListItemText primary="Purchase" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([SUPPORT, NURSE, DOCTOR, DIRECTOR]) && (
          <NavigationLink to="/patients" onClose={onClose}>
            <ListItemText primary="Patients" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([SUPPORT, NURSE, DOCTOR]) && (
          <NavigationLink to="/assigned-patients" onClose={onClose}>
            <ListItemText primary="Assigned" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([SUPPORT, DIRECTOR, PHARMACIST]) && (
          <NavigationLink to="/orders" onClose={onClose}>
            <ListItemText primary="Orders" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([PHARMACIST]) && (
          <NavigationLink to="/pharmacy-stock" onClose={onClose}>
            <ListItemText primary="Stock" />
          </NavigationLink>
        )}
        {catalogues?.length > 0 && (
          <Accordion elevation={0} sx={{ '&:before': { display: 'none' } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordion}
              style={{ backgroundColor: path.pathname.includes('/catalogue') ? '#F5F5F5' : '' }}
            >
              <Typography className={classes.link}>Catalogue</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, margin: 0 }}>
              {catalogues.map((catalogue) => (
                <MenuItem
                  key={catalogue}
                  onClick={() => onClickSubMenu(catalogue)}
                  className={classes.link}
                  style={{
                    backgroundColor:
                      cataloguePage === catalogue && path.pathname.includes('/catalogue') ? '#F5F5F5' : '',
                  }}
                >
                  <ListItemText style={{ paddingLeft: 20, textTransform: 'capitalize' }} primary={catalogue} />
                </MenuItem>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
        {!getAllowedUserTypes([SUPPLIER]) && (
          <NavigationLink
            to={getAllowedUserTypes([SUPPORT, PHARMACIST]) ? '/messages/list/patients' : '/messages'}
            onClose={onClose}
          >
            <ListItemText primary="Messaging" />
            <Badge badgeContent={messagesNotifCount} color="secondary" />
          </NavigationLink>
        )}
        {!getAllowedUserTypes([PHARMACIST]) && (
          <NavigationLink to="/pharmacies" onClose={onClose}>
            <ListItemText primary="Pharmacies" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([PHARMACIST]) && (
          <NavigationLink to="/order-history" onClose={onClose}>
            <ListItemText primary="Order History" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([PATIENT]) && (
          <NavigationLink to="/appointments" onClose={onClose}>
            <ListItemText primary="Appointments" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([PATIENT]) && (
          <NavigationLink to="/purchase-history" onClose={onClose}>
            <ListItemText primary="Purchase History" />
          </NavigationLink>
        )}
        {isTGAFilesLinkVisible && (
          <NavigationLink to="/tga" onClose={onClose}>
            <ListItemText primary="TGA Files" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([DIRECTOR, SUPPORT, NURSE]) && (
          <NavigationLink to="/admin-control" onClose={onClose}>
            <ListItemText primary="Admin" />
          </NavigationLink>
        )}
        {getAllowedUserTypes([PATIENT]) && (
          <Accordion elevation={0} sx={{ '&:before': { display: 'none' } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordion}
              style={{ backgroundColor: path.pathname.includes('/medical-info') ? '#F5F5F5' : '' }}
            >
              <Typography className={classes.link}>Medical Info</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, margin: 0 }}>
              {user && (
                <MenuItem
                  onClick={() => onClickSubMenu('medical-info')}
                  className={classes.link}
                  style={{
                    backgroundColor:
                      medicalPage === 'medical-info' && path.pathname.includes('/medical-info') ? '#F5F5F5' : '',
                  }}
                >
                  <ListItemText style={{ paddingLeft: 20 }} primary="Medications" />
                </MenuItem>
              )}
              {user && (
                <MenuItem
                  onClick={() => onClickSubMenu('conditions')}
                  className={classes.link}
                  style={{
                    backgroundColor:
                      medicalPage === 'conditions' && path.pathname.includes('/medical-info') ? '#F5F5F5' : '',
                  }}
                >
                  <ListItemText style={{ paddingLeft: 20 }} primary="Conditions" />
                </MenuItem>
              )}
              {user && (
                <MenuItem
                  onClick={() => onClickSubMenu('concession cards')}
                  className={classes.link}
                  style={{
                    backgroundColor:
                      medicalPage === 'concession cards' && path.pathname.includes('/medical-info') ? '#F5F5F5' : '',
                  }}
                >
                  <ListItemText style={{ paddingLeft: 20 }} primary="Concessions" />
                </MenuItem>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion elevation={0} sx={{ '&:before': { display: 'none' } }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordion}
            style={{ backgroundColor: path.pathname.includes('/account-details') ? '#F5F5F5' : '' }}
          >
            <Typography className={classes.link}>Account</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0, margin: 0 }}>
            {user && (
              <MenuItem
                onClick={() => onClickSubMenu(ACCOUNT_INFO)}
                className={classes.link}
                style={{
                  backgroundColor:
                    accountDetailsPage === ACCOUNT_INFO && path.pathname.includes('/account-details') ? '#F5F5F5' : '',
                }}
              >
                <ListItemText style={{ paddingLeft: 20 }} primary="Account Info" />
              </MenuItem>
            )}
            {user && (
              <MenuItem
                onClick={() => onClickSubMenu(SECURITY)}
                className={classes.link}
                style={{
                  backgroundColor:
                    accountDetailsPage === SECURITY && path.pathname.includes('/account-details') ? '#F5F5F5' : '',
                }}
              >
                <ListItemText style={{ paddingLeft: 20 }} primary="Security" />
              </MenuItem>
            )}
            {user && getAllowedUserTypes([PATIENT, PHARMACIST, SUPPLIER]) && (
              <MenuItem
                onClick={() => onClickSubMenu(DOCUMENTS)}
                className={classes.link}
                style={{
                  backgroundColor:
                    accountDetailsPage === DOCUMENTS && path.pathname.includes('/account-details') ? '#F5F5F5' : '',
                }}
              >
                <ListItemText style={{ paddingLeft: 20 }} primary="Documents" />
              </MenuItem>
            )}
          </AccordionDetails>
        </Accordion>
        {user !== null && (
          <Link to="/" style={{ textDecoration: 'none', color: '#0E6D81' }}>
            <MenuItem onClick={handleLogoutClose} className={classes.link}>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Link>
        )}
      </div>
    </StyledDrawer>
  );
};

export default Sidebar;
