import { captureException as sentryCaptureException } from '@sentry/react';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { getCatalogueId } from '../../../../utils/constants';
import { generateScriptOnlyEmailData } from './generateScriptOnlyEmailData';

export const processData = async ({
  order,
  userDoc,
  setItems,
  setUserDoc,
  setEmailObj,
  setDataLoaded,
  catalogue,
  snackbar,
}) => {
  try {
    const patientRef = doc(db, 'patients', order.user);
    const tempUserDoc = userDoc || { ...(await getDoc(patientRef)).data(), id: order.user };

    const tempPrescriptionArray = order.scriptsArray.reduce((acc, script) => {
      if (!script.eScriptToken) {
        return acc;
      }

      const { item: productDescriptiveName } = catalogue[getCatalogueId(script.priceRef)] || {};

      if (!productDescriptiveName) {
        return acc;
      }

      return { ...acc, [script.priceRef]: productDescriptiveName };
    }, {});

    // Initial result template
    const initialResult = {
      // NOTE: This is the email address that the out of network pharmacy should use to ask
      // any questions regarding the prescriptions or tga files sent to them in the Script Only Email.
      // FUTURE: delete this from the sendGrid template and here.
      doctorEmail: 'support@candor.org',
      patientName: order.name,
      scripts: [],
    };

    const scriptOnlyEmailObj = await generateScriptOnlyEmailData({
      initialResult,
      order,
      catalogue,
      userData: tempUserDoc,
    });

    setEmailObj(scriptOnlyEmailObj);
    setUserDoc(tempUserDoc);
    setItems(tempPrescriptionArray);
    setDataLoaded(true);
  } catch (error) {
    sentryCaptureException(error, {
      extra: {
        issueIn: 'useCallback script only',
        order,
        user: 'support/director',
      },
    });
    snackbar(`An error was encountered while retrieving the data. Error: ${error}`);
  }
};
