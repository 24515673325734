import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

const Selection = ({ children, value, onChange, label, disabled = false, size, error = '' }) => (
  <FormControl fullWidth size={size}>
    <InputLabel id={`label-${label}`}>{label}</InputLabel>
    <Select
      disabled={disabled}
      fullWidth
      value={value}
      labelId={`label-${label}`}
      id={`select-${label}`}
      label={label}
      onChange={onChange}
      error={error?.length > 0}
    >
      {children}
    </Select>
    {error?.length > 0 && <FormHelperText sx={{ m: 0, color: '#d32f2f' }}>{error}</FormHelperText>}
  </FormControl>
);

Selection.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  error: PropTypes.string,
};

export default Selection;
