import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';

import { PharmacyDetailsPage } from '../pages';
import { PharmacyProvider } from '../providers';

export const PharmacyDetailsModal = ({ pharmacy, isOpen, onClose }) => {
  if (!pharmacy || !isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <PharmacyProvider pharmacyId={pharmacy.id}>
          <PharmacyDetailsPage pharmacy={pharmacy} />
        </PharmacyProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
