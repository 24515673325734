import { doc, updateDoc } from 'firebase/firestore';
import React, { useState, useEffect, useMemo } from 'react';
import { captureException as sentryCaptureException } from '@sentry/react';
import { FormControl, Button, FormGroup, Link, TextField, Typography, Stack } from '@mui/material';

import { db } from '../../../../firebase-config';
import { SHIPMENT_TYPES } from '../../../../utils/constants';
import { useAuthContext, useOrderDetailsContext, usePharmacyContext } from '../../hooks';
import {
  getShippedBy,
  getTrackingCode,
  getUpdateTracking,
  processTrackingLink,
} from '../OrderDetailsToolsButton/MassAddTracking/helpers';
import {
  getUniqueScripts,
  getRepeatTokens,
  processOrderCompletion,
  getReasonsThatOrderIsNotReadyForShipment,
  getReasonsThatOrderIsNotReadyForPickup,
} from './helpers';
import { classes, StyledGrid } from './styles';
import { ConditionalButton } from './ConditionalButton';
import { ScriptRelatedSections } from './ScriptRelatedSections';
import { SendNotificationToPatient } from './SendNotificationToPatient';

const { OUTSTANDING, SHIPMENT_REQUIRED, PICKUP_REQUIRED } = SHIPMENT_TYPES;

/**
 * @param {Object} props
 * @param {string} props.userId
 * @param {ExtendedOrder} props.shipment
 * @param {string} props.initialTrackingCode
 * @param {string} props.trackingCode
 * @param {Function} props.setTrackingCode
 * @returns {React.ReactNode}
 */
const ShippingDetailsSection = ({ userId, shipment, initialTrackingCode, trackingCode, setTrackingCode }) => {
  const { pharmacy, snackbar } = usePharmacyContext();
  const { refreshOrder } = useOrderDetailsContext();
  const [isSavingTracking, setIsSavingTracking] = useState(false);
  const shippedByLabel = useMemo(() => getShippedBy({ shipment, pharmacyData: pharmacy }), [shipment, pharmacy]);

  const handleSaveTracking = async () => {
    setIsSavingTracking(true);
    try {
      const outstandingOrderRef = doc(db, 'orders', userId, OUTSTANDING, shipment.payment);
      const { UPSERT_FIRESTORE_TRACKING } = getUpdateTracking({
        trackingCode,
        shipment,
        pharmacyData: pharmacy,
      });
      await updateDoc(outstandingOrderRef, UPSERT_FIRESTORE_TRACKING);
      refreshOrder();
      setIsSavingTracking(false);
      snackbar('Tracking data saved');
    } catch (error) {
      sentryCaptureException(error, {
        extra: { shipment, userId, issueIn: 'handleSaveTracking' },
      });
      snackbar('An error was encountered while saving the tracking data');
      setIsSavingTracking(false);
    }
  };

  if (shipment.isPickup) {
    return null;
  }

  return (
    <Stack direction="column" gap={1}>
      <Typography variant="body1" fontWeight="bold">
        Shipping Details
      </Typography>

      <Stack direction="row" alignItems="center" spacing={1} mb={2}>
        <Typography variant="body2" style={{ fontWeight: 600 }}>
          Shipped By:
        </Typography>
        <Typography variant="body2">{shippedByLabel}</Typography>
      </Stack>

      {shipment.status === SHIPMENT_REQUIRED && (
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
          <TextField
            type="text"
            variant="outlined"
            onChange={(e) => setTrackingCode(e.target.value)}
            value={trackingCode || ''}
            label="Tracking Code"
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveTracking}
            disabled={trackingCode === initialTrackingCode || !trackingCode}
            loading={isSavingTracking}
            sx={{ width: 'fit-content', flexShrink: 0 }}
          >
            Save
          </Button>
        </Stack>
      )}

      {!shipment.isPickup && (
        <Link
          variant="body2"
          className={classes.link}
          target="_blank"
          href={processTrackingLink({ shipment, trackingCode: initialTrackingCode })}
          sx={{ width: 'fit-content', pt: 1 }}
        >
          View Tracking Link
        </Link>
      )}
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {ExtendedOrder} props.shipment
 * @param {string} props.initialTrackingCode
 * @param {string} props.trackingCode
 * @param {boolean} props.isSubmit
 * @param {(isSubmit: boolean) => void} props.setIsSubmit
 * @returns {React.ReactNode}
 */
const ActionsSection = ({ shipment, initialTrackingCode, trackingCode, isSubmit, setIsSubmit }) => {
  const { pharmacy, snackbar } = usePharmacyContext();
  const { handleDialogue } = useOrderDetailsContext();

  const handleOrderCompletion = async (isPickup) => {
    await processOrderCompletion({
      shipment,
      isPickup,
      snackbar,
      setIsSubmit,
      handlePrimaryModal: handleDialogue,
      pharmacyData: pharmacy,
    });
  };

  const reasonsThatOrderIsNotReadyForShipment = getReasonsThatOrderIsNotReadyForShipment({
    userVerified: shipment.userVerified,
    initialTrackingCode,
    trackingCode,
    isEscriptValid: shipment.isEscriptValid,
    isAwaitingPayment: shipment.isAwaitingPayment,
  });

  const reasonsThatOrderIsNotReadyForPickup = getReasonsThatOrderIsNotReadyForPickup({
    userVerified: shipment.userVerified,
    isAwaitingPayment: shipment.isAwaitingPayment,
    isEscriptValid: shipment.isEscriptValid,
  });

  return (
    <Stack direction="column" spacing={2}>
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{
          visibility: 'hidden',
          ':has(+ div:not(:empty))': {
            visibility: 'visible',
          },
        }}
      >
        Actions
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        {shipment.status === SHIPMENT_REQUIRED && (
          <Stack direction="column" spacing={1}>
            <ConditionalButton
              className={classes.button}
              shouldRender
              displayText={isSubmit ? 'Submitting...' : 'Mark Order As Shipped'}
              isDisabled={reasonsThatOrderIsNotReadyForShipment.length > 0 || isSubmit}
              onClick={() => handleOrderCompletion(false)}
            />
            {reasonsThatOrderIsNotReadyForShipment.length > 0 && (
              <Typography variant="caption" color="error">
                {reasonsThatOrderIsNotReadyForShipment.join(', ')}
              </Typography>
            )}
          </Stack>
        )}
        {shipment.status === PICKUP_REQUIRED && (
          <Stack direction="column" spacing={1}>
            <ConditionalButton
              className={classes.pickupButton}
              shouldRender
              displayText={isSubmit ? 'Submitting...' : 'Pickup Complete'}
              isDisabled={reasonsThatOrderIsNotReadyForPickup.length > 0 || isSubmit}
              onClick={() => handleOrderCompletion(true)}
            />
            {reasonsThatOrderIsNotReadyForPickup.length > 0 && (
              <Typography variant="caption" color="error">
                {reasonsThatOrderIsNotReadyForPickup.join(', ')}
              </Typography>
            )}
          </Stack>
        )}
        <SendNotificationToPatient order={shipment} />
      </Stack>
    </Stack>
  );
};

/**
 * TODO: This component has just been moved for the most part, with some slight refactoring.
 * We need to refactor this component to be more modular and reusable.
 */

export const PharmacistSection = () => {
  const { user } = useAuthContext();
  const { uid: userId } = user || { uid: null };
  const { catalogue } = usePharmacyContext();
  const { order: shipment } = useOrderDetailsContext();

  // will be improve in the future - @krochet94
  const initialTrackingCode = getTrackingCode(shipment);
  const initialRepeatTokens = getRepeatTokens(shipment);

  const [trackingCode, setTrackingCode] = useState(initialTrackingCode);
  const [repeatTokens, setRepeatTokens] = useState(initialRepeatTokens);

  const [isSubmit, setIsSubmit] = useState(false);

  const uniqueScriptsArray = useMemo(() => getUniqueScripts(shipment.scriptsArray ?? []), [shipment]);

  useEffect(() => {
    setRepeatTokens(getRepeatTokens(shipment));
    setTrackingCode(getTrackingCode(shipment));
  }, [shipment]);

  return (
    <StyledGrid>
      <ScriptRelatedSections
        isSubmit={isSubmit}
        catalogue={catalogue}
        uniqueScriptsArray={uniqueScriptsArray}
        repeatTokens={repeatTokens}
        setRepeatTokens={setRepeatTokens}
      />

      <FormControl>
        <FormGroup>
          <ShippingDetailsSection
            shipment={shipment}
            userId={userId}
            initialTrackingCode={initialTrackingCode}
            trackingCode={trackingCode}
            setTrackingCode={setTrackingCode}
          />

          <ActionsSection
            shipment={shipment}
            initialTrackingCode={initialTrackingCode}
            trackingCode={trackingCode}
            isSubmit={isSubmit}
            setIsSubmit={setIsSubmit}
          />
        </FormGroup>
      </FormControl>
    </StyledGrid>
  );
};
