import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { httpsCallable } from 'firebase/functions';
import React, { useCallback, useEffect, useState } from 'react';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Box, Button, CircularProgress, Grid2 as Grid, Typography } from '@mui/material';

import { functions } from '../../../firebase-config';
import { useOrderDetailsContext, usePharmacyContext } from '../hooks';

const PREFIX = 'VerifyIdentityThroughPharmacy';

const classes = {
  modalPaper: `${PREFIX}-modalPaper`,
  imageContainer: `${PREFIX}-imageContainer`,
};

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 4,
  overflowY: 'auto',
  maxHeight: '80%',
  [`& .${classes.imageContainer}`]: {
    objectFit: 'contain',
    width: '100%',
    padding: 20,
  },
}));

const verifyPatientThroughPharmacy = httpsCallable(functions, 'verifyPatientThroughPharmacy');

// TODO: Refactor to not use base64 images @jmossesgeld
const getImgSrc = (base64) => `data:image/png;base64,${base64}`;

const Details = ({ xs = 1, label, fontWeight = 'regular' }) => (
  <Grid size={{ xs: xs || 1 }}>
    <Typography variant="body1" fontWeight={fontWeight || 'regular'}>
      {label}
    </Typography>
  </Grid>
);

Details.propTypes = {
  xs: PropTypes.number,
  label: PropTypes.string.isRequired,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Header = () => (
  <>
    <Typography variant="h5" color="primary">
      Verify Identity
    </Typography>
    <Typography variant="body1" mt={2}>
      Verify the identity of the patient through manual verification
    </Typography>
  </>
);

const PatientDetails = ({ patientDetails }) => (
  <Grid container spacing={2} mt={2} px={4}>
    <Details xs={5} label="Name" fontWeight="500" />
    <Details xs={7} label={patientDetails.name} />
    <Details xs={5} label="Sex" fontWeight="500" />
    <Details xs={7} label={patientDetails.sex} />
    <Details xs={5} label="Date of Birth" fontWeight="500" />
    <Details xs={7} label={patientDetails.dob} />
    <Details xs={5} label="Email" fontWeight="500" />
    <Details xs={7} label={patientDetails.email} />
    <Details xs={5} label="Address" fontWeight="500" />
    <Details xs={7} label={patientDetails.address} />
  </Grid>
);

const identifyPatientDetailsResponseShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  sex: PropTypes.string,
  dob: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  doctor: PropTypes.string,
  idFront: PropTypes.string,
  idBack: PropTypes.string,
});

PatientDetails.propTypes = {
  patientDetails: identifyPatientDetailsResponseShape.isRequired,
};

const VerifyIdentityButton = ({ isVerifyLoading, handleVerifyIdentity }) => {
  if (isVerifyLoading) {
    return <CircularProgress />;
  }

  return (
    <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleVerifyIdentity}>
      Verify
    </Button>
  );
};

VerifyIdentityButton.propTypes = {
  isVerifyLoading: PropTypes.bool.isRequired,
  handleVerifyIdentity: PropTypes.func.isRequired,
};

const VerifyPatientIdentityContent = ({ patientDetails, isVerifyLoading, handleVerifyIdentity }) => {
  // FIXME: This will be stuck infinitely if the patient has no data
  if (!Object.values(patientDetails || {}).length) {
    return <CircularProgress sx={{ mx: 'auto', my: 2 }} />;
  }

  return (
    <>
      <PatientDetails patientDetails={patientDetails} />
      {/* ID to Verify */}
      <img className={classes.imageContainer} src={getImgSrc(patientDetails.idFront)} alt="front" />
      <img className={classes.imageContainer} src={getImgSrc(patientDetails.idBack)} alt="back" />
      <Typography textAlign="center" fontWeight="bold" my={2}>
        If the details above match EXACTLY click verify. Otherwise contact the patient for clarification.
      </Typography>
      <VerifyIdentityButton isVerifyLoading={isVerifyLoading} handleVerifyIdentity={handleVerifyIdentity} />
    </>
  );
};

VerifyPatientIdentityContent.propTypes = {
  patientDetails: identifyPatientDetailsResponseShape.isRequired,
  isVerifyLoading: PropTypes.bool.isRequired,
  handleVerifyIdentity: PropTypes.func.isRequired,
};

const VerifyIdentityThroughPharmacyModal = () => {
  const { order: shipment, handleDialogue } = useOrderDetailsContext();
  const { snackbar } = usePharmacyContext();
  const [patientDetails, setPatientDetails] = useState({});
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user: patientId } = shipment;

  const handleVerifyIdentity = async () => {
    try {
      setIsVerifyLoading(true);

      await verifyPatientThroughPharmacy({
        mode: 'FINISH_VERIFYING',
        patientId,
      });

      setIsVerifyLoading(false);
      handleDialogue({ isOpen: false, content: null });
      snackbar('Patient identity verified successfully');
    } catch (error) {
      sentryCaptureException(error, { extra: { shipment, patientId, issueIn: 'verify identity through pharmacy' } });

      snackbar('Errror verifying patient identity, please try again');
      setIsVerifyLoading(false);
    }
  };

  const getPatientDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await verifyPatientThroughPharmacy({
        mode: 'GET_DETAILS',
        patientId,
      });

      setPatientDetails(data);
    } catch (error) {
      sentryCaptureException(error, {
        extra: { shipment, patientId, issueIn: 'get patient details in verify identity through pharmacy ' },
      });

      snackbar('Error getting patient details, please try again');
    } finally {
      setLoading(false);
    }
  }, [patientId, setPatientDetails, shipment, snackbar]);

  /**
   * TODO: This is a temporary fix to load the user data on the first render
   * This should be refactored to use context or something else :)
   */
  useEffect(() => {
    getPatientDetails();
  }, [getPatientDetails]);

  if (loading) {
    return <CircularProgress sx={{ mx: 'auto', my: 2 }} />;
  }

  return (
    <StyledBox onClick={(e) => e.stopPropagation()}>
      <Header />
      <VerifyPatientIdentityContent
        patientDetails={patientDetails}
        isVerifyLoading={isVerifyLoading}
        handleVerifyIdentity={handleVerifyIdentity}
      />
    </StyledBox>
  );
};

export const VerifyIdentityThroughPharmacy = () => {
  const { handleDialogue } = useOrderDetailsContext();

  const handleModalOpen = async () => {
    handleDialogue({
      isOpen: true,
      content: <VerifyIdentityThroughPharmacyModal />,
    });
  };

  return (
    <Button color="primary" onClick={handleModalOpen}>
      Verify
    </Button>
  );
};
