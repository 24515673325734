const trimStringsInObject = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    const newObj = Array.isArray(obj) ? [] : {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value !== undefined) {
        newObj[key] = trimStringsInObject(value);
      }
    });

    return newObj;
  }

  if (typeof obj === 'string') {
    return obj.trim();
  }

  return obj;
};

export default trimStringsInObject;
