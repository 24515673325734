import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { extractNumericCharacters } from '../../utils/constants';

// WARNING: There are other definitions of this in our codebase with slight variations
// TODO: The below is misnamed, this is checking if it's a digit not numerical value
const isNumeric = (value) => /^\d*$/.test(value);

const CustomPhoneNumber = ({ phoneValue, onChangePhoneValue, isPhoneError }) => {
  const handleChange = (e) => {
    const { value } = e.target;
    // blocks input if the value is not numeric or exceeds 15 characters
    const formattedValue = extractNumericCharacters(value);
    if (formattedValue.length > 15 || !isNumeric(formattedValue)) {
      return;
    }
    onChangePhoneValue(formattedValue);
  };

  return (
    <TextField
      label="Phone Number"
      value={phoneValue}
      onChange={handleChange}
      variant="outlined"
      placeholder="614 XXXX XXXX"
      error={isPhoneError}
      required
      slotProps={{
        input: {
          inputProps: { min: 0, maxLength: 15 },
          startAdornment: <InputAdornment position="start">+</InputAdornment>,
        },
      }}
    />
  );
};

CustomPhoneNumber.propTypes = {
  phoneValue: PropTypes.string.isRequired,
  onChangePhoneValue: PropTypes.func.isRequired,
  isPhoneError: PropTypes.bool.isRequired,
};

export default CustomPhoneNumber;
