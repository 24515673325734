import React from 'react';
import { Menu as MenuIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, Stack } from '@mui/material';

import { SHIPMENT_TYPES } from '../../../utils/constants';
import { useAuthContext } from '../hooks';
import { getShowTools } from '../helpers';
import { OrderDetailsProvider } from '../providers';
import { PharmacyOrderDetailsPageWithoutProvider } from '../pages';
import { OrderDetailsToolsDropdownMenu } from './OrderDetailsToolsDropdownMenu';

const { COMPLETE } = SHIPMENT_TYPES;
/**
 * @param {Object} props
 * @param {Order} props.order - The order.
 * @param {number} props.orderIdx - The order index.
 * @param {number} props.numberOfOrders - The number of orders.
 * @param {() => void} props.onClose - The on close function.
 * @param {() => void} props.onPrevious - The on previous function.
 * @param {() => void} props.onNext - The on next function.
 * @returns {React.ReactNode} - The order details modal.
 */
export const OrderDetailsModal = ({ order, orderIdx, numberOfOrders, onClose, onPrevious, onNext }) => {
  const { userType } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState(/** @type {HTMLButtonElement | null} */ (null));
  const showTools = getShowTools({ shipment: order, userType, isHistorical: order.status === COMPLETE });

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        id: 'order-details-modal-container',
        sx: { display: 'flex', flexDirection: 'row', gap: 2, width: '100%' },
      }}
    >
      <div style={{ width: '100%', flex: 1 }}>
        <OrderDetailsProvider order={order}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
              <Button
                disabled={Object.values(showTools).every((show) => !show)}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                startIcon={<MenuIcon />}
              >
                Tools
              </Button>
              <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
                {Object.entries(showTools).map(([type, show]) => {
                  if (!show) {
                    return null;
                  }

                  return <OrderDetailsToolsDropdownMenu type={type} key={type} onClose={() => setAnchorEl(null)} />;
                })}
              </Menu>
            </Stack>
            <Button onClick={onClose} sx={{}}>
              Close
            </Button>
          </DialogTitle>
          <DialogContent
            id="order-details-modal-content"
            sx={{
              position: 'relative',
              height: '70vh',
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              flex: 1,
            }}
          >
            <PharmacyOrderDetailsPageWithoutProvider order={order} hideTools />
          </DialogContent>

          <DialogActions sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
              <IconButton
                aria-label="previous order"
                style={{ visibility: orderIdx === 0 ? 'hidden' : 'visible' }}
                onClick={onPrevious}
                size="large"
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              <IconButton
                aria-label="next order"
                style={{
                  visibility: orderIdx === numberOfOrders - 1 ? 'hidden' : 'visible',
                }}
                onClick={onNext}
                size="large"
              >
                <ArrowForwardIcon fontSize="large" />
              </IconButton>
            </Stack>
          </DialogActions>
        </OrderDetailsProvider>
      </div>
    </Dialog>
  );
};
