import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Container, Typography, Button, MenuItem, FormControl, Select } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { AuthContext } from '../../../../auth-context';
import { isCandor } from '../../../../utils/roles';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'SelecterFormElementPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  formControl: `${PREFIX}-formControl`,
  button: `${PREFIX}-button`,
  candorHeading: `${PREFIX}-candorHeading`,
  candorSubheading: `${PREFIX}-candorSubheading`,
  candorPaper: `${PREFIX}-candorPaper`,
  candorFormControl: `${PREFIX}-candorFormControl`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
  },

  [`& .${classes.accountGridContainer}`]: {
    height: 'auto',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.formControl}`]: {
    margin: '20px 0px',
    minWidth: 100,
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '130px',
    display: 'block',
  },

  [`& .${classes.candorHeading}`]: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  [`& .${classes.candorSubheading}`]: {
    textAlign: 'left',
    margin: '0 0px 10px 0px',
  },

  [`& .${classes.candorPaper}`]: {
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(0 0 0 / 20%)',
    borderRadius: 'unset',
    padding: '0px 0 20px',
    width: '100%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.candorFormControl}`]: {
    minWidth: 50,
    fontSize: '13px',
  },
}));

const SelecterFormElementPresentation = (props) => {
  const { isLoading, currentFormObject, handleInput, inputValue, handleChange, simpleView } = props;

  const { userType } = useContext(AuthContext);
  const buttonText = getFormsButtonText({ isLoading, isUpdateDetails: false });

  return (
    <StyledContainer maxWidth="lg">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={!isCandor(userType) ? classes.accountGridContainer : classes.gridContainer}
      >
        <Paper className={simpleView ? classes.candorPaper : classes.paper}>
          <Typography
            className={simpleView ? classes.candorHeading : classes.heading}
            variant={simpleView ? 'body1' : 'h5'}
            align="center"
            gutterBottom
          >
            {currentFormObject.headingText}
          </Typography>
          <>
            {simpleView ? (
              <FormControl className={classes.candorFormControl} size="small">
                <Select
                  style={{ fontSize: '13px', padding: 0 }}
                  value={inputValue || 1}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  {currentFormObject.valueRange.map((val) => {
                    const key = uuidv4();
                    return (
                      <MenuItem key={key} value={val}>
                        {val}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <Grid sx={{ textAlign: 'center' }}>
                <FormControl className={classes.formControl}>
                  <Select value={inputValue || 1} onChange={(e) => handleChange(e.target.value)}>
                    {currentFormObject.valueRange.map((val) => {
                      const key = uuidv4();
                      return (
                        <MenuItem key={key} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </>
          {!isCandor(userType) && (
            <Button
              disabled={!inputValue || isLoading}
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleInput}
            >
              {buttonText}
            </Button>
          )}
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default SelecterFormElementPresentation;
