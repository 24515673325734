import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography, Modal, Button, Paper } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../firebase-config';

const PREFIX = 'SelectPharmacyModal';

const classes = {
  modal: `${PREFIX}-modal`,
  pharmacyOptionsContainer: `${PREFIX}-pharmacyOptionsContainer`,
  pharmacyOptions: `${PREFIX}-pharmacyOptions`,
  pharmacyOption: `${PREFIX}-pharmacyOption`,
};

const StyledModal = styled(Modal)(() => ({
  [`& .${classes.modal}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: {
      xs: 5,
      sm: 20,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '70vh',
  },

  [`& .${classes.pharmacyOptionsContainer}`]: {
    position: 'relative',
    flex: 1,
    maxWidth: '32rem',
    width: '85vw',
  },

  [`& .${classes.pharmacyOptions}`]: {
    position: 'absolute',
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    padding: 20,
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.pharmacyOption}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
    border: '1px solid #e0e0e0',
  },
}));

const isSubjectToSubstitutionRules = (pharmacy, priceRefs, pharmacyStock) => {
  const restrictedStates = ['Queensland', 'New South Wales'];
  const { state } = pharmacy.shipping || {};

  const doesHaveZeroStock = priceRefs.some(
    (priceRef) =>
      // Check if any of the selected treatments are out of stock
      (pharmacyStock?.[pharmacy.id]?.[priceRef] || 0) <= 0,
  );

  return restrictedStates.includes(state) && doesHaveZeroStock;
};

const SelectPharmacyModal = ({
  filteredPharmacies,
  selectedTreatments,
  setSelectedPharmacy,
  selectedPharmacy = '',
  setScriptMode,
  deliveryMode,
  patientInfo,
  formName,
  pharmacyStock,
  pickupFromOtherStates,
  setPickupFromOtherStates,
}) => {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState({});

  const handleOpen = () => setOpen(true);
  const doesItemHaveStock = ({ priceRef, pharmacyId }) => Number(pharmacyStock?.[pharmacyId]?.[priceRef] || 0) > 0;

  // Disable scriptMode if patient has scripts with other pharmacies or exclusives
  const scriptsOnCheckout = selectedTreatments.map((treatment) => patientInfo?.prescriptions?.[formName]?.[treatment]);
  const hasScriptOnFileWithAPharmacy = scriptsOnCheckout.some((script) => script?.pharmacy);

  const shouldShowScriptMode =
    deliveryMode === 'pickup' &&
    !hasScriptOnFileWithAPharmacy &&
    patientInfo.shipping.state !== 'WA' &&
    !selectedTreatments.some((treatment) => {
      const product = products[treatment];
      return product?.exclusive;
    });

  const handleSelectPharmacy = (pharmacy) => {
    // If the pharmacy is subject to substitution rules, don't allow the user to select it
    if (isSubjectToSubstitutionRules(pharmacy, selectedTreatments, pharmacyStock)) {
      return;
    }

    setSelectedPharmacy(pharmacy.id);
    setOpen(false);
  };

  const handleScriptMode = () => {
    setScriptMode(true);
    setOpen(false);
  };

  useEffect(() => {
    const fetchCatalogue = async () => {
      const catalogue = {};
      await Promise.all(
        selectedTreatments.map(async (treatment) => {
          const product = await getDoc(doc(db, 'catalogue', treatment.replace('price_', '')));
          catalogue[treatment] = product.data();
        }),
      );
      setProducts(catalogue);
    };
    fetchCatalogue();
  }, [filteredPharmacies, selectedTreatments]);

  return (
    <>
      <Button variant={selectedPharmacy ? 'text' : 'contained'} onClick={handleOpen}>
        {selectedPharmacy ? 'Change' : 'Select Pharmacy'}
      </Button>

      <StyledModal data-testid="patient-purchase-pharmacy-selection-modal" open={open} onClose={() => setOpen(false)}>
        <Paper className={classes.modal}>
          <Typography variant="h6" color="primary" my={1}>
            Select Pharmacy
          </Typography>
          <Box className={classes.pharmacyOptionsContainer}>
            {!filteredPharmacies[0] && (
              <Typography style={{ textAlign: 'center', marginTop: 20 }} variant="body1">
                No pharmacies available
              </Typography>
            )}
            <Box className={classes.pharmacyOptions}>
              {filteredPharmacies?.map((pharmacy, idx) => (
                <React.Fragment key={pharmacy.id || pharmacy.uid || pharmacy.name}>
                  {filteredPharmacies?.[idx]?.shipping?.state !==
                    filteredPharmacies?.[idx - 1 >= 0 ? idx - 1 : filteredPharmacies.length - 1]?.shipping?.state && (
                    <Typography variant="body2" fontWeight="bold" color="primary" textAlign="center" sx={{ mt: 3 }}>
                      {pharmacy.shipping?.state}
                    </Typography>
                  )}
                  <Paper
                    key={pharmacy.id}
                    className={classes.pharmacyOption}
                    onClick={() => handleSelectPharmacy(pharmacy)}
                  >
                    <Typography fontWeight="bold">{pharmacy.name}</Typography>
                    <Typography>
                      {pharmacy.shipping?.street} {pharmacy.shipping?.suburb} {pharmacy.shipping?.state}
                    </Typography>
                    {isSubjectToSubstitutionRules(pharmacy, selectedTreatments, pharmacyStock) && (
                      <Typography variant="caption" color="error">
                        This pharmacy does not have the necessary stock and cannot substitute
                      </Typography>
                    )}
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="body2" fontWeight={500} my={1}>
                        Stock Levels
                      </Typography>
                      {selectedTreatments?.map((priceRef) => (
                        <Box
                          key={priceRef}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #eee',
                            py: 1,
                          }}
                        >
                          <Typography variant="body2">{products?.[priceRef]?.name}</Typography>
                          {products[priceRef]?.exclusive || doesItemHaveStock({ priceRef, pharmacyId: pharmacy.id }) ? (
                            <CheckIcon color="primary" />
                          ) : (
                            <CloseIcon color="error" />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Paper>
                </React.Fragment>
              ))}
              {shouldShowScriptMode && (
                <Paper className={classes.pharmacyOption} onClick={handleScriptMode} style={{ marginTop: 20 }}>
                  <Typography fontWeight="bold">Other Pharmacy</Typography>
                  <Typography sx={{ mt: 2 }} variant="body2">
                    We&apos;ll send your prescription to a pharmacy outside our network.
                  </Typography>
                  <Typography fontWeight={500} variant="body2">
                    ($30 admin fee applies)
                  </Typography>
                </Paper>
              )}
              {!pickupFromOtherStates && deliveryMode === 'pickup' && (
                <Button fullWidth sx={{ pt: 2 }} onClick={() => setPickupFromOtherStates(true)}>
                  Show Other States
                </Button>
              )}
            </Box>
          </Box>
          <Button variant="contained" sx={{ mb: 2 }} onClick={() => setOpen(false)}>
            Close
          </Button>
        </Paper>
      </StyledModal>
    </>
  );
};

SelectPharmacyModal.propTypes = {
  filteredPharmacies: PropTypes.arrayOf(
    PropTypes.shape({
      shipping: PropTypes.shape({ state: PropTypes.string }),
    }),
  ).isRequired,
  selectedTreatments: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedPharmacy: PropTypes.func.isRequired,
  selectedPharmacy: PropTypes.string,
  setScriptMode: PropTypes.func.isRequired,
  deliveryMode: PropTypes.string.isRequired,
  patientInfo: PropTypes.shape({
    shipping: PropTypes.shape({
      state: PropTypes.string,
    }),
    prescriptions: PropTypes.shape({}),
  }).isRequired,
  formName: PropTypes.string.isRequired,
  pharmacyStock: PropTypes.shape({}).isRequired,
  pickupFromOtherStates: PropTypes.bool.isRequired,
  setPickupFromOtherStates: PropTypes.func.isRequired,
};

export default SelectPharmacyModal;
