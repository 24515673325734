import React from 'react';
import { Button } from '@mui/material';

/**
 * FIXME: This should really be refactored and thought about a bit more.
 * It's a bit of a mess and it's not the best way to do it. It was copied over from the old code.
 */

/**
 * ConditionalButton is a component that renders a button only if the shouldRender prop is true.
 * @param {Object} props - The props object.
 * @param {string} [props.className] - The class name to apply to the button.
 * @param {boolean} [props.shouldRender] - Whether the button should be rendered.
 * @param {string} props.displayText - The text to display on the button.
 * @param {boolean} [props.isDisabled] - Whether the button should be disabled.
 * @param {'text'| 'outlined' | 'contained'} [props.variant] - The variant of the button.
 * @param {() => void} [props.onClick] - The function to call when the button is clicked.
 * @returns {React.ReactNode} The ConditionalButton component.
 */
export const ConditionalButton = ({
  className = '',
  shouldRender = false,
  displayText = '',
  isDisabled,
  variant = 'contained',
  onClick,
}) => {
  if (!shouldRender) {
    return null;
  }

  return (
    <Button className={className} disabled={isDisabled} variant={variant} color="primary" onClick={onClick}>
      {displayText}
    </Button>
  );
};
