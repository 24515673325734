import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Button, Typography, FormControlLabel, RadioGroup, Box, Radio } from '@mui/material';

import { AuthContext } from '../../../../auth-context';
import { isCandor, isPatient } from '../../../../utils/roles';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'RadioFormElementPresentation';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  wrapperAcctDetails: `${PREFIX}-wrapperAcctDetails`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  label: `${PREFIX}-label`,
  booleanLabel: `${PREFIX}-booleanLabel`,
  checkbox: `${PREFIX}-checkbox`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  chip: `${PREFIX}-chip`,
  candorHeading: `${PREFIX}-candorHeading`,
  candorSubheading: `${PREFIX}-candorSubheading`,
  candorPaper: `${PREFIX}-candorPaper`,
};

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyConter: 'center',

  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
    alignContent: 'center',
  },

  [`& .${classes.accountGridContainer}`]: {
    height: 'auto',
    margin: '20px 0px',
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    padding: '40px 30px 40px 40px',
    width: '100%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
    '@media (max-width: 500px)': {
      padding: '27px',
    },
    '@media (max-width: 450px)': {
      padding: '23px',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.label}`]: {
    margin: '10px auto',
    padding: '10px',
    textAlign: 'center',
    width: '300px',
  },

  [`& .${classes.booleanLabel}`]: {
    margin: '10px auto',
    padding: '10px',
    textAlign: 'center',
    width: '150px',
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '140px',
    display: 'block',
  },

  [`& .${classes.chip}`]: {
    fontSize: '15px',
    padding: '10px 5px',
  },

  [`& .${classes.candorHeading}`]: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  [`& .${classes.candorSubheading}`]: {
    textAlign: 'left',
    margin: '0 0px 10px 0px',
  },

  [`& .${classes.candorPaper}`]: {
    height: '100%',
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(0 0 0 / 20%)',
    borderRadius: 'unset',
    padding: '0px 0 20px',
    width: '100%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
    candorAnswer: {
      width: '100%',
      textAlign: 'left',
    },
  },
});

const StyledFormControlLabel = FormControlLabel;

const RadioFormElementPresentation = (props) => {
  const { isLoading, inputValue, currentFormObject, handleInput, handleChange, simpleView, isUpdateDetails } = props;

  const { userType } = useContext(AuthContext);

  const buttonText = getFormsButtonText({ isUpdateDetails, isLoading });

  // return true if array has equals to the inputValue
  const hasTrueValue = (arr) => arr.includes(inputValue);

  return (
    ((simpleView && hasTrueValue(currentFormObject.options)) ||
      (isCandor(userType) && !simpleView) ||
      isPatient(userType) ||
      !userType ||
      isUpdateDetails) && (
      <StyledContainer className={classes.wrapperAcctDetails}>
        <Grid
          container
          className={isCandor(userType) ? classes.accountGridContainer : classes.gridContainer}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper className={simpleView ? classes.candorPaper : classes.paper}>
            <div>
              <Typography
                className={simpleView ? classes.candorHeading : classes.heading}
                variant={simpleView ? 'body1' : 'h5'}
                align="center"
                gutterBottom
              >
                {currentFormObject.headingText}
              </Typography>
            </div>
            {currentFormObject.subheadingText && (
              <div>
                <Typography
                  className={simpleView ? classes.candorSubheading : classes.subheading}
                  variant="body2"
                  align="center"
                  gutterBottom
                >
                  {currentFormObject.subheadingText}
                </Typography>
              </div>
            )}
            <Grid container alignItems="center" justifyContent={simpleView ? 'left' : 'center'}>
              <RadioGroup name="use-radio-group" defaultValue="first">
                {currentFormObject.options?.map((option) => {
                  let displayOption = option;
                  if (option === true) displayOption = 'Yes';
                  if (option === false) displayOption = 'No';
                  return (
                    <Grid key={option} size={{ sm: 12 }}>
                      {simpleView ? (
                        (inputValue === option || (inputValue === undefined && option === false)) && (
                          <Typography className={classes.candorAnswer}>{displayOption}</Typography>
                        )
                      ) : (
                        <StyledFormControlLabel
                          control={
                            <Radio
                              checked={inputValue === option}
                              onChange={() => {
                                handleChange(option);
                              }}
                              className={classes.checkbox}
                            />
                          }
                          label={
                            <Box
                              border={2}
                              borderColor="primary.main"
                              className={typeof option === 'boolean' ? classes.booleanLabel : classes.label}
                              borderRadius="8px"
                            >
                              {' '}
                              {displayOption}
                            </Box>
                          }
                          classes={{
                            root: classes.root,
                          }}
                        />
                      )}
                    </Grid>
                  );
                })}
                {(!isCandor(userType) || isUpdateDetails) && (
                  <Button
                    disabled={inputValue === undefined || isLoading}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleInput}
                  >
                    {buttonText}
                  </Button>
                )}
              </RadioGroup>
            </Grid>
          </Paper>
        </Grid>
      </StyledContainer>
    )
  );
};

export default RadioFormElementPresentation;
