import { DescriptionRounded, ListAltRounded, LocalShippingRounded, ShoppingBasketRounded } from '@mui/icons-material';

import { SHIPMENT_TYPES } from '../../utils/constants';

/**
 * List of Australian states for filtering
 */
export const AUSTRALIAN_STATES = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

/**
 * Pharmacy types for filtering
 */
export const PHARMACY_TYPES = {
  ALL: 'all',
  PHARMACY: 'pharmacy',
  SUPPLIER: 'supplier',
  SUPPORT: 'support',
};

/**
 * Current orders filters
 * @type {readonly ["", "shipment required", "pickup required", "script only"]}
 */
export const CURRENT_ORDERS_FILTERS = [
  /** @type {""} */ (''),
  /** @type {"shipment required"} */ (SHIPMENT_TYPES.SHIPMENT_REQUIRED),
  /** @type {"pickup required"} */ (SHIPMENT_TYPES.PICKUP_REQUIRED),
  /** @type {"script only"} */ (SHIPMENT_TYPES.SCRIPT_ONLY),
];

/**
 * Order current statuses
 * @type {Object<string, OrderCurrentStatus>}
 */
export const ORDER_CURRENT_STATUS = {
  READY_FOR_PICKUP: 'READY_FOR_PICKUP',
  READY_TO_SHIP: 'READY_TO_SHIP',
};

/**
 * Current orders current status filters
 */
export const CURRENT_ORDERS_CURRENT_STATUS_FILTERS = [
  /** @type {""} */ (''),
  /** @type {"READY_FOR_PICKUP"} */ (ORDER_CURRENT_STATUS.READY_FOR_PICKUP),
  /** @type {"READY_TO_SHIP"} */ (ORDER_CURRENT_STATUS.READY_TO_SHIP),
];

/**
 * @type {Record<CURRENT_ORDERS_FILTERS[number], import('@mui/icons-material').SvgIconComponent>}
 */
export const STATUS_ICON = {
  '': ListAltRounded,
  'pickup required': ShoppingBasketRounded,
  'shipment required': LocalShippingRounded,
  'script only': DescriptionRounded,
};
