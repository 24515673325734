import { useContext } from 'react';

import { AuthContext } from '../../../auth-context';

/**
 * Custom hook to access authentication context
 * @returns {Omit<import('../../../auth-context').AuthContextValue, 'user'> & { user: NonNullable<import('../../../auth-context').AuthContextValue['user']> }} Authentication context values
 */
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  if (!context.user) {
    throw new Error('User is not set');
  }

  return { ...context, user: context.user };
};
