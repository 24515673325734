import React, { useEffect, useState } from 'react';
import { IconButton, styled, Tooltip } from '@mui/material';
import { doc, collection, writeBatch } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

import { db } from '../../../../firebase-config';
import { isPharmacist } from '../../../../utils/roles';
import { SHIPMENT_TYPES } from '../../../../utils/constants';
import createEmailObj from '../../../../functions/create-email-obj';
import { useAuthContext, usePharmacyContext } from '../../hooks';
import { ConditionalButton } from './ConditionalButton';
import { getReasonsThatOrderIsNotReadyForNotification } from './helpers';

const { PICKUP_REQUIRED } = SHIPMENT_TYPES;

const AnimatedIconButton = styled(IconButton)`
  height: 20px;
  width: 20px;
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(-13deg);
    }
    75% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  &.wiggling {
    animation: wiggle 0.5s ease;
  }
`;

/**
 * @param {Object} props
 * @param {ExtendedOrder} props.order - The order object
 * @param {boolean} props.isNotificationSent - Whether the notification has been sent
 * @param {string[]} props.reasonsThatOrderIsNotReadyForNotification - The reasons that the order is not ready for notification
 * @returns {string}
 */
const getFullTooltipText = ({ order, isNotificationSent, reasonsThatOrderIsNotReadyForNotification }) => {
  const canNotifyPatient = reasonsThatOrderIsNotReadyForNotification.length === 0 && !isNotificationSent;

  const patientName = order.name || 'patient';

  if (canNotifyPatient) {
    return `Notify ${patientName} that their order is ready`;
  }

  if (isNotificationSent) {
    return `Notification already sent to ${patientName}`;
  }

  return `Cannot notify ${patientName} because: ${reasonsThatOrderIsNotReadyForNotification.join(', ')}`;
};

/**
 * @param {Object} props
 * @param {ExtendedOrder} props.order - The order object
 * @param {"full" | "icon"} [props.variant="full"] - The variant of the button
 * @param {string} [props.className] - The class name of the button
 * @returns {React.ReactNode}
 */
export const SendNotificationToPatient = ({ order, variant = 'full', className }) => {
  const { user, userType } = useAuthContext();
  const [isNotificationSent, setIsNotificationSent] = useState(order.notificationSent ?? false);
  const [isWiggling, setIsWiggling] = useState(false);
  const { snackbar } = usePharmacyContext();

  const handleNotifyPatient = async () => {
    setIsWiggling(true);

    try {
      const batch = writeBatch(db);
      const mailRef = doc(collection(db, 'mail'));

      const emailData = createEmailObj(`${order.name} <${order.email}>`, 'order_ready_for_pickup', {
        patientName: order.name.split(' ')[0],
        pharmacyName: order.pharmacyInfo?.name,
        pharmacyAddress: order.pharmacyInfo?.address,
      });

      batch.set(mailRef, emailData);

      const outstandingOrderRef = doc(db, 'orders', user.uid, 'outstanding', order.payment);

      batch.update(outstandingOrderRef, { notificationSent: true });

      await batch.commit();

      setIsNotificationSent(true);
      snackbar(`A notification has been sent to the ${order.name}`);
    } catch (error) {
      sentryCaptureException(error, {
        extra: {
          order,
          userId: user.uid,
          pharmacyName: order.pharmacyInfo?.name,
          issueIn: 'handleNotifyPatient',
        },
      });
      snackbar('An error was encountered while sending the notification email');
    }
  };

  useEffect(() => {
    if (!isWiggling) {
      // We have to return a function here to appease the linting gods
      return () => {};
    }

    const timer = setTimeout(() => {
      setIsWiggling(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isWiggling]);

  if (!isPharmacist(userType) || order.status !== PICKUP_REQUIRED) {
    return null;
  }

  const reasonsThatOrderIsNotReadyForNotification = getReasonsThatOrderIsNotReadyForNotification({
    isAwaitingPayment: order.isAwaitingPayment,
    isEscriptValid: order.isEscriptValid,
    userVerified: order.userVerified,
  });

  if (variant === 'full') {
    return (
      <Tooltip title={getFullTooltipText({ order, isNotificationSent, reasonsThatOrderIsNotReadyForNotification })}>
        <div>
          <ConditionalButton
            className={className}
            shouldRender
            displayText={isNotificationSent ? 'Notification Sent' : 'Notify Patient Order Is Ready'}
            isDisabled={reasonsThatOrderIsNotReadyForNotification.length > 0 || isNotificationSent}
            onClick={handleNotifyPatient}
            variant="outlined"
          />
        </div>
      </Tooltip>
    );
  }

  if (isNotificationSent && !isWiggling) {
    return null;
  }

  return (
    <Tooltip title={`Notify ${order.name || 'patient'} that their order is ready`}>
      <AnimatedIconButton
        onClick={handleNotifyPatient}
        disabled={reasonsThatOrderIsNotReadyForNotification.length > 0 || isNotificationSent}
        className={isWiggling ? 'wiggling' : ''}
      >
        <NotificationsActiveRoundedIcon sx={{ fontSize: 14 }} />
      </AnimatedIconButton>
    </Tooltip>
  );
};
