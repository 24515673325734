import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid2 as Grid, Paper, Container, Typography, Button } from '@mui/material';

import { AuthContext } from '../../../auth-context';

const PREFIX = 'AuthLandingPageSuccess';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  logo: `${PREFIX}-logo`,
  paper: `${PREFIX}-paper`,
  link: `${PREFIX}-link`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.input}`]: {
    margin: '20px auto',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto',
  },

  [`& .${classes.logo}`]: {
    margin: '20px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.paper}`]: {
    display: 'grid',
    padding: '20px',
    width: '80%',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#2AAFBB',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: '16px',
  },
}));

const AUTH_LANDING_PAGE_SUCCESS_TITLE = {
  VERIFY_AND_CHANGE_EMAIL: 'Success: Email Verified & Changed',
  RECOVER_EMAIL: 'Success: Email Recovered',
  REVERT_SECOND_FACTOR_ADDITION: 'Success: Two-Factor Authentication Removed',
  RESET_PASSWORD: 'Success: Password Reset',
};

const AuthLandingPageSuccess = () => {
  const { user, setAccountDetailsPage } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();

  const operation = location.state?.operation;

  const handleButtonClick = () => {
    if (operation === 'RECOVER_EMAIL' || operation === 'VERIFY_AND_CHANGE_EMAIL') {
      navigate('/');
    } else navigate('/dashboard');
  };

  const redirectToSecurityDetails = () => {
    navigate('/account-details');
    setAccountDetailsPage('security');
  };

  return (
    <StyledContainer maxWidth="md">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '70vh' }}
      >
        <Paper className={classes.paper}>
          <Typography variant="h5" align="center" gutterBottom>
            {AUTH_LANDING_PAGE_SUCCESS_TITLE[operation]}
          </Typography>
          {operation === 'REVERT_SECOND_FACTOR_ADDITION' && (
            <Typography variant="body1" align="center" gutterBottom>
              If you think that there is an unusual activity on your account, please contact support.
            </Typography>
          )}
          {user && (operation === 'VERIFY_AND_CHANGE_EMAIL' || operation === 'RESET_PASSWORD') && (
            <Grid size={{ xs: 12 }} alignContent="center" textAlign="center">
              <br />
              <Typography variant="body1" gutterBottom>
                Tip: For Extra Security Enable 2-Factor Auth in the{' '}
                <Typography display="inline-block" onClick={redirectToSecurityDetails} className={classes.link}>
                  account page
                </Typography>
              </Typography>
            </Grid>
          )}
          {!user && operation === 'VERIFY_AND_CHANGE_EMAIL' && (
            <Grid size={{ xs: 12 }} alignContent="center" textAlign="center">
              <br />
              <Typography variant="body1" gutterBottom>
                Account Successfully Created and Email Changed
              </Typography>
            </Grid>
          )}
          {operation === 'RECOVER_EMAIL' && (
            <Grid size={{ xs: 12 }} alignContent="center" textAlign="center">
              <br />
              <Typography variant="body1" align="center" gutterBottom>
                Your email has been recovered. You can now sign in with your previous email.
              </Typography>
            </Grid>
          )}
          <Button onClick={handleButtonClick} variant="contained" color="primary" className={classes.button}>
            {!user ? 'Sign In' : 'Go to Dashboard'}
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default AuthLandingPageSuccess;
