import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Tabs,
  Tab,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../../../../firebase-config';
import Loading from '../../../../layout/loading';

const PREFIX = 'ScoresHistory';

const classes = {
  container: `${PREFIX}-container`,
  emptyContainer: `${PREFIX}-emptyContainer`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  button: `${PREFIX}-button`,
  date: `${PREFIX}-date`,
  scoreCard: `${PREFIX}-scoreCard`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.emptyContainer}`]: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    width: '85%',
    maxWidth: '800px',
    minHeight: '400px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.button}`]: {
    margin: '20px 20px 0px 0px',
    width: '160px',
  },

  [`& .${classes.date}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.scoreCard}`]: {
    margin: '10px 0',
  },
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <>{children}</>
      </Box>
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const sortObjectKeys = (itemData = {}) =>
  Object.fromEntries(Object.entries(itemData).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)));

const convertAndSortScoresData = (scoreData = {}) =>
  Object.entries(scoreData)
    .reduce(
      (accData, [date, itemData = {}]) => (date ? [...accData, { date, results: sortObjectKeys(itemData) }] : accData),
      [],
    )
    // sort by date
    .sort((a, b) => b.date - a.date);

const convertData = (infoData = {}) =>
  Object.entries(infoData).reduce((acc, [scoreName = '', scoreData = {}]) => {
    // exits if the field does not contain 'scores'
    if (!scoreName.toLowerCase().includes('scores')) {
      return acc;
    }

    return [
      ...acc,
      {
        name: scoreName,
        data: convertAndSortScoresData(scoreData),
      },
    ];
  }, []);

const ScoresHistory = ({ handleModalClose, patientId }) => {
  const isMounted = useRef(false);
  const [scores, setScores] = useState([]);
  const [scoreValue, setScoreValue] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleChangeTab = (_, newValue) => {
    setScoreValue(newValue);
  };

  const getData = useCallback(async () => {
    const patientData = await getDoc(doc(db, 'patients', patientId, 'general', 'information'));
    setScores(convertData(patientData.data() || {}));
    setDataLoaded(true);
  }, [patientId]);

  useEffect(() => {
    isMounted.current = true;
    getData();
    return () => {
      isMounted.current = false;
    };
  }, [getData]);

  return (
    <StyledContainer maxWidth="md">
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box className={classes.box}>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
            </Box>
          </Grid>
          {!dataLoaded ? (
            <Loading />
          ) : (
            <Grid size={{ xs: 12}}>
              {scores.length > 0 ? (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={scoreValue}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                    >
                      {scores.map((score) => (
                        <Tab key={score.name} label={score.name} />
                      ))}
                    </Tabs>
                  </Box>
                  <Grid
                    sx={{
                      padding: '10px',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 500,
                    }}
                  >
                    {scores.map((score, index) => (
                      <div key={score.name}>
                        {score.data.map((item) => (
                          <Paper className={classes.scoreCard} key={item.date}>
                            <TabPanel value={scoreValue} index={index} className={classes.scoreCard}>
                              <Grid container>
                                <Box>
                                  <Typography className={classes.date}>
                                    {new Date(Number(item.date)).toLocaleString('en-AU', {
                                      day: 'numeric',
                                      month: 'numeric',
                                      year: '2-digit',
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true,
                                    })}
                                  </Typography>
                                  <List dense>
                                    {Object.keys(item.results).map((result) => (
                                      <ListItem key={result}>
                                        <ListItemText primary={`${result} : ${item.results[result]}`} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Box>
                              </Grid>
                            </TabPanel>
                          </Paper>
                        ))}
                      </div>
                    ))}
                  </Grid>
                </>
              ) : (
                <Grid container className={classes.emptyContainer}>
                  <Typography> No Record </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

ScoresHistory.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default ScoresHistory;
