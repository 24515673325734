import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { getUserTypeValidity, SUPPORT_ID, USER_TYPES } from '../../../utils/constants';
import { useAuthContext } from '../hooks';
import { PharmacyProvider } from '../providers';

const { SUPPORT, DOCTOR, NURSE, DIRECTOR } = USER_TYPES;

export const PharmacyLayout = () => {
  const { user, userType } = useAuthContext();

  const orderReceiverId = useMemo(() => {
    const validUsers = [SUPPORT, DOCTOR, NURSE, DIRECTOR];
    if (getUserTypeValidity({ userType, validUsers })) {
      return SUPPORT_ID;
    }

    return user?.uid;
  }, [userType, user?.uid]);

  return (
    <PharmacyProvider pharmacyId={orderReceiverId}>
      <Outlet />
    </PharmacyProvider>
  );
};
