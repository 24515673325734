import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const MinoxidilInformation = () => (
  <Grid>
    <Typography variant="h6">About Minoxidil</Typography>
    <br />
    <Typography variant="body1">
      Minoxidil promotes hair growth by increasing the length of the growth cycle of hair and enlarging hair follicles.
      More information on how hair grows and why you may be experiencing hair loss can be found on our website under
      hair treatments.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Minoxidil is a topical treatment in the form of a liquid or foam. It is applied to areas when hair growth is
      required.
      <br />
      <br />
      If using the liquid, apply 1ml twice a day.
      <br />
      <br />
      If using the foam apply approximately half a capful twice a day (for men) or once a day (for women).
      <br />
      <br />
      There are several important considerations when using minoxidil:
      <br />
      <br />
      - Avoid contact with eyes
      <br />
      - Apply to dry skin and hair. Make sure to wash hands thoroughly after application to avoid transfer to other
      areas
      <br />
      - Allow hair to dry completely before going to bed
      <br />
      - Do not wear a wig, scarf or hat for at least 1 hour after use to avoid excess absorption
      <br />- Wait at least 4 hours after use before washing hair
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Minoxidil is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (More than 1%):
      <br />
      - Itch
      <br />
      - Erythema
      <br />
      - Dry skin
      <br />
      - Dermatitis
      <br />
      - Hypertrichosis (excess hair growth)
      <br />
      <br />
      Hypertrichosis is more common in women, particularly if high strengths are used. This commonly occurs on the face
      but may occur in other areas. It is important to read the instructions on how to use carefully.
      <br />
      <br />
      When first using minoxidil a temporary increase in hair loss may occur. Increase in hair growth generally occurs
      after 3-4 months of treatment and minoxidil use needs to be continued to keep seeing a benefit. On stopping
      minoxidil use, hair that is grown may be lost again. If no benefit is seen after 6 months, discuss with your
      Candor GP.
      <br />
      <br />
      Please note further detailed information on Minoxidil including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Minoxidil when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. There is limited data on Minoxidil use in pregnancy and it is therefore not recommended.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Minoxidil when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      While Minoxidil appears to be safe when breastfeeding, it does appear to be transmitted to breastmilk and may have
      effects on infants. Therefore we do not recommend use.
    </Typography>
  </Grid>
);

export default MinoxidilInformation;
