import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Grid2 as Grid, Paper, Typography, Button, TextField, CircularProgress } from '@mui/material';
import { doc, collection, writeBatch } from 'firebase/firestore';

import { db } from '../../../../../firebase-config';
import { AuthContext } from '../../../../../auth-context';
import { GEN_PATIENT_INFO_PROP_TYPES } from '../../../constants';

const getText = ({ origFlagText, flagText, email }) => {
  if (!origFlagText) {
    return `${email} updated the optional flag to '${flagText}'`;
  }
  if (!flagText) {
    return `${email} removed the optional flag (previous: ${origFlagText})`;
  }
  return `${email} updated the optional flag from '${origFlagText}' to '${flagText}'`;
};

const OptionalFlag = ({ handleModalClose, handleModalOpen, generalPatientInfo }) => {
  const { id: patientId, optionalFlag } = generalPatientInfo;
  const { user, userType } = useContext(AuthContext);
  const origFlagText = optionalFlag || '';
  const [flagText, setFlagText] = useState(origFlagText);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (flagText === origFlagText) {
      return;
    }
    try {
      setLoading(true);
      const batch = writeBatch(db);
      const date = Date.now();
      batch.update(doc(db, 'patients', patientId), { optionalFlag: flagText });
      batch.set(doc(collection(db, 'patients', patientId, 'activity')), {
        createdAt: date,
        generalData: true,
        author: 'System',
        text: getText({ origFlagText, flagText, email: user.email }),
      });
      handleModalOpen('Result: Optional flag updated successfully');
      await batch.commit();
      setLoading(false);
    } catch (error) {
      handleModalOpen('Error: Failed to update optional flag');
      sentryCaptureException(error, {
        extra: { generalPatientInfo, userType, userId: user.uid, issueIn: 'optional flag' },
      });
    }
  };

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        minHeight: '60vh',
        mt: '40px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      spacing={0}
    >
      <Paper
        elevation={0}
        sx={{
          p: '40px',
          width: '85%',
          maxWidth: '500px',
          minWidth: '300px',
          '@media (max-width: 600px)': {
            p: '20px',
          },
        }}
      >
        <Typography variant="h5" align="center" marginBottom="30px">
          Optional Flag
        </Typography>
        <TextField
          type="text"
          variant="outlined"
          multiline
          fullWidth
          maxRows={4}
          onChange={({ target }) => setFlagText(target.value)}
          value={flagText || ''}
          disabled={loading}
          slotProps={{
            htmlInput: { maxLength: 140 },
          }}
        />
        {loading ? (
          <CircularProgress sx={{ m: '30px auto 20px auto', display: 'block' }} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{ m: '30px auto 20px auto', width: '200px', display: 'block' }}
            disabled={origFlagText === flagText}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
        <Button
          variant="text"
          color="primary"
          sx={{ width: '200px', display: 'block', m: 'auto' }}
          onClick={handleModalClose}
        >
          Close
        </Button>
      </Paper>
    </Grid>
  );
};

OptionalFlag.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.shape(GEN_PATIENT_INFO_PROP_TYPES).isRequired,
};

export default OptionalFlag;
