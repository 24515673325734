import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { Grid2 as Grid, Typography, Box, Button, Stack, useMediaQuery, useTheme, Tooltip } from '@mui/material';

import { isCandor } from '../../../utils';
import { AuthContext } from '../../../auth-context';
import { CopyButton } from '../../../components/ui/CopyButton';
import { convertBirthdayText } from '../../../functions/date-conversion';
import { getCatalogueId, getUserTypeValidity } from '../../../utils/constants';
import { getPharmacyAddressAsText } from '../helpers';
import { useAuthContext, usePharmacyActions, usePharmacyContext } from '../hooks';

const PREFIX = 'ScriptsDetail';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  box: `${PREFIX}-box`,
  detailsText: `${PREFIX}-detailsText`,
  boldText: `${PREFIX}-boldText`,
  link: `${PREFIX}-link`,
};

const StyledGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.gridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.box}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    width: '100%',
    alignItems: 'center',
  },

  [`& .${classes.detailsText}`]: {
    marginTop: 5,
  },

  [`& .${classes.boldText}`]: {
    fontWeight: 600,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },
}));

const validUsers = ['director', 'support'];

const PrescriberSectionRender = ({ isInsideScriptsArray = false, prescribersData }) => {
  // Removes duplicate prescribers
  const uniquePrescribers = isInsideScriptsArray
    ? prescribersData.reduce((acc, script) => {
        if (!acc.some((checkScript) => checkScript.prescriber === script.prescriber)) {
          acc.push(script);
        }
        return acc;
      }, [])
    : prescribersData;

  return (
    <StyledGrid mb={2}>
      <Typography variant="body1" className={classes.boldText}>
        Prescriber{uniquePrescribers.length > 1 && 's'}
      </Typography>
      {uniquePrescribers.map((data) => {
        const prescriber = isInsideScriptsArray ? data.prescriber : data;

        if (!prescriber) {
          return null;
        }

        return (
          <Box key={prescriber} component="span" style={{ display: 'block' }}>
            <CopyButton noHighlight textToCopy={prescriber}>
              {isInsideScriptsArray ? data.prescriber : data}
            </CopyButton>
          </Box>
        );
      })}
    </StyledGrid>
  );
};

PrescriberSectionRender.propTypes = {
  isInsideScriptsArray: PropTypes.bool,
  prescribersData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])).isRequired,
};

const DetailsRender = ({ label, text, copyable = false }) => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Typography variant="body2" fontWeight="bold">
      {label}:
    </Typography>
    {copyable ? (
      <CopyButton noHighlight textToCopy={text}>
        {text}
      </CopyButton>
    ) : (
      <Typography variant="body2">{text}</Typography>
    )}
  </Stack>
);

/**
 * @param {Object} props
 * @param {Object} props.order
 * @param {boolean} [props.isTga=false]
 * @param {Object} props.script
 * @param {string} props.productName
 * @param {boolean} [props.isSafeToRender=false]
 * @param {boolean} props.isDownloading
 * @param {import('../hooks/usePharmacyActions').ScriptDownloadReturn['scriptDownload']} props.scriptDownload
 * @param {() => void} props.setIsDownloading
 * @returns {JSX.Element | null}
 */
const ButtonDownload = ({
  order,
  isTga = false,
  script,
  productName,
  isSafeToRender = false,
  isDownloading,
  scriptDownload,
  setIsDownloading,
}) => {
  if (!isSafeToRender) {
    return null;
  }

  return (
    <Grid size={{ xs: 12 }}>
      <Button
        variant="text"
        loading={isDownloading}
        loadingPosition="end"
        onClick={() =>
          scriptDownload({
            order,
            script,
            isTga,
            setIsLoading: setIsDownloading,
          })
        }
      >
        {productName} {isTga ? 'TGA Approval' : 'Prescription'}
      </Button>
    </Grid>
  );
};

const OtherRefundsSection = ({ otherRefunds }) => {
  if (!Object.keys(otherRefunds || []).length) {
    return null;
  }

  const sortedOtherRefunds = Object.entries(otherRefunds).sort((a, b) => b[0] - a[0]);

  return (
    <StyledGrid mb={2}>
      <Typography variant="body1" className={classes.boldText}>
        Other Refunds
      </Typography>
      <Stack direction="column" alignItems="flex-start" spacing={1} width="100%">
        {sortedOtherRefunds.map(([key, val]) => {
          const date = new Date(Number(key));
          return (
            <Box key={key} component="span" className={classes.box} sx={{ width: '100%' }}>
              <Typography variant="body2" sx={{ width: 60, minWidth: 'fit-content' }}>
                $ {Number.parseFloat(val).toFixed(2)}
              </Typography>
              <Tooltip
                placement="right"
                title={`Refunded ${
                  date
                    ? date.toLocaleString('en-AU', {
                        dateStyle: 'full',
                        timeStyle: 'medium',
                      })
                    : 'No date provided'
                }`}
              >
                <Typography variant="body2" color="secondary">
                  - Refunded {date ? date.toLocaleDateString('en-AU', { dateStyle: 'medium' }) : 'No date provided'}
                </Typography>
              </Tooltip>
            </Box>
          );
        })}
      </Stack>
    </StyledGrid>
  );
};

const ScriptOnlyPharmacySection = ({
  isScriptOnly = false,
  pharmacyName = '',
  pharmacyAddress = '',
  pharmacyPhone = '',
}) => {
  if (!isScriptOnly) {
    return null;
  }

  return (
    <Grid mb={2}>
      <Typography variant="body1" style={{ fontWeight: 600, marginTop: 20 }}>
        Nominated Pharmacy
      </Typography>
      <DetailsRender label="Pharmacy Name" text={pharmacyName} />
      <DetailsRender label="Pharmacy Address" text={pharmacyAddress} />
      <DetailsRender label="Pharmacy Phone" text={pharmacyPhone} />
    </Grid>
  );
};

const PatientDetails = ({ id, name = '', legalName = '', dob = '', phone = '', email = '', shipping = {} }) => {
  const { userType } = useAuthContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewPatient = () => {
    if (!id) {
      return;
    }

    navigate(`/patients/${id}`);
  };

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={2}>
      <Stack>
        <Typography variant="body1" style={{ fontWeight: 600 }}>
          Patient Details
        </Typography>
        <DetailsRender label="Preferred Name" text={name} />
        <DetailsRender label="Legal Name" text={legalName || 'Not set yet'} />
        <DetailsRender label="DOB" text={convertBirthdayText(dob)} />
        <DetailsRender label="Phone" text={phone} copyable />
        <DetailsRender label="Email" text={email} copyable />
        <DetailsRender label="Shipping Address" text={getPharmacyAddressAsText({ shipping })} />
      </Stack>

      {isCandor(userType) && (
        <Button variant="outlined" onClick={handleViewPatient}>
          {isMobile ? <ArrowForward fontSize="small" /> : 'View Patient'}
        </Button>
      )}
    </Stack>
  );
};

const PrescriberSection = ({ scriptsArray = [], prescribers = [] }) => {
  if (scriptsArray?.some((script) => script.prescriber)) {
    return <PrescriberSectionRender isInsideScriptsArray prescribersData={scriptsArray} />;
  }

  if (prescribers?.length) {
    return <PrescriberSectionRender prescribersData={prescribers} />;
  }

  return null;
};

const renderScriptOnFileMsg = ({ scriptOnFile, eScriptToken, overTheCounter, productName }) => {
  if (scriptOnFile && !eScriptToken && !overTheCounter) {
    return (
      <Typography variant="body2" fontWeight="bold">
        No script attached for {productName}. Patient has a repeat on file.
      </Typography>
    );
  }
  return null;
};

const EscriptToken = ({ refunded, productName, eScriptToken, lastRepeat }) => {
  if (!eScriptToken) {
    return null;
  }

  const eScriptText = `${refunded ? 'Refunded: ' : ''}${productName} - eRx Token: `;
  const lastRepeatText = lastRepeat ? '(Last Repeat)' : null;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} mb={1} sx={{ textAlign: 'left' }}>
      <Typography variant="body2">{eScriptText}</Typography>
      <CopyButton
        textToCopy={eScriptToken}
        sx={{
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'flex-start',
        }}
      >
        {eScriptToken}
      </CopyButton>
      {lastRepeatText && <Typography variant="body2">{lastRepeatText}</Typography>}
    </Stack>
  );
};

const TransferMsg = ({ scriptOnFile, transfer, originalRemaining, remainingCount }) => {
  if (scriptOnFile || !transfer) {
    return null;
  }

  return (
    <Grid size={{ xs: 12 }}>
      <Typography variant="body2" fontWeight="bold">
        Script transferred from {transfer}.
        {originalRemaining && ` Originally prescribed: ${originalRemaining} dispenses.`}{' '}
        {remainingCount && `Currently ${remainingCount} dispense${remainingCount > 1 ? 's' : ''} remaining.`}
      </Typography>
    </Grid>
  );
};

const ScriptsList = ({
  order,
  isValidUser = false,
  scriptsArray = [],
  catalogue = {},
  isTgaDownloading = false,
  isMedicalCannabis = false,
  setIsTgaDownloading,
  isPrescriptionDownloading = false,
  setIsPrescriptionDownloading,
}) => {
  const { scriptDownload } = usePharmacyActions();

  if (!scriptsArray?.length || !isValidUser || !Object.keys(catalogue || {}).length) {
    return null;
  }

  return (
    <Stack direction="column" spacing={2}>
      {scriptsArray.map((script, idx) => {
        const {
          priceRef,
          refunded = false,
          transfer,
          lastRepeat,
          eScriptToken,
          scriptOnFile,
          remainingCount,
          originalRemaining,
        } = script;

        const id = getCatalogueId(priceRef);

        const { overTheCounter, name: productName = '' } = catalogue?.[id] || {};

        const key = `${priceRef ?? idx}-${idx}`;

        return (
          <Stack key={key} direction="column" alignItems="flex-start" justifyContent="space-between" gap={1} mb={1}>
            {renderScriptOnFileMsg({ scriptOnFile, eScriptToken, overTheCounter, productName })}
            <EscriptToken
              refunded={refunded}
              productName={productName}
              eScriptToken={eScriptToken}
              lastRepeat={lastRepeat}
            />
            <ButtonDownload
              order={order}
              script={script}
              productName={productName}
              scriptDownload={scriptDownload}
              isDownloading={isPrescriptionDownloading}
              setIsDownloading={setIsPrescriptionDownloading}
              isSafeToRender={!scriptOnFile && !eScriptToken}
            />
            <ButtonDownload
              order={order}
              isTga
              script={script}
              productName={productName}
              scriptDownload={scriptDownload}
              isDownloading={isTgaDownloading}
              setIsDownloading={setIsTgaDownloading}
              isSafeToRender={!scriptOnFile && isMedicalCannabis}
            />
            <TransferMsg
              scriptOnFile={scriptOnFile}
              transfer={transfer}
              originalRemaining={originalRemaining}
              remainingCount={remainingCount}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export const OrderDetailsScriptDetails = ({ order, scriptOnly = false }) => {
  const { userType } = React.useContext(AuthContext);
  const [isTgaDownloading, setIsTgaDownloading] = React.useState(false);
  const [isPrescriptionDownloading, setIsPrescriptionDownloading] = React.useState(false);
  const { catalogue } = usePharmacyContext();

  const {
    formName,
    otherRefunds,
    scriptsArray,
    prescribers,
    name = '',
    legalName = '',
    dob = '',
    email = '',
    pharmacyInfo: { name: pharmacyName = '', address: pharmacyAddress = '', phone: pharmacyPhone = '' } = {},
    shipping: { phone = '' } = {},
  } = order;

  const isMedicalCannabis = formName === 'medicinal cannabis';
  const isValidUser = getUserTypeValidity({ userType, validUsers });

  React.useEffect(() => {
    setIsTgaDownloading(false);
    setIsPrescriptionDownloading(false);
  }, [order, setIsTgaDownloading, setIsPrescriptionDownloading]);

  return (
    <Stack direction="column" spacing={2}>
      <OtherRefundsSection otherRefunds={otherRefunds} />
      <PrescriberSection scriptsArray={scriptsArray} prescribers={prescribers} />
      <ScriptOnlyPharmacySection
        isScriptOnly={scriptOnly}
        pharmacyName={pharmacyName}
        pharmacyAddress={pharmacyAddress}
        pharmacyPhone={pharmacyPhone}
      />
      <PatientDetails
        id={order.user}
        dob={dob}
        name={name}
        legalName={legalName}
        phone={phone}
        email={email}
        shipping={order.shipping}
      />
      <ScriptsList
        order={order}
        isValidUser={isValidUser}
        scriptsArray={scriptsArray}
        catalogue={catalogue}
        isMedicalCannabis={isMedicalCannabis}
        isTgaDownloading={isTgaDownloading}
        setIsTgaDownloading={setIsTgaDownloading}
        isPrescriptionDownloading={isPrescriptionDownloading}
        setIsPrescriptionDownloading={setIsPrescriptionDownloading}
      />
    </Stack>
  );
};
