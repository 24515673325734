import React from 'react';

import { useOrderDetailsContext } from '../../hooks/useOrderDetailsContext';
import {
  AddNote,
  ArchiveOrder,
  RedirectOrder,
  RefundProduct,
  ReturnToPharma,
  RevertOrder,
  SubstituteItem,
  UndoMarkComplete,
  MessagePatient,
} from './ToolsDropDown';

/**
 * @typedef {keyof typeof MENU_ITEMS} MenuItem
 */

const MENU_ITEMS = {
  addNote: AddNote,
  archive: ArchiveOrder,
  messagePatient: MessagePatient,
  redirectOrder: RedirectOrder,
  refundOrder: RefundProduct, // Why is this called refundOrder not refund product :,(?
  returnToPharma: ReturnToPharma,
  revertOrder: RevertOrder,
  substituteItem: SubstituteItem,
  undoMarkComplete: UndoMarkComplete,
};

/**
 * @param {Object} props
 * @param {MenuItem} props.type - The type of tool to display.
 * @param {Function} props.onClose - The function to call when the menu is closed.
 */
export const OrderDetailsToolsDropdownMenu = ({ type, onClose }) => {
  const { handleDialogue } = useOrderDetailsContext();

  /**
   * @param {React.ReactNode} content
   * @param {boolean} [attemptToAttachToDialogue=false]
   */
  const openSecondaryModal = (content, attemptToAttachToDialogue) => {
    handleDialogue({ isOpen: true, content, attemptToAttachToDialogue });
    onClose();
  };

  const Component = MENU_ITEMS[type];

  return Component ? <Component openSecondaryModal={openSecondaryModal} /> : null;
};
