import React from 'react';
import { Message } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material';

import { SendMessage as SendMessageComponent } from '../../../../../components/patient-management/PatientPage/head-section/head-section-tools';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';

const MessagePatientModalContent = () => {
  const { pharmacy } = usePharmacyContext();
  const { order, handleDialogue } = useOrderDetailsContext();

  const handleOnClose = () => {
    handleDialogue({ isOpen: false, content: null });
  };

  return (
    <SendMessageComponent
      patientId={order.user}
      pharmacyId={pharmacy.id}
      handleModalClose={handleOnClose}
      onClose={handleOnClose}
    />
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode, attemptToAttachToDialogue: boolean) => void} props.openSecondaryModal
 */
export const MessagePatient = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<MessagePatientModalContent />, true);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<Message />}>
        Message Patient
      </Button>
    </MenuItem>
  );
};
