import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Checkbox, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../../../firebase-config';
import { GEN_PATIENT_INFO_PROP_TYPES } from '../../../constants';

const COMMON_PROPTYPES = {
  userId: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
};

const EmailUpdateForm = ({ userId, existingEmail, mode, setMode }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [checklist, setChecklist] = useState([
    {
      message: 'I have called the patient on their account phone number to confirm their identity',
      checked: false,
      id: 1,
    },
  ]);

  const handleEmailUpdate = async () => {
    setLoading(true);
    const result = await httpsCallable(functions, 'updateSecurityDetails')({ mode, userDetails: { userId, email } });
    setLoading(false);
    if (result.data.error) {
      window.alert(result.data.error);
    } else {
      window.alert('Email has been updated');
      setMode('select');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
        Update Email
      </Typography>
      {checklist.map((checkItem, idx) => (
        <Box key={checkItem.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Checkbox
            checked={checklist[idx].checked}
            onChange={(e) => {
              setChecklist((prev) => {
                const newChecklist = [...prev];
                newChecklist[idx].checked = e.target.checked;
                return newChecklist;
              });
            }}
          />
          <Typography variant="body1">{checkItem.message}</Typography>
        </Box>
      ))}
      <Typography variant="caption" color="primary" textAlign="center" sx={{ my: 2 }}>
        Current Email: {existingEmail}
      </Typography>
      <TextField label="New Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
      {loading && <CircularProgress sx={{ alignSelf: 'center', my: 2 }} />}
      {!loading && (
        <>
          <Button
            disabled={checklist.some((checkItem) => !checkItem.checked)}
            variant="contained"
            sx={{ mt: 2, mb: 1 }}
            onClick={handleEmailUpdate}
          >
            Update Email
          </Button>
          <Button onClick={() => setMode('select')}>Back</Button>
        </>
      )}
    </Box>
  );
};

EmailUpdateForm.propTypes = { ...COMMON_PROPTYPES, existingEmail: PropTypes.string.isRequired };

const RemoveTwoFactorForm = ({ userId, existingPhone, mode, setMode }) => {
  const [loading, setLoading] = useState(false);
  const [checklist, setChecklist] = useState([
    {
      message: 'I have emailed the patient on their account email to confirm their identity.',
      checked: false,
      id: 1,
    },
  ]);

  const handleTwoFactorRemoval = async () => {
    setLoading(true);
    const result = await httpsCallable(functions, 'updateSecurityDetails')({ mode, userDetails: { userId } });
    setLoading(false);
    if (result.data.error) {
      window.alert(result.data.error);
    } else {
      window.alert('Two-factor has been removed');
      setMode('select');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="h6" textAlign="center" sx={{ mb: 1 }}>
        Remove Two-factor
      </Typography>
      {checklist.map((checkItem, idx) => (
        <Box key={checkItem.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Checkbox
            checked={checklist[idx].checked}
            onChange={(e) => {
              setChecklist((prev) => {
                const newChecklist = [...prev];
                newChecklist[idx].checked = e.target.checked;
                return newChecklist;
              });
            }}
          />
          <Typography variant="body1">{checkItem.message}</Typography>
        </Box>
      ))}
      <Typography variant="caption" color="primary" textAlign="center" sx={{ alignSelf: 'center', mt: 2 }}>
        Current Phone: {existingPhone}
      </Typography>
      {loading && <CircularProgress />}
      {!loading && (
        <>
          <Button
            variant="contained"
            disabled={checklist.some((checkItem) => !checkItem.checked)}
            sx={{ mt: 1 }}
            onClick={handleTwoFactorRemoval}
          >
            Remove Two-factor
          </Button>
          <Button onClick={() => setMode('select')}>Back</Button>
        </>
      )}
    </Box>
  );
};

RemoveTwoFactorForm.propTypes = { ...COMMON_PROPTYPES, existingPhone: PropTypes.string.isRequired };

const SecurityDetails = ({ handleModalOpen, handleModalClose, generalPatientInfo }) => {
  const [mode, setMode] = useState('select');

  const userId = generalPatientInfo.id;
  const existingEmail = generalPatientInfo.email;
  const existingPhone = generalPatientInfo?.shipping?.phone;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <Paper sx={{ p: 3, width: '95vw', minWidth: '300px', maxWidth: '450px' }}>
        {mode === 'select' && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
              Security Details
            </Typography>
            <Typography variant="body1" color="secondary" textAlign="center" sx={{ mb: 2 }}>
              Danger Zone Below: Please ensure you have taken the necessary steps to verify the user who requested this
              action is the legitimate account holder.
            </Typography>
            <Button
              variant="contained"
              sx={{ mb: 1 }}
              onClick={() => {
                setMode('updateEmail');
              }}
            >
              Update Email
            </Button>
            <Button
              variant="contained"
              sx={{ mb: 1 }}
              onClick={() => {
                setMode('removeTwoFactor');
              }}
            >
              Remove Two-factor
            </Button>
            <Button
              variant="contained"
              sx={{ mb: 1 }}
              onClick={() => {
                handleModalOpen('verify email');
              }}
            >
              Manually Verify Email
            </Button>
            <Button onClick={handleModalClose}>Cancel</Button>
          </Box>
        )}
        {mode === 'updateEmail' && (
          <EmailUpdateForm userId={userId} existingEmail={existingEmail} mode={mode} setMode={setMode} />
        )}
        {mode === 'removeTwoFactor' && (
          <RemoveTwoFactorForm userId={userId} existingPhone={existingPhone} mode={mode} setMode={setMode} />
        )}
      </Paper>
    </Box>
  );
};

SecurityDetails.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.PropTypes.shape(GEN_PATIENT_INFO_PROP_TYPES).isRequired,
};

export default SecurityDetails;
