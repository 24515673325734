import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ref, getDownloadURL } from 'firebase/storage';
import { captureException as sentryCaptureException } from '@sentry/react';
import { doc, getDocs, where, query, collection, writeBatch } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Container, Typography, Button, CircularProgress } from '@mui/material';

import { db, storage } from '../../../../../firebase-config';
import createEmailObj from '../../../../../functions/create-email-obj';
import { convertBirthdayText } from '../../../../../functions/date-conversion';

const PREFIX = 'VerifyIdentity';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  identityImage: `${PREFIX}-identityImage`,
  backButton: `${PREFIX}-backButton`,
  loading: `${PREFIX}-loading`,
};

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '95dvh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
    padding: '40px',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.identityImage}`]: {
    width: '400px',
    margin: '0px auto 30px auto',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '30px auto 0px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.loading}`]: {
    margin: '40px auto 10px auto',
    display: 'block',
  },
}));

const VerifyIdentity = ({ patientId, handleModalClose, generalPatientInfo, handleModalOpen, handleSnackbarOpen }) => {
  const [patientIdentityObj, setPatientIdentityObj] = useState({});
  const [identityDocs, setIdentityDocs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleVerifyIdentity = async () => {
    if (!identityDocs.length || !patientId) {
      handleSnackbarOpen(`Can't verify. Some of the identity documents are missing.`);
      return;
    }
    setLoading(true);
    try {
      const batch = writeBatch(db);
      const mailRef = doc(collection(db, 'mail'));
      batch.update(doc(db, 'patients', patientId), { identification: 'verified' });
      batch.set(
        mailRef,
        createEmailObj(`${patientIdentityObj.name} <${patientIdentityObj.email}>`, 'identity_verified'),
      );
      // updates all exiistng patient orders user verification
      const querySnapshot1 = await getDocs(
        query(collection(db, 'orders'), where('type', 'in', ['pharmacy', 'support'])),
      );
      await Promise.all(
        querySnapshot1.docs.map(async (snap) => {
          const orderIds = [];
          const querySnapshot2 = await getDocs(
            query(collection(db, 'orders', snap.id, 'outstanding'), where('user', '==', patientId)),
          );
          querySnapshot2.forEach((subSnap) => {
            orderIds.push(subSnap.id);
          });
          orderIds.forEach((orderId) => {
            batch.update(doc(db, 'orders', snap.id, 'outstanding', orderId), { userVerified: true });
          });
        }),
      );

      // update activity log
      batch.set(doc(collection(db, `patients/${patientId}/activity`)), {
        author: 'System',
        text: `Patient photo ID was verified by Support.`,
        createdAt: Date.now(),
        generalData: true,
      });

      await batch.commit();

      handleModalOpen('Result: Identity Verification Successful');
      setPatientIdentityObj({});
      setIdentityDocs([]);
      setLoading(false);
    } catch (error) {
      sentryCaptureException(error, {
        extra: { patientId, generalPatientInfo, issueIn: 'handleVerifyIdentity' },
      });
      handleModalOpen(`Error: Identity Verification Failed. ${error}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getData() {
      try {
        setPatientIdentityObj({
          name: `${generalPatientInfo.firstName} ${generalPatientInfo.lastName}`,
          sex: generalPatientInfo.sex,
          dob: convertBirthdayText(generalPatientInfo.dob),
          email: generalPatientInfo.email,
          address: `${generalPatientInfo.shipping['street address']}, ${generalPatientInfo.shipping.suburb}, ${generalPatientInfo.shipping.state}`,
          doctor: generalPatientInfo.doctor,
        });
        const idFront = await getDownloadURL(ref(storage, `/patient_documents/${patientId}/identity/front`));
        const idBack = await getDownloadURL(ref(storage, `/patient_documents/${patientId}/identity/back`));
        setIdentityDocs([idFront, idBack]);
      } catch (error) {
        handleSnackbarOpen(`Identity documents not found.`);
      }
    }
    getData();
  }, [generalPatientInfo, patientId]);

  return (
    <StyledContainer>
      <Grid className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <div>
            <Typography variant="h5" align="center" gutterBottom>
              Verify Identity
            </Typography>
          </div>
          <br />
          <Typography variant="body1" align="center" gutterBottom>
            {Object.entries(patientIdentityObj).map(([key, val]) => (
              <span key={val}>
                {key !== 'doctor' && val}
                <br />
                <br />
              </span>
            ))}
          </Typography>
          {identityDocs[0] && <img className={classes.identityImage} src={identityDocs[0]} alt="front" />}
          {identityDocs[1] && <img className={classes.identityImage} src={identityDocs[1]} alt="back" />}
          <Typography variant="body1" align="center" fontWeight="bold" gutterBottom>
            If the details above match EXACTLY click verify.
            <br /> Otherwise contact the patient for clarification.
          </Typography>
          {loading ? (
            <CircularProgress className={classes.loading} />
          ) : (
            <>
              <Button variant="contained" color="primary" className={classes.button} onClick={handleVerifyIdentity}>
                Verify
              </Button>
              <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
                Cancel
              </Button>
            </>
          )}
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

VerifyIdentity.propTypes = {
  patientId: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sex: PropTypes.string,
    dob: PropTypes.string,
    email: PropTypes.string,
    shipping: PropTypes.shape({
      'street address': PropTypes.string,
      suburb: PropTypes.string,
      state: PropTypes.string,
    }),
    doctor: PropTypes.string,
  }).isRequired,
};

export default VerifyIdentity;
