import React from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';

import { db } from '../../../firebase-config';

/**
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.orderReceiverId - The ID of the order receiver.
 * @returns {Object} - The state object containing the catalogue data, loading status, and error.
 */
export const useCatalogue = ({ orderReceiverId }) => {
  const [state, setState] = React.useState({
    data: {},
    loading: true,
    error: null,
  });

  React.useEffect(() => {
    const unsubscribe = onSnapshot(query(collection(db, 'catalogue')), (querySnapshot) => {
      const data = {};
      querySnapshot.docs.forEach((doc) => {
        data[doc.id] = { ...doc.data(), id: doc.id, uid: doc.id };
      });

      setState({ data, loading: false, error: null });
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [orderReceiverId]);

  return { data: state.data, loading: state.loading, error: state.error };
};
