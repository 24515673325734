import React, { useState } from 'react';
import { Box, Button, Menu } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children - The children to render
 */
export const OrderDetailsToolsButton = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(/** @type {HTMLElement | null} */ (null));

  return (
    <>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="small"
        sx={{ height: 20 }}
        endIcon={<MenuIcon sx={{ position: 'relative', bottom: 0 }} />}
      >
        Tools
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1, px: 2, py: 1 }}>
          {children}
        </Box>
      </Menu>
    </>
  );
};
