import { CSV_HEADERS, DEFAULT_VALUES } from './constants';

export const formatDate = (dateString) => new Date(dateString).toLocaleDateString('en-AU').replaceAll('/', '-');

// Safer regex pattern:
// ^ - start of string
// till_ - literal text
// [0-9]{8} - exactly 8 digits for yyyymmdd format
// - - literal hyphen
// [a-f0-9]{20} - exactly 20 hex chars
// $ - end of string
const TILL_ID_PATTERN = /^till_\d{8}-([a-f0-9]{20})$/;

/**
 * Extracts the unique identifier portion from a Till order ID
 * Returns everything after "till_YYYYMMDD-"
 * @param {string} orderId - Till order ID (format: till_YYYYMMDD-uniqueId)
 * @returns {string} Unique identifier portion of the Till order ID
 * @throws {Error} If orderId format is invalid
 */
export const extractTillOrderId = (orderId) => {
  // Limit input length for safety
  // Length should be 34 chars long with prefix infront
  if (!orderId || orderId.length > 34) {
    return '';
  }

  const match = orderId.match(TILL_ID_PATTERN);

  return match ? match[1] : ''; // Returns everything after "till_YYYYMMDD-"
};

/**
 * @typedef {Object} ShipmentOrder
 * @property {string} id
 * @property {string} name
 * @property {string} email
 * @property {Object} shipping
 * @property {string} shipping.street_address
 * @property {string} shipping.suburb
 * @property {string} shipping.state
 * @property {string} shipping.postcode
 * @property {string} shipping.phone
 */

// TODO: Add validation and enforce maxium chars for each field if Aus Post Template states
// that a field has restrictions.
// reference https://auspost.com.au/business/parcel-send-help-and-support/shipments/bulk-importing-shipments-with-csv-files/create-your-csv-file
/**
 * Creates a CSV row object from a shipment order
 * @param {Object} object
 * @param {ShipmentOrder} object.order - The order to convert
 * @param {boolean} object.signatureRequired - Whether signature is required
 * @returns {Object} CSV Row as an object with all required column headers
 */
export const createCsvRow = ({ order, signatureRequired }) => ({
  'Row type': 'S', // Mandatory: Shipment row
  'Sender account': '', // Optional: Left blank as per template
  'Payer account': '', // Optional: Left blank as per template
  'Recipient contact name': order.name || DEFAULT_VALUES.UNKNOWN, // Mandatory
  'Recipient business name': '', // Optional
  'Recipient address line 1': order.shipping?.['street address'] || DEFAULT_VALUES.UNKNOWN, // Mandatory
  'Recipient address line 2': '', // Optional
  'Recipient address line 3': '', // Optional
  'Recipient suburb': order.shipping?.suburb || DEFAULT_VALUES.UNKNOWN, // Mandatory
  'Recipient state': order.shipping?.state || DEFAULT_VALUES.UNKNOWN, // Mandatory
  'Recipient postcode': order.shipping?.postcode || DEFAULT_VALUES.UNKNOWN, // Mandatory
  'Send tracking email to recipient': order.email ? 'YES' : 'NO', // Optional
  'Recipient email address': order.email || '', // Optional unless tracking emails required
  'Recipient phone number': order.shipping?.phone || '', // Optional
  'Delivery/special instruction 1': '', // Optional
  'Special instruction 2': '', // Optional
  'Special instruction 3': '', // Optional
  'Sender reference 1': extractTillOrderId(order.id), // Optional: Using order ID for tracking, but can be anything
  'Sender reference 2': '', // Optional
  'Product id': DEFAULT_VALUES.PRODUCT_ID, // Mandatory: Express Post
  'Authority to leave': signatureRequired ? 'NO' : 'YES', // Optional
  'Safe drop': 'NO', // Optional
  Quantity: '1', // Optional: Default to 1
  'Packaging type': '', // Optional
  Weight: DEFAULT_VALUES.WEIGHT, // Mandatory: Default 4kg
  Length: '', // Optional
  Width: '', // Optional
  Height: '', // Optional
  'Parcel contents': '', // DEFAULT_VALUES.CONTENTS, // Optional
  'Transit cover value': '', // Optional
});

export const generateCsv = (orders, signatureRequired) => {
  const rows = orders.map((order) => createCsvRow({ order, signatureRequired }));
  return [CSV_HEADERS, ...rows.map((row) => Object.values(row))].join('\n');
};

export const downloadCsv = (csv, fileName) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};
