export const TEMPLATE_VARIABLES = {
  patientFirstName: {
    label: 'Patient First Name',
    description: "Patient's first name",
  },
  patientFullName: {
    label: 'Patient Full Name',
    description: "Patient's full name",
  },
  myFirstName: {
    label: 'My First Name',
    description: 'Your first name',
  },
};
