import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Button, useTheme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

/**
 * Copy button component
 * @param {Object} props - The component props
 * @param {string} props.textToCopy - The text to copy
 * @param {React.ReactNode} props.children - The children to render
 * @param {boolean} [props.iconOnLeft=false] - Whether the icon should be on the left
 * @param {boolean} [props.noHighlight=false] - Whether the text should not be highlighted
 * @param {Object | undefined} [props.sx] - The MUI sx prop
 * @returns {JSX.Element} The copy button component
 */
export const CopyButton = ({ textToCopy, iconOnLeft = false, noHighlight = false, children, ...props }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const theme = useTheme();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
    } catch (err) {
      // @keeganpotgieter - We should add a snackbar here
    }
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const { sx, ...restOfProps } = props;

  return (
    <Button
      variant="text"
      onClick={handleCopy}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disableRipple={noHighlight}
      sx={{
        width: 'fit-content',
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        flexDirection: iconOnLeft ? 'row-reverse' : 'row',
        gap: 1,
        ...(noHighlight && {
          backgroundColor: 'transparent',
          padding: 0,
          height: '1rem',
        }),
        ...theme.typography.body2,
        ...sx,
      }}
      {...restOfProps}
    >
      {children}
      {isCopied ? <CheckIcon sx={{ fontSize: 12 }} /> : isHovering && <ContentCopyIcon sx={{ fontSize: 12 }} />}
    </Button>
  );
};
