import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../../../firebase-config';

const PREFIX = 'DisableAccount';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '180px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const disableAccount = httpsCallable(functions, 'disableAccount');

const DisableAccount = (props) => {
  const { handleModalOpen, handleModalClose, patientEmail, patientId } = props;

  const [disableReason, setDisableReason] = useState('');

  const handleDisableAccountRadioChange = (event) => {
    setDisableReason(event.target.value);
  };

  const handleDisableAccount = () => {
    disableAccount({ email: patientEmail, patientId, disableReason, isPatient: true }).then((result) => {
      if (result?.data?.message) {
        handleModalOpen(`Result: ${result.data.message}`);
      } else {
        handleModalOpen('Error: Something went wrong. Contact your team leader.');
      }
    });
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid>
            <div>
              <Typography variant="h5" align="center" gutterBottom>
                Disable Account
              </Typography>
            </div>
            <br />
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component="fieldset">
                <RadioGroup row name="row-radio-buttons-group" onChange={handleDisableAccountRadioChange}>
                  <FormControlLabel
                    value="abusive behaviour"
                    control={<Radio color="primary" />}
                    label="Abusive Behaviour"
                  />
                  <FormControlLabel
                    value="suspect activity"
                    control={<Radio color="primary" />}
                    label="Suspect Activity"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <div>
              <Button
                disabled={!disableReason}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleDisableAccount}
              >
                Disable Account
              </Button>
            </div>
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

DisableAccount.propTypes = {
  handleModalOpen: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  patientEmail: PropTypes.string.isRequired,
};

export default DisableAccount;
