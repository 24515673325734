import React from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';

import { db } from '../../../firebase-config';
import { USER_TYPES, PHARMACY } from '../../../utils/constants';
import { PHARMACY_TYPES } from '../constants';
import { useAuthContext } from './useAuthContext';
import { usePharmaciesFilters } from './usePharmaciesFilters';

const { SUPPORT, DIRECTOR, NURSE, DOCTOR } = USER_TYPES;

/**
 * @typedef {Object} UsePharmacyDirectoryReturn
 * @property {Pharmacy[]} pharmacies The pharmacies filtered by the current filters
 * @property {Pharmacy[]} allPharmacies all pharmacies
 * @property {boolean} isLoading Whether the pharmacies are loading
 * @property {Error | null} error The error
 * @property {Object} filters The filters
 * @property {(filters: Object) => void} setFilters The set filters function
 * @property {() => void} refreshPharmacies The refresh pharmacies function
 */

/**
 * Generates a Firestore query to fetch orders based on user role permissions
 * @param {Object} params - The parameters object
 * @param {string} params.userRole - The role of the user (SUPPORT, DIRECTOR, DOCTOR, NURSE, SUPPLIER, PATIENT)
 * @param {boolean} [params.pharmaciesOnly=false] - Whether to only return pharmacies
 * @returns {import('firebase/firestore').Query} Firestore query that:
 * - For staff roles (SUPPORT, DIRECTOR, DOCTOR, NURSE): returns orders of type [PHARMACY, SUPPLIER, SUPPORT]
 * - For all other roles: returns only PHARMACY type orders
 */
const generateOrdersQuery = ({ userRole, pharmaciesOnly = false }) => {
  switch (userRole) {
    case SUPPORT:
    case DIRECTOR:
    case DOCTOR:
    case NURSE:
      return query(
        collection(db, 'orders'),
        where('type', 'in', [PHARMACY, ...(pharmaciesOnly ? [] : ['supplier', 'support'])]),
      );
    default:
      return query(collection(db, 'orders'), where('type', '==', PHARMACY));
  }
};

/**
 * Custom hook to manage pharmacy directory data and operations
 *
 * @param {Object} options - The options for the hook
 * @param {boolean} [options.pharmaciesOnly=false] - Whether to exclude suppliers from the pharmacy directory
 * @returns {UsePharmacyDirectoryReturn} Pharmacy directory state and operations
 */
export const usePharmacyDirectory = ({ pharmaciesOnly = false } = {}) => {
  const { userType } = useAuthContext();
  const [pharmacies, setPharmacies] = React.useState(/** @type {Pharmacy[]} */ ([]));
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(/** @type {Error | null} */ (null));
  const [filters, setFilters] = usePharmaciesFilters();

  /**
   * Fetches pharmacies based on user type and filters
   */
  const fetchPharmacies = React.useCallback(async () => {
    try {
      setLoading(true);
      const pharmacyQuery = generateOrdersQuery({ userRole: userType, pharmaciesOnly });

      const querySnapshot = await getDocs(pharmacyQuery);
      const pharmacyData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((pharmacy) => userType !== 'patient' || !pharmacy.hasOrdersBlocked);

      setPharmacies(pharmacyData);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch pharmacies'));
    } finally {
      setLoading(false);
    }
  }, [userType, pharmaciesOnly]);

  React.useEffect(() => {
    fetchPharmacies();
  }, [fetchPharmacies]);

  /**
   * Filters pharmacies based on current filter settings
   */
  const filteredPharmacies = React.useMemo(
    () =>
      pharmacies.filter((pharmacy) => {
        const matchesSearch =
          pharmacy.name.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
          pharmacy.email.toLowerCase().includes(filters.searchTerm.toLowerCase());
        const matchesState = !filters.state || pharmacy.shipping?.state === filters.state;
        const matchesType = filters.type === PHARMACY_TYPES.ALL || pharmacy.type === filters.type;

        return matchesSearch && matchesState && matchesType;
      }),
    [pharmacies, filters],
  );

  return {
    error,
    filters,
    setFilters,
    isLoading: loading,
    pharmacies: filteredPharmacies,
    allPharmacies: pharmacies,
    refreshPharmacies: fetchPharmacies,
  };
};
