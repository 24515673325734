import {
  Autocomplete,
  Stack,
  Typography,
  IconButton,
  Skeleton,
  TextField,
  createFilterOptions,
  Tooltip,
  Collapse,
} from '@mui/material';
import React from 'react';
import { CheckCircle, ContentCopy, OpenInNew, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { CopyButton } from '../../../../../ui/CopyButton';

const TILL_PAYMENTS_URL = import.meta.env.VITE_APP_TILL_PAYMENTS_URL;
const COPY_ID_TIMEOUT_MS = 2000;

const stringifyOrder = (/** @type {Order} */ order) =>
  JSON.stringify({
    id: order.id,
    pharmacyInfo: order.pharmacyInfo?.name,
    formName: order.formName,
    orderPlaced: new Date(order.orderPlaced).toLocaleDateString('en-AU'),
  });

const filterOptions = createFilterOptions({
  ignoreCase: true,
  stringify: stringifyOrder,
});

/**
 * Component that renders a context section for an order
 * @param {Object} props - Component props
 * @param {Order} props.order - The order to render
 * @returns {JSX.Element} ContextSection component
 */
const ContextSection = ({ order }) => {
  if (!order.pharmacyInfo) {
    return (
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
        <Typography variant="subtitle2">Consultation:</Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {order.formName}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
      <Typography variant="subtitle2">Pharmacy:</Typography>
      <Typography variant="body2">{order.pharmacyInfo?.name}</Typography>
    </Stack>
  );
};

/**
 * Component that renders expanded details for an order
 * @param {Object} props - Component props
 * @param {Order} props.order - The order to render
 */
const OrderDetails = React.memo(({ order }) => {
  const isPharmacyOrder = !!order.pharmacyInfo;

  return (
    <Stack spacing={0.5} sx={{ p: 1, bgcolor: 'grey.50', borderRadius: 1 }}>
      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2">Form:</Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {order.formName}
        </Typography>
      </Stack>

      {isPharmacyOrder && (
        <>
          <Stack direction="row" spacing={2}>
            <Typography variant="subtitle2">Pharmacy Name:</Typography>
            <Typography variant="body2">{order.pharmacyInfo.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle2">Pharmacy ID:</Typography>
            <CopyButton textToCopy={order.pharmacyInfo.id}>
              <Typography variant="body2">{order.pharmacyInfo.id}</Typography>
            </CopyButton>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography variant="subtitle2">Items:</Typography>
            <Typography variant="body2">{order.scriptsArray?.length ?? 0}</Typography>
          </Stack>
        </>
      )}

      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2">Order Placed:</Typography>
        <Typography variant="body2">{new Date(order.orderPlaced).toLocaleString('en-AU')}</Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2">Last Updated:</Typography>
        <Typography variant="body2">{new Date(order.updated).toLocaleString('en-AU')}</Typography>
      </Stack>
    </Stack>
  );
});

/**
 * Component that renders a select order component
 * @param {Object} props - Component props
 * @param {Array<Order>} props.orders - Array of orders
 * @param {string|null} props.selectedOrderId - Currently selected order ID
 * @param {Function} props.setSelectedOrder - Function to set the selected order
 * @param {boolean} props.isLoading - Loading state indicator
 * @returns {JSX.Element} SelectOrder component
 */
export const SelectOrder = React.memo(({ orders, selectedOrderId, setSelectedOrder, isLoading }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, COPY_ID_TIMEOUT_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  const selectedOrder = React.useMemo(
    () => orders.find((order) => order.id === selectedOrderId) ?? null,
    [orders, selectedOrderId],
  );

  /**
   * Function to get the total order amount
   * @param {Order} order - The order to get the total amount for
   * @returns {string} The total order amount
   */
  const getTotalOrderAmount = React.useCallback((order) => {
    const total = order.totalAmount;
    return total === null ? '-' : `$${total}`;
  }, []);

  const handleCopyId = () => {
    if (selectedOrderId) {
      navigator.clipboard.writeText(selectedOrderId);
      setIsCopied(true);
    }
  };

  const handleOpenInNewTab = () => {
    if (!selectedOrderId) {
      return;
    }

    const referenceId = selectedOrderId.split('_').pop();
    const parsedId = referenceId?.split('-').pop();

    if (!parsedId) {
      return;
    }

    const paymentReferenceUrl = `${TILL_PAYMENTS_URL}/en/transactions/show/${parsedId}`;
    window.open(paymentReferenceUrl, '_blank');
  };

  if (isLoading) {
    return <Skeleton variant="rounded" height={56} />;
  }

  if (!orders.length) {
    return (
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        No orders found
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      <Autocomplete
        value={selectedOrder}
        onChange={(_, newValue) => setSelectedOrder(newValue)}
        options={orders}
        filterOptions={filterOptions}
        getOptionKey={(option) => option.id ?? ''}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) => option?.id ?? ''}
        renderOption={({ key, ...props }, order) => (
          <li key={key} {...props}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" width="100%">
              <Stack direction="column" spacing={0} justifyContent="flex-start" sx={{ width: '70%' }}>
                <ContextSection order={order} />

                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                  <Typography variant="subtitle2">ID:</Typography>
                  <Typography
                    variant="body2"
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '80%' }}
                  >
                    {order.id}
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction="column"
                spacing={0}
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ width: '30%' }}
              >
                <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="flex-end" flexWrap="nowrap">
                  <Typography
                    variant="body2"
                    fontWeight={600}
                  >{`${new Date(order.orderPlaced).toLocaleDateString('en-AU')}`}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="flex-end"
                  sx={{ flexWrap: 'wrap' }}
                  justifyContent="flex-end"
                  alignContent="center"
                >
                  <Typography variant="subtitle2">Total:</Typography>
                  <Typography variant="body2">{getTotalOrderAmount(order)}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Search orders" placeholder="Search by ID, pharmacy, or date..." fullWidth />
        )}
      />

      {!!selectedOrder && (
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" sx={{ flexGrow: 1 }}>
              {selectedOrderId}
            </Typography>
            <Tooltip title="Copy Order ID">
              <IconButton size="small" onClick={handleCopyId}>
                {isCopied ? <CheckCircle sx={{ fontSize: '10px' }} /> : <ContentCopy sx={{ fontSize: '10px' }} />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Open till gateway order in new tab">
              <IconButton size="small" onClick={handleOpenInNewTab}>
                <OpenInNew sx={{ fontSize: '10px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={showDetails ? 'Hide details' : 'Show details'}>
              <IconButton size="small" onClick={() => setShowDetails((prev) => !prev)}>
                {showDetails ? (
                  <KeyboardArrowUp sx={{ fontSize: '10px' }} />
                ) : (
                  <KeyboardArrowDown sx={{ fontSize: '10px' }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>

          <Collapse in={showDetails} timeout="auto">
            <OrderDetails order={selectedOrder} />
          </Collapse>
        </>
      )}
    </Stack>
  );
});
