import { getDownloadURL, ref } from 'firebase/storage';
import { captureException as sentryCaptureException } from '@sentry/react';

import { storage } from '../../../../firebase-config';
import { SUPPORT_ID } from '../../../../utils/constants';
import { handleTgaFile } from './handleTgaFile';

/**
 * @typedef {Object} GenerateScriptEmailParams
 * @param {InitialResult} params.initialResult - Initial result template
 * @param {Shipment} params.shipment - Shipment information
 * @param {(message: string) => void} params.snackbar - Snackbar notification function
 * @param {Array<any>} params.catalogueList - List of catalogue items
 * @param {Object} params.userData - User data
 */

/**
 * @typedef {Object} Script
 * @property {string|null} tga - TGA identifier
 * @property {number} index - Script index
 * @property {string|null} eScriptToken - Electronic script token
 * @property {string|null} paperScript - Paper script identifier
 */

/**
 * @typedef {Object} InitialResult
 * @property {string} doctorEmail - Doctor's email address
 * @property {string} patientName - Patient's name
 * @property {Array<Script>} scripts - Array of script objects
 */

// Update the existing type
/**
 * @typedef {Object} Shipment
 * @property {string} user - User identifier
 * @property {string} name - Shipment name
 * @property {Array<any>} scriptsArray - Array of scripts
 */

const getPaperScriptUrl = async ({ pathScript, snackbar }) => {
  try {
    return await getDownloadURL(ref(storage, pathScript));
  } catch (error) {
    sentryCaptureException(error, { extra: { issueIn: 'getPaperScriptUrl', pathScript } });
    snackbar(`Failed to retrieve paper script from ${pathScript}`);

    return '';
  }
};

/**
 * @typedef {Object} ScriptDataParams
 * @property {string|null} eScriptToken - The electronic script token, or null if not available
 * @property {number} index - The index of the script
 * @property {string|null} paperScriptURL - The paper script, or null if eScriptToken is provided
 * @property {string|null} tgaURL - The TGA URL, or null if not available
 */

/**
 * @typedef {Object} ScriptData
 * @property {number} index - The incremented index of the script
 * @property {string|null} eScriptToken - The electronic script token
 * @property {string|null} paperScript - The paper script, or null if eScriptToken is provided
 * @property {string|null} tga - The TGA URL
 */

/**
 * Creates a script data object with the provided parameters.
 * @param {ScriptDataParams} params - The parameters for creating the script data
 * @returns {ScriptData} The script data object
 */
const createScriptData = ({ eScriptToken = null, index, paperScriptURL = null, tgaURL = null }) => ({
  index,
  eScriptToken,
  paperScript: eScriptToken ? null : paperScriptURL,
  tga: tgaURL,
});

/**
 * Generates email data for script-only cases
 * @param {GenerateScriptEmailParams} params - Parameters for generating script email
 * @returns {Promise<Object>} Generated email data
 */
export const generateScriptOnlyEmailData = async ({ initialResult, snackbar, shipment, catalogueList, userData }) => {
  // Early return if no scripts
  if (!shipment?.scriptsArray?.length) {
    return initialResult;
  }

  return shipment.scriptsArray.map(async ({ priceRef: productId = '', eScriptToken }, index) => {
    if (!productId) {
      return { scripts: [] };
    }
    const sendGridIndex = index + 1;

    try {
      const pathScript = `pharmacy/${SUPPORT_ID}/prescriptions/${shipment.user}/${productId}`;
      const paperScriptURL = !eScriptToken ? await getPaperScriptUrl({ pathScript, snackbar }) : null;

      // Build script data upfront with all possible values
      const scriptData = createScriptData({
        eScriptToken,
        paperScriptURL,
        tgaURL: null, // Will be updated if needed
        index: sendGridIndex,
      });

      // Create base result with script/eScript
      const baseResult = {
        scripts: [scriptData],
      };

      // Return early if not medicinal cannabis
      if (shipment.formName !== 'medicinal cannabis') {
        return baseResult;
      }

      // Handle TGA file
      const scriptUserData = userData?.prescriptions?.[shipment.formName]?.[productId] || {};

      const tgaResult = await handleTgaFile({
        snackbar,
        shipment,
        productId,
        scriptData,
        catalogueList,
        initialResult,
        scriptUserData,
      });

      // Return combined result with TGA data
      return {
        ...baseResult,
        ...tgaResult,
      };
    } catch (error) {
      sentryCaptureException(error, {
        extra: { issueIn: 'generateScriptOnlyEmailData', productId, shipment },
      });

      return { scripts: [] };
    }
  });
};
