import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const WafersInfo = () => (
  <Grid>
    <Typography variant="h6">Taking medicinal cannabis wafers</Typography>
    <br />
    <Typography variant="body1">
      The wafer should only be removed from the blister immediately prior to administration. The wafer should be removed
      from the blister by carefully peeling off the blister backing and tapping or pushing the wafer out into dry hands.
      To prevent crushing, the wafer must not be removed by pushing through the blister foil.
      <br />
      <br />
      For dry mouth, patient should be instructed to rinse their mouth with a little bit of water prior to
      administration.
      <br />
      <br />
      5 mg or 10 mg dose (one wafer): Place the wafer as far back under the tongue as possible. The wafer should be kept
      under the tongue until it has dissolved, which has been shown to take less than 1 minute.
      <br />
      <br />
      50mg dose (two 25mg wafers): Place one wafer on one side under the tongue, as far back as possible. After 1-2
      minutes, place a second wafer on the opposite side under the tongue and allow to dissolve.
      <br />
      <br />
      The wafers should not be chewed, sucked or swallowed. No food or drink should be consumed until the wafer has
      dissolved. Occasionally, a small amount of residue may be noticeable after the wafer has dissolved which can be
      swallowed.
      <br />
      <br />
      Titration is important, especially for new patients. Dosing schedules differ for each patient and should be
      discussed with your doctor. Doses should be increased gradually over time and the effect fully assessed before
      continuing to increase. Note that consuming too much cannabis can result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default WafersInfo;
