import { getIsEscriptValid, getRepeatTokens, getUniqueScripts } from '../components/PharmacistSection/helpers';

/**
 * @param {Object} params
 * @param {Order} params.order - The order
 * @returns {boolean} - Whether the order is valid
 */
export const checkIfEscriptIsValid = ({ order }) => {
  const initialRepeatTokens = getRepeatTokens(order);
  const uniqueScriptsArray = getUniqueScripts(order.scriptsArray ?? []);

  return getIsEscriptValid({ uniqueScriptsArray, initialRepeatTokens });
};
