// Check if any substituted products are awaiting payment before releasing order to patient
// NOTE: Unsure why we're only checking substited produces and not everything.

/**
 * @param {Object} params
 * @param {Order} params.order - The order
 * @returns {boolean} - Whether the order is awaiting payment
 */
export const checkIfOrderIsAwaitingPayment = ({ order }) =>
  order?.scriptsArray?.some((script) => script?.substitute?.isPaid === false);
