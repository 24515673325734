import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const TretinoinInformation = () => (
  <Grid>
    <Typography variant="h6">About Tretinoin</Typography>
    <br />
    <Typography variant="body1">
      Tretinoin is a topical retinoid cream that is used to treat acne and ageing skin. It affects the growth of skin
      cells, decreases inflammation and helps prevent the formation of new acne lesions. When acne does develop it can
      help decrease the severity of the outbreaks. Sometimes it is combined with other medications such as topical
      antibiotics.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Tretinoin is a topical cream that is applied in the evenings. To prevent irritation and side effects it is
      important to carefully follow the application instructions:
      <br />
      <br />
      Before applying, wash your face with mild soap and warm water; rinse and gently pat dry.
      <br />
      <br />
      Using a very thin layer of cream, apply enough to cover the face entirely (not just areas you want to treat). Do
      not apply to eyes, lips, irritated areas (eg sunburnt or broken skin) or in nostrils. Wash hands after
      application.
      <br />
      <br />
      This medication may cause irritation (redness, peeling, stinging), especially during the first few weeks of
      treatment. To reduce irritation:
      <br />
      <br />
      - wait until skin is completely dry (usually 20–30 minutes) before applying
      <br />
      - apply a thin layer (using too much increases irritation and won’t make it work faster); a pea-sized amount is
      usually enough to cover the whole face
      <br />
      - avoid using other acne medications on the skin unless advised to by your doctor
      <br />
      - use a moisturiser in the morning
      <br />
      - avoid waxing treated areas
      <br />
      - consider applying the medication every other night for the first couple of weeks until your skin tolerates it
      well, then use every night
      <br />
      <br />
      If irritation is troublesome, apply the medication less often or have a break from treatment for a few days. If it
      persists or is severe, stop treatment. Please contact your Candor GP at any time to discuss side effects and a
      plan for managing these.
      <br />
      <br />
      Improvement in skin condition may be seen within a couple of weeks but often is noticeable after a few months of
      use. Once effective it may be possible to maintain this with less frequent application.
      <br />
      <br />
      Tretinoin increases your likelihood of sunburn. It is important to protect treated areas from sunlight with
      protective clothing or broad-spectrum sunscreen and avoid sunlamps and tanning beds.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Tretinoin is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      <br />
      - Erythema
      <br />
      - Peeling
      <br />
      - Irritation
      <br />
      - Itch
      <br />
      - Dry skin
      <br />
      - Burning sensation
      <br />
      - Stinging
      <br />
      <br />
      Infrequent (0.1% to 1%):
      <br />
      <br />
      - Pigmentation changes
      <br />
      - Phototoxicity
      <br />
      - Blisters
      <br />
      - Oedema
      <br />
      <br />
      Rare (less than 0.1%):
      <br />
      <br />
      - Hypersensitivity reactions (urticaria, allergic contact dermatitis)
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Tretinoin including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Tretinoin when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. Tretinoin is not safe to use during pregnancy or when trying to conceive. Although it is likely only small
      amounts will be absorbed there is the potential to cause damage to the developing fetus.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Tretinoin when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      While it is likely that only minimal amounts will be absorbed and excreted in breastmilk, Candor does not
      prescribe Tretinoin to breastfeeding women.
    </Typography>
  </Grid>
);

export default TretinoinInformation;
