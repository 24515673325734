import React from 'react';
import DoctorFormView from '../form-views/doctor-form-view';

const GeneralForm = (props) => {
  const { patient } = props;

  const formObjects = [
    {
      headingText: 'Sex',
      fieldText: 'basic info.sex',
      options: ['Male', 'Female'],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'Medical Conditions',
      subheadingText: 'Please list any current medical conditions below',
      fieldText: 'medical conditions',
      inputs: ['condition', 'diagnosed'],
      elementType: 'expandable',
      creationTest: () => true,
    },
    {
      headingText: 'Medical Allergies',
      fieldText: 'general health.medication allergies',
      elementType: 'basic',
      maxRows: 3,
      skippable: true,
      notifyDoctor: true,
      creationTest: () => true,
    },
    {
      headingText: 'Treatments & Medications',
      subheadingText: "Please list any treatments or medications you're currently using",
      fieldText: 'used for',
      inputs: ['treatment', 'currently using'],
      elementType: 'expandable',
      creationTest: () => true,
    },
    {
      headingText: 'Vitamins & Supplements',
      fieldText: 'general health.vitamins or supplements',
      elementType: 'basic',
      maxRows: 3,
      skippable: true,
      notifyDoctor: true,
      creationTest: () => true,
    },
    {
      headingText: 'Height (cm)',
      fieldText: 'general health.height',
      elementType: 'basic',
      creationTest: () => true,
    },
    {
      headingText: 'Weight (kg)',
      fieldText: 'general health.weight',
      elementType: 'basic',
      creationTest: () => true,
    },
  ];

  return <DoctorFormView patient={patient} formName="general" formObjects={formObjects} />;
};

export default GeneralForm;
