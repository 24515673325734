export const TRACKING_TYPES = {
  STANDARD: 'Standard',
  EXPRESS: 'Express',
  SAME_DAY: 'Same Day', // ONLY FOR STAR_TRACK
};

// REFERENCE https://www.auspost.com.au/about-us/contact-us/auspost-tracking-codes
export const AUSPOST_CODES = {
  // Standard Services
  AUSPOST_E_PARCEL_RETURNS: 'PR',
  AUSPOST_PARCEL_7C55: '7C55',
  AUSPOST_PARCEL_7E55: '7E55',
  AUSPOST_PARCEL_OVER_5KG: 'B31',
  AUSPOST_PARCEL_UNDER_5KG: 'B30',
  // NOTE: Not sure the difference between 3D55 & 3D85
  AUSPOST_PARCEL_SIGNATURE_3D55: '3D55',
  AUSPOST_PARCEL_SIGNATURE_3D85: '3D85',
  AUSPOST_PARCEL_BOX_XL: 'BE1PB4',
  AUSPOST_PARCEL_BOX_L: 'BE1PB3',
  AUSPOST_PARCEL_BOX_M: 'BE1PB2',
  AUSPOST_PARCEL_BOX_S: 'BE1PB1',
  AUSPOST_PARCEL_SATCHEL_XL: 'BE1P50',
  AUSPOST_PARCEL_SATCHEL_L: 'BE1P30',
  AUSPOST_PARCEL_SATCHEL_M: 'BE1P10',
  AUSPOST_PARCEL_SATCHEL_S: 'BE1P05',

  // Express Services
  AUSPOST_EXPRESS_E_PARCEL_RETURNS: 'XPR',
  AUSPOST_EXPRESS_3K55: '3K55',
  AUSPOST_EXPRESS_7I55: '7I55',
  AUSPOST_EXPRESS_OVER_5KG: 'B21',
  AUSPOST_EXPRESS_UNDER_5KG: 'B20',
  // NOTE: Not sure the difference between 3J55 & 3J85
  // but 3J55 is the one that one pharmacy has mentioned to us.
  // Pharamcies might have to specify this to us/is editable.
  AUSPOST_EXPRESS_SIGNATURE_3J55: '3J55', // NOTE: This one is the one we are using currently
  AUSPOST_EXPRESS_SIGNATURE_3J85: '3J85',
  // Express Flat Rate Services
  AUSPOST_EXPRESS_FLAT_RATE_BOX_XL: 'BE9PB4',
  AUSPOST_EXPRESS_FLAT_RATE_BOX_L: 'BE9PB3',
  AUSPOST_EXPRESS_FLAT_RATE_BOX_M: 'BE9PB2',
  AUSPOST_EXPRESS_FLAT_RATE_BOX_S: 'BE9PB1',
  // NOTE: Flat Rate Satchels are all max weight of 5KG
  AUSPOST_EXPRESS_FLAT_RATE_SATCHEL_XL: 'BE9P50',
  AUSPOST_EXPRESS_FLAT_RATE_SATCHEL_L: 'BE9P30',
  AUSPOST_EXPRESS_FLAT_RATE_SATCHEL_M: 'BE9P10',
  AUSPOST_EXPRESS_FLAT_RATE_SATCHEL_S: 'BE9P05',
};

export const STARTRACK_CODES = {
  STARTRACK_EXPRESS: 'STEXP',
  STARTRACK_EXPRESS_ATL: 'STEXPA',
  STARTRACK_NATIONAL_PREMIUM: 'STNP',
  STARTRACK_NATIONAL_PREMIUM_ATL: 'STNPA',
  STARTRACK_NEXT_FLIGHT: 'STNF',
  STARTRACK_PREMIUM: 'STPRE',
  STARTRACK_PREMIUM_ATL: 'STPREA',
};

export const SHIPPING_SERVICE_PRODUCT_CODES = {
  // Standard Services
  [AUSPOST_CODES.PARCEL_RETURNS]: {
    label: 'Australia Post eParcel Post Returns',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_RETURNS,
  },
  [AUSPOST_CODES.PARCEL_7C55]: {
    label: 'Australia Post Parcel Post (7C55)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_7C55,
  },
  [AUSPOST_CODES.PARCEL_7E55]: {
    label: 'Australia Post Parcel Post (7E55)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_7E55,
  },
  [AUSPOST_CODES.PARCEL_OVER_5KG]: {
    label: 'Australia Post Parcel Post (over 5kg)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_OVER_5KG,
  },
  [AUSPOST_CODES.PARCEL_UNDER_5KG]: {
    label: 'Australia Post Parcel Post (up to 5kg)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_UNDER_5KG,
  },
  [AUSPOST_CODES.PARCEL_SIGNATURE_3D55]: {
    label: 'Australia Post Parcel Post + Signature (3D55)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_SIGNATURE_3D55,
  },
  [AUSPOST_CODES.PARCEL_SIGNATURE_3D85]: {
    label: 'Australia Post Parcel Post + Signature (3D85)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_SIGNATURE_3D85,
  },
  [AUSPOST_CODES.PARCEL_BOX_XL]: {
    label: 'Australia Post Parcel Post Flat Rate Box (Extra Large)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_BOX_XL,
  },
  [AUSPOST_CODES.PARCEL_BOX_L]: {
    label: 'Australia Post Parcel Post Flat Rate Box (Large)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_BOX_L,
  },
  [AUSPOST_CODES.PARCEL_BOX_M]: {
    label: 'Australia Post Parcel Post Flat Rate Box (Medium)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_BOX_M,
  },
  [AUSPOST_CODES.PARCEL_BOX_S]: {
    label: 'Australia Post Parcel Post Flat Rate Box (Small)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_BOX_S,
  },
  [AUSPOST_CODES.PARCEL_SATCHEL_XL]: {
    label: 'Australia Post Parcel Post Flat Rate Satchel 5kg (Extra Large)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_SATCHEL_XL,
  },
  [AUSPOST_CODES.PARCEL_SATCHEL_L]: {
    label: 'Australia Post Parcel Post Flat Rate Satchel 5kg (Large)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_SATCHEL_L,
  },
  [AUSPOST_CODES.PARCEL_SATCHEL_M]: {
    label: 'Australia Post Parcel Post Flat Rate Satchel 5kg (Medium)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_SATCHEL_M,
  },
  [AUSPOST_CODES.PARCEL_SATCHEL_S]: {
    label: 'Australia Post Parcel Post Flat Rate Satchel 5kg (Small)',
    type: TRACKING_TYPES.STANDARD,
    code: AUSPOST_CODES.PARCEL_SATCHEL_S,
  },

  // Express Services
  [AUSPOST_CODES.EXPRESS_RETURNS]: {
    label: 'Australia Post Express eParcel Post Returns',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_RETURNS,
  },
  [AUSPOST_CODES.EXPRESS_3K55]: {
    label: 'Australia Post Express Post (3K55)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_3K55,
  },
  [AUSPOST_CODES.EXPRESS_7I55]: {
    label: 'Australia Post Express Post (7I55)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_7I55,
  },
  [AUSPOST_CODES.EXPRESS_OVER_5KG]: {
    label: 'Australia Post Express Post (over 5kg)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_OVER_5KG,
  },
  [AUSPOST_CODES.EXPRESS_UNDER_5KG]: {
    label: 'Australia Post Express Post (up to 5kg)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_UNDER_5KG,
  },
  [AUSPOST_CODES.EXPRESS_SIGNATURE_3J55]: {
    label: 'Australia Post Express Post + Signature (3J55)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_SIGNATURE_3J55,
  },
  [AUSPOST_CODES.EXPRESS_SIGNATURE_3J85]: {
    label: 'Australia Post Express Post + Signature (3J85)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_SIGNATURE_3J85,
  },
  [AUSPOST_CODES.EXPRESS_BOX_XL]: {
    label: 'Australia Post Express Post Flat Rate Box (Extra Large)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_BOX_XL,
  },
  [AUSPOST_CODES.EXPRESS_BOX_L]: {
    label: 'Australia Post Express Post Flat Rate Box (Large)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_BOX_L,
  },
  [AUSPOST_CODES.EXPRESS_BOX_M]: {
    label: 'Australia Post Express Post Flat Rate Box (Medium)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_BOX_M,
  },
  [AUSPOST_CODES.EXPRESS_BOX_S]: {
    label: 'Australia Post Express Post Flat Rate Box (Small)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_BOX_S,
  },
  [AUSPOST_CODES.EXPRESS_SATCHEL_XL]: {
    label: 'Australia Post Express Post Flat Rate Satchel 5kg (Extra Large)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_SATCHEL_XL,
  },
  [AUSPOST_CODES.EXPRESS_SATCHEL_L]: {
    label: 'Australia Post Express Post Flat Rate Satchel 5kg (Large)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_SATCHEL_L,
  },
  [AUSPOST_CODES.EXPRESS_SATCHEL_M]: {
    label: 'Australia Post Express Post Flat Rate Satchel 5kg (Medium)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_SATCHEL_M,
  },
  [AUSPOST_CODES.EXPRESS_SATCHEL_S]: {
    label: 'Australia Post Express Post Flat Rate Satchel 5kg (Small)',
    type: TRACKING_TYPES.EXPRESS,
    code: AUSPOST_CODES.EXPRESS_SATCHEL_S,
  },

  // StarTrack Services
  [STARTRACK_CODES.EXPRESS]: {
    label: 'StarTrack Express',
    type: TRACKING_TYPES.STANDARD,
    code: STARTRACK_CODES.EXPRESS,
  },
  [STARTRACK_CODES.EXPRESS_ATL]: {
    label: 'StarTrack Express ATL',
    type: TRACKING_TYPES.STANDARD,
    code: STARTRACK_CODES.EXPRESS_ATL,
  },
  [STARTRACK_CODES.NATIONAL_PREMIUM]: {
    label: 'StarTrack National Premium',
    type: TRACKING_TYPES.EXPRESS,
    code: STARTRACK_CODES.NATIONAL_PREMIUM,
  },
  [STARTRACK_CODES.NATIONAL_PREMIUM_ATL]: {
    label: 'StarTrack National Premium ATL',
    type: TRACKING_TYPES.EXPRESS,
    code: STARTRACK_CODES.NATIONAL_PREMIUM_ATL,
  },
  [STARTRACK_CODES.NEXT_FLIGHT]: {
    label: 'StarTrack Next Flight',
    type: TRACKING_TYPES.SAME_DAY,
    code: STARTRACK_CODES.NEXT_FLIGHT,
  },
  [STARTRACK_CODES.PREMIUM]: {
    label: 'StarTrack Premium',
    type: TRACKING_TYPES.EXPRESS,
    code: STARTRACK_CODES.PREMIUM,
  },
  [STARTRACK_CODES.PREMIUM_ATL]: {
    label: 'StarTrack Premium ATL',
    type: TRACKING_TYPES.EXPRESS,
    code: STARTRACK_CODES.PREMIUM_ATL,
  },
};
