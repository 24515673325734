import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import PillTabsImage from '../../../../media/PillTabs.png';

const CombinedOralContraceptiveInformation = () => (
  <Grid>
    <Typography variant="h6">About the Combined Oral Contraceptive Pill (COCP)</Typography>
    <br />
    <Typography variant="body1">
      The COCP contains both estrogen and progesterone. There are a number of different formulations of both estrogen
      and progesterone and this will vary depending which pill you are prescribed. This information can be found with
      your prescription.
      <br />
      <br />
      The COCP contains active (hormonal) and inactive pills. The active pills contain estrogen and progesterone while
      the inactive pills do not. In most situations there will be 7 or 4 inactive pills as shown in the examples below -
      the blue pills are active while the pink are inactive.
    </Typography>
    <br />
    <img style={{ width: 310, maxWidth: '80%' }} src={PillTabsImage} alt="Pill Tabs" />
    <br />
    <br />
    <Typography variant="body1">
      Taking the inactive pills is what triggers a withdrawal bleed. It may be possible to avoid having a bleed each
      month - talk to your Candor GP about how this may work for you.
      <br />
      <br />
      When taken perfectly the POP is 99.7% effective. Typical use is 91% effective.
    </Typography>
    <br />
    <Typography variant="h6">How the COCP works</Typography>
    <br />
    <Typography variant="body1">
      The COCP works as a contraceptive by:
      <br />
      - Stopping you from ovulating
      <br />
      - Changing the lining of your uterus to make it less favourable to sperm, and
      <br />
      - Thickening the cervical mucous to make it harder for sperm to get through
      <br />
      <br />
      The COCP does not prevent sexually transmitted infections.
      <br />
      <br />
      The COCP can also decrease acne symptoms and assist with symptoms or pain relief for endometriosis, painful
      periods and PCOS.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Take your COCP at the same time everyday. It’s best to set a reminder until this becomes a habit as missing pills
      may result in an unwanted pregnancy. You can choose the best time for you to take the pill, but aim to be
      consistent.
      <br />
      <br />
      If you start the COCP within the first 5 days of your period starting it will be effective immediately (please
      note this doesn’t apply for Qlaira or Zoely). If you start after day 5 you will need to take 7 days of active
      pills before you have contraceptive cover. You should use additional contraceptives or avoid intercourse during
      this time. Start with active pills and follow the directions on the packet.
      <br />
      <br />
      When you get to the inactive pills take them as you would the active ones and you should experience a withdrawal
      bleed, similar to a period (talk to your Candor GP about skipping this if you would like).
    </Typography>
    <br />
    <Typography variant="h6">What if I miss a pill?</Typography>
    <br />
    <Typography variant="body1">
      If you are less than 24 hours late taking an active pill: Take it as soon as you remember and take the next pill
      at the usual time; contraception will not be affected.
      <br />
      <br />
      If you are more than 24 hours late taking an active pill or you miss more than one: It depends where you are in
      the packet of pills.
      <br />
      <br />
      In week 1:
      <br />
      - Take the missed pill straight away and further pills as usual
      <br />
      - Use condoms for the next 7 days
      <br />
      - Emergency contraception is recommended if you’ve had unprotected sex
      <br />
      <br />
      In week 2:
      <br />
      - Take the missed pill straight away and further pills as usual
      <br />
      - Use condoms for the next 7 days
      <br />
      <br />
      In week 3:
      <br />
      - Take the missed pill straight away and further pills as usual
      <br />
      - Skip the inactive pills and start the next packet when you finish the current packet - you may not experience a
      withdrawal bleed - Use condoms for the next 7 days
      <br />
      <br />
      If you find you’re missing a lot of pills it may be best to consider a different form of contraception. Talk to
      your Candor GP or your regular GP about options.
    </Typography>
    <br />
    <Typography variant="h6">What if I vomit after taking the pill?</Typography>
    <br />
    <Typography variant="body1">
      If you vomit within 2 hours of taking an active pill: Take another active pill as soon as possible.
      <br />
      <br />
      If you have severe vomiting or diarrhoea for 24 hours or more: Follow the advice as above for missed pills.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      The COCP is a commonly used medication and side effects are generally mild. However it is important to be aware of
      any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Breakthrough bleeding
      <br />
      - Amenorrhea (absence of periods)
      <br />
      - Nausea
      <br />
      - Vomiting
      <br />
      - Breast enlargement or tenderness
      <br />
      - Headache
      <br />
      - Mood changes
      <br />
      - Libido changes
      <br />
      - Increased blood pressure
      <br />
      - Fluid retention
      <br />
      - Chloasma
      <br />
      - Acne
      <br />
      - Thrush
      <br />
      <br />
      Infrequent (0.1 to 1%):
      <br />
      - Contact lens intolerance
      <br />
      - Rash
      <br />
      - Hirsutism
      <br />
      - Alopecia
      <br />
      - Changes to liver enzymes
      <br />
      - Hyperinsulinaemia
      <br />
      <br />
      <br />
      Rare (less than 0.1%):
      <br />
      - Allergic reaction
      <br />
      - Hypertension
      <br />
      - Venous thromboembolism
      <br />
      - Stroke
      <br />
      - Jaundice
      <br />
      - Pancreatitis
      <br />
      - Photosensitivity
      <br />
      <br />
      Breakthrough bleeding:
      <br />
      Bleeding between periods usually settles after the first 3 months of use. If it continues, talk to your Candor GP
      about reasons why and options for management.
      <br />
      <br />
      Venous thromboembolism (VTE):
      <br />
      The risk of VTE depends on the type of estrogen, the progestogen and the presence of other risk factors. At Candor
      we ask you so many questions to ensure we choose the safest option for you based on your medical history. If your
      circumstances change - especially if you develop bad headaches or migraines - please let us know. Please note
      further detailed information on the COCP including usage and side effects will be provided in a medication safety
      information sheet with your prescription. It is important that you read this thoroughly before use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use the COCP when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. If you discover you are pregnant while taking the COCP it is very unlikely to do harm, however we suggest
      stopping it immediately.
    </Typography>
    <br />
    <Typography variant="h6">Can I use the COCP when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      The COCP is not recommended in the first 3 months following pregnancy due to VTE risk. Progesterone only options
      can be used from 6 weeks and are less likely to affect breastmilk supply. After breastfeeding is established it is
      unlikely the COCP will decrease supply significantly, however there are anecdotal reports that this may occur.
    </Typography>
  </Grid>
);

export default CombinedOralContraceptiveInformation;
