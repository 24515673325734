import {
  Box,
  Chip,
  Stack,
  styled,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker, MobileDatePicker } from '@mui/x-date-pickers';

import { parseStatus } from '../helpers';

/**
 * @typedef {Object} Filters
 * @property {string} search - Search term for filtering orders
 * @property {string} status - Status filter for orders
 * @property {(number|null)} fromDate - Start date for filtering orders
 * @property {(number|null)} toDate - End date for filtering orders
 * @property {string} sortBy - Sort by filter for orders
 * @property {OrderCurrentStatus[]} currentStatus - Current status filter for orders
 */

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minWidth: 300,
  gap: 2,
  textTransform: 'capitalize',
});

/**
 * @param {Object} props
 * @param {Filters} props.filters - The current filters object
 * @param {React.Dispatch<React.SetStateAction<import('./OrderFilters').Filters>>} props.onFiltersChange - Callback function when filters change
 * @returns {JSX.Element}
 */
const HistoricalFilters = ({ filters, onFiltersChange }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const DatePickerComponent = isMobile ? MobileDatePicker : DatePicker;

  return (
    <Stack direction="row" gap={2} width={{ xs: 'auto', lg: 'auto' }}>
      <DatePickerComponent
        label="From Date"
        format="DD/MM/YYYY"
        value={filters.fromDate ? moment(filters.fromDate) : null}
        onChange={(date) => onFiltersChange({ ...filters, fromDate: date?.toDate().getTime() ?? null })}
        sx={{ flex: 1, maxWidth: { xs: '120px', md: '150px', minWidth: { xs: 'auto', md: '150px' } } }}
      />
      <DatePickerComponent
        label="To Date"
        format="DD/MM/YYYY"
        value={filters.toDate ? moment(filters.toDate) : null}
        onChange={(date) => onFiltersChange({ ...filters, toDate: date?.toDate().getTime() ?? null })}
        sx={{ flex: 1, maxWidth: { xs: '120px', md: '150px', minWidth: { xs: 'auto', md: '150px' } } }}
      />
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {Filters} props.filters - The current filters object
 * @param {React.Dispatch<React.SetStateAction<import('./OrderFilters').Filters>>} props.onFiltersChange - Callback function when filters change
 * @returns {JSX.Element}
 */
const SortBySection = ({ filters, onFiltersChange }) => {
  const handleSortChange = (event) => {
    onFiltersChange((prevFilters) => ({
      ...prevFilters,
      sortBy: event.target.value,
    }));
  };

  return (
    <Stack direction="row" alignItems="center" height="100%">
      <Select value={filters.sortBy || 'newest'} onChange={handleSortChange} sx={{ minWidth: 120 }}>
        <MenuItem value="newest">Newest First</MenuItem>
        <MenuItem value="oldest">Oldest First</MenuItem>
        <MenuItem value="name">Patient Name</MenuItem>
        <MenuItem value="status">Status</MenuItem>
        <MenuItem value="noi">Number of Items</MenuItem>
      </Select>
    </Stack>
  );
};

const StatusFilter = ({ isHistorical, filters, onFiltersChange, orders }) => {
  const statusCounts = React.useMemo(
    () =>
      orders.reduce((acc, order) => {
        acc[order.status] = (acc[order.status] || 0) + 1;

        return acc;
      }, {}),
    [orders],
  );

  const uniqueActiveStatuses = useMemo(() => Array.from(new Set(orders.map((order) => order.status))), [orders]);

  if (isHistorical) {
    return null;
  }

  return (
    <Select
      value={filters.status}
      displayEmpty
      onChange={(e) => onFiltersChange({ ...filters, status: e.target.value })}
      sx={{ display: 'flex', alignItems: 'center', minWidth: 300 }}
      renderValue={(value) => (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="space-between"
          width="100%"
          sx={{ textTransform: 'capitalize' }}
        >
          {value === '' ? 'All Statuses' : parseStatus(value)}
          <Chip label={value === '' ? orders.length : statusCounts[value] || 0} color="primary" size="small" />
        </Stack>
      )}
    >
      <StyledMenuItem key="all" value="">
        All Statuses <Chip label={orders.length || 0} color="primary" size="small" />
      </StyledMenuItem>
      {uniqueActiveStatuses.map((status) => (
        <StyledMenuItem value={status} key={status}>
          {parseStatus(status)} <Chip label={statusCounts[status] || 0} color="primary" size="small" />
        </StyledMenuItem>
      ))}
    </Select>
  );
};

/**
 * Component for filtering orders
 * @param {Object} props
 * @param {Filters} props.filters - The current filters object
 * @param {React.Dispatch<React.SetStateAction<import('./OrderFilters').Filters>>} props.onFiltersChange - Callback function when filters change
 * @param {boolean} props.isHistorical - Whether to show historical filters
 * @param {Array<Object>} props.orders - List of orders to display
 * @returns {JSX.Element}
 */
export const OrderFilters = ({ filters, onFiltersChange, isHistorical, orders }) => (
  <Box
    sx={{
      display: 'flex',
      gap: 2,
      background: 'white',
      flexWrap: 'wrap',
    }}
  >
    <TextField
      placeholder="Search Orders"
      value={filters.search}
      onChange={(e) => onFiltersChange({ ...filters, search: e.target.value })}
      sx={{ minWidth: 200, flex: 1 }}
      slotProps={{
        input: {
          endAdornment: filters.search ? (
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <IconButton
                aria-label="clear search"
                onClick={() => onFiltersChange({ ...filters, search: '' })}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        },
      }}
    />

    <StatusFilter filters={filters} onFiltersChange={onFiltersChange} orders={orders} isHistorical={isHistorical} />

    {isHistorical && <HistoricalFilters filters={filters} onFiltersChange={onFiltersChange} />}

    <SortBySection filters={filters} onFiltersChange={onFiltersChange} />
  </Box>
);
