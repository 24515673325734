import React from 'react';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';

import { db } from '../../../firebase-config';
import { getOrderCurrentStatus, checkIfOrderIsAwaitingPayment, checkIfEscriptIsValid } from '../helpers';

/**
 * @param {object} props object containing pharmacyId, historical, searchType, searchValue
 * @param {string} props.pharmacyId id of the pharmacy
 * @param {boolean} props.isHistorical true if historical orders are being fetched, false if active orders are being fetched
 * @param {number | null} [props.fromDate] start date of search
 * @param {number | null} [props.toDate] end date of search
 * @returns {object} orders, loading, error
 */
export const useOrders = ({ pharmacyId, isHistorical, fromDate, toDate }) => {
  const [orders, setOrders] = React.useState(/** @type {ExtendedOrder[]} */ []);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);

    const ordersRef = collection(db, 'orders', pharmacyId, isHistorical ? 'completed' : 'outstanding');

    // Build query constraints
    const constraints = [];
    if (fromDate) {
      constraints.push(where('updated', '>=', fromDate));
    }
    if (toDate) {
      constraints.push(where('updated', '<=', toDate));
    }
    constraints.push(orderBy('updated', 'desc'));

    const ordersQuery = query(ordersRef, ...constraints);

    // Create listener and store unsubscribe function
    const unsubscribe = onSnapshot(
      ordersQuery,
      (snapshot) => {
        const ordersData = snapshot.docs.map((doc) => {
          const order = doc.data();

          const isAwaitingPayment = checkIfOrderIsAwaitingPayment({ order });
          const isEscriptValid = checkIfEscriptIsValid({ order });

          return {
            id: doc.id,
            ...order,
            currentStatus: getOrderCurrentStatus({ order, isAwaitingPayment, isEscriptValid }),
            isEscriptValid,
            isAwaitingPayment,
          };
        });
        setOrders(ordersData);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      },
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [pharmacyId, isHistorical, fromDate, toDate]);

  return { orders, loading, error };
};
