import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { captureException as sentryCaptureException } from '@sentry/react';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
} from '@mui/material';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { db, functions } from '../../../../../firebase-config';
import { TableRowDetails, MultiSelection } from './approve-pharmacy';

const MEDICINAL_CANNABIS = 'medicinal cannabis';
const PREFIX = 'ApproveSupplier';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  confirmButton: `${PREFIX}-confirmButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    width: '85%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.confirmButton}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const createSupplierAccount = httpsCallable(functions, 'createSupplierAccount');

const ApproveSupplier = ({ handleModalClose, handleModalOpen, generalPatientInfo }) => {
  const isMounted = useRef(false);
  const [allBrands, setAllBrands] = useState([]);
  const [brands, setBrands] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleConfirm = async () => {
    const { id: patientId } = generalPatientInfo || {};
    try {
      setIsSubmit(true);
      handleModalOpen('loading');

      const supplierData = (await getDoc(doc(db, 'patients', patientId))).data();
      const { isSupplier, acceptedAgreements, ...alteredData } = supplierData || {};

      const payload = { ...alteredData, ordersAccepted: [MEDICINAL_CANNABIS], brands, regional: false, software: 0 };

      const { data: createSupplierAccountResponse } = await createSupplierAccount({ id: patientId, payload });
      if (createSupplierAccountResponse?.error) {
        throw new Error(createSupplierAccountResponse.error);
      }

      handleModalOpen(`Result: ${createSupplierAccountResponse.message}`);
    } catch (error) {
      const sentryErrorId = await sentryCaptureException(error, {
        extra: { patientId, issueIn: 'supplier account approval' },
      });
      handleModalOpen(`Error: The supplier could not be approved. Sentry Error ID: ${sentryErrorId}`);
    }
  };

  const onSelectBrands = ({ target }) => {
    const { value } = target || {};
    setBrands(typeof value === 'string' ? value.split(',') : value);
  };

  const getBrands = async () => {
    try {
      const brandsArray = await getDocs(collection(db, 'catalogue')).then((snapshot) =>
        snapshot.docs.reduce((acc, productDoc) => {
          const { brand } = productDoc.data();
          if (!brand || acc.includes(brand)) {
            return acc;
          }
          return [...acc, brand];
        }, []),
      );

      if (isMounted.current) {
        setAllBrands(brandsArray.sort());
      }
    } catch (error) {
      sentryCaptureException(error, { extra: { issueIn: 'supplier account approval' } });
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getBrands();
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
            </Box>
          </Grid>
          <Grid>
            <Divider sx={{ opacity: '0.6' }} />
            <Typography fontWeight="bold" textAlign="center" paddingTop="20px">
              Account Information
            </Typography>
          </Grid>
          <Box paddingTop="20px" paddingBottom="20px">
            <Typography textAlign="center">Select which treatments this supplier offers</Typography>
            <MultiSelection
              isSubmit={isSubmit}
              selectedArray={brands}
              onSelect={onSelectBrands}
              label="brand"
              referenceArray={allBrands}
              disabled={!allBrands.length}
            />
            <TableContainer style={{ marginBottom: 20 }}>
              <Table aria-label="simple table">
                <colgroup>
                  <col width="60%" />
                  <col width="40%" />
                </colgroup>
                <TableBody>
                  <TableRowDetails label="ABN" value={generalPatientInfo.abn} />
                  <TableRowDetails label="Email" value={generalPatientInfo.email} />
                  <TableRowDetails label="Name" value={generalPatientInfo.name} />
                  <TableRowDetails label="Legal Name" value={generalPatientInfo.legalName} />
                  <TableRowDetails label="Phone" value={generalPatientInfo.shipping?.phone} />
                  <TableRowDetails label="Postcode" value={generalPatientInfo.shipping?.postcode} />
                  <TableRowDetails label="Street" value={generalPatientInfo.shipping?.street} />
                  <TableRowDetails label="Suburb" value={generalPatientInfo.shipping?.suburb} />
                  <TableRowDetails label="State" value={generalPatientInfo.shipping?.state} />
                  <TableRowDetails label="Region" value={generalPatientInfo.region} />
                  <TableRowDetails label="Contact Person" value={generalPatientInfo.contactPerson} />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.confirmButton}>
            <Button disabled={!brands.length} variant="contained" color="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </Box>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

ApproveSupplier.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    abn: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    legalName: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      phone: PropTypes.string,
      postcode: PropTypes.string,
      street: PropTypes.string,
      suburb: PropTypes.string,
      state: PropTypes.string,
    }),
    region: PropTypes.string.isRequired,
    contactPerson: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApproveSupplier;
