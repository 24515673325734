import React from 'react';
import { styled } from '@mui/material/styles';
import { doc, updateDoc } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Container, Typography, Button } from '@mui/material';

import { db } from '../../../../../firebase-config';

const PREFIX = 'AwaitingSummary';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridItem: `${PREFIX}-gridItem`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    border: '1px solid #609FAC',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px auto',
    width: '100px',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto 0 auto',
    width: '220px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '20px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const AwaitingSummary = (props) => {
  const { patientId, handleModalClose, handleModalOpen, generalPatientInfo } = props;
  const handleAwaitingSummary = async () => {
    try {
      if (generalPatientInfo.awaitingHealthSummary) {
        await updateDoc(doc(db, 'patients', patientId), { awaitingHealthSummary: false, banner: {} });
        handleModalOpen('Result: Health Summary Task Marked Complete');
      } else {
        await updateDoc(doc(db, 'patients', patientId), {
          awaitingHealthSummary: true,
          banner: { title: 'health summary', link: '/patient-documents' },
        });
        handleModalOpen('Result: Health Summary Set To Pending');
      }
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper elevation={0} className={classes.paper}>
          <Grid className={classes.gridItem}>
            <Typography variant="h5" align="center" gutterBottom>
              {generalPatientInfo.awaitingHealthSummary ? 'Health Summary Uploaded' : 'Awaiting Health Summary'}
            </Typography>
            <br />
          </Grid>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleAwaitingSummary}>
            {generalPatientInfo.awaitingHealthSummary ? 'Make Complete' : 'Submit'}
          </Button>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default AwaitingSummary;
