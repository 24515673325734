import React from 'react';
import { parseAsString, parseAsStringEnum, useQueryStates } from 'nuqs';

import { PHARMACY_TYPES } from '../constants';

export const usePharmaciesFilters = () => {
  const keyMap = React.useMemo(
    () => ({
      searchTerm: parseAsString.withDefault(''),
      state: parseAsString.withDefault(''),
      type: parseAsStringEnum(Object.values(PHARMACY_TYPES)).withDefault(PHARMACY_TYPES.ALL),
    }),
    [],
  );

  return useQueryStates(keyMap);
};
