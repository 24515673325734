import { useState, useCallback } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/react';
import { doc, updateDoc, addDoc, collection, runTransaction, query, where, limit, getDocs } from 'firebase/firestore';

import { db, storage } from '../../../firebase-config';
import { getCatalogueId } from '../../../utils/constants';
import createEmailObj from '../../../functions/create-email-obj';
import { sortPharmaciesByStateAndAlphabetically } from '../../../utils/pharmacies-helper';
import { usePharmacyContext } from './usePharmacyContext';

/**
 * Handles the AP file download
 * @param {Object} params
 * @param {string} params.apRef - The AP reference
 * @param {string} params.doctorEmail - The doctor email
 * @param {function} params.handleFileDownload - The function to handle the file download
 * @param {function} params.setIsLoading - The function to set the loading state
 * @param {Object} params.shipment - The shipment object
 * @param {function} params.snackbar - The function to show the snackbar
 */
const handleApFile = async ({ apRef, doctorEmail, handleFileDownload, setIsLoading, shipment, snackbar }) => {
  const doctorQueryRef = query(collection(db, 'doctors'), where('email', '==', doctorEmail), limit(1));

  try {
    const doctorSnapShot = doctorEmail ? await getDocs(doctorQueryRef) : {};
    const { id: doctorId } = doctorSnapShot?.docs?.[0] || {};

    if (!doctorId || !apRef) {
      throw new Error('No doctor id or apRef found');
    }

    const path = `doctor/${doctorId}/prescriber_authorities/${apRef}.pdf`;
    await handleFileDownload({ path, shipment });
    setIsLoading(false);

    return path;
  } catch (e) {
    sentryCaptureException(e, { extra: { doctorEmail, apRef } });
    snackbar('Error downloading the AP file.');
    setIsLoading(false);

    return e;
  }
};

const handleScriptDownload = async ({
  shipment,
  script,
  setIsLoading,
  isTga,
  pharmacyId,
  catalogue,
  handleFileDownload,
  snackbar,
}) => {
  setIsLoading(true);

  const { priceRef, tgaFile, prescriber: doctorEmail } = script;
  const id = getCatalogueId(priceRef);
  const isAp = tgaFile === 'ap' && isTga;
  const defaultPath = `pharmacy/${pharmacyId || shipment.pharmacyInfo.id}/${isTga ? 'tga' : 'prescriptions'}/${
    shipment.user
  }/${priceRef}`;

  if (isAp) {
    const apRef = catalogue[id]?.apRef;
    await handleApFile({ apRef, doctorEmail, handleFileDownload, setIsLoading, shipment, snackbar });
  }

  await handleFileDownload({ path: defaultPath, shipment });
  setIsLoading(false);

  return defaultPath;
};

/**
 * @typedef {Object} ScriptDownloadReturn
 * @property {function} updateSoftwareFee - The function to update the software fee
 * @property {function} handleRefund - The function to handle the refund
 * @property {function} togglePharmacyBlock - The function to toggle the pharmacy block
 * @property {function} sortPharmacies - The function to sort the pharmacies
 * @property {(params: ScriptDownloadParams) => Promise<void>} scriptDownload - The function to download the script
 * @property {boolean} loading - The loading state
 * @property {Object} error - The error object
 */

/**
 * @typedef {Object} ScriptDownloadParams
 * @property {Object} order - The order object
 * @property {Object} script - The script object
 * @property {() => void} setIsLoading - The function to set the loading state
 * @property {boolean} isTga - Whether the script is a TGA script
 * @property {string} [path] - The path to the file
 */

/**
 * Custom hook to handle pharmacy-related actions like updating fees,
 * handling refunds, and blocking/unblocking orders
 * @returns {ScriptDownloadReturn} - The pharmacy actions
 */
export const usePharmacyActions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { pharmacy, catalogue, snackbar } = usePharmacyContext();

  /**
   * Updates the software fee for a pharmacy and sends notification email
   * @param {Object} params
   * @param {number} params.fee - The new software fee
   */
  const updateSoftwareFee = useCallback(
    async ({ fee }) => {
      try {
        setLoading(true);
        await runTransaction(db, async (transaction) => {
          const pharmacyInfoDoc = (
            await transaction.get(doc(db, 'orders', pharmacy.id, 'information', 'general'))
          ).data();

          const mailRef = collection(db, 'mail');
          transaction.set(
            doc(mailRef),
            createEmailObj(pharmacyInfoDoc?.accountsEmail || pharmacyInfoDoc?.email, 'software_fee_update', {
              softwareFee: fee,
              gst: fee !== 0,
            }),
          );
        });

        snackbar('Software fee updated successfully');
      } catch (err) {
        setError(err);
        snackbar('Failed to update software fee');

        throw err;
      } finally {
        setLoading(false);
      }
    },
    [pharmacy, snackbar],
  );

  /**
   * Issues a refund for a pharmacy
   * @param {Object} params
   * @param {number} params.amount
   */
  const handleRefund = useCallback(
    async ({ amount }) => {
      if (typeof amount !== 'number' || amount < 0) {
        snackbar('Please enter a valid amount');

        return;
      }

      try {
        setLoading(true);
        await addDoc(collection(db, 'orders', pharmacy.id, 'supportIssuedRefunds'), {
          amount: Number(amount),
          timestamp: Date.now(),
        });

        snackbar('Refund issued successfully');
      } catch (err) {
        setError(err);
        snackbar('Failed to issue refund');

        throw err;
      } finally {
        setLoading(false);
      }
    },
    [pharmacy, snackbar],
  );

  /**
   * Toggles the blocked status of a pharmacy
   * @param {Object} params
   * @param {boolean} params.blocked - The new blocked status
   */
  const togglePharmacyBlock = useCallback(
    async ({ blocked }) => {
      try {
        setLoading(true);
        const pharmacyRef = doc(db, 'orders', pharmacy.id);
        await updateDoc(pharmacyRef, {
          hasOrdersBlocked: blocked,
        });

        snackbar(`Pharmacy ${blocked ? 'blocked' : 'unblocked'} successfully`);
      } catch (err) {
        setError(err);
        snackbar('Failed to update pharmacy block status');

        throw err;
      } finally {
        setLoading(false);
      }
    },
    [pharmacy, snackbar],
  );

  const handleFileDownload = useCallback(
    async ({ path, order }) => {
      try {
        const downloadUrl = await getDownloadURL(ref(storage, path));

        window.open(downloadUrl);
      } catch (err) {
        sentryCaptureMessage(`Document is missing. (${path})`, {
          level: 'warning',
          extra: { shipment: order, issueIn: 'fileDownload in orders', path, error: err },
        });

        snackbar('Document is missing.');
      }
    },
    [snackbar],
  );

  const scriptDownload = useCallback(
    /**
     * @param {ScriptDownloadParams} params
     */
    async ({ order, script, setIsLoading, isTga, path }) => {
      if (path) {
        await handleFileDownload({ path, order });

        return;
      }

      await handleScriptDownload({
        isTga,
        script,
        catalogue,
        setIsLoading,
        snackbar,
        handleFileDownload,
        shipment: order,
        pharmacyId: pharmacy?.id,
      });
    },
    [handleFileDownload, pharmacy?.id, snackbar, catalogue],
  );

  return {
    updateSoftwareFee,
    handleRefund,
    togglePharmacyBlock,
    sortPharmacies: sortPharmaciesByStateAndAlphabetically,
    scriptDownload,
    loading,
    error,
  };
};
