import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid2 as Grid, Stack, Typography } from '@mui/material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../../firebase-config';
import { SHIPMENT_TYPES, TREATMENT_TYPES } from '../../../../utils/constants';
import { useAuthContext, usePharmacyContext } from '../../hooks';
import { classes } from './styles';
import { isToShowScriptRelatedContents } from './helpers';

const { COMPLETE } = SHIPMENT_TYPES;
const { MEDICINAL_CANNABIS } = TREATMENT_TYPES;

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {Object} props.shipment
 * @param {Object} props.script
 * @param {Object} props.catalogue
 * @param {import('../../hooks/usePharmacyActions').ScriptDownloadReturn['scriptDownload']} props.scriptDownload
 * @returns {React.ReactNode}
 */
export const ScriptDownloadsSections = ({ id, shipment, script, catalogue, scriptDownload }) => {
  const { user } = useAuthContext();
  const { snackbar } = usePharmacyContext();
  const [isTgaDownloading, setIsTgaDownloading] = useState(false);
  const [isPrescriptionDownloading, setIsPrescriptionDownloading] = useState(false);
  const [isRecentOrder, setIsRecentOrder] = useState(false);

  const medCan = useMemo(() => shipment.formName === MEDICINAL_CANNABIS, [shipment]);

  const checkMostRecentScript = useCallback(async () => {
    try {
      const completedQueryRef = query(
        collection(db, 'orders', user.uid, 'completed'),
        where('user', '==', shipment.user),
      );
      const querySnapshot = await getDocs(completedQueryRef);
      const orders = querySnapshot.docs.map((order) => order.data());
      const sortOrders = orders.sort((orderA, orderB) => orderB.updated - orderA.updated);

      const { payment } = sortOrders[0] || {};

      setIsRecentOrder(payment === shipment.payment);
    } catch (error) {
      setIsRecentOrder(false);
      sentryCaptureException(error, {
        extra: { shipment, userId: user.uid, issueIn: 'checkMostRecentScript' },
      });
      snackbar('An error was encountered while checking the most recent script');
    }
  }, [shipment, user.uid, snackbar]);

  useEffect(() => {
    if (shipment.status === COMPLETE) {
      checkMostRecentScript();
    }
  }, [checkMostRecentScript, shipment.status]);

  if (
    script.scriptOnFile ||
    !isToShowScriptRelatedContents({
      shipment,
      isRecentOrder,
      historical: shipment.status === COMPLETE,
      isSubmit: false,
    })
  ) {
    return null;
  }

  return (
    <Stack direction="column" spacing={0}>
      <Typography variant="body1" fontWeight="bold" mb={1}>
        Script Downloads
      </Typography>

      {!script?.eScriptToken && (
        <Grid size={{ xs: 12 }}>
          <Button
            className={classes.downloadLink}
            variant="outlined"
            loading={isPrescriptionDownloading}
            size="small"
            onClick={() =>
              scriptDownload({
                order: shipment,
                script,
                setIsLoading: setIsPrescriptionDownloading,
                isTga: false,
              })
            }
          >
            {catalogue[id]?.name} Prescription
          </Button>
        </Grid>
      )}

      {medCan && (
        <Grid size={{ xs: 12 }}>
          <Button
            className={classes.downloadLink}
            size="small"
            variant="outlined"
            loading={isTgaDownloading}
            loadingPosition="end"
            onClick={() =>
              scriptDownload({
                order: shipment,
                script,
                setIsLoading: setIsTgaDownloading,
                isTga: true,
              })
            }
          >
            {catalogue[id]?.name} TGA Approval
          </Button>
        </Grid>
      )}

      {script.transfer && (
        <Grid size={{ xs: 12 }}>
          <br />
          <Typography variant="body2" fontWeight="bold">
            Script transferred from {script.transfer}.
            {script.originalRemaining && ` Originally prescribed: ${script.originalRemaining} dispenses.`}{' '}
            {script.remainingCount &&
              `Currently ${script.remainingCount} dispense${script.remainingCount > 1 ? 's' : ''} remaining.`}
          </Typography>
        </Grid>
      )}
    </Stack>
  );
};
