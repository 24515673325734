import { styled } from '@mui/material/styles';
import React, { useState, useContext } from 'react';
import { Grid2 as Grid, Paper, Button, Box, useMediaQuery } from '@mui/material';

import { AuthContext } from '../../../../../auth-context';
import MessagesSelection from '../../../../messenger/messages-selection';
import MessagesContainer from '../../../../messenger/messages-container';
import HealthOverview from './health-overview';

const PREFIX = 'SendMessage';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  overview: `${PREFIX}-overview`,
};

const Root = styled('div')(() => ({
  width: '100%',
  [`& .${classes.container}`]: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    maxHeight: '100vh',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    position: 'relative',
    margin: 0,
    minHeight: '60vh',
    height: '100%',
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      flexDirection: 'column-reverse',
      width: '100%',
    },
    gap: 4,
  },

  [`& .${classes.paper}`]: {
    maxWidth: '800px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.backButtonGrid}`]: {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 30,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    margin: '0px 0px 10px 25px',
  },

  [`& .${classes.overview}`]: {
    flex: '1',
  },
}));

const SendMessage = ({ handleModalClose, patientId, pharmacyId, onClose }) => {
  const { userType } = useContext(AuthContext);
  const [showOverview, setShowOverview] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');

  const isMobile = useMediaQuery('(max-width:600px)');

  const handleMessageSelection = (message) => {
    setSelectedMessage(message);
  };

  return (
    <Root>
      {userType === 'pharmacist' && !isMobile ? (
        <MessagesContainer patientId={patientId} onClose={onClose} />
      ) : (
        <div className={classes.container}>
          <Box className={classes.gridContainer}>
            {showOverview && (
              <Paper className={classes.overview}>
                <HealthOverview patientId={patientId} handleModalClose={() => setShowOverview((prev) => !prev)} />
              </Paper>
            )}
            <Paper className={classes.paper} sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
              <Grid className={classes.backButtonGrid}>
                <Box sx={{ display: 'flex', height: '100%' }}>
                  <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                    Close
                  </Button>
                  {['doctor', 'nurse', 'director'].includes(userType) && (
                    <Button
                      className={classes.backButton}
                      color="primary"
                      onClick={() => setShowOverview((prev) => !prev)}
                    >
                      Patient Overview
                    </Button>
                  )}
                </Box>
              </Grid>
              {selectedMessage || userType === 'pharmacist' || userType === 'support' ? (
                <MessagesContainer patientId={patientId} onClose={onClose} />
              ) : (
                <MessagesSelection
                  handleMessageSelection={handleMessageSelection}
                  patientId={patientId}
                  pharmacyId={pharmacyId}
                />
              )}
            </Paper>
          </Box>
        </div>
      )}
    </Root>
  );
};

export default SendMessage;
