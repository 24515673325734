import React from 'react';
import { Typography } from '@mui/material';

import { ModalContainer } from '../OrderDetailsToolsDropdownMenu/ModalContainer';

// TODO @krochet94: rename bypass to something more descriptive, no dispenses remaining
const MESSAGES = {
  bypass: {
    title: 'No Repeats Remaining',
    content: 'Mark prescription as fully dispensed, no further repeats (refills) for ',
  },
  unbypass: {
    title: 'Repeats Available',
    content: 'Restore prescription, allowing further refills (repeats) for ',
  },
};

const Subtext = ({ content, productName }) => (
  <Typography variant="body2" align="center" gutterBottom>
    {content} <b>{productName}</b>.
  </Typography>
);

/**
 * BypassEscriptModal is a modal that allows the user to bypass the script for a product.
 * @param {Object} props - The props object.
 * @param {Object} props.script - The script object.
 * @param {string} props.productName - The name of the product.
 * @param {() => void} props.onClose - The function to call when the modal is closed.
 * @param {() => void} props.handleProceed - The function to call when the user confirms the bypass.
 * @param {boolean} props.isLoading - Whether the modal is loading.
 * @returns {React.ReactNode} The BypassEscriptModal component.
 */
export const BypassEscriptModal = ({ script, productName, onClose, handleProceed, isLoading }) => {
  const messageType = script.manualBypassed ? 'unbypass' : 'bypass';
  const { title, content } = MESSAGES[messageType];

  return (
    <ModalContainer
      text={title}
      subtext={<Subtext content={content} productName={productName} />}
      onClose={onClose}
      handleProceed={handleProceed}
      loadingComponent={isLoading}
    />
  );
};
