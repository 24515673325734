import { getTemplateVariablesRegex } from './getTemplateVariablesRegex';

/**
 * @typedef {Object} Segment
 * @property {string} key - Unique identifier
 * @property {'text'|'template'} type - Segment type
 * @property {string} content - Segment content
 * @property {string|null} [defaultValue] - Template default value
 */

/**
 * @typedef {Object} Match
 * @property {number} index - Match start index
 * @property {string} variable - Template variable
 * @property {string|null} defaultValue - Default value
 * @property {number} endIndex - Match end index
 */

/**
 * Creates a segment object
 * @param {Object} params - Segment parameters
 * @param {('text' | 'template')} params.type - Segment type
 * @param {string} params.content - Segment content
 * @param {number} params.start - Segment start index
 * @param {number} params.end - Segment end index
 * @param {string | null} [params.defaultValue] - Template default value
 * @returns {Segment} - The created segment
 */
const createSegment = ({ type, content, start, end, defaultValue = null }) => ({
  key: `${type}-${start}-${end}`,
  type,
  content,
  ...(defaultValue && { defaultValue }),
});

/**
 * Finds the next match in the text
 * @param {string} text - The text to search
 * @param {number} [startIndex=0] - The start index
 * @returns {Match | null} - The next match or null if no match is found
 */
const findNextMatch = (text, startIndex = 0) => {
  const regex = getTemplateVariablesRegex();
  const match = regex.exec(text.slice(startIndex));

  if (!match) {
    return null;
  }

  return {
    index: match.index + startIndex,
    variable: match[1],
    defaultValue: match[2] || null,
    endIndex: match.index + startIndex + match[0].length,
  };
};

/**
 * Processes the matches and creates segments
 * @param {Object} params - Parameters
 * @param {string} params.text - The text to process
 * @param {Segment[]} [params.segments=[]] - The existing segments
 * @param {number} [params.lastIndex=0] - The last index
 * @returns {Segment[]} - The processed segments
 */
const processMatches = ({ text, segments = [], lastIndex = 0 }) => {
  if (!text) {
    return segments;
  }

  const match = findNextMatch(text, lastIndex);

  if (!match) {
    return lastIndex < text.length
      ? [
          ...segments,
          createSegment({ type: 'text', content: text.slice(lastIndex), start: lastIndex, end: text.length }),
        ]
      : segments;
  }

  const newSegments = [
    ...(match.index > lastIndex
      ? [
          createSegment({
            type: 'text',
            content: text.slice(lastIndex, match.index),
            start: lastIndex,
            end: match.index,
          }),
        ]
      : []),
    createSegment({
      type: 'template',
      content: match.variable,
      start: match.index,
      end: match.endIndex,
      defaultValue: match.defaultValue,
    }),
  ];

  return processMatches({ text, segments: [...segments, ...newSegments], lastIndex: match.endIndex });
};

/**
 * Parses the text into segments of text and template variables
 * @param {string} text - The text to parse
 * @returns {Array<Segment>} - The parsed segments
 */
export const parseTemplateSegments = (text) => {
  if (!text || typeof text !== 'string') {
    return [];
  }

  return processMatches({ text });
};
