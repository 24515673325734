import React, { useState, useEffect, useCallback } from 'react';
import {
  getAuth,
  multiFactor,
  PhoneAuthProvider,
  EmailAuthProvider,
  RecaptchaVerifier,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
} from 'firebase/auth';
import { styled } from '@mui/material/styles';
import { addDoc, collection } from 'firebase/firestore';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Button, Box, TextField, Grid2 as Grid, Paper, Typography, CircularProgress } from '@mui/material';

import { auth, db } from '../../../firebase-config';
import SecondFactorLogin from '../../login/second-factor-login';
import { ERROR_MESSAGES } from '../../../utils/error-messages';

const PREFIX = 'account-details-two-factor';

const classes = {
  paperHolder: `${PREFIX}-paperHolder`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
  sendCode: `${PREFIX}-sendCode`,
  icon: `${PREFIX}-icon`,
  mobileInputBox: `${PREFIX}-mobileInputBox`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.paperHolder}`]: {
    display: 'flex',
  },

  [`& .${classes.paper}`]: {
    margin: '10px 10px 40px 10px',
    padding: '40px',
    '@media (max-width: 1000px)': {
      width: '100%',
    },
  },

  [`& .${classes.toggle}`]: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.sendCode}`]: {
    display: 'block',
  },

  [`& .${classes.icon}`]: {
    fontSize: 70,
    color: '#0E6D81',
    alignSelf: 'center',
  },

  [`& .${classes.mobileInputBox}`]: {
    display: 'block',
    width: '300px',
    margin: '10px auto 10px auto',
  },
}));

export const AccountDetailsTwoFactor = ({ handleSnackbarOpen }) => {
  const [mode, setMode] = useState('start');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState(null);
  const [isTwoFactorAuthError, setTwoFactorAuthError] = useState(false);
  const [lastSent, setLastSent] = useState(Date.now());
  const [remainingCooldown, setRemainingCooldown] = useState(0);

  useEffect(() => {
    const cooldownMs = 32000;
    const interval = setInterval(() => {
      setRemainingCooldown(Math.floor((cooldownMs - (Date.now() - lastSent)) / 1000));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastSent]);

  const user = auth.currentUser;

  const removeNumber = useCallback(() => {
    // Bail out if user is not authenticated
    if (!user) return;

    setMode('loading');
    const options = multiFactor(user).enrolledFactors;

    const date = Date.now();
    multiFactor(user)
      .unenroll(options[0])
      .then(async () => {
        setMode('start');
        setIsEnrolled(false);
        await addDoc(collection(db, 'patients', user.uid, 'activity'), {
          author: 'System',
          createdAt: date,
          generalData: true,
          text: 'User successfully removed their two-factor authentication',
        });
        window?.location?.reload();
      });
  }, [user]);

  const submitPhoneNumber = async ({ elementId = `recaptcha-container-${lastSent}` }) => {
    const currentAuth = getAuth();
    const { currentUser } = currentAuth;

    // Bail out if user is not authenticated
    if (!currentUser) {
      return;
    }

    try {
      const recaptchaVerifier = new RecaptchaVerifier(currentAuth, elementId, { size: 'invisible' });
      setMode('recaptcha');
      const multiFactorSession = await multiFactor(currentUser).getSession();

      let formattedPhoneNumber = phoneNumber.trim();
      if (formattedPhoneNumber[0] === '0') {
        formattedPhoneNumber = `+61${formattedPhoneNumber.substring(1)}`;
      }
      // Specify the phone number and pass the MFA session.
      const phoneInfoOptions = {
        phoneNumber: formattedPhoneNumber,
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(currentAuth);
      setMode('loading');
      // Send SMS verification code.
      const id = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      setVerificationId(id);
      setMode('verification code');
      setLastSent(Date.now());
    } catch (error) {
      // TODO: Report error in Sentry
      console.error(error);
      handleSnackbarOpen('Code Error: Something went wrong');
      setMode('start');
    }
  };

  const submitCode = async () => {
    // Bail out if user is not authenticated
    if (!user) {
      return;
    }

    try {
      setMode('loading');
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      const date = Date.now();
      // Complete enrollment.
      await multiFactor(user).enroll(multiFactorAssertion, 'Phone Number');
      await addDoc(collection(db, 'patients', user.uid, 'activity'), {
        author: 'System',
        createdAt: date,
        generalData: true,
        text: 'User successfully added two-factor authentication',
      });

      setMode('start');
      setIsEnrolled(true);
      window?.location?.reload();
    } catch (error) {
      setTwoFactorAuthError(true);
      setMode('verification code');
      handleSnackbarOpen('Incorrect Code: Please try again');
    }
  };

  const relogin = async (actionType) => {
    // Bail out if user is not authenticated
    if (!user) {
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(email, password);
      await reauthenticateWithCredential(user, credential);
      setMode('phone number');
    } catch (error) {
      // TODO: FIXME: Report error in Sentry @jmossesgeld
      console.error(error);

      if (error.message === 'Firebase: Error (auth/multi-factor-auth-required).') {
        setAuthError(error);
        // TODO: Handle this better, refactor to not be if/else if
        if (actionType === 'adding') {
          handleSnackbarOpen('Incorrect Login Details');
          setMode('relogin');
          // FIXME: @jmossesgeld Previously was  } else if ('removing') { which looked to be a bug
          // please handle the other potential actionTypes or refactor code to be more readable.
        } else if (actionType === 'removing') {
          setMode('remove number');
        }
      } else {
        handleSnackbarOpen(error.message);
        setMode('start');
      }
    }
  };

  const resendSMS = async () => {
    const timestamp = Date.now();
    setLastSent(timestamp);
    setVerificationId('');
    setTimeout(() => {
      submitPhoneNumber({ elementId: `recaptcha-container-${timestamp}` });
    }, 1000);
  };

  return (
    <StyledGrid size={{ sm: 6 }} className={classes.paperHolder}>
      <Paper className={classes.paper}>
        <Grid container rowSpacing={3}>
          {mode === 'start' && (
            <Grid size={{ xs: 12 }} className={classes.toggle}>
              <Typography variant="h5" align="center" gutterBottom>
                Two-factor Authentication
              </Typography>
              {!user?.reloadUserInfo?.mfaInfo && !isEnrolled && (
                <>
                  <Typography variant="body1" align="center" gutterBottom>
                    Add an extra layer of security to your account
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setMode('adding');
                    }}
                  >
                    Add Mobile Auth
                  </Button>
                </>
              )}
              {(isEnrolled || user?.reloadUserInfo?.mfaInfo) && (
                <>
                  <HowToRegIcon className={classes.icon} />
                  <Typography sx={{ mt: 1 }} variant="h6" align="center" gutterBottom>
                    {user?.reloadUserInfo?.mfaInfo[0].phoneInfo || phoneNumber}
                  </Typography>
                  <Button onClick={() => setMode('removing')}>Remove</Button>
                </>
              )}
            </Grid>
          )}
          {(mode === 'adding' || mode === 'removing') && (
            <>
              <Grid size={{ xs: 12 }}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ padding: '20px', textAlign: 'center' }}>
                <Button variant="contained" onClick={() => relogin(mode)} sx={{ justifyContent: 'center' }}>
                  Confirm Login Details
                </Button>
              </Grid>
            </>
          )}
          {mode === 'remove number' && (
            <SecondFactorLogin error={authError} removeContext removeNumber={removeNumber} />
          )}
          {mode === 'phone number' && (
            <Box>
              <Box className={classes.mobileInputBox} display="flex" gap="2px">
                <TextField
                  placeholder="0412345678"
                  label="Mobile Number"
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Grid size={{ xs: 12 }} sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}>
                  <Button variant="contained" onClick={submitPhoneNumber} className={classes.sendCode}>
                    Send Code
                  </Button>
                </Grid>
              </Box>
            </Box>
          )}
          {mode !== 'start' && !verificationId && <div id={`recaptcha-container-${lastSent}`} />}
          {mode === 'verification code' && (
            <Box display="flex" flexDirection="column" gap="2px">
              <Typography alignSelf="center">Please enter the 6-digit code sent to your device</Typography>
              <Box display="flex" gap="2px" alignItems="stretch" flexDirection="column">
                <TextField
                  placeholder="123456"
                  size="small"
                  label="Verification Code"
                  fullWidth
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  error={isTwoFactorAuthError}
                  helperText={isTwoFactorAuthError ? ERROR_MESSAGES.TWO_FACTOR_AUTH : ''}
                />
                <Button variant="text" onClick={resendSMS} disabled={remainingCooldown > 0}>
                  {remainingCooldown > 0 ? `You can resend the SMS in ${remainingCooldown} seconds` : 'Resend SMS'}
                </Button>
                <Button variant="contained" onClick={submitCode} className={classes.sendCode}>
                  Submit
                </Button>
              </Box>
            </Box>
          )}
          {mode === 'loading' && <CircularProgress />}
        </Grid>
      </Paper>
    </StyledGrid>
  );
};
