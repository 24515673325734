import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../../../firebase-config';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { getTrackingCode, getUpdateTracking, processTrackingLink, getShippedBy } from './helpers';

const TrackingItems = ({ shipment, handleUpdateTracking, summarizedScript, pharmacyData }) => {
  const { user } = useAuthContext();
  const { snackbar } = usePharmacyContext();
  const { name = '', payment: orderId } = shipment || {};
  const tracking = useMemo(() => getTrackingCode(shipment), [shipment]);
  const shippedByLabel = useMemo(() => getShippedBy({ shipment, pharmacyData }), [shipment, pharmacyData]);

  const [trackingCode, setTrackingCode] = useState(tracking);
  const [isSavingTracking, setIsSavingTracking] = useState(false);

  const handleSaveTracking = async () => {
    if (!trackingCode || tracking === trackingCode) {
      snackbar(`Tracking code is the same as the original tracking code: ${orderId}`);
      return;
    }

    setIsSavingTracking(true);
    try {
      const outstandingOrderRef = doc(db, 'orders', pharmacyData.id, 'outstanding', orderId);
      const { UPSERT_FIRESTORE_TRACKING } = getUpdateTracking({ trackingCode, pharmacyData, shipment });
      await updateDoc(outstandingOrderRef, UPSERT_FIRESTORE_TRACKING);
      // remove from the trackingList if it was saved successfully
      // not the updated tracking being used but created this way to remove this item from the trackingList
      handleUpdateTracking({ shipment, newTracking: tracking });
      snackbar(`Tracking code updated successfully.`);
      setIsSavingTracking(false);
    } catch (error) {
      snackbar(`Error updating tracking code: ${orderId}`);
      sentryCaptureException(error, {
        extra: { user, shipment, issueIn: 'handleSaveTracking- TrackingItems' },
      });
      setIsSavingTracking(false);
    }
  };

  return (
    <Box sx={{ m: 3 }}>
      <Typography variant="body1" fontWeight="500">
        {name}
      </Typography>
      {Object.entries(summarizedScript).map(([item, qty]) => (
        <Box key={item} display="flex" gap="0.5px" alignItems="start">
          <Typography variant="body2" fontSize="small">
            {item} <strong>x{qty}</strong>
          </Typography>
        </Box>
      ))}
      <Box sx={{ display: 'flex', mt: 1 }}>
        <TextField
          size="small"
          fullWidth
          value={trackingCode}
          label={shippedByLabel}
          onChange={(e) => setTrackingCode(e.target.value)}
          onBlur={() => handleUpdateTracking({ shipment, newTracking: trackingCode })}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Button
          onClick={handleSaveTracking}
          disabled={isSavingTracking || tracking === trackingCode || !trackingCode}
          loading={isSavingTracking}
        >
          Save
        </Button>
      </Box>
      {tracking && (
        <Link
          variant="body2"
          fontSize="small"
          target="_blank"
          href={processTrackingLink({ shipment, trackingCode: tracking })}
        >
          View Tracking Link
        </Link>
      )}
    </Box>
  );
};

TrackingItems.propTypes = {
  shipment: PropTypes.shape({
    name: PropTypes.string,
    tracking: PropTypes.string,
    payment: PropTypes.string,
  }).isRequired,
  handleUpdateTracking: PropTypes.func.isRequired,
  summarizedScript: PropTypes.objectOf(PropTypes.number).isRequired,
  pharmacyData: PropTypes.shape({
    id: PropTypes.string,
    tracking:
      PropTypes.string ||
      PropTypes.shape({
        trackingCode: PropTypes.string,
        shippedBy: PropTypes.string,
        createdAt: PropTypes.number,
        createdBy: PropTypes.string,
        updatedAt: PropTypes.number,
        updatedBy: PropTypes.string,
      }),
    shipmentProvider: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

export default TrackingItems;
