import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Typography, Grid2 as Grid, Checkbox, Button, Box, styled } from '@mui/material';

const ExportOrderCard = styled(Box)(() => ({
  width: '95vw',
  maxWidth: '32rem',
}));

export const ExportModal = ({ onExport, onClose }) => {
  const [signatureRequired, setSignatureRequired] = useState(false);

  return (
    <ExportOrderCard>
      <Typography variant="h6" color="primary" sx={{ marginBottom: '1rem', textAlign: 'center' }}>
        Export AusPost CSV for Merchant Portal
      </Typography>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid size={{ xs: 12 }} container alignItems="center">
          <Grid size={{ xs: 8 }}>
            <Typography>Require Signature on Delivery</Typography>
          </Grid>
          <Grid size={{ xs: 4 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Checkbox checked={signatureRequired} onChange={() => setSignatureRequired((prev) => !prev)} />
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              onExport(signatureRequired);
              onClose();
            }}
          >
            Export CSV
          </Button>
        </Grid>
      </Grid>
    </ExportOrderCard>
  );
};

ExportModal.propTypes = {
  onExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
