import React from 'react';
import PropTypes from 'prop-types';
import { Ballot } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';

import { getCatalogueId } from '../../../../utils/constants';
import { parseStatus } from '../../helpers';
import { useOrders, usePharmacyContext } from '../../hooks';

// FIXME:  Add this to NPM PKGS or at root of project as we need to keep an eye on the version to update the script
const jsPDFSrc = 'https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js';

/**
 * Get the summarized items from the orders
 * @param {Object} params - The parameters to use
 * @param {Order[]} params.orders - The orders to summarize
 * @param {Object} params.catalogue - The catalogue to use
 * @returns {Array} The summarized items
 */
const getSummarizedItems = ({ orders, catalogue }) =>
  Object.entries(
    orders.reduce((acc, order) => {
      order?.scriptsArray.forEach(({ priceRef }) => {
        const catalogueId = getCatalogueId(priceRef);
        const productData = catalogue[catalogueId];

        if (!productData) {
          return null;
        }

        const { item } = productData;

        if (!acc[item]) {
          acc[item] = 0;
        }

        acc[item] += 1;

        return null;
      });

      return acc;
    }, {}),
  ).sort(([a], [b]) => a.localeCompare(b));

const processPdf = (summarizedItems) => {
  const script = window.document.createElement('script');
  script.src = jsPDFSrc;
  script.async = true;
  script.onload = () => {
    // eslint-disable-next-line new-cap
    const doc = new window.jspdf.jsPDF();
    const x = 10;
    let y = 20;
    // Add heading at center
    doc.setFontSize(18);
    doc.setTextColor('#2aafbb');
    doc.text('Picking Manifest', 105, y, { align: 'center' });
    doc.setTextColor('#000');
    // Add line
    doc.setDrawColor(0);
    doc.line(x, y + 5, x + 190, y + 5);
    // Increment y
    y += 20;

    summarizedItems.forEach(([item, qty]) => {
      // Set font size
      doc.setFontSize(11);
      // Insert checkbox
      doc.setDrawColor(0);
      doc.setFillColor(255);
      doc.rect(x, y + 1, 5, 5, 'FD');
      // Set font type and insert qty
      doc.setTextColor('#2aafbb');
      doc.setFont('helvetica', 'bold');
      doc.text(`${qty}x`, x + 10, y + 5);
      // Set font type and insert item
      doc.setTextColor('#000');
      doc.setFont('helvetica', 'normal');
      doc.text(`${item}`, x + 20, y + 5);
      // Insert line
      doc.setDrawColor(0);
      doc.line(x, y + 10, x + 190, y + 10);
      // Increment y
      y += 15;
      // If we're at the bottom of the page, create a new page
      if (y >= 285) {
        doc.addPage();
        y = 10;
      }
    });
    doc.save('Picking Manifest.pdf');
  };
  window.document.body.appendChild(script);
};

const Pickup = ({ pickup }) => {
  const [item, qty] = pickup;
  return (
    <Box display="flex" mb={1} gap={1} alignItems="start">
      <Typography variant="body1" color="primary" flex={1} fontWeight={500}>
        {qty}x
      </Typography>
      <Typography variant="body1" flex={20}>
        {item}
      </Typography>
    </Box>
  );
};

Pickup.propTypes = {
  pickup: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

/**
 * @param {Object} props
 * @param {} props.orderType
 */
const PickUpManifestModal = ({ orderType }) => {
  const { pharmacy, catalogue, handleDialogue } = usePharmacyContext();
  const { orders } = useOrders({
    pharmacyId: pharmacy.id,
    isHistorical: false,
  });

  const filteredOrders = React.useMemo(
    () => orders.filter((o) => !orderType || o.status === orderType),
    [orders, orderType],
  );

  const summarizedItems = getSummarizedItems({ orders: filteredOrders, catalogue });

  const saveAsPDF = () => {
    processPdf(getSummarizedItems({ orders: filteredOrders, catalogue }));
    handleDialogue({ isOpen: false, content: null });
  };
  return (
    <Box maxWidth="100%" maxHeight="50%" height="50%" overflow="clip">
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack direction="column" gap={0}>
          <Typography variant="h6" color="primary">
            Picking Manifest
          </Typography>
          <Typography variant="caption" color="primary">
            For {orderType ? parseStatus(orderType) : 'all'} orders
          </Typography>
        </Stack>

        <Button onClick={saveAsPDF}>Save as PDF</Button>
      </Stack>
      <Box
        sx={{
          padding: '1rem',
          maxHeight: '70vh',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#2AAFBB',
            borderRadius: '100px',
          },
        }}
      >
        {summarizedItems.map((pickup) => (
          <Pickup key={pickup[0]} pickup={pickup} pharmacyData={pharmacy} />
        ))}
      </Box>
    </Box>
  );
};

PickUpManifestModal.propTypes = {
  orderType: PropTypes.string.isRequired,
};

/**
 * The Picking Manifest button
 * @param {Object} props - The parameters to use
 * @param {string} props.orderType - The order type
 * @returns {JSX.Element} The Picking Manifest button
 */
export const PickingManifest = ({ orderType }) => {
  const { handleDialogue } = usePharmacyContext();

  const openModal = () => {
    handleDialogue({
      isOpen: true,
      content: <PickUpManifestModal orderType={orderType} />,
    });
  };

  return (
    <Button startIcon={<Ballot />} onClick={openModal}>
      Picking Manifest for {orderType ? parseStatus(orderType) : 'all'} orders
    </Button>
  );
};
