import {
  TextField,
  Grid2 as Grid,
  Paper,
  Typography,
  Box,
  Fab,
  InputAdornment,
  Button,
  Chip,
  IconButton,
  CircularProgress,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Stack,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';

import { AuthContext } from '../../auth-context';
import { getTemplateVariablesRegex } from '../account-details/misc-section/CannedResponses';
import MessageBox from './MessageBox';
import ChatbotIcon from './ChatbotIcon';
import LinearProgressWithLabel from './message-upload-bar';

const SCROLL_DISTANCE = 40;

const PREFIX = 'MessagesPresentation';

const classes = {
  paper: `${PREFIX}-paper`,
  desktopPaper: `${PREFIX}-desktopPaper`,
  photoBox: `${PREFIX}-photoBox`,
  nameBox: `${PREFIX}-nameBox`,
  avatar: `${PREFIX}-avatar`,
  buttons: `${PREFIX}-buttons`,
  messageDisplay: `${PREFIX}-messageDisplay`,
  myMessageBox: `${PREFIX}-myMessageBox`,
  myMessageGrid: `${PREFIX}-myMessageGrid`,
  yourMessageBox: `${PREFIX}-yourMessageBox`,
  yourMessageGrid: `${PREFIX}-yourMessageGrid`,
  writeMessageBox: `${PREFIX}-writeMessageBox`,
  textProfile: `${PREFIX}-textProfile`,
  sendButton: `${PREFIX}-sendButton`,
  backLink: `${PREFIX}-backLink`,
  dateTime: `${PREFIX}-dateTime`,
  warningBox: `${PREFIX}-warningBox`,
  uploadIcon: `${PREFIX}-uploadIcon`,
  arrowIcon: `${PREFIX}-arrowIcon`,
  disableArrowIcon: `${PREFIX}-disableArrowIcon`,
  desktopContainer: `${PREFIX}-desktopContainer`,
  senderIcon: `${PREFIX}-senderIcon`,
};

const StyledBox = styled(Box)({
  width: '100%',

  [`& .${classes.paper}`]: {
    height: '100%',
    marginBottom: '10px',
    position: 'relative',
    '@media (max-height: 400px)': {
      height: '75vh',
    },
    '@media (max-height: 800px)': {
      height: '85vh',
    },
  },
  [`& .${classes.desktopPaper}`]: {
    height: '89vh',
    marginBottom: '10px',
    position: 'relative',
    '@media (max-height: 400px)': {
      height: '75vh',
    },
    '@media (max-height: 800px)': {
      height: '85vh',
    },
  },
  [`& .${classes.photoBox}`]: {
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.nameBox}`]: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.avatar}`]: {
    display: 'none',
    '@media (min-height: 750px)': {
      display: 'block',
      width: '40px',
      height: '40px',
    },
    '@media (min-height: 850px)': {
      width: '60px',
      height: '60px',
    },
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    gap: '10px',
  },
  [`& .${classes.messageDisplay}`]: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
    overflowX: 'hidden',
  },
  [`& .${classes.myMessageBox}`]: {
    width: 'auto',
    maxWidth: '80%',
    color: '#fff',
    background: '#303F56',
    margin: 10,
    padding: 10,
    borderRadius: 10,
  },
  [`& .${classes.myMessageGrid}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  [`& .${classes.yourMessageBox}`]: {
    width: 'auto',
    maxWidth: '80%',
    color: '#303F56',
    background: 'rgb(224, 224, 224)',
    margin: 10,
    padding: 10,
    borderRadius: 10,
  },
  [`& .${classes.yourMessageGrid}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  [`& .${classes.writeMessageBox}`]: {
    padding: '4px',
    '@media (min-height: 700px)': {
      padding: '10px',
    },
  },
  [`& .${classes.textProfile}`]: {
    '@media (max-width: 600px)': {
      fontSize: 12,
    },
  },
  [`& .${classes.sendButton}`]: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.backLink}`]: {
    margin: '10px 0px 20px 0px',
    fontWeight: 'bold',
    fontSize: 16,
  },
  [`& .${classes.dateTime}`]: {
    fontSize: '0.6rem',
    display: 'flex',
    width: '100%',
    margin: '0 10px 10px',
    padding: '0 10px',
  },
  [`& .${classes.warningBox}`]: {
    backgroundColor: '#EB8382',
    padding: '5px 0px',
  },
  [`& .${classes.uploadIcon}`]: {
    color: 'white',
  },
  [`& .${classes.arrowIcon}`]: {
    color: '#2AAFBB',
    width: 25,
    height: 25,
  },
  [`& .${classes.disableArrowIcon}`]: {
    color: '#d7d7d7',
    width: 25,
    height: 25,
  },
  [`& .${classes.desktopContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
  [`& .${classes.senderIcon}`]: {
    display: 'flex',
    alignItems: 'end',
    gap: 5,
  },
});

/**
 * Processes handlebars template with provided data
 * @param {string} template - Template string with handlebars
 * @param {Record<keyof typeof import('../account-details/misc-section/CannedResponses').TEMPLATE_VARIABLES, string>} data - Data object containing values for variables
 * @returns {string} - Processed string with replaced variables
 */
const processHandlebars = (template, data) => {
  const regex = getTemplateVariablesRegex();

  return template.replace(regex, (match, variable, defaultValue) => {
    const value = data[variable];

    if (value) {
      return value;
    }

    if (defaultValue) {
      return defaultValue;
    }

    return match; // Keep original template if no value or default
  });
};

const ErrorDisplay = ({ errorMessage, className, widthMatch }) => (
  <Box className={className}>
    <Typography
      variant="body2"
      textAlign="center"
      whiteSpace="nowrap"
      {...((!widthMatch && { fontSize: 10 }) || {})}
      color="#fff"
      gutterBottom
      style={{ fontWeight: 500 }}
    >
      {errorMessage}
    </Typography>
  </Box>
);

const MessagesPresentation = (props) => {
  const widthMatch = useMediaQuery('(min-width:400px)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const {
    cancel,
    handleChange,
    handleSend,
    handleUpload,
    messageLog,
    scrollRef,
    messageText,
    recipientName,
    outsideRegularHours,
    messagingAllowed,
    savedResponses,
    progress,
    messageBoxRef,
    noPermission,
    recipientId,
    isSending,
    onClose,
    handleChatbotSuggestion,
    isChatbotActive,
    senderDetails,
    geminiErrorMessage,
    handleChatbotActiveSwitch,
    conversation,
  } = props;

  const history = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const access = searchParams.get('access');
  const { userType } = React.useContext(AuthContext);
  const [isChatbotSuggestionLoading, setIsChatbotSuggestionLoading] = React.useState(false);
  const [isAppendMode, setIsAppendMode] = React.useState(false);
  const textFieldRef = React.useRef(/** @type {HTMLDivElement | null} */ (null));
  const savedResponsesRef = React.useRef(/** @type {HTMLDivElement | null} */ (null));
  const [canScrollUp, setCanScrollUp] = React.useState(false);
  const [canScrollDown, setCanScrollDown] = React.useState(false);
  const resizeObserverRef = React.useRef(/** @type {ResizeObserver | null} */ (null));

  /**
   * @param {{ top: number }} params
   */
  const updateScrollButtons = React.useCallback(({ top }) => {
    if (!savedResponsesRef.current) {
      return;
    }

    const element = savedResponsesRef.current;
    setCanScrollUp(top > 0);
    setCanScrollDown(top + element.clientHeight < element.scrollHeight);
  }, []);

  const refCallback = React.useCallback(
    /**
     * @param {HTMLDivElement | null} node
     */
    (node) => {
      if (!node) {
        resizeObserverRef.current?.disconnect();
        return;
      }

      savedResponsesRef.current = node;

      resizeObserverRef.current = new ResizeObserver(() => {
        updateScrollButtons({ top: node.scrollTop });
      });

      // Start observing
      resizeObserverRef.current.observe(node);

      // Initial calculation
      updateScrollButtons({ top: 0 });

      // TODO @keeganpotgieter: When we move to React 19, can make this cleaner by making and cleaning up observer in the refCallback
      // return () => resizeObserver.disconnect();
    },
    [updateScrollButtons],
  );

  const handleUpIcon = () => {
    if (!savedResponsesRef.current || !canScrollUp) {
      return;
    }

    const element = savedResponsesRef.current;
    // Round to the nearest multiple of SCROLL_DISTANCE
    const top = Math.floor(element.scrollTop / SCROLL_DISTANCE) * SCROLL_DISTANCE - SCROLL_DISTANCE;

    element.scrollTo({
      top,
      behavior: 'smooth',
    });

    updateScrollButtons({ top });
  };

  const handleDownIcon = () => {
    if (!savedResponsesRef.current || !canScrollDown) {
      return;
    }

    const element = savedResponsesRef.current;
    // Round to the nearest multiple of SCROLL_DISTANCE
    const top = Math.floor(element.scrollTop / SCROLL_DISTANCE) * SCROLL_DISTANCE + SCROLL_DISTANCE;

    element.scrollTo({
      top,
      behavior: 'smooth',
    });

    updateScrollButtons({ top });
  };

  const handleChatbotSuggestionClick = async () => {
    setIsChatbotSuggestionLoading(true);
    await handleChatbotSuggestion();
    setIsChatbotSuggestionLoading(false);
  };

  /**
   * @param {Object} params
   * @param {string} params.responseText - The response text to handle
   */
  const handleSavedResponseClick = ({ responseText }) => {
    const processedText = processHandlebars(responseText, {
      patientFirstName: recipientName?.split(' ')[0],
      patientFullName: recipientName,
      myFirstName: senderDetails.name?.split(' ')[0],
    });

    if (!isAppendMode) {
      handleChange(processedText);
      return;
    }

    if (!textFieldRef.current) {
      handleChange(processedText);
      return;
    }

    const input = textFieldRef.current.querySelector('textarea');

    if (!input) {
      handleChange(processedText);
      return;
    }

    const start = input.selectionStart;
    const end = input.selectionEnd;
    const currentText = input.value || '';
    const newText = currentText.substring(0, start) + processedText + currentText.substring(end);

    handleChange(newText);

    setTimeout(() => {
      input.focus();
      input.setSelectionRange(start + processedText.length, start + processedText.length);
    }, 0);
  };

  /**
   * @param {React.ChangeEvent<unknown>} _event
   * @param {string} value
   */
  const handleAppendModeToggleChange = (_event, value) => {
    if (value === null) {
      return;
    }

    setIsAppendMode(value === 'append');
  };

  const isInMessages = history.pathname.includes('messages');

  const InnerContainer = isInMessages ? Paper : 'div';
  const boxSx = isInMessages ? { px: 2, pb: 2 } : {};

  return (
    <StyledBox maxWidth="md" style={{ margin: 0, height: '100%' }} sx={boxSx}>
      <InnerContainer style={{ height: '100%' }}>
        <Grid container style={{ height: '100%' }}>
          <Grid size={{ xs: 12 }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Grid container>
              <Grid size={{ xs: 4 }}>
                {history.pathname.includes('messages') && (
                  <Button variant="contained" size="small" onClick={cancel} sx={{ mt: 1, ml: 1 }}>
                    Back
                  </Button>
                )}
                {((userType === 'pharmacist' && !isMobile && !history.pathname.includes('messages')) ||
                  (!access &&
                    !history.pathname.includes('messages') &&
                    history.pathname.includes('patients') &&
                    userType !== 'support')) && (
                  <Button variant="contained" size="small" onClick={onClose} sx={{ mt: 1, ml: 1 }}>
                    Close
                  </Button>
                )}
              </Grid>
              <Grid size={{ xs: 4 }}>
                <Box className={classes.photoBox}>
                  {isChatbotActive ? (
                    <ChatbotIcon />
                  ) : (
                    <AccountCircleRoundedIcon color="primary" className={classes.avatar} />
                  )}
                </Box>
                <Box className={classes.nameBox}>
                  <Typography variant="body2" textAlign="center" gutterBottom style={{ fontWeight: 600 }}>
                    {isChatbotActive ? 'Candi Chatbot' : recipientName}
                  </Typography>
                </Box>
                {userType === 'support' && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="caption" textAlign="center">
                      Chatbot Active
                    </Typography>
                    <Switch
                      color="primary"
                      size="small"
                      checked={conversation?.isChatbotActive ?? !conversation?.hasChatbotIntroduced}
                      onChange={(e) => handleChatbotActiveSwitch(e.target.checked)}
                    />
                  </Box>
                )}
              </Grid>
              <Grid size={{ xs: 4 }} style={{ display: 'flex', alignItems: 'start', justifyContent: 'flex-end' }}>
                {userType !== 'patient' && userType !== 'pharmacist' && history.pathname.includes('messages') && (
                  <Button
                    className={classes.textProfile}
                    variant="contained"
                    size="small"
                    onClick={() => navigate(`/patients/${recipientId}`)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    View Profile
                  </Button>
                )}
              </Grid>
            </Grid>
            {userType === 'patient' && (outsideRegularHours || Date.now() < 1681135140000) && (
              <ErrorDisplay
                errorMessage="Responses may be slower outside regular hours"
                className={classes.warningBox}
                widthMatch={widthMatch}
              />
            )}
            {userType === 'patient' && geminiErrorMessage && (
              <ErrorDisplay errorMessage={geminiErrorMessage} className={classes.warningBox} widthMatch={widthMatch} />
            )}
            {noPermission && (
              <ErrorDisplay
                errorMessage="You are not able to view this chat"
                className={classes.warningBox}
                widthMatch={widthMatch}
              />
            )}
            <Divider
              variant="middle"
              sx={{
                opacity: '0.6',
              }}
            />
            <Box style={{ flex: 1, position: 'relative', minHeight: '40vh' }}>
              <Grid
                ref={messageBoxRef}
                className={classes.messageDisplay}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
              >
                <div ref={scrollRef} />
                {messageLog.map((message, index, messages) => (
                  <MessageBox
                    key={message.id}
                    message={message}
                    senderDetails={senderDetails}
                    recipientName={recipientName}
                    previousMessage={messages[index + 1]}
                  />
                ))}
              </Grid>
            </Box>
            {progress !== 0 && <LinearProgressWithLabel value={progress} />}
            <Grid container className={classes.writeMessageBox} alignItems="center">
              <Grid size={{ xs: 12 }}>
                <TextField
                  ref={textFieldRef}
                  disabled={!messagingAllowed || noPermission}
                  multiline
                  minRows={isMobile && userType !== 'pharmacist' ? 4 : 1}
                  maxRows={12}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => handleChange(e.target.value)}
                  value={messagingAllowed ? messageText || '' : 'This chat is currently offline.'}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="start">
                          <Box className={classes.buttons} sx={{ flexDirection: isMobile ? 'column' : 'row' }}>
                            {isSending && <CircularProgress />}
                            {!isSending && (
                              <Fab
                                className={classes.fab}
                                color="primary"
                                size="small"
                                aria-label="add"
                                onClick={handleSend}
                                disabled={!messagingAllowed || noPermission}
                              >
                                <SendRoundedIcon />
                              </Fab>
                            )}
                            {userType !== 'pharmacist' && (
                              <Fab
                                color="primary"
                                size="small"
                                onClick={handleSend}
                                disabled={!messagingAllowed || noPermission || isSending}
                              >
                                <IconButton aria-label="upload" component="label" size="large">
                                  <input
                                    hidden
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg, application/pdf"
                                    onChange={handleUpload}
                                  />
                                  <UploadFileIcon className={classes.uploadIcon} />
                                </IconButton>
                              </Fab>
                            )}
                          </Box>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                {userType !== 'patient' && (
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 1,
                      height: 72,
                    }}
                  >
                    <Box
                      ref={refCallback}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        overflow: 'hidden',
                        width: '100%',
                      }}
                    >
                      {userType === 'support' && !isChatbotSuggestionLoading && (
                        <Chip
                          color="primary"
                          sx={{ whiteSpace: 'pre-line' }}
                          label="Chatbot Suggestion"
                          onClick={handleChatbotSuggestionClick}
                        />
                      )}
                      {userType === 'support' && isChatbotSuggestionLoading && (
                        <CircularProgress size={26} sx={{ mx: '2.5rem' }} />
                      )}
                      {savedResponses.map((response) => (
                        <Chip
                          id={`key_${response.key}`}
                          color="primary"
                          variant="outlined"
                          sx={{ whiteSpace: 'pre-line' }}
                          key={`key_${response.key}`}
                          label={response.title}
                          onClick={() => handleSavedResponseClick({ responseText: response.response })}
                        />
                      ))}
                    </Box>

                    <Stack direction="row" justifyContent="space-between" gap={1}>
                      {savedResponses.length > 0 && (
                        <ToggleButtonGroup
                          orientation="vertical"
                          color="primary"
                          value={isAppendMode ? 'append' : 'replace'}
                          size="small"
                          exclusive
                          onChange={handleAppendModeToggleChange}
                          sx={{ height: '100%' }}
                        >
                          <Tooltip
                            placement="left"
                            title="When selected, the saved response will replace the current message."
                          >
                            <ToggleButton sx={{ fontSize: 10, textTransform: 'none', flex: 1 }} value="replace">
                              Replace
                            </ToggleButton>
                          </Tooltip>
                          <Tooltip
                            placement="left"
                            title="When selected, the saved response will be appended to the current message."
                          >
                            <ToggleButton sx={{ fontSize: 10, textTransform: 'none', flex: 1 }} value="append">
                              Append
                            </ToggleButton>
                          </Tooltip>
                        </ToggleButtonGroup>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: 1,
                        }}
                      >
                        <IconButton
                          onClick={handleUpIcon}
                          color="primary"
                          component="label"
                          sx={{ padding: 0 }}
                          size="small"
                          disabled={!canScrollUp}
                        >
                          <ArrowDropDownCircleRoundedIcon style={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                        <IconButton
                          onClick={handleDownIcon}
                          color="primary"
                          component="label"
                          sx={{ padding: 0 }}
                          size="small"
                          disabled={!canScrollDown}
                        >
                          <ArrowDropDownCircleRoundedIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </InnerContainer>
    </StyledBox>
  );
};

MessagesPresentation.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  messageLog: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string,
      sender: PropTypes.string,
      timestamp: PropTypes.string,
    }),
  ).isRequired,
  scrollRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  messageText: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  outsideRegularHours: PropTypes.bool.isRequired,
  messagingAllowed: PropTypes.bool.isRequired,
  savedResponses: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      response: PropTypes.string,
    }),
  ).isRequired,
  progress: PropTypes.number.isRequired,
  messageBoxRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  noPermission: PropTypes.bool.isRequired,
  recipientId: PropTypes.string.isRequired,
  isSending: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleChatbotSuggestion: PropTypes.func.isRequired,
  isChatbotActive: PropTypes.bool.isRequired,
  senderDetails: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  geminiErrorMessage: PropTypes.string.isRequired,
  handleChatbotActiveSwitch: PropTypes.func.isRequired,
  conversation: PropTypes.shape({
    isChatbotActive: PropTypes.bool,
    hasChatbotIntroduced: PropTypes.bool,
  }).isRequired,
};

ErrorDisplay.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  widthMatch: PropTypes.bool.isRequired,
};

export default MessagesPresentation;
