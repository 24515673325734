import React from 'react';
import { Grid2 as Grid, Typography, Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';

import TeaInfo from './tea-info';
import OilInfo from './oil-info';
import FlowerInfo from './flower-info';
import WafersInfo from './wafers-info';
import GummiesInfo from './gummies-info';
import CapsuleInfo from './capsule-info';
import CartridgeInfo from './cartridge-info';
import ChocolateInfo from './chocolate-info';
import PessariesInfo from './pessaries-info';
import SuppositoriesInfo from './suppositories-info';
import TerpeneTranslator from './terpene-translator';
import ConcentratedExtractInfo from './concentrated-extract-info';
import PressurisedInhalerInfo from './pressurised-inhalation-info';

const Section = ({ title, children }) => (
  <>
    <Typography variant="h6">{title}</Typography>
    <br />
    <Typography variant="body1">{children}</Typography>
    <br />
  </>
);

const ListSection = ({ title, items = [] }) =>
  items?.length ? (
    <Section title={title}>
      {items.map((item) => (
        <React.Fragment key={title + item}>
          {item}
          <br />
        </React.Fragment>
      ))}
    </Section>
  ) : null;

const TerpeneSection = ({ title, terpenes = [] }) =>
  terpenes?.length ? (
    <Section title={title}>
      {terpenes.map((terpene) => (
        <React.Fragment key={terpene}>
          <Box component="span" style={{ fontWeight: 'bold' }}>
            {terpene}
          </Box>
          <br />
          Aromas: {TerpeneTranslator[terpene]?.aromas}
          <br />
          Benefits: {TerpeneTranslator[terpene]?.benefits}
          <br />
          <br />
        </React.Fragment>
      ))}
    </Section>
  ) : null;

const dosageFormComponents = {
  Capsule: CapsuleInfo,
  Cartridge: CartridgeInfo,
  'Oral Liquid': OilInfo,
  Wafer: WafersInfo,
  Herb: FlowerInfo,
  Tea: TeaInfo,
  Gummies: GummiesInfo,
  Chocolate: ChocolateInfo,
  'Concentrated Extract': ConcentratedExtractInfo,
  Pessaries: PessariesInfo,
  Suppositories: SuppositoriesInfo,
  'Pressurised Inhalation': PressurisedInhalerInfo,
};

const CannabisInfoSheet = ({ title, treatmentDetails }) => {
  const DosageFormComponent = dosageFormComponents[treatmentDetails.dosageForm];
  return (
    <Grid>
      <Typography variant="body1">
        Information relating to the claimed therapeutic effects and the conditions this product is used for is based on
        patient feedback and supplier guidelines. This means the information may be less accurate than information for
        products that have undergone rigorous clinical trials.
      </Typography>
      <br />
      <Divider
        sx={{
          opacity: '0.6',
        }}
      />
      <br />
      <Typography variant="h5" fontWeight="800">
        {title}
      </Typography>
      <br />
      {treatmentDetails.dosageForm && <Section title="Dosage Form">{treatmentDetails.dosageForm}</Section>}
      <ListSection title="Claimed Therapeutic effects" items={treatmentDetails.therapeuticEffects} />
      <ListSection title="Often Tried for" items={treatmentDetails.usedFor} />
      <ListSection title="Flavours & Aromas" items={treatmentDetails.flavoursAromas} />
      <TerpeneSection title="Terpenes" terpenes={treatmentDetails.terpenes} />
      <ListSection title="Minor Terpenes" items={treatmentDetails.minorTerpenes} />
      <ListSection title="Cannabinoids" items={treatmentDetails.cannabinoids} />
      <ListSection title="Minor Cannabinoids" items={treatmentDetails.minorCannabinoids} />
      {treatmentDetails.cultivar && <Section title="Cultivar">{treatmentDetails.cultivar}</Section>}
      {treatmentDetails.carrierOil && <Section title="Carrier Oil">{treatmentDetails.carrierOil}</Section>}
      {treatmentDetails.strainLineage && <Section title="Strain Lineage">{treatmentDetails.strainLineage}</Section>}
      {treatmentDetails.concentration && (
        <Section title="THC:CBD concentration">{treatmentDetails.concentration}</Section>
      )}
      {treatmentDetails.drawConcentration && (
        <Typography variant="body1">
          UrbanLeaf mCARTs deliver a metered dosage of 5mg of extract per 3 second draw. 1 draw delivers{' '}
          {treatmentDetails.drawConcentration}.
          <br />
          <br />
          Each mCART contains approximately 100 draws.
        </Typography>
      )}
      {treatmentDetails.tgaCategory && <Section title="TGA category">{treatmentDetails.tgaCategory}</Section>}
      {DosageFormComponent && <DosageFormComponent />}
      <>
        <Section title="Important Safety Information">
          Do not drive or operate machinery while taking Medicinal Cannabis.
          <br />
          <br />
          Before taking medicinal cannabis, tell your doctor and pharmacist about all prescription and over-the-counter
          medications and supplements you take or plan to take.
        </Section>
        <Section title="Possible Short-term effects of Cannabis">
          - Altered visual and / or auditory perception
          <br />
          - High doses of THC may increase anxiety, depression, paranoia and psychotic symptoms
          <br />
          - Increased phlegm, cough, wheezing and shortness of breath
          <br />
          - Impaired short-term memory
          <br />
          - Altered thinking
          <br />
          - Loss of inhibition
          <br />
          - Spontaneous laughter
          <br />
          - Quiet and reflective mood
          <br />
          - Confusion
          <br />
          - Reddened / bloodshot eyes
          <br />
          - Sleepiness
          <br />
          - Reduced coordination and balance
          <br />
          - Increased heart rate
          <br />
          - Low blood pressure
          <br />- Increased appetite
        </Section>
        <Section title="Indicators that the dose might be too high or the strain isn’t suitable for you">
          - Confusion
          <br />
          - Restlessness
          <br />
          - Excitement
          <br />
          - Hallucinations
          <br />
          - Anxiety or panic
          <br />
          - Detachment from reality
          <br />
          - Decreased reaction time
          <br />- Paranoia
        </Section>
        <Section title="Storage">
          Cannabis should be stored in a cool, dark, and dry place such as a cupboard. Store in a secure location out of
          the reach of children.
        </Section>
      </>
    </Grid>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ListSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
};

TerpeneSection.propTypes = {
  title: PropTypes.string.isRequired,
  terpenes: PropTypes.arrayOf(PropTypes.string),
};

CannabisInfoSheet.propTypes = {
  title: PropTypes.string.isRequired,
  treatmentDetails: PropTypes.shape({
    form: PropTypes.string,
    dosageForm: PropTypes.string,
    therapeuticEffects: PropTypes.arrayOf(PropTypes.string),
    usedFor: PropTypes.arrayOf(PropTypes.string),
    flavoursAromas: PropTypes.arrayOf(PropTypes.string),
    terpenes: PropTypes.arrayOf(PropTypes.string),
    minorTerpenes: PropTypes.arrayOf(PropTypes.string),
    cannabinoids: PropTypes.arrayOf(PropTypes.string),
    minorCannabinoids: PropTypes.arrayOf(PropTypes.string),
    cultivar: PropTypes.string,
    carrierOil: PropTypes.string,
    strainLineage: PropTypes.string,
    concentration: PropTypes.string,
    drawConcentration: PropTypes.string,
    tgaCategory: PropTypes.string,
  }).isRequired,
};

export default CannabisInfoSheet;
