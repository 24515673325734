import React from 'react';
import { Box, Typography, Grid2 as Grid } from '@mui/material';

/**
 * @param {Object} params
 * @param {string | Date | number} params.date - The date to format
 * @returns {string} - The formatted date
 */
const getFormattedDate = ({ date }) =>
  new Date(date).toLocaleString('en-AU', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

export const OrderDetailsNotes = ({ order }) => {
  const { notes, revertReason } = order;

  if (!order.notes?.length && !order.revertReason) {
    return null;
  }

  return (
    <Grid mb={1}>
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        Notes
      </Typography>
      {revertReason && (
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" color="#EB8382" mr={1}>
            <strong>Reverted by {order.pharmacyInfo.name}</strong>
          </Typography>
          <Typography variant="body1" fontStyle="italic">
            {revertReason}
          </Typography>
        </Box>
      )}
      {(notes || []).map((note) => (
        <Box key={note.sent} sx={{ mb: 1 }}>
          <Typography variant="caption" color="primary" sx={{ mr: 1 }}>
            <strong>{note.from}</strong>
          </Typography>
          <Typography variant="caption">{getFormattedDate({ date: note.sent })}</Typography>
          <Typography variant="body1" fontStyle="italic">
            {note.text}
          </Typography>
        </Box>
      ))}
    </Grid>
  );
};
