import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { httpsCallable } from 'firebase/functions';
import { Button, CircularProgress, Paper, Typography } from '@mui/material';
import { captureMessage as sentryCaptureMessage } from '@sentry/react';

import { functions } from '../../../../../firebase-config';

const ExportPatientMedicalRecords = ({ patientId, handleModalClose, handleModalOpen }) => {
  const [loading, setLoading] = React.useState(false);
  const [pdfData, setPdfData] = React.useState('');

  useEffect(() => {
    const generatePdf = async () => {
      setLoading(true);
      const { data } = await httpsCallable(functions, 'exportPatientMedicalRecords')(patientId);
      if (data?.error) {
        setLoading(false);
        handleModalOpen(data.error);
        sentryCaptureMessage(data.error);
      }
      setPdfData(data.pdfData);
      setLoading(false);
    };

    generatePdf();
  }, [handleModalClose, handleModalOpen, patientId]);

  return (
    <Paper
      sx={{
        mx: 'auto',
        mt: '20vh',
        width: '80%',
        maxWidth: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
        gap: 2,
      }}
    >
      <Typography variant="h5" align="center" mt={2}>
        Patient Medical Records
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button fullWidth variant="contained" disabled={loading} download="Patient Medical Records.pdf" href={pdfData}>
          Download PDF
        </Button>
      )}
      <Button variant="text" color="primary" onClick={handleModalClose}>
        Cancel
      </Button>
    </Paper>
  );
};

ExportPatientMedicalRecords.propTypes = {
  patientId: PropTypes.string.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
};

export default ExportPatientMedicalRecords;
