import React from 'react';
import { Stack } from '@mui/system';
import { InfoOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Card, CardContent, Typography, IconButton, Collapse, useTheme, alpha } from '@mui/material';

import { AuthContext } from '../../auth-context';
import { USER_TYPES } from '../../utils/constants';

/**
 * This is a quick and dirty solution to show a banner for the Christmas season.
 * It's not a perfect solution, but it's a start.
 */

const CHRISTMAS_BANNER_STORAGE_KEY = 'christmasBannerExpanded';

const START_MONTH = 11; // December
const START_DAY = 2;
const END_MONTH = 0; // January
const END_DAY = 2;

/**
 * @param {Date} date - The date to check
 * @returns {boolean} Whether the date is within the Christmas season
 *
 * Note: If the dates to include more that two months, then the logic will have
 * to be changed slightly.
 */
const isDateWithinRange = (date) => {
  const currentMonth = date.getMonth();
  const currentDay = date.getDate();

  if (currentMonth === START_MONTH) {
    return currentDay >= START_DAY;
  }

  if (currentMonth === END_MONTH) {
    return currentDay <= END_DAY;
  }

  return false;
};

/**
 * @param {{sx?: import('@mui/system').SxProps}} props - Component props
 */
export const ChristmasBanner = ({ sx = {} }) => {
  const theme = useTheme();
  const { userType } = React.useContext(AuthContext);
  const [expanded, setExpanded] = React.useState(() => localStorage.getItem(CHRISTMAS_BANNER_STORAGE_KEY) !== 'false');

  React.useEffect(() => {
    localStorage.setItem(CHRISTMAS_BANNER_STORAGE_KEY, expanded ? 'true' : 'false');
  }, [expanded]);

  const isWithinDateRange = React.useMemo(() => {
    const today = new Date();
    return isDateWithinRange(today);
  }, []);

  const handleToggleExpanded = (e) => {
    e.stopPropagation();
    setExpanded((prev) => !prev);
  };

  if (userType !== USER_TYPES.PATIENT || !isWithinDateRange) {
    return null;
  }

  return (
    <Card
      sx={{
        width: '100%',
        cursor: 'pointer',
        backgroundColor: alpha(theme.palette.info.light, 0.1),
        ...sx,
      }}
      onClick={handleToggleExpanded}
    >
      <CardContent
        sx={{ padding: 1, paddingInline: 2, paddingBottom: expanded ? '1rem !important' : '0.5rem !important' }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <InfoOutlined fontSize="small" />
            <Typography variant="body2" fontWeight="600">
              Christmas Pharmacy Closures
            </Typography>
          </Stack>
          <IconButton onClick={handleToggleExpanded}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </div>
        <Collapse in={expanded}>
          <Typography variant="body2">
            As the Christmas season approaches, please be aware that some pharmacies may have altered operating hours or
            may be closed during this period. We encourage you to plan ahead and check with your chosen pharmacy to
            confirm their availability over Christmas.
          </Typography>
        </Collapse>
      </CardContent>
    </Card>
  );
};
