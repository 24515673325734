import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Chip,
  Grid2 as Grid,
  Stack,
  Paper,
  Select,
  Button,
  MenuItem,
  TextField,
  Container,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { getDocs, collection } from 'firebase/firestore';
import InputAdornment from '@mui/material/InputAdornment';

import Loading from '../../../../layout/loading';
import { functions, db } from '../../../../../firebase-config';
import CustomizableSnackbar from '../../../../layout/snackbar';

const PREFIX = 'CreateOrder';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  addButton: `${PREFIX}-addButton`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.addButton}`]: {
    width: '120px',
    display: 'block',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const manualPaymentCreation = httpsCallable(functions, 'manualPaymentCreation');

const CreateOrder = (props) => {
  const { patientId, handleModalClose, handleModalOpen, prescriptions } = props;
  const [loading, setLoading] = useState(false);
  const [paymentType, setPaymentType] = useState('novatti');
  const [paymentId, setPaymentId] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [priceCodes, setPriceCodes] = useState([]);
  const [catalogue, setCatalogue] = useState([]);
  const [productsValues, setProductsValues] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      // get patient's prescriptions items
      const prescriptionList = [];
      Object.values(prescriptions).forEach((prescription) => {
        Object.keys(prescription).forEach((item) => {
          prescriptionList.push(item);
        });
      });
      const querySnapshot = await getDocs(collection(db, 'catalogue'));
      // Get unique values of the properties of the catalogue objects and filter out by prescriptions keys
      const catalogueDocs = Object.values(
        querySnapshot.docs
          .map((product) => product.data())
          .filter(
            (product) =>
              prescriptionList.includes(product.stripePrice) ||
              (product.overTheCounter && Object.keys(prescriptions).includes(product.form)),
          )
          .reduce((acc, product) => {
            acc[`${product.name}${product?.item ? ` - ${product.item}` : ''}`] = { ...product };
            return acc;
          }, {}),
      );
      // assignn each catalogue doc to their stripePrice
      const result = {};
      catalogueDocs.forEach((product) => {
        result[product.stripePrice] = product;
      });

      setCatalogue(catalogueDocs);
      setProductsValues(result);
      setLoading(false);
    };
    fetchProducts();
  }, [prescriptions]);

  const handleCreateOrder = async () => {
    const prependedPaymentId = `${paymentType}_${paymentId}`;
    try {
      await manualPaymentCreation({ paymentId: prependedPaymentId, prices: priceCodes, patientId });
      handleModalOpen('Result: Order created. Check order tracking to confirm.');
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  const addProduct = () => {
    setPriceCodes((pc) => [...pc, selectedProduct.stripePrice]);
    setSnackbarOpen(true);
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid>
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {catalogue.length > 0 ? (
                    <>
                      <div>
                        <Typography variant="h5" align="center" gutterBottom>
                          Manually Create Order
                        </Typography>
                      </div>
                      <br />
                      <div>
                        <Typography variant="body1" textAlign="center" gutterBottom>
                          Create an order and send it to the pharmacy
                        </Typography>
                      </div>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                          labelId="payment-select-label"
                          id="payment-select"
                          value={paymentType}
                          label="Type"
                          onChange={(e) => setPaymentType(e.target.value)}
                        >
                          <MenuItem value="novatti">Novatti</MenuItem>
                          <MenuItem value="paypal">Paypal</MenuItem>
                          <MenuItem value="till">Till Payments</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        type="text"
                        variant="outlined"
                        fullWidth
                        placeholder="Payment ID"
                        label="Payment ID"
                        className={classes.input}
                        onChange={(e) => setPaymentId(e.target.value)}
                        value={paymentId || ''}
                        slotProps={{
                          input: {
                            startAdornment: <InputAdornment position="start">{`${paymentType}_`}</InputAdornment>,
                          },
                        }}
                      />
                      <Stack direction="row" spacing={2} alignItems="stretch">
                        <Autocomplete
                          loading={loading}
                          style={{ width: '100%' }}
                          freeSolo
                          disableClearable
                          value={selectedProduct}
                          options={catalogue.sort((a, b) => (a.form > b.form ? 1 : -1))}
                          groupBy={(option) => option.form.trim().toUpperCase()}
                          getOptionLabel={(option) => `${option.name}${option?.item ? ` - ${option.item}` : ''}`}
                          onChange={(e, value) => {
                            if (value) setSelectedProduct(value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Product"
                              variant="outlined"
                              slotProps={{
                                input: { ...params.InputProps, type: 'search' },
                              }}
                            />
                          )}
                        />
                        <Button
                          disabled={!selectedProduct}
                          variant="contained"
                          color="primary"
                          className={classes.addButton}
                          onClick={() => addProduct()}
                        >
                          Add
                        </Button>
                      </Stack>
                      <br />
                      {priceCodes.length > 0 && (
                        <>
                          <Typography variant="body1" textAlign="center" gutterBottom>
                            Products
                          </Typography>
                          <Stack spacing={2} style={{ maxHeight: '250px', overflow: 'auto' }}>
                            {priceCodes.map((priceCode, idx) => (
                              <Chip
                                style={{ display: 'flex', justifyContent: 'space-between' }}
                                key={priceCode}
                                label={`${`${idx + 1}`}: ${productsValues[priceCode].name}${productsValues[priceCode]?.item ? ` - ${productsValues[priceCode].item}` : ''}`}
                                onDelete={() => {
                                  const newPriceCodes = [...priceCodes];
                                  newPriceCodes.splice(idx, 1);
                                  setPriceCodes(newPriceCodes);
                                }}
                              />
                            ))}
                          </Stack>
                        </>
                      )}
                      <div>
                        <Button
                          disabled={!paymentId || paymentId.trim().length < 1 || priceCodes.length < 1}
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={handleCreateOrder}
                        >
                          Send Order
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" align="center" gutterBottom>
                        Manually Create Order
                      </Typography>
                      <Typography variant="body1" textAlign="center" gutterBottom>
                        Patient has no prescriptions available.
                      </Typography>
                    </>
                  )}
                  <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
                    Cancel
                  </Button>
                </>
              )}
            </>
          </Grid>
        </Paper>
      </Grid>
      <CustomizableSnackbar message="Product Added" snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
    </StyledContainer>
  );
};

export default CreateOrder;
