import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, LinearProgress, Typography } from '@mui/material';

const PREFIX = 'LinearProgressWithLabel';

const classes = {
  bar: `${PREFIX}-bar`,
  progressBar1: `${PREFIX}-progressBar1`,
  progressBar2: `${PREFIX}-progressBar2`,
};

const StyledBox = styled(Box)({
  [`&.${classes.bar}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  [`& .${classes.progressBar1}`]: {
    width: '90%',
    marginRight: 20,
  },
  [`& .${classes.progressBar2}`]: {
    minWidth: 35,
  },
});

// FIXME: Rename the file to match the component name in PascalCase
const LinearProgressWithLabel = ({ value }) => (
    <StyledBox className={classes.bar}>
      <Box className={classes.progressBar1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box className={classes.progressBar2}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </StyledBox>
  );

export default LinearProgressWithLabel;
