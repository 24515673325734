import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Grid2 as Grid, Button, Typography, Modal, Fade } from '@mui/material';
import { Container } from '@mui/system';

import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { doc, getDoc, updateDoc, deleteField, collection, writeBatch } from 'firebase/firestore';

import Loading from '../../layout/loading';
import { db } from '../../../firebase-config';
import { AuthContext } from '../../../auth-context';
import EmailAuthCards from '../../login/email-auth-container';
import formatDatabaseValues from '../../../functions/format-database-values';
import BasicFormElement from '../horizontal-form-elements/container-elements/basic-form-element';
import DateFormElement from '../horizontal-form-elements/container-elements/date-form-element';
import SelecterFormElement from '../horizontal-form-elements/container-elements/selecter-form-element';
import RadioFormElement from '../horizontal-form-elements/container-elements/radio-form-element';
import CheckboxFormElement from '../horizontal-form-elements/container-elements/checkbox-form-element';
import WarningFormElement from '../horizontal-form-elements/container-elements/warning-form-element';
import PaymentFormElement from '../horizontal-form-elements/container-elements/payment-form-element';
import ExitFormElement from '../horizontal-form-elements/container-elements/exit-form-element';
import SubmitFormElement from '../horizontal-form-elements/container-elements/submit-form-element';
import DeniedComponent from '../../layout/denied-component';
import UploadFormElement from '../horizontal-form-elements/container-elements/upload-form-element';
import ShippingFormElement from '../horizontal-form-elements/container-elements/shipping-form-element';
import TreatmentsTriedFormElement from '../vertical-form-elements/container-elements/treatments-tried-form-element';
import ExpandableFormElement from '../vertical-form-elements/container-elements/expandable-form-element';

import MedicareCardFormElement from '../horizontal-form-elements/container-elements/medicare-card-form-element';
import HealthCardFormElement from '../horizontal-form-elements/container-elements/health-card-form-elements';
import VeteranCardFormElement from '../horizontal-form-elements/container-elements/veteran-card-form-element';
import { FORM_STATUS } from '../../../utils/constants';

const PREFIX = 'PatientFormView';

const classes = {
  prevButton: `${PREFIX}-prevButton`,
  prevIcon: `${PREFIX}-prevIcon`,
  loginText: `${PREFIX}-loginText`,
};

const Root = styled(Container)({
  [`& .${classes.prevButton}`]: {
    padding: 0,
    margin: '30px 0',
    '@media (max-width: 1000px)': {
      margin: '30px 0px 10px 16px',
    },
    '@media (max-width: 768px)': {
      margin: '50px 0px 10px 16px',
    },
  },

  [`& .${classes.prevIcon}`]: {
    color: 'white',
  },

  [`& .${classes.loginText}`]: {
    fontWeight: 'bold',
    margin: 'auto',
  },
});

const { AWAITING_REVIEW } = FORM_STATUS;

// TODO: Might need to refactor this component to reduce complexity
const PatientFormView = (props) => {
  const { stripePrice, formObjects, formName, initialRejection, preLoadedData } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const isMounted = useRef(false);
  const nodeRef = useRef(null);

  const [index, setIndex] = useState(0);
  const [formInputs, setFormInputs] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [flowDirection, setFlowDirection] = useState('forward');
  const [formCompletionStatus, setFormCompletionStatus] = useState(false);
  const [consultPaid, setConsultPaid] = useState(false);
  const [awaitingReview, setAwaitingReview] = useState(false);
  const [creationPending, setCreationPending] = useState(false);
  const [initialRejectionReason, setInitialRejectionReason] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [animate, doAnimate] = useState(true);

  const formComponents = formObjects;

  useEffect(() => {
    doAnimate(true);
  }, [index]);

  const handleNext = () => {
    setFlowDirection('forward');
    setIndex((i) => i + 1);
    doAnimate(false);
  };

  const handlePrev = () => {
    setFlowDirection('backward');
    setIndex((i) => i - 1);
    doAnimate(false);
  };

  const getData = useCallback(async () => {
    const batch = writeBatch(db);
    const infoDoc =
      formName === 'checkup' ? preLoadedData : await getDoc(doc(db, 'patients', user?.uid, 'general', 'information'));
    if (!infoDoc.exists()) {
      setCreationPending(true);
      setDataLoaded(true);
      return;
    }
    const infoDocData = infoDoc.data();
    Object.values(infoDocData.forms).forEach((form) => {
      if (form.step === AWAITING_REVIEW || form.step === 'awaiting upload' || form.step === 'awaiting script')
        setAwaitingReview(true);
    });
    const obj = formatDatabaseValues(infoDocData);
    // Now that we have the initial values, we can see if the user passes the initial form test
    if (initialRejection) setInitialRejectionReason(initialRejection(obj));
    setFormInputs((prevFormInputs) => ({ ...prevFormInputs, ...obj }));
    // Figure out correct starting value
    if (obj[`forms.${formName}.step`] === undefined) {
      try {
        batch.update(doc(db, 'patients', user?.uid, 'general', 'information'), {
          [`forms.${formName}.started`]: Date.now(),
          [`forms.${formName}.step`]: 0,
        });
        batch.update(doc(db, 'patients', user?.uid), {
          [`forms.${formName}`]: 'in progress',
          [`catalogueItems.${formName}`]: [],
          banner: { title: 'in progress', formName, link: `/${formName?.replace?.(' ', '-') || ''}-form` },
        });
        // Set worker to follow up if form is not completed in 2 days (checkups have their own reminder system)
        if (formName !== 'checkup') {
          const newTaskRef = doc(collection(db, 'tasks'));
          const newTaskObj = {
            due: Date.now() + 172800000,
            options: { user: user?.uid, formName },
            worker: 'form_completion_reminder',
          };
          batch.set(newTaskRef, newTaskObj);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (typeof obj[`forms.${formName}.step`] === 'number') {
      // Check if the form was started more than two weks ago
      if (Date.now() - 1209600000 > obj[`forms.${formName}.started`]) {
        batch.update(doc(db, 'patients', user?.uid, 'general', 'information'), {
          [`forms.${formName}.started`]: Date.now(),
          [`forms.${formName}.step`]: 0,
        });
        setIndex(0);
      } else {
        setIndex(obj[`forms.${formName}.step`]);
      }
    } else {
      if (obj[`forms.${formName}.step`] !== 'awaiting payment') setConsultPaid(true);
      setFormCompletionStatus(true);
    }
    setDataLoaded(true);
    await batch.commit();
  }, [user, formName, initialRejection, preLoadedData]);

  useEffect(() => {
    isMounted.current = true;
    // This converts the map objects in the database into strings on the general state object
    if (isMounted.current) {
      if (user) {
        getData();
      } else {
        setDataLoaded(true);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [user, getData]);

  let component;

  if (!dataLoaded) {
    return <Loading />;
  }
  if (initialRejectionReason && index === 0) {
    return <ExitFormElement currentFormObject={{}} initialRejectionReason={initialRejectionReason} />;
  }
  if (creationPending) {
    const creationPendingObj = {
      headingText: 'Account Created',
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          <br />
          Thanks for signing up! Just a few more questions and a Candor GP can assign you a treatment option.
          <br />
          <br />
        </Typography>
      ),
    };
    return (
      <WarningFormElement
        currentFormObject={creationPendingObj}
        handleNext={() => {
          navigate(0);
        }}
        formInputs={formInputs}
        formName={formName}
      />
    );
  }
  if (formCompletionStatus) {
    return (
      <PaymentFormElement
        formName={formName}
        stripePrice={stripePrice}
        consultPaid={consultPaid}
        formInputs={formInputs}
      />
    );
  }
  if (awaitingReview) {
    return (
      <DeniedComponent
        heading="Medical Info Under Review"
        fieldText="You recently completed a consult and your medical info is being reviewed. You will be able to complete this form once your GP has finished reviewing your responses."
        buttonHref="/"
        buttonText="Dashboard"
      />
    );
  }
  // Skip forward or back form elements if necessary
  if (formComponents[index] && !formComponents[index]?.creationTest(formInputs)) {
    let tempIndex = index;
    // Handle navigaiton and remove elements from the database that might need removing
    while (!formComponents[tempIndex].creationTest(formInputs)) {
      if (flowDirection === 'forward') {
        // First we check if the fieldtext is an array because arrays need to be removed differently
        if (Array.isArray(formComponents[tempIndex].fieldText)) {
          if (formInputs[formComponents[tempIndex].fieldText[0]] !== undefined) {
            const obj = {};
            const docsObj = {};
            const formInputsCopy = formInputs;
            formComponents[tempIndex].fieldText.forEach((field) => {
              delete formInputsCopy[field];
              obj[field] = deleteField();
              // get and convert concession card to camel case data on userDoc
              if (field && field.split('.')[0] === 'concession cards') {
                const newField = field?.replace?.('concession cards', 'concessionCards') || '';
                if (newField) {
                  docsObj[newField] = deleteField();
                }
              }
            });
            setFormInputs(formInputsCopy);

            // delete concessionCards data on userDoc
            if (formComponents[tempIndex].fieldText[0].startsWith('concession cards.')) {
              updateDoc(doc(db, 'patients', user?.uid), docsObj);
            }

            if (user) {
              updateDoc(doc(db, 'patients', user?.uid, 'general', 'information'), obj);
            }
          }
        } else if (
          formInputs[formComponents[tempIndex].fieldText] !== undefined &&
          !formComponents[tempIndex].fieldText.startsWith('basic info.')
        ) {
          const obj = {};
          const formInputsCopy = formInputs;
          obj[formComponents[tempIndex].fieldText] = deleteField();
          delete formInputsCopy[formComponents[tempIndex].fieldText];
          setFormInputs(formInputsCopy);
          if (user) {
            updateDoc(doc(db, 'patients', user?.uid, 'general', 'information'), obj);
          }
        }
        tempIndex += 1;
      } else {
        tempIndex -= 1;
      }
    }
    setIndex(tempIndex);
  }

  switch (formComponents[index]?.elementType) {
    case 'basic':
      component = (
        <BasicFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'selecter':
      component = (
        <SelecterFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'date':
      component = (
        <DateFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'radio':
      component = (
        <RadioFormElement
          formName={formName}
          index={index}
          formComponents={formComponents}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'checkbox':
      component = (
        <CheckboxFormElement
          formName={formName}
          index={index}
          flowDirection={flowDirection}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'warning':
      component = (
        <WarningFormElement
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          formName={formName}
        />
      );
      break;
    case 'exit':
      component = (
        <ExitFormElement currentFormObject={formComponents[index]} formInputs={formInputs} formName={formName} />
      );
      break;
    case 'upload':
      component = (
        <UploadFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'login':
      component = (
        <EmailAuthCards formContext formName={formName} index={index} setIndex={setIndex} formInputs={formInputs} />
      );
      break;
    case 'treatments tried':
      component = (
        <TreatmentsTriedFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'expandable':
      component = (
        <ExpandableFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'medicare card':
      component = (
        <MedicareCardFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'health card':
      component = (
        <HealthCardFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'veterans card':
      component = (
        <VeteranCardFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'shipping':
      component = (
        <ShippingFormElement
          formName={formName}
          index={index}
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
        />
      );
      break;
    case 'submit':
      component = (
        <SubmitFormElement
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
          formName={formName}
          stripePrice={stripePrice}
        />
      );
      break;
    case 'payment':
      component = (
        <PaymentFormElement
          currentFormObject={formComponents[index]}
          handleNext={handleNext}
          stripePrice={stripePrice}
          formInputs={formInputs}
          consultPaid={consultPaid}
          formName={formName}
        />
      );
      break;
    default:
      component = <Loading />;
  }

  // Save the index of the element to the database for form retreival
  const previousButtonBool = index !== 0 && formComponents[index]?.elementType !== 'payment';

  return (
    <Root sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Grid container sx={{ display: 'flex', justifyContent: 'start', maxWidth: '700px', width: '100%' }}>
        {previousButtonBool && (
          <Button variant="contained" color="primary" className={classes.prevButton} onClick={handlePrev}>
            <KeyboardBackspaceRoundedIcon className={classes.prevIcon} fontSize="large" />
          </Button>
        )}
        {index === 0 && user === null && (
          <Typography className={classes.loginText} variant="h6" align="center">
            <Button
              variant="text"
              onClick={() => {
                setModalOpen(true);
              }}
              sx={{ textTransform: 'none', padding: 0, fontSize: '20px', fontWeight: 'bold' }}
            >
              Have an account? Click here to login
            </Button>
          </Typography>
        )}
      </Grid>
      {animate && (
        <Fade in={animate} timeout={1000} ref={nodeRef}>
          <Grid>{component}</Grid>
        </Fade>
      )}
      <Modal open={modalOpen}>
        <>
          <EmailAuthCards
            formContext
            quickLogin
            handleModalClose={() => {
              setModalOpen(false);
            }}
          />
        </>
      </Modal>
    </Root>
  );
};

export default PatientFormView;
