import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Container, Typography, Stack, IconButton, Chip } from '@mui/material';

import { AuthContext } from '../../../auth-context';
import { isDirector, isSupport } from '../../../utils/roles';
import { CopyButton } from '../../../components/ui/CopyButton';
import { PharmacyProvider } from '../providers';
import { usePharmacyContext } from '../hooks/usePharmacyContext';

/**
 * Get the context from the pathname
 * @param {object} props - The pathname and params
 * @param {string} props.pathname - The pathname
 * @param {object} props.params - The params
 * @returns {string | null} The context
 */
const getContext = ({ pathname, params }) => {
  const pathnameWithoutParams = Object.values(params).reduce((path, param) => path.replace(`/${param}`, ''), pathname);
  const splitPath = pathnameWithoutParams.split('/');

  if (splitPath.length <= 2) {
    return null;
  }

  if ('orderId' in params) {
    return 'Order Details';
  }

  return `${splitPath[splitPath.length - 1].replace(/-/g, ' ')}`;
};

const PharmacyDetailsLayoutInner = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userType } = React.useContext(AuthContext);
  const { pharmacy, loading, error } = usePharmacyContext();

  const handleBack = () => {
    navigate(pathname.split('/').slice(0, -1).join('/'));
  };

  if (loading) {
    return null;
  }

  if (error) {
    return <div>Error loading pharmacy details</div>;
  }

  if (!pharmacy) {
    return null;
  }

  const context = getContext({ pathname, params });
  const showId = isSupport(userType) || isDirector(userType);

  return (
    <Container maxWidth="md" sx={{ paddingBottom: 2 }}>
      <Stack direction="column" spacing={0} pb={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" component="h1" display="inline">
              {pharmacy.name}
            </Typography>
          </Stack>

          <Chip label={pharmacy.type} sx={{ textTransform: 'capitalize' }} />
        </Stack>

        {(context || showId) && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} height="2rem">
            <Typography variant="body1" fontWeight={600} sx={{ textTransform: 'capitalize' }}>
              {context}
            </Typography>

            {showId && (
              <CopyButton iconOnLeft textToCopy={pharmacy.id} sx={{ width: 'fit-content' }}>
                <Typography variant="body2">{pharmacy.id}</Typography>
              </CopyButton>
            )}
          </Stack>
        )}
      </Stack>

      <Outlet />
    </Container>
  );
};

export const PharmacyDetailsLayout = () => (
  <PharmacyProvider>
    <PharmacyDetailsLayoutInner />
  </PharmacyProvider>
);
