import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Container, Typography, Link, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../auth-context';

const PREFIX = 'AuthLandingPageError';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  logo: `${PREFIX}-logo`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(() => ({
  [`& .${classes.input}`]: {
    margin: '20px auto',
  },

  [`& .${classes.button}`]: {
    margin: '8px 0px',
  },

  [`& .${classes.logo}`]: {
    margin: '20px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.paper}`]: {
    display: 'grid',
    padding: '20px',
    width: '80%',
  },
}));

const EMAIL_UPDATE_ERROR_TITLE = {
  VERIFY_EMAIL: 'Email Verification Error',
  VERIFY_AND_CHANGE_EMAIL: 'Email Verification Error',
  RECOVER_EMAIL: 'Email Recovery Error',
  REVERT_SECOND_FACTOR_ADDITION: 'Two-Factor Authentication Removal Error',
};

const AuthLandingPageError = () => {
  const { setAccountDetailsPage } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const operation = location.state?.operation;

  const redirectToSecurityDetails = () => {
    // Sending them back to security page so they can just do the update again
    navigate('/account-details');
    setAccountDetailsPage('security');
  };

  return (
    <Root>
      <Container maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '80vh' }}
        >
          <Paper component="div" className={classes.paper}>
            <Typography variant="h5" align="center" /* Remove gutterBottom prop */>
              {EMAIL_UPDATE_ERROR_TITLE[operation]}
            </Typography>
            <Typography variant="body1" align="center" /* Remove gutterBottom prop */>
              The link has already expired. Click button below to go back to the security page
            </Typography>
            <Button variant="contained" color="primary" className={classes.button} onClick={redirectToSecurityDetails}>
              Security Page
            </Button>
          </Paper>
        </Grid>
      </Container>
    </Root>
  );
};

export default AuthLandingPageError;
