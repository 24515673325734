import { useCallback } from 'react';
import { captureException as SentryCaptureException } from '@sentry/react';

import { generateCsv, downloadCsv, formatDate } from './csvHelpers';

export const useExportCsv = (orderReceiverData) =>
  useCallback(
    ({ shipmentRequiredOrders, signatureRequired }) => {
      const fileName = `AusPost-MerchantPortal-Orders-${orderReceiverData.name}-${formatDate(Date.now())}.csv`;

      try {
        const csv = generateCsv(shipmentRequiredOrders, signatureRequired);

        downloadCsv(csv, fileName);

        return true;
      } catch (error) {
        SentryCaptureException(error, {
          extra: {
            issueIn: 'useExportCsv',
            fileName,
            pharmacyId: orderReceiverData.id,
            pharmacyName: orderReceiverData.name,
            numberOfOrders: shipmentRequiredOrders.length,
          },
          tags: { component: 'useExportCsv' },
        });
        // console.error('Failed to export CSV:', error);
        return false;
      }
    },
    [orderReceiverData],
  );
