import {
  Grid2 as Grid,
  Button,
  Divider,
  Checkbox,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  Stack,
} from '@mui/material';
import React from 'react';
import { captureException as sentryCaptureException } from '@sentry/react';
import { CurrencyExchangeRounded as CurrencyExchangeRoundedIcon } from '@mui/icons-material';

import {
  formatCurrency,
  getCatalogueId,
  SHIPMENT_TYPES,
  RATE_AFTER_CHANGE_DATE,
  RATE_BEFORE_CHANGE_DATE,
  SHIPPING_CHARGE_CHANGE_DATE_UNIX_TIMESTAMP_MS,
} from '../../../../../utils/constants';
import { refundProcessor } from '../../../../../components/checkout/paymentHelpers';
import PaymentMaintenance from '../../../../../components/checkout/misc/PaymentMaintenance';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';

const { PICKUP_REQUIRED, SCRIPT_ONLY } = SHIPMENT_TYPES;

const REFUND_MESSAGES = {
  REFUND_SUCCESS: 'Order refunded successfully',
  REFUND_FAILURE: 'Sorry, we are not able to refund the item. Please try again later',
  SELECTION_ERROR: 'Please select at least one product to refund',
  INVALID_PAYMENT_PROCESSOR: 'Invalid Payment Processor.',
};

/**
 * @param {Object} props
 * @param {Object[]} props.products - The products to refund.
 * @param {number[]} props.selectedProducts - The indices of the products to refund.
 * @param {number} props.otherRefund - The amount to refund for other products.
 * @returns {number} The total refund amount.
 */
const getRefundAmount = ({ products, selectedProducts, otherRefund }) =>
  selectedProducts.reduce((acc, idx) => acc + (products[idx]?.paidAmount || products[idx]?.price || 0), 0) +
  Number(otherRefund);

/**
 * @param {Object} otherRefunds - The other refunds.
 * @returns {number} The partial refund amount.
 */
const getPartialRefundAmount = (otherRefunds) =>
  otherRefunds && !!Object.keys(otherRefunds).length ? Object.values(otherRefunds).reduce((a, b) => a + b, 0) : 0;

/**
 * @param {Object[]} scriptsArray - The scripts to refund.
 * @returns {number} The extra postage amount.
 */
const getExtraPostagesAmount = (scriptsArray) =>
  Array.isArray(scriptsArray) ? scriptsArray.reduce((acc, script) => acc + (script?.extraPostage || 0), 0) : 0;

/**
 * @param {Object} order - The order to refund.
 * @returns {string} The function name.
 */
const getFunctionName = (order) => (order?.id?.split('_')[0] === 'till' ? 'tillRefundProduct' : 'novattiRefundProduct');

/**
 * @param {Object} params
 * @param {Order} params.order
 * @param {Object[]} params.products
 * @param {number[]} params.selectedProducts
 * @param {number} params.otherRefund
 * @param {boolean} params.isSelectedAll
 * @param {(message: string) => void} params.snackbar
 * @param {(loading: boolean) => void} params.setIsRefundLoading
 * @param {() => void} params.handleAllModalClose
 * @param {number} params.refundAmount
 * @param {string} params.functionName
 * @returns {Promise<void>}
 */
const processRefund = async ({
  order,
  products,
  selectedProducts,
  otherRefund,
  isSelectedAll,
  snackbar,
  setIsRefundLoading,
  handleAllModalClose,
  refundAmount,
  functionName,
}) => {
  try {
    setIsRefundLoading(true);
    const otherRefundAmount = Number(otherRefund);
    const reference = order.payment;
    const priceIds = selectedProducts.map((idx) => {
      if (products[idx].name === 'Shipping') {
        return 'shipping';
      }
      return products[idx].stripePrice;
    });

    if (!selectedProducts.length && !otherRefundAmount) {
      snackbar(REFUND_MESSAGES.SELECTION_ERROR);
      return;
    }

    const res = await refundProcessor({
      functionName,
      data: {
        priceIds,
        refundAmount,
        reference,
        orderComplete: isSelectedAll,
        otherRefund: otherRefundAmount,
        pharmacyName: order.pharmacyInfo.name,
        orderTimestamp: order.updated,
      },
    });

    if (res?.invalid) {
      snackbar(res.message);
      return;
    }

    if (res?.error || res?.data?.error) {
      throw new Error(res?.message || res?.data?.error);
    }

    snackbar(REFUND_MESSAGES.REFUND_SUCCESS);
    handleAllModalClose();
  } catch (error) {
    sentryCaptureException(error, {
      extra: { order, patientId: order.user, issueIn: 'refundProduct' },
    });
    snackbar(REFUND_MESSAGES.REFUND_FAILURE);
    setIsRefundLoading(false);
  }
};

const RefundProductModalContent = () => {
  const { order, handleDialogue } = useOrderDetailsContext();
  const { catalogue, snackbar } = usePharmacyContext();
  const [isRefundLoading, setIsRefundLoading] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState(/** @type {number[]} */ ([]));
  const [otherRefund, setOtherRefund] = React.useState(0);

  const isPickupRequired = order?.status === PICKUP_REQUIRED;
  const isScriptOnly = order?.status === SCRIPT_ONLY;
  const isMedicinalCannabis = order?.formName === 'medicinal cannabis';
  const isShippingRefunded = order?.shipping?.refunded;
  const hasShipping = isMedicinalCannabis && !isShippingRefunded && !isScriptOnly && !isPickupRequired;
  const isSelectedAll = selectedProducts.length === products.length;
  const functionName = getFunctionName(order);

  const partialRefundAmount = getPartialRefundAmount(order?.otherRefunds);
  const refundAmount = getRefundAmount({ products, selectedProducts, otherRefund });

  const handleAllModalClose = () => {
    setIsRefundLoading(false);
    handleDialogue({ isOpen: false, content: null });
  };

  const handleRefund = async () => {
    await processRefund({
      order,
      products,
      otherRefund,
      functionName,
      refundAmount,
      isSelectedAll,
      selectedProducts,
      snackbar,
      setIsRefundLoading,
      handleAllModalClose,
    });
  };

  React.useEffect(() => {
    if (!order?.scriptsArray) {
      return;
    }

    const scripts = order.scriptsArray.filter((script) => !script.refunded);
    const priceIds = scripts.map((script) => script.priceRef);

    if (!priceIds.length) {
      return;
    }

    const tempProducts = scripts.map(({ priceRef, paidAmount }) => {
      const product = catalogue[getCatalogueId(priceRef)];
      return { ...product, paidAmount };
    });

    const shippingItem = {
      name: 'Shipping',
      // This is only necessary for a week or two after the shipping price change
      price:
        (order?.updated > SHIPPING_CHARGE_CHANGE_DATE_UNIX_TIMESTAMP_MS
          ? RATE_AFTER_CHANGE_DATE
          : RATE_BEFORE_CHANGE_DATE) + getExtraPostagesAmount(order?.scriptsArray),
    };

    setProducts(hasShipping ? [shippingItem, ...tempProducts] : tempProducts);
  }, [order, catalogue, hasShipping]);

  const handleSelectAll = () => {
    setSelectedProducts(isSelectedAll ? [] : products.map((_, idx) => idx));
  };

  return (
    <>
      <Stack width="100%" justifyContent="center" alignItems="justify-between">
        <Typography variant="h6" align="center">
          Refund Products
        </Typography>

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid size={{ xs: 8 }}>
            <Typography>All</Typography>
          </Grid>
          <Grid size={{ xs: 4 }} alignItems="center" justifyContent="flex-end" display="flex">
            <Checkbox
              checked={isSelectedAll}
              indeterminate={!!selectedProducts.length && selectedProducts.length < products.length}
              onChange={handleSelectAll}
            />
          </Grid>

          <Grid size={{ xs: 12 }} mb={1}>
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
          </Grid>

          {products.map((product, idx) => {
            const key = `${product.id}${idx}`;
            const price = product?.paidAmount || product?.price || 0;
            return (
              <React.Fragment key={key}>
                <Grid size={{ xs: 6 }}>
                  <Typography>{product?.name || ''}</Typography>
                </Grid>
                <Grid size={{ xs: 2 }}>
                  <Typography>$ {price.toFixed(2) || ''}</Typography>
                </Grid>
                <Grid size={{ xs: 4 }} alignItems="center" justifyContent="flex-end" display="flex">
                  <Checkbox
                    color="primary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      setSelectedProducts(
                        selectedProducts.includes(idx)
                          ? selectedProducts.filter((item) => item !== idx)
                          : [...selectedProducts, idx],
                      )
                    }
                    checked={selectedProducts.includes(idx)}
                  />
                </Grid>
              </React.Fragment>
            );
          })}
          {!!products.length && (
            <Grid size={{ xs: 12 }} my={1} display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              <Grid size={{ xs: 6 }}>
                <Typography>
                  Other{' '}
                  {Number(partialRefundAmount) > 0 && `(Total: ${Number(partialRefundAmount) + Number(otherRefund)})`}
                </Typography>
              </Grid>
              <Grid size={{ xs: 3 }}>
                <Typography
                  sx={{ textDecoration: isSelectedAll ? 'line-through' : 'none', opacity: isSelectedAll ? 0.5 : 1 }}
                >
                  $ {Number(otherRefund)}
                </Typography>
              </Grid>

              <Grid size={{ xs: 3 }} pr={1}>
                <Grid size={{ xs: 12 }}>
                  <TextField
                    type="number"
                    size="small"
                    prefix="$"
                    value={otherRefund}
                    disabled={isSelectedAll}
                    onChange={(e) =>
                      setOtherRefund(
                        formatCurrency({
                          value: e.target.value,
                          lastValue: otherRefund,
                          maxValue: 999,
                        }),
                      )
                    }
                    slotProps={{
                      input: { startAdornment: <InputAdornment position="start">$</InputAdornment> },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!products.length && (
            <Grid size={{ xs: 12 }} sx={{ my: 1 }}>
              <Typography align="center" fontStyle="italic">
                All products related to this order were refunded.
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid size={{ xs: 12 }} my={1}>
          <Divider
            sx={{
              opacity: '0.6',
            }}
          />
        </Grid>
        <Grid container sx={{ my: 2 }}>
          <Grid size={{ xs: 9 }}>
            <Typography>Amount To Be Refunded To Patient</Typography>
          </Grid>
          <Grid size={{ xs: 3 }}>
            <Typography align="right">$ {refundAmount.toFixed(2)}</Typography>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRefund}
          loading={isRefundLoading}
          disabled={!refundAmount}
        >
          Process Refund
        </Button>
      </Stack>
      <PaymentMaintenance />
    </>
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal - The function to open the secondary modal.
 */
export const RefundProduct = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<RefundProductModalContent />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<CurrencyExchangeRoundedIcon />}>
        Refund Order
      </Button>
    </MenuItem>
  );
};
