import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../../firebase-config';
import ModalCardOptions from '../../../../layout/modal-card-options';

const disableAccount = httpsCallable(functions, 'disableAccount');

const TakeOfflineOnline = ({ handleModalOpen, handleModalClose, email, isToActivate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const changeAccountStatus = async () => {
    setIsLoading(true);
    try {
      const { data } = (await disableAccount({ email, isToActivate, isPatient: false })) || {};
      if (data?.message) {
        handleModalOpen(`Result: ${data.message}`);
        return;
      }
      throw new Error(data.error);
    } catch {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalCardOptions
      text={`Are you sure you want to take this doctor ${isToActivate ? 'on' : 'off'}line?`}
      subtext={`This process would ${isToActivate ? 'en' : 'dis'}able the account.${isToActivate ? '' : ' You can always reactivate the account later.'}`}
      handleModalClose={handleModalClose}
      handleModalProceed={changeAccountStatus}
      loadingComponent={isLoading}
    />
  );
};

TakeOfflineOnline.propTypes = {
  handleModalOpen: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  isToActivate: PropTypes.bool.isRequired,
};

export default TakeOfflineOnline;
