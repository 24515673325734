import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../../../../../firebase-config';

const PREFIX = 'AssignRole';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  formControl: `${PREFIX}-formControl`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.formControl}`]: {
    margin: '0px auto',
    width: '100%',
  },
}));

const assignAdminRole = httpsCallable(functions, 'assignAdminRole');

const AssignRole = (props) => {
  const { handleModalOpen, handleModalClose, patientEmail } = props;

  const [adminType, setAdminType] = useState('');

  const handleAssignRole = () => {
    assignAdminRole({ email: patientEmail, adminType }).then((result) => {
      if (result.data.message) {
        handleModalOpen(`Result: ${result.data.message}`);
      } else {
        handleModalOpen('Error: Something went wrong. Contact your team leader.');
      }
    });
  };

  const handleRadioChange = (event) => {
    setAdminType(event.target.value);
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid>
            <div>
              <Typography variant="h5" align="center" gutterBottom>
                Assign Role
              </Typography>
            </div>
            <br />
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <FormControl component="fieldset">
                <RadioGroup name="row-radio-buttons-group" onChange={handleRadioChange}>
                  <FormControlLabel value="director" control={<Radio color="primary" />} label="Director" />
                  <FormControlLabel value="doctor" control={<Radio color="primary" />} label="Doctor" />
                  <FormControlLabel value="nurse" control={<Radio color="primary" />} label="Nurse" />
                  <FormControlLabel value="support" control={<Radio color="primary" />} label="Support" />
                  <FormControlLabel value="pharmacist" control={<Radio color="primary" />} label="Pharmacist" />
                  <FormControlLabel value="supplier" control={<Radio color="primary" />} label="Supplier" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <div>
              <Button variant="contained" color="primary" className={classes.button} onClick={handleAssignRole}>
                Assign Role
              </Button>
            </div>
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Back to main control
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default AssignRole;
