import { httpsCallable } from 'firebase/functions';
import { doc, collection, writeBatch } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db, functions } from '../../../../firebase-config';
import { SHIPMENT_TYPES } from '../../../../utils/constants';
import createEmailObj from '../../../../functions/create-email-obj';
import { getBaseTrackingUrl, getTrackingCode } from '../OrderDetailsToolsButton/MassAddTracking/helpers';

const markOrderComplete = httpsCallable(functions, 'markOrderComplete');
const clearTransferField = httpsCallable(functions, 'clearTransferField');

const { COMPLETE, COMPLETED, OUTSTANDING } = SHIPMENT_TYPES;

const MESSAGES = {
  INVALID_ERX_TOKEN: 'You have entered an old eRx token.',
  ORDER_COMPLETE: 'Order has been marked as complete',
  ERROR_OCCURED: `An error occurred: Some products may no longer be in the patient's active prescription. Please verify and try again.`,
};

export const MIN_TOKEN_LENGTH = 16;
export const MAX_TOKEN_LENGTH = 18;

export const isToShowEscriptTokenComponent = ({ script: { eScriptToken, lastRepeat, refunded }, isHistorical }) =>
  eScriptToken && !isHistorical && !lastRepeat && !refunded;

export const getUniqueScripts = (scriptsArray) =>
  scriptsArray?.filter((v, i, a) => a.findIndex((x) => x.priceRef === v.priceRef) === i);

export const getRepeatTokens = (shipment) =>
  (shipment?.scriptsArray || []).reduce(
    (acc, { repeatToken, priceRef }) => (repeatToken ? { ...acc, [priceRef]: repeatToken } : acc),
    {},
  );

export const getIsEscriptValid = ({ uniqueScriptsArray, initialRepeatTokens }) =>
  uniqueScriptsArray.every((script) => {
    // If script is refunded, there is no need to validate eScripts
    if (script.refunded) {
      return true;
    }

    // If the script is manually bypassed, there is no need to validate
    if (script.manualBypassed) {
      return true;
    }

    // If it is not an eScript, there is no need to validate
    if (!script.eScriptToken) {
      return true;
    }

    // If there is a previously saved repeat token, there is no need to validate
    if (script.repeatToken) {
      return true;
    }

    // If last repeat, there is no need to validate
    if (script.lastRepeat) {
      return true;
    }

    const repeatTokenLength = initialRepeatTokens?.[script.priceRef]?.length || 0;
    return repeatTokenLength >= MIN_TOKEN_LENGTH && repeatTokenLength <= MAX_TOKEN_LENGTH;
  });

export const isToShowScriptRelatedContents = ({ shipment, historical, isRecentOrder, isSubmit }) =>
  shipment.scriptsArray && (!historical || isRecentOrder) && !isSubmit;

export const isOrderCompleteButtonDisabled = ({
  shipment: { userVerified },
  initialTrackingCode,
  trackingCode,
  isSubmit,
  isEscriptValid,
  isAwaitingPayment,
}) => {
  const isOrderInvalid = !trackingCode || initialTrackingCode !== trackingCode;
  const isOrderValid = !userVerified || !isEscriptValid || isAwaitingPayment;

  return isOrderValid || isOrderInvalid || isSubmit;
};

export const getReasonsThatOrderIsNotReadyForShipment = ({
  userVerified,
  initialTrackingCode,
  trackingCode,
  isEscriptValid,
  isAwaitingPayment,
}) => {
  const reasons = [];

  if (!userVerified) {
    reasons.push('User is not verified');
  }

  if (!trackingCode || initialTrackingCode !== trackingCode) {
    reasons.push('Tracking code is not valid');
  }

  if (isAwaitingPayment) {
    reasons.push('Awaiting payment');
  }

  if (!isEscriptValid) {
    reasons.push('eScript is not valid');
  }

  return reasons;
};

export const getReasonsThatOrderIsNotReadyForPickup = ({ userVerified, isAwaitingPayment, isEscriptValid }) => {
  const reasons = [];

  if (!userVerified) {
    reasons.push('User is not verified');
  }

  if (isAwaitingPayment) {
    reasons.push('Awaiting payment');
  }

  if (!isEscriptValid) {
    reasons.push('eScript is not valid');
  }

  return reasons;
};

export const getReasonsThatOrderIsNotReadyForNotification = ({ userVerified, isAwaitingPayment, isEscriptValid }) => {
  const reasons = [];

  if (!userVerified) {
    reasons.push('User is not verified');
  }

  if (isAwaitingPayment) {
    reasons.push('Awaiting payment');
  }

  if (!isEscriptValid) {
    reasons.push('eScript is not valid');
  }

  return reasons;
};

export const processOrderCompletion = async ({
  shipment,
  isPickup,
  snackbar,
  setIsSubmit,
  handlePrimaryModal,
  pharmacyData,
}) => {
  const repeatTokens = getRepeatTokens(shipment);
  const trackingCode = getTrackingCode(shipment);
  // Prevent using old tokens
  if (
    shipment?.scriptsArray?.some(
      (script) =>
        script.eScriptToken && repeatTokens[script.priceRef] && repeatTokens[script.priceRef] === script.eScriptToken,
    )
  ) {
    snackbar(MESSAGES.INVALID_ERX_TOKEN);
    return;
  }

  const { id: userId, shipmentProvider } = pharmacyData;

  try {
    setIsSubmit(true);
    const date = Date.now();
    const batch = writeBatch(db);
    const outstandingOrderRef = doc(db, 'orders', userId, OUTSTANDING, shipment.payment);
    const completedOrderRef = doc(db, 'orders', userId, COMPLETED, shipment.payment);
    const trackingLink = `${getBaseTrackingUrl({ shipment, shipmentProvider })}${trackingCode}`;

    const { data } =
      (await markOrderComplete({
        patientId: shipment.user,
        title: isPickup ? 'order collected' : 'order sent',
        payment: shipment.payment,
        scriptsArray: shipment.scriptsArray,
        repeatTokens,
        formName: shipment.formName,
        isPickup,
        ...(!isPickup && {
          code: trackingCode,
          shippedBy: shipment.tracking.shippedBy,
          link: trackingLink,
        }),
      })) || {};

    if (data?.error) {
      throw new Error(data.error);
    }

    if (!isPickup) {
      const mailRef = doc(collection(db, 'mail'));
      const mailData = createEmailObj(`${shipment.name} <${shipment.email}>`, 'order_shipped_with_tracking', {
        pharmacyEmail: shipment.pharmacyInfo.email,
        pharmacyName: shipment.pharmacyInfo.name,
        pharmacyPhone: shipment.pharmacyInfo.phone,
        trackingLink,
      });

      batch.set(mailRef, mailData);
    }

    // Clear the transfer field if necessary
    const transferredScripts = shipment.scriptsArray.reduce((acc, script) => {
      if (!script.transfer) {
        return acc;
      }
      return [...acc, script.priceRef];
    }, []);

    if (transferredScripts.length) {
      await clearTransferField({
        userId: shipment.user,
        formName: shipment.formName,
        scriptsArray: transferredScripts,
      });
    }

    const newShipment = {
      ...shipment,
      scriptsArray: shipment?.scriptsArray.map((script) => {
        if (!script.manualBypassed) {
          return script;
        }

        // removes the repeat token from the script if it is manually bypassed
        const { repeatToken, ...rest } = script;

        // adds the previous remaining in the prescription value to the completed order script
        // this may be used again if the completed order will be undone [undo mark complete]
        return { ...rest, previousRemaining: data.previousRemaining?.[script.priceRef] };
      }),
      status: COMPLETE,
      updated: date,
    };

    batch.set(completedOrderRef, newShipment);
    batch.delete(outstandingOrderRef);
    await batch.commit();
    setIsSubmit(false);
    handlePrimaryModal({ isOpen: false });
    snackbar(MESSAGES.ORDER_COMPLETE);
  } catch (error) {
    sentryCaptureException(error, {
      extra: {
        shipment,
        pharmacyName: pharmacyData.name,
        issueIn: 'handleOrderCompletion',
      },
    });
    snackbar(MESSAGES.ERROR_OCCURED);
    setIsSubmit(false);
  }
};

/**
 * Processes and updates an array of order items based on specific conditions.
 * Output is being used to update the order items in the database.
 *
 * @param {Object} params - The parameters object.
 * @param {Array} params.scriptsArray - The array of order items to be processed.
 * @param {Object} params.script - The reference script object containing `priceRef`.
 * @param {Record<string, string>} params.repeatToken - A token object used for updating repeat status.
 * @param {boolean} params.isCheckboxChanged - A flag indicating whether the checkbox state has changed.
 * @returns {Array} - A new array of processed order items.
 */
export const processUpdatedOrderItems = ({ scriptsArray, script, repeatToken, isCheckboxChanged }) =>
  Array.isArray(scriptsArray)
    ? scriptsArray.map((orderItem) => {
        // If the order item does not match the script, return it as is
        if (orderItem.priceRef !== script.priceRef) {
          return orderItem;
        }

        // changes below are for the order item that are meant to be updated
        // If the checkbox state has changed and the order item is not manually bypassed, add manualBypassed and lastRepeat properties
        if (isCheckboxChanged && !orderItem.manualBypassed) {
          return { ...orderItem, manualBypassed: true };
        }

        // If the checkbox state has changed and the order item is manually bypassed, omit the manualBypassed and lastRepeat properties
        if (isCheckboxChanged) {
          const { manualBypassed, ...rest } = orderItem;
          return rest;
        }

        // adds the repeat token to the order item
        return { ...orderItem, repeatToken };
      })
    : [];

/**
 * Returns an appropriate message based on the validity of the provided repeat token.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.repeatToken - The repeat token to be validated.
 * @param {Object} params.script - The script object containing the `eScriptToken` to compare against.
 * @returns {string} - A validation message. If the repeat token is invalid or matches the current script's token, an appropriate message is returned; otherwise, an empty string(falsy) is returned.
 */
export const getInitialCheckMessage = ({ repeatToken, script }) => {
  const trimmedToken = repeatToken?.trim();
  if (!trimmedToken || trimmedToken?.length < MIN_TOKEN_LENGTH || trimmedToken?.length > MAX_TOKEN_LENGTH) {
    return 'Please enter a valid eRx token.';
  }

  if (trimmedToken && trimmedToken === script.eScriptToken) {
    return 'Please do not use the same eRx tokens.';
  }

  return '';
};
