import React from 'react';
import { getDoc, doc } from 'firebase/firestore';

import { db } from '../../../firebase-config';
import { getOrderCurrentStatus, checkIfEscriptIsValid, checkIfOrderIsAwaitingPayment } from '../helpers';

/**
 * @typedef {Object} UseOrderResult
 * @property {ExtendedOrder | null} order The order.
 * @property {boolean} loading Whether the order is loading.
 * @property {string | null} error The error.
 * @property {() => void} refreshOrder The function to refresh the order.
 */

/**
 * @param {Object} props - The props for the useOrder hook.
 * @param {string} props.pharmacyId - The pharmacy ID.
 * @param {string} props.orderId - The order ID.
 * @param {boolean} [props.enabled] - Whether to fetch the order.
 * @returns {UseOrderResult} - The order, loading, and error.
 */
export const useOrder = ({ pharmacyId, orderId, enabled = true }) => {
  const [order, setOrder] = React.useState(/** @type {ExtendedOrder | null} */ (null));
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(/** @type {string | null} */ (null));

  const fetchOrders = React.useCallback(async () => {
    if (!pharmacyId || !orderId || !enabled) {
      return;
    }

    try {
      setLoading(true);

      // Define order types
      const orderTypes = ['completed', 'archived', 'outstanding'];

      // Try to find the order in each subcollection
      const orderPromises = orderTypes.map(async (orderType) => {
        const orderRef = doc(db, 'orders', pharmacyId, orderType, orderId);
        const orderSnap = await getDoc(orderRef);

        if (!orderSnap.exists()) {
          return null;
        }

        const orderData = orderSnap.data();

        const isAwaitingPayment = checkIfOrderIsAwaitingPayment({ order: orderData });
        const isEscriptValid = checkIfEscriptIsValid({ order: orderData });

        return {
          id: orderSnap.id,
          path: orderSnap.ref.path,
          type: orderType,
          ...orderData,
          currentStatus: getOrderCurrentStatus({ order: orderData, isAwaitingPayment, isEscriptValid }),
          isEscriptValid,
          isAwaitingPayment,
        };
      });

      const results = await Promise.all(orderPromises);
      const foundOrder = results.find((result) => result !== null);

      if (foundOrder) {
        setOrder(foundOrder);
      } else {
        setError('Order not found');
        setOrder(null);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [pharmacyId, orderId, enabled]);

  React.useEffect(() => {
    if (!pharmacyId || !orderId || !enabled) {
      return;
    }

    fetchOrders();
  }, [pharmacyId, orderId, enabled, fetchOrders]);

  return { order, loading, error, refreshOrder: fetchOrders };
};
