import { read, writeFile } from 'xlsx';
import { styled } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import { FileDownload } from '@mui/icons-material';
import { Box, Button, Checkbox, Grid2 as Grid, Typography } from '@mui/material';

import { SHIPMENT_TYPES } from '../../../../utils/constants';
import { usePharmacyContext, useOrders } from '../../hooks';
import template from './order-csv-template';

const { SHIPMENT_REQUIRED } = SHIPMENT_TYPES;

export const ExportOrderCard = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '95vw',
  maxWidth: '32rem',
}));

const formatDate = (dateString) =>
  new Date(dateString)
    .toLocaleDateString('en-AU', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour12: true,
    })
    .toString()
    .replaceAll('/', '-');

const processExportCSV = ({ shipmentRequiredOrders, orderReceiverData, signatureOnDelivery }) => {
  // Column labels
  const cols = {
    sendTrackingNotifications: 'B',
    pharmacyName: 'C',
    pharmacyAddress1: 'E',
    pharmacySuburb: 'H',
    pharmacyState: 'I',
    pharmacyPostCode: 'J',
    pharmacyPhone: 'K',
    pharmacyEmail: 'L',
    patientName: 'M',
    patientAddress1: 'Q',
    patientSuburb: 'T',
    patientState: 'U',
    patientPostCode: 'V',
    patientPhone: 'W',
    patientEmail: 'X',
    itemPackageType: 'Y',
    itemDeliveryService: 'Z',
    itemWeight: 'AE',
    signatureOnDelivery: 'AG',
  };
  const wb = read(template, {
    type: 'base64',
  });
  const ws = wb.Sheets.order_import;
  ws['!ref'] = `A1:AH${shipmentRequiredOrders.length + 1}`;

  // Write shipment values to sheet
  shipmentRequiredOrders.forEach((shipment, index) => {
    const row = index + 2;
    // Pharmacy fields
    ws[`${cols.sendTrackingNotifications}${row}`] = { t: 's', v: 'YES' };
    ws[`${cols.pharmacyName}${row}`] = { t: 's', w: orderReceiverData.name, v: orderReceiverData.name };
    ws[`${cols.pharmacyAddress1}${row}`] = { t: 's', v: orderReceiverData.shipping.street };
    ws[`${cols.pharmacySuburb}${row}`] = { t: 's', v: orderReceiverData.shipping.suburb };
    ws[`${cols.pharmacyState}${row}`] = { t: 's', v: orderReceiverData.shipping.state };
    ws[`${cols.pharmacyPostCode}${row}`] = { t: 's', v: orderReceiverData.shipping.postcode };
    ws[`${cols.pharmacyPhone}${row}`] = { t: 's', v: orderReceiverData.phone };
    ws[`${cols.pharmacyEmail}${row}`] = { t: 's', v: orderReceiverData.email };

    // Patient fields
    ws[`${cols.patientName}${row}`] = { t: 's', v: shipment.name };
    ws[`${cols.patientAddress1}${row}`] = { t: 's', v: shipment.shipping['street address'] };
    ws[`${cols.patientSuburb}${row}`] = { t: 's', v: shipment.shipping.suburb };
    ws[`${cols.patientState}${row}`] = { t: 's', v: shipment.shipping.state };
    ws[`${cols.patientPostCode}${row}`] = { t: 's', v: shipment.shipping.postcode };
    ws[`${cols.patientPhone}${row}`] = { t: 's', v: shipment.shipping.phone };
    ws[`${cols.patientEmail}${row}`] = { t: 's', v: shipment.email };

    // Item fields (Disabled for now)
    ws[`${cols.itemPackageType}${row}`] = { t: 's', v: 'AP_SATCHEL_S' };
    ws[`${cols.itemDeliveryService}${row}`] = { t: 's', v: 'EXP' };
    ws[`${cols.itemWeight}${row}`] = { t: 's', v: 4 };
    ws[`${cols.signatureOnDelivery}${row}`] = { t: 's', v: signatureOnDelivery ? 'YES' : '' };
  });

  // Write and download file
  writeFile(wb, `Orders - ${orderReceiverData.name} (${formatDate(Date.now())}).csv`);
};

const OrderExportCSVModal = () => {
  const { handleDialogue } = usePharmacyContext();
  const { pharmacy } = usePharmacyContext();
  const [signatureOnDelivery, setSignatureOnDelivery] = useState(false);
  const { orders } = useOrders({
    pharmacyId: pharmacy.id,
    isHistorical: false,
  });

  const shipmentRequiredOrders = useMemo(() => orders.filter((o) => o.status === SHIPMENT_REQUIRED), [orders]);

  const handleExportCSV = () => {
    processExportCSV({ shipmentRequiredOrders, orderReceiverData: pharmacy, signatureOnDelivery });
    handleDialogue({ isOpen: false, content: null });
  };
  const handleClick = () => {
    setSignatureOnDelivery(!signatureOnDelivery);
  };

  return (
    <ExportOrderCard>
      <Typography variant="h6" color="primary" my={1}>
        Export CSV
      </Typography>
      <Grid container sx={{ width: '100%', alignItems: 'center' }}>
        <Grid size={{ xs: 6 }}>
          <Typography whiteSpace="nowrap">Signature on Delivery</Typography>
        </Grid>
        <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Checkbox checked={signatureOnDelivery} onClick={handleClick} />
        </Grid>
        <Grid size={{ xs: 12 }} mt={2}>
          <Button variant="contained" fullWidth onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Grid>
      </Grid>
    </ExportOrderCard>
  );
};

export const OrderExportCSV = () => {
  const { handleDialogue } = usePharmacyContext();

  const openModal = () => {
    handleDialogue({
      isOpen: true,
      content: <OrderExportCSVModal />,
    });
  };

  return (
    <Button startIcon={<FileDownload />} onClick={openModal}>
      Export AusPost CSV - My Business
    </Button>
  );
};
