import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const OzempicInformation = () => (
  <Grid>
    <Typography variant="h6">Ozempic</Typography>
    <br />
    <Typography variant="body1">
      Ozempic (semaglutide) is an injectable medication used to improve blood sugar control in adults with type 2
      diabetes, and to assist with weight loss.
    </Typography>
    <br />
    <Typography variant="h6">Warnings</Typography>
    <br />
    <Typography variant="body1">
      - Ozempic may increase the risk of thyroid tumors, including thyroid cancer. Monitor for symptoms such as a lump
      or swelling in the neck, hoarseness, trouble swallowing, or shortness of breath. Report any such symptoms to your
      healthcare provider.
      <br />
      <br />
      Do not use Ozempic if you or any of your family have ever had a type of thyroid cancer called medullary thyroid
      carcinoma (MTC).
      <br />
      Do not use Ozempic if you have Multiple Endocrine Neoplasia syndrome type 2 (MEN 2).
      <br />
      Do not use Ozempic if you are allergic to it or any of its ingredients.
      <br />
      <br />
      Ozempic is not recommended for patients with:
      <br />
      <br />- Pancreatitis or inflammation of the pancreas
      <br />- Type 1 diabetes
      <br />
      <br />
      Inform your doctor if you take birth control pills, as Ozempic may affect their effectiveness. Your doctor may
      advise additional contraception or changing your contraceptive method while using Ozempic.
      <br />
      <br />
      The effects of Ozempic on pregnancy, breastfeeding, or fertility are not fully known. Avoid using Ozempic if
      pregnant, breastfeeding, or planning to conceive.
    </Typography>
    <br />
    <Typography variant="h6">Serious side effects</Typography>
    <br />
    <Typography variant="body1">
      Ozempic may cause serious side effects including:
      <br />
      <br />- Pancreatitis: Discontinue Ozempic and contact your healthcare provider immediately if you experience
      severe abdominal pain, with or without vomiting.
      <br />- Hypoglycemia: Risk increases if used with other drugs that lower blood sugar, like sulfonylureas or
      insulin. Symptoms include dizziness, sweating, confusion, blurred vision, slurred speech, shakiness, fast
      heartbeat, irritability, or hunger.
      <br />- Severe allergic reactions: Seek immediate medical help if you experience symptoms like facial swelling,
      breathing or swallowing difficulties, rash or itching, fainting, or rapid heartbeat.
      <br />- Kidney problems: Dehydration caused by diarrhea, nausea, or vomiting may worsen kidney issues. Stay
      hydrated to minimize this risk.
      <br />- Stomach problems: Report persistent or severe stomach issues to your healthcare provider.
      <br />- Vision changes: Notify your healthcare provider of any changes in vision during treatment with Ozempic.
      <br />- Gallbladder problems: Symptoms include upper abdominal pain, fever, jaundice, and clay-colored stools.
      Contact your healthcare provider immediately if you experience these symptoms.
    </Typography>
    <br />
    <Typography variant="h6">Common side effects</Typography>
    <br />
    <Typography variant="body1">
      The most frequent side effects of Ozempic include nausea, diarrhea, decreased appetite, vomiting, constipation,
      indigestion, and stomach pain.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      - Follow the Instructions for Use provided with Ozempic.
      <br />
      - Administer as prescribed by your healthcare provider.
      <br />
      - Use a sterile syringe to draw up the medication.
      <br />- Inject Ozempic subcutaneously in your stomach, thigh, or upper arm.
      <br />- Administer Ozempic once a week, at any time of day.
      <br />- Do not mix Ozempic and insulin in the same injection.
      <br />- Its okay to inject Ozempic and insulin in the same body area but not next to each other.
      <br />- Rotate your injection site with each dose and avoid using the same site repeatedly.
      <br />- Dispose of used sharps properly and do not reuse syringes.
    </Typography>
  </Grid>
);

export default OzempicInformation;
