const getStreetAddress = (shipping) => shipping?.['street address'] || shipping?.street;

/**
 * Get the pharmacy address
 * @param {Object} params
 * @param {Object} params.shipping - The shipping object
 * @returns {string | null} The pharmacy address
 */
export const getPharmacyAddressAsText = ({ shipping }) =>
  [getStreetAddress(shipping), shipping?.suburb, shipping?.state, shipping?.postcode].filter(Boolean).join(', ') ||
  null;
