import React, { useContext } from 'react';
import { Box, Divider, Link, Typography } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PropTypes from 'prop-types';

import { AuthContext } from '../../auth-context';
import ChatbotIcon from './ChatbotIcon';
import getNameFromCandorEmail from '../../utils/getNameFromCandorEmail';

const PATIENT = 'patient';
const CANDOR = 'candor';
const CANDI_SUPPORT_EMAIL = 'candi-support@candor.org';
const AUTOMATED_MESSAGE_PREFIX = 'Automated Message: ';

const SENDER_THEME = {
  color: '#fff',
  background: '#303F56',
};

const NOT_SENDER_THEME = {
  color: '#303F56',
  background: 'rgb(224, 224, 224)',
};

const UserTimeLabel = ({ text, timeSent }) => (
  <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1, mt: 3 }}>
    <Typography variant="caption">{text}</Typography>
    <Typography variant="caption" sx={{ fontSize: '0.6rem' }}>
      {new Date(Number(timeSent)).toLocaleString('en-AU', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}
    </Typography>
  </Box>
);

const getMessageDetails = ({ message, senderDetails, previousMessage, userType }) => {
  // This is assuming the non-patient userTypes won't talk to each other
  const isPatient = userType === PATIENT;
  const currentUserType = isPatient ? PATIENT : CANDOR;
  const isFromChatbot = message?.senderEmail === CANDI_SUPPORT_EMAIL;
  const isFromSystem = message?.text?.startsWith(AUTOMATED_MESSAGE_PREFIX);

  const isSameSenderEmail = message?.senderEmail === senderDetails?.email;
  const isSameOrigin = message?.from === currentUserType && !isFromChatbot;

  // If the senderEmail exists in the message object, use that to determine if the sender is the same as the user
  // Otherwise, use the `from` field to determine if the sender is the same as the user
  const isSender = message?.senderEmail ? isSameSenderEmail : isSameOrigin;

  const shouldDisplayIconAndLabel = !previousMessage || previousMessage?.senderEmail !== message?.senderEmail;

  const messageBackgroundTheme = isSameOrigin ? SENDER_THEME : NOT_SENDER_THEME;
  return {
    isPatient,
    isFromChatbot,
    isSender,
    shouldDisplayIconAndLabel,
    messageBackgroundTheme,
    isFromSystem,
  };
};

const getMessageOrigin = ({ isFromChatbot, isSender, senderDetails, message, recipientName, isFromSystem }) => {
  if (isFromSystem) {
    return 'System';
  }
  if (isFromChatbot) {
    return 'Candi Chatbot';
  }

  if (message.from === PATIENT) {
    if (isSender) {
      return senderDetails?.name || 'You';
    }
    return recipientName;
  }

  // Ensure that all Candor messages are displayed with only the first name and initial
  return getNameFromCandorEmail(message.senderEmail);
};

const MessageBox = ({ message, senderDetails, recipientName, previousMessage }) => {
  const { userType } = useContext(AuthContext);

  const { isPatient, isFromChatbot, isSender, messageBackgroundTheme, isFromSystem } = getMessageDetails({
    message,
    senderDetails,
    previousMessage,
    userType,
  });

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ mt: 3 }}>
          {isFromChatbot ? (
            <ChatbotIcon width="60px" height="60px" />
          ) : (
            <AccountCircleRoundedIcon color="primary" sx={{ width: 60, height: 60, position: 'relative', top: -5 }} />
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', mx: '10px' }}>
          <UserTimeLabel
            timeSent={Number(message.sent?.seconds ? message.sent.seconds * 1000 : message.sent)}
            text={getMessageOrigin({
              isFromChatbot,
              isSender,
              senderDetails,
              message,
              recipientName,
              isFromSystem,
            })}
          />
          <Box
            sx={{
              borderRadius: 3,
              p: '10px',
              ...messageBackgroundTheme,
              mb: 1,
            }}
          >
            {message.url ? (
              <Link href={message.url} target="_blank">
                <Typography style={{ whiteSpace: 'pre-line' }}>{message.text}</Typography>
              </Link>
            ) : (
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {message.text || ''}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {message?.messageWith && isPatient && (
        <Divider sx={{ width: '100%', my: 2 }}>
          <Typography>{message?.messageWith}</Typography>
        </Divider>
      )}
    </>
  );
};

UserTimeLabel.propTypes = {
  text: PropTypes.string.isRequired,
  timeSent: PropTypes.number.isRequired,
};

MessageBox.propTypes = {
  message: PropTypes.shape({
    from: PropTypes.string,
    text: PropTypes.string,
    sent: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    senderEmail: PropTypes.string,
    messageWith: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  senderDetails: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  recipientName: PropTypes.string.isRequired,
  previousMessage: PropTypes.shape({
    from: PropTypes.string,
    text: PropTypes.string,
    sent: PropTypes.number,
    senderEmail: PropTypes.string,
    messageWith: PropTypes.string,
  }).isRequired,
};

export default MessageBox;
