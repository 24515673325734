import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Typography, Grid2 as Grid, Badge } from '@mui/material';

import { ChristmasBanner } from '../banners';
import { AuthContext } from '../../auth-context';
import MessageNurse from '../../media/messaging/message_nurse.png';
import MessageDoctor from '../../media/messaging/message_doctor.png';
import MessageSupport from '../../media/messaging/message_support.png';
import MessagePharmacy from '../../media/messaging/message_pharmacy.png';

const PREFIX = 'MessagesSelection';

const classes = {
  button: `${PREFIX}-button`,
  image: `${PREFIX}-image`,
  container: `${PREFIX}-container`,
  card1: `${PREFIX}-card1`,
  card2: `${PREFIX}-card2`,
};

const StyledContainer = styled(Container)({
  [`& .${classes.button}`]: {
    padding: 20,
    backgroundColor: '#fff',
    color: '#0E6D81',
    textTransform: 'none',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: '#F6F9FC',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.image}`]: {
    width: '100%',
    height: 'auto',
    marginTop: 20,
  },
  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.card1}`]: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1000px)': {
      justifyContent: 'end',
    },
  },
  [`& .${classes.card2}`]: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MESSAGE_SELCTIONS = [
  {
    name: 'Practitioner',
    role: 'doctor',
    image: MessageDoctor,
    isHidden: () => false,
    altLabel: 'Message Doctor',
  },
  {
    name: 'Nurse',
    role: 'nurse',
    image: MessageNurse,
    isHidden: () => false,
    altLabel: 'Message Nurse',
  },
  {
    name: 'Pharmacist',
    role: 'pharmacist',
    image: MessagePharmacy,
    // QUESTION: Why is this the only check caring about userType?
    // Why are we hiding this from everyone but Patient?
    // Why are we hiding all the other messaging options from the patient?
    // This makes no sense...
    isHidden: (userType) => userType !== 'patient',
    altLabel: 'Message Pharmacist',
  },
  {
    name: 'Support',
    role: 'support',
    image: MessageSupport,
    isHidden: () => false,
    altLabel: 'Message Support',
  },
];

const handleRole = ({ role, userType }) => {
  if (role === userType) {
    return 'patient';
  }

  return role;
};

const MessagesSelection = ({ handleMessageSelection, patientId, pharmacyId }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const location = useLocation();
  const { userType, notifications } = useContext(AuthContext);

  const messageSelections = MESSAGE_SELCTIONS.filter((item) => !item.isHidden(userType));

  const handleStyle = (role) => {
    if (role === 'doctor' || role === 'pharmacist') {
      return classes.card1;
    }
    return classes.card2;
  };

  const handleDisabled = (role) => {
    if (role === 'doctor') {
      return userType === 'pharmacist' || userType === 'support';
    }
    if (role === 'nurse') {
      return userType === 'pharmacist' || userType === 'support';
    }
    if (role === 'support') {
      return userType === 'pharmacist';
    }
    if (role === 'pharmacist') {
      return userType === 'support' || userType === 'nurse' || userType === 'doctor';
    }
    return false;
  };

  const handleSelection = (role) => {
    if (role === 'support') {
      if (location.pathname.includes('patients')) {
        navigate(`/patients/${id}?access=${role}`);
        handleMessageSelection(role);
      } else if (userType === 'nurse' || userType === 'doctor' || userType === 'director') {
        navigate(`/messages/list/patients?access=${role}`);
      } else if (userType === 'patient') {
        navigate(`/messages/${role}`);
      } else {
        navigate('/messages/list/patients');
      }
    } else if (role === 'nurse') {
      if (location.pathname.includes('patients')) {
        navigate(`/patients/${id}?access=${role}`);
        handleMessageSelection(role);
      } else if (userType === 'doctor' || userType === 'director') {
        navigate(`/messages/list/patients?access=${role}`);
      } else if (userType === 'patient') {
        navigate(`/messages/${role}`);
      } else {
        navigate('/messages/list/patients');
      }
    } else if (role === 'doctor') {
      if (location.pathname.includes('patients')) {
        navigate(`/patients/${id}?access=${role}`);
        handleMessageSelection(role);
      } else if (userType === 'director') {
        navigate(`/messages/list/patients?access=${role}`);
      } else if (userType === 'patient') {
        navigate(`/messages/${role}`);
      } else {
        navigate('/messages/list/patients');
      }
    } else if (role === 'pharmacist') {
      if (location.pathname.includes('orders')) {
        navigate(`/orders?&orderPatientId=${patientId}`);
        handleMessageSelection(role);
      } else if (userType === 'director') {
        if (location.pathname.includes('orders')) {
          navigate(`/orders?&orderPatientId=${patientId}&pharmacyId=${pharmacyId}`);
        } else {
          navigate(`/messages/list/patients?access=${role}`);
        }
      } else if (userType === 'patient') {
        navigate('/messages/list/pharmacists');
      } else if (userType === 'pharmacist') {
        navigate('/messages/list/patients');
      }
    }
  };

  return (
    <StyledContainer className={classes.container}>
      <ChristmasBanner sx={{ marginBottom: 2 }} />
      <Typography variant="h5" textAlign="center" gutterBottom>
        Messaging
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={3} marginTop="40px" marginBottom="40px">
        {messageSelections.map((item) => (
          <Grid key={item.role} className={handleStyle(item.role)} size={{ xs: 6, sm: 6, md: 3 }}>
            <Button
              variant="contained"
              disabled={handleDisabled(item.role)}
              className={classes.button}
              onClick={() => handleSelection(item.role)}
            >
              {!location.pathname.includes('patients') && (
                <Badge
                  sx={{ ml: 'auto' }}
                  badgeContent={notifications[handleRole({ role: item.role, userType })]}
                  color="secondary"
                />
              )}
              {item.role}
              <img src={item.image} className={classes.image} alt={item.altLabel} />
            </Button>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
};

export default MessagesSelection;
