import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  styled,
  Stack,
  Button,
  Grid2 as Grid,
} from '@mui/material';
import React from 'react';

import { isSupplier } from '../../../utils/roles';
import { useAuthContext } from '../hooks';
import { AUSTRALIAN_STATES, PHARMACY_TYPES } from '../constants';

const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,
  textTransform: 'capitalize',
});

/**
 * @param {Object} params - The component props
 * @param {Filters} params.filters - The filters
 * @returns {boolean} - Whether the filters are set
 */
const hasFilters = ({ filters }) =>
  filters && !!(filters.searchTerm || filters.state || filters.type !== PHARMACY_TYPES.ALL);

/**
 * @typedef {Object} Filters
 * @property {string} searchTerm - The search term
 * @property {string} state - The state
 * @property {string} type - The type
 */

/**
 * Component for filtering pharmacies
 * @param {Object} props - The component props
 * @param {Array<Pharmacy>} props.allPharmacies - The all pharmacies
 * @param {Filters} props.filters - The current filters
 * @param {Function} props.onFiltersChange - Function to update the filters
 * @returns {JSX.Element} - The PharmacyFilters component
 */
export const PharmacyFilters = ({ allPharmacies, filters, onFiltersChange }) => {
  const { userType } = useAuthContext();

  const stateCounts = React.useMemo(
    () =>
      allPharmacies.reduce((acc, pharmacy) => {
        acc[pharmacy.state] = (acc[pharmacy.state] || 0) + 1;

        return acc;
      }, {}),
    [allPharmacies],
  );

  const handleClearFilters = React.useCallback(() => {
    onFiltersChange(null);
  }, [onFiltersChange]);

  const hasActiveFilters = React.useMemo(() => hasFilters({ filters }), [filters]);

  const uniqueStoreTypes = React.useMemo(
    () => [...new Set(allPharmacies.map((pharmacy) => pharmacy.type))],
    [allPharmacies],
  );

  return (
    <Grid container spacing={2} mb={3}>
      <TextField
        label="Search Pharmacies"
        value={filters.searchTerm}
        onChange={(e) => onFiltersChange({ ...filters, searchTerm: e.target.value })}
        sx={{ minWidth: 200 }}
      />

      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel>State</InputLabel>
        <Select
          value={filters.state}
          label="State"
          onChange={(e) => onFiltersChange({ ...filters, state: e.target.value })}
          renderValue={(value) => (
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              justifyContent="space-between"
              width="100%"
              sx={{ textTransform: 'capitalize' }}
            >
              {value}
              <Chip label={stateCounts[value] ?? 0} color="primary" size="small" />
            </Stack>
          )}
        >
          <StyledMenuItem value="">
            All States <Chip label={allPharmacies.length ?? 0} size="small" color="primary" />
          </StyledMenuItem>
          {AUSTRALIAN_STATES.map((state) => (
            <StyledMenuItem key={state} value={state}>
              {state}
              <Chip label={stateCounts[state] || 0} size="small" color="primary" />
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>

      {!isSupplier(userType) && (
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Type</InputLabel>
          <Select
            value={filters.type}
            label="Type"
            sx={{ textTransform: 'capitalize' }}
            onChange={(e) => onFiltersChange({ ...filters, type: e.target.value })}
          >
            <MenuItem value={PHARMACY_TYPES.ALL}>All Types</MenuItem>
            {uniqueStoreTypes.map((type) => (
              <MenuItem key={type} value={type} sx={{ textTransform: 'capitalize' }}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {hasActiveFilters && (
        <Button variant="outlined" onClick={handleClearFilters}>
          Clear Filters
        </Button>
      )}
    </Grid>
  );
};
