import React from 'react';
import PharmacyInformationPresentation from './pharmacy-information-presentation';

const PharmacyInformation = (props) => {
  const {
    preferredPharmacy,
    setPreferredPharmacy,
    handleBackButton,
    pharmacyConsentRead,
    setPharmacyConsentRead,
    collectingCardInfo,
    setScriptMode,
  } = props;

  const handleChange = (value, key) => {
    if (value.trim() === '') {
      setPharmacyConsentRead(false);
    }
    setPreferredPharmacy((pd) => ({ ...pd, ...{ [key]: value } }));
  };

  return (
    <PharmacyInformationPresentation
      handleBackButton={handleBackButton}
      preferredPharmacy={preferredPharmacy}
      handleChange={handleChange}
      pharmacyConsentRead={pharmacyConsentRead}
      setPharmacyConsentRead={setPharmacyConsentRead}
      collectingCardInfo={collectingCardInfo}
      setScriptMode={setScriptMode}
    />
  );
};

export default PharmacyInformation;
