import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Typography, Button } from '@mui/material';

const PREFIX = 'UploadDocumentSuccess';

const classes = {
  paper: `${PREFIX}-paper`,
  fullWidthGrid: `${PREFIX}-fullWidthGrid`,
  gridContainer: `${PREFIX}-gridContainer`,
  heading: `${PREFIX}-heading`,
  navButton: `${PREFIX}-navButton`,
  textField: `${PREFIX}-textField`,
};

const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: 10,

  [`& .${classes.fullWidthGrid}`]: {
    margin: 10,
    position: 'relative',
  },

  [`& .${classes.heading}`]: {
    alignSelf: 'center',
    marginBottom: '50px',
    marginTop: '30px',
  },

  [`& .${classes.textField}`]: {
    marginBottom: '20px',
    width: '80%',
  },

  [`& .${classes.navButton}`]: {
    margin: 10,
  },
}));

const UploadDocumentSuccess = ({ fileName, reset, exitPage, exitDescription }) => (
  <StyledPaper elevation={0}>
    <Grid size={{ xs: 12 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Typography variant="h5" align="center" className={classes.heading}>
        File Upload Successful
      </Typography>
      <Typography className={classes.textField} variant="body1" align="center" gutterBottom>
        The file &quot;{fileName}&quot; was successfully uploaded and the Candor team has been notified.
      </Typography>
      <br />
      <Grid size={{ xs: 12 }} className={classes.fullWidthGrid}>
        <Button variant="contained" className={classes.navButton} color="primary" onClick={exitPage}>
          {exitDescription}
        </Button>
        <Button variant="contained" className={classes.navButton} color="primary" onClick={reset}>
          New Upload
        </Button>
      </Grid>
    </Grid>
  </StyledPaper>
);

UploadDocumentSuccess.propTypes = {
  fileName: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  exitPage: PropTypes.func.isRequired,
  exitDescription: PropTypes.string.isRequired,
};

export default UploadDocumentSuccess;
