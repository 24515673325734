import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  FormControl,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { httpsCallable } from 'firebase/functions';
import { parseAsString, parseAsStringEnum, useQueryState } from 'nuqs';
import { captureException as sentryCaptureException } from '@sentry/react';
import { collection, query, orderBy, getDocs, where } from 'firebase/firestore';

import { db, functions } from '../../../../../../firebase-config';
import { SelectOrder } from './SelectOrder';
import { ChargebackDisputeIdInput } from './ChargebackDisputeIdInput';

const createPatientChargeback = httpsCallable(functions, 'createPatientChargeback');

/**
 * When we introduce more payment providers, we can add them here.
 */
const DEFAULT_PAYMENT_PROVIDER = 'TILL_PAYMENTS';

const PREFIX = 'ChargebackHandler';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridItem: `${PREFIX}-gridItem`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  toggleGroup: `${PREFIX}-toggleGroup`,
  formControl: `${PREFIX}-formControl`,
  switchLabel: `${PREFIX}-switchLabel`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px auto',
    width: '100px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '220px',
    display: 'flex',
  },

  [`& .${classes.backButton}`]: {
    margin: '10px auto 0px auto',
    width: '200px',
    display: 'flex',
  },

  [`& .${classes.toggleGroup}`]: {
    width: '100%',
    justifyContent: 'center',
  },

  [`& .${classes.formControl}`]: {},

  [`& .${classes.switchLabel}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      flex: 1,
    },
  },
}));

/**
 * Parses the order ID from the order ID string
 * @param {string} orderId - The order ID string
 * @returns {string} The parsed order ID
 */
const parseOrderId = (orderId) => orderId.split('_').pop() || '';

/**
 * Gets the order amount from the order
 * @param {string} orderAmount - The order amount
 * @returns {number | null} The order amount or null if it is not a number
 */
const getOrderAmount = (orderAmount) => {
  const amount = Number(orderAmount);

  if (Number.isNaN(amount)) {
    return null;
  }

  return amount;
};

/**
 * Component that renders a chargeback handler
 * @param {Object} props - Component props
 * @param {string} props.patientId - The ID of the patient
 * @param {Function} props.handleModalClose - Callback function to close modal dialogs
 * @param {Function} props.handleModalOpen - Callback function to open modal dialogs
 * @returns {JSX.Element} ChargebackHandler component
 */
export const ChargebackHandler = ({ patientId, handleModalClose, handleModalOpen }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sendPatientEmail, setSendPatientEmail] = React.useState(true);
  const [sendPharmacyEmail, setSendPharmacyEmail] = React.useState(true);
  const [chargebackDisputeId, setChargebackDisputeId] = React.useState('');
  const [orders, setOrders] = React.useState(/** @type {Array<Order>} */ ([]));
  const [orderAmount, setOrderAmount] = React.useState(/** @type {string | null} */ (null));

  const [chargebackType, setChargebackType] = useQueryState(
    'chargebackType',
    parseAsStringEnum(['PHARMACY', 'CONSULT']).withOptions({ clearOnDefault: false }).withDefault('PHARMACY'),
  );
  const [selectedOrderId, setSelectedOrderId] = useQueryState('selectedOrderId', parseAsString.withDefault(''));

  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    async function getData() {
      setIsLoading(true);
      const patientsOrdersQuery = query(
        collection(db, 'patients', patientId, 'purchase_history'),
        orderBy('orderPlaced', 'desc'),
      );
      const patientsOrdersQuerySnapshot = await getDocs(patientsOrdersQuery);

      const patientsOrders = [];
      patientsOrdersQuerySnapshot.forEach((doc) => {
        patientsOrders.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      // Fetch corresponding till order attempts
      const enrichedPatientsOrders = await Promise.all(
        patientsOrders.map(async (patientsOrder) => {
          const tillOrderQuery = query(
            collection(db, `patients/${patientId}/tillOrderAttempts`),
            where('orderResponse.purchaseId', '==', parseOrderId(patientsOrder.id)),
          );
          const tillOrderSnapshot = await getDocs(tillOrderQuery);
          const tillOrder = tillOrderSnapshot.docs[0]?.data() ?? null;

          return {
            ...patientsOrder,
            totalAmount: getOrderAmount(tillOrder?.orderRequest?.amount),
          };
        }),
      );

      if (!isMounted.current) {
        return;
      }

      setOrders(enrichedPatientsOrders);
      setIsLoading(false);

      if (!selectedOrderId) {
        return;
      }

      const order = enrichedPatientsOrders.find((patientsOrder) => patientsOrder.id === selectedOrderId);

      if (order) {
        setOrderAmount(order.totalAmount ?? 0);
      }
    }

    getData();

    return () => {
      isMounted.current = false;
    };
  }, [patientId, selectedOrderId]);

  const filteredOrders = React.useMemo(
    () => orders.filter((order) => (chargebackType === 'PHARMACY' ? order.pharmacyInfo : !order.pharmacyInfo)),
    [orders, chargebackType],
  );

  const selectedOrder = React.useMemo(
    () => orders.find((order) => order.id === selectedOrderId),
    [orders, selectedOrderId],
  );

  const handleSubmit = async () => {
    if (!selectedOrderId) {
      handleModalOpen('Error: No order selected.');

      return;
    }

    if (!selectedOrder) {
      handleModalOpen('Error: Could not find order.');

      return;
    }

    const orderAmountAsNumber = Number(orderAmount);

    if (Number.isNaN(orderAmountAsNumber)) {
      handleModalOpen('Error: Invalid order amount.');

      return;
    }

    try {
      setIsSubmitting(true);

      await createPatientChargeback({
        patientId,
        sendPatientEmail,
        sendPharmacyEmail,
        chargebackDisputeId,
        type: chargebackType,
        amountDisputed: orderAmountAsNumber,
        disputedTransactionId: selectedOrderId,
        paymentProvider: DEFAULT_PAYMENT_PROVIDER,
        pharmacyId: selectedOrder.pharmacyInfo?.id,
      });

      handleModalOpen('Result: The chargeback was submitted.');
    } catch (error) {
      const id = sentryCaptureException(error, {
        extra: { patientId, orderAmount, selectedOrder, issueIn: 'handleChargebackHandler' },
      });
      handleModalOpen(`Error: Something went wrong. Contact your team leader. Error: ${error.message} Error ID: ${id}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Function to handle the change of the chargeback type
   * @param {React.MouseEvent<HTMLElement>} _ - The event object
   * @param {ChargebackType} newValue - The new value of the chargeback type
   */
  const handleChargebackTypeChange = (_, newValue) => {
    setChargebackType(newValue);
    setSelectedOrderId(null);
    setOrderAmount(null);
    setChargebackDisputeId('');
  };

  /**
   * Function to handle the change of the order amount
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object
   */
  const handleOrderAmountChange = (e) => {
    if (e.target.value === '') {
      setOrderAmount(null);
      return;
    }

    // Regex to allow only one decimal point
    if (!/^\d+(\.\d{0,2})?$/.test(e.target.value)) {
      return;
    }

    setOrderAmount(e.target.value);
  };

  /**
   * Function to handle the change of the chargeback id
   * @param {string} id - The dispute ID
   */
  const handleChargebackDisputeIdChange = (id) => {
    setChargebackDisputeId(id);
  };

  /**
   * Function to handle the change of the selected order
   * @param {Order} order - The order
   */
  const handleSelectedOrderChange = (order) => {
    setSelectedOrderId(order.id);

    if (!order?.totalAmount) {
      setOrderAmount(null);
      return;
    }

    setOrderAmount(String(order?.totalAmount ?? 0));
  };

  /**
   * Function to handle the cancel button
   */
  const handleCancel = () => {
    setOrders([]);
    setSelectedOrderId(null);
    setOrderAmount(null);
    setChargebackType('PHARMACY');
    setChargebackDisputeId('');
    handleModalClose();
  };

  const chargebackTypeLabel = chargebackType === 'PHARMACY' ? 'pharmacy and patient' : 'patient';

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.gridItem} gap={2}>
            <Typography variant="h5" align="center">
              Chargeback Handler
            </Typography>

            <Typography variant="body1" textAlign="center" gutterBottom>
              Applies a purchase lock to the patient and sends chargeback notification to the {chargebackTypeLabel}
            </Typography>

            <ToggleButtonGroup
              value={chargebackType}
              exclusive
              onChange={handleChargebackTypeChange}
              className={classes.toggleGroup}
              fullWidth
            >
              <ToggleButton value="PHARMACY">Pharmacy Order</ToggleButton>
              <ToggleButton value="CONSULT">Consult</ToggleButton>
            </ToggleButtonGroup>

            <FormControl fullWidth>
              <SelectOrder
                selectedOrderId={selectedOrderId}
                setSelectedOrder={handleSelectedOrderChange}
                orders={filteredOrders}
                isLoading={isLoading}
              />
            </FormControl>

            <TextField
              fullWidth
              value={orderAmount ?? ''}
              onChange={handleOrderAmountChange}
              label="Order Amount"
              variant="outlined"
            />

            <ChargebackDisputeIdInput
              chargebackDisputeId={chargebackDisputeId}
              handleChargebackDisputeIdChange={handleChargebackDisputeIdChange}
            />

            <FormControl fullWidth className={classes.formControl}>
              <FormControlLabel
                className={classes.switchLabel}
                control={<Switch checked={sendPatientEmail} onChange={(e) => setSendPatientEmail(e.target.checked)} />}
                label="Send Email to Patient"
                labelPlacement="start"
              />
            </FormControl>

            {chargebackType === 'PHARMACY' && (
              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  className={classes.switchLabel}
                  control={
                    <Switch checked={sendPharmacyEmail} onChange={(e) => setSendPharmacyEmail(e.target.checked)} />
                  }
                  label="Send Email to Pharmacy"
                  labelPlacement="start"
                />
              </FormControl>
            )}

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
              disabled={!selectedOrderId || !orderAmount || !chargebackDisputeId || isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={20} /> : 'Submit'}
            </Button>
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleCancel}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};
