import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { addDoc, collection } from 'firebase/firestore';
import {
  Box,
  Grid2 as Grid,
  Paper,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import { db } from '../../firebase-config';
import { AuthContext } from '../../auth-context';
import createEmailObj from '../../functions/create-email-obj';
import SelectPharmacyModal from './select-pharmacies-modal';
import GoogleAddressAutocomplete from './google-address-autocomplete';

const PREFIX = 'ShippingInformationPresentation';

const classes = {
  container: `${PREFIX}-container`,
  backButton: `${PREFIX}-backButton`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  termsText: `${PREFIX}-termsText`,
  button: `${PREFIX}-button`,
  inputGrid: `${PREFIX}-inputGrid`,
  suggestedPharmacyModalInputWrapper: `${PREFIX}-suggestedPharmacyModalInputWrapper`,
  pharmacyBox: `${PREFIX}-pharmacyBox`,
  pharmacyBoxSelected: `${PREFIX}-pharmacyBoxSelected`,
  pharmacyGridItem: `${PREFIX}-pharmacyGridItem`,
  paperHolder: `${PREFIX}-paperHolder`,
  suggestionPaperContainer: `${PREFIX}-suggestionPaperContainer`,
  cancelButton: `${PREFIX}-cancelButton`,
  snackbar: `${PREFIX}-snackbar`,
  snackbarContent: `${PREFIX}-snackbarContent`,
  address: `${PREFIX}-address`,
  toggleButton: `${PREFIX}-toggleButton`,
};

const StyledShippingContainer = styled(Grid)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [`& .${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.backButton}`]: {
    padding: 5,
  },

  [`& .${classes.paper}`]: {
    padding: '1rem',
    margin: '10px 10px 30px 10px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      maxWidth: '500px',
      padding: '1rem',
      margin: '2rem 0.25rem',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 20,
  },

  [`& .${classes.termsText}`]: {
    marginBottom: 20,
  },

  [`& .${classes.button}`]: {
    margin: 'auto',
  },

  [`& .${classes.inputGrid}`]: {
    margin: '10px 0',
  },

  [`& .${classes.suggestedPharmacyModalInputWrapper}`]: {
    margin: '10px 0 15px',
  },

  [`& .${classes.pharmacyBox}`]: {
    padding: 20,
    width: '100%',
    cursor: 'pointer',
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'start',
    wordBreak: 'break-word',
  },

  [`& .${classes.pharmacyBoxSelected}`]: {
    backgroundColor: '#303f56',
    padding: 20,
    width: '100%',
    cursor: 'pointer',
    marginLeft: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'start',
    wordBreak: 'break-word',
    color: 'white',
  },

  [`& .${classes.pharmacyGridItem}`]: {
    padding: '10px 0px 10px 10px',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    width: '100%',
  },

  [`& .${classes.suggestionPaperContainer}`]: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '40rem',
    padding: '2rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '@media (max-width: 768px)': {
      width: '75%',
    },
  },

  [`& .${classes.cancelButton}`]: {
    height: 50,
    position: 'absolute',
    right: 0,
    top: 3,
  },

  [`& .${classes.snackbar}`]: {
    minWidth: '50px',
  },

  [`& .${classes.snackbarContent}`]: {
    backgroundColor: '#303F56',
    minWidth: '50px',
  },

  [`& .${classes.address}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 10,
    columnGap: 20,
  },

  [`& .${classes.toggleButton}`]: {
    textTransform: 'none',
  },
}));

const ShippingInformationPresentation = ({
  patientInfo,
  scriptMode,
  patientData = {
    'street address': '',
    phone: '',
    state: '',
    suburb: '',
    postcode: '',
  },
  handleChange,
  handleSaveButton,
  context,
  handleBackButton,
  collectingCardInfo,
  filteredPharmacies,
  pharmacies,
  formName,
  deliveryMode,
  setDeliveryMode,
  selectedPharmacy,
  setSelectedPharmacy,
  pharmacySelectionMode,
  setPharmacySelectionMode,
  selectedTreatments,
  setScriptMode,
  pharmacyStock,
  pickupFromOtherStates,
  setPickupFromOtherStates,
}) => {
  const { userType } = useContext(AuthContext);

  const location = useLocation();
  const [showPharmacySuggestionModal, setShowPharmacySuggestionModal] = useState(false);
  const [suggestedPharmacy, setSuggestedPharmacy] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPharmacy, setIsEditingPharmacy] = useState(false);
  const [isUsingGoogleAddress, setIsUsingGoogleAddress] = useState(true);

  const defaultSelectedPharmacy = filteredPharmacies.find(
    (pharm) => pharm.name === patientInfo?.nextDelivery || pharm.name === patientInfo?.nextPickup,
  );

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const pharmacySuggestionModal = async () => {
    const patientName = `${patientInfo.firstName} ${patientInfo.lastName}`;
    const recipientEmail = 'support@candor.org';
    const messageObj = {
      patientName,
      patientEmail: patientInfo.email,
      suggestedPharmacy,
    };
    await addDoc(collection(db, 'mail'), createEmailObj(recipientEmail, 'pharmacy_referral', messageObj));
    setShowPharmacySuggestionModal(false);
    setSnackbarOpen(true);
  };

  // Automatically select the pharmacy based on the patient's nextPickup or nextDelivery field
  const handleChangeButton = () => {
    const defaultSelected = filteredPharmacies.find((pharmacy) => pharmacy.name === defaultSelectedPharmacy);
    if (defaultSelected) handleChange(defaultSelected.id, 'orderSelectedPharmacy');
    setSelectedPharmacy(defaultSelected.id || null);
    setIsEditingPharmacy(true);
  };

  // Reset the selected pharmacy if the user changes the pharmacy selection mode to manual
  const handleSelectManual = () => {
    setSelectedPharmacy('');
    handleChange(null, 'orderSelectedPharmacy');
  };

  // If the delivery mode is pickup, set the pharmacy selection mode to manual
  const handleSelectPickup = () => {
    setPharmacySelectionMode('manual');
    handleSelectManual();
  };

  // When the pharmacy selection mode set to automatic, reset the selected pharmacy
  const handleSelectAutomatic = () => {
    if (pharmacySelectionMode === 'automatic') {
      setSelectedPharmacy('');
      handleChange(null, 'orderSelectedPharmacy');
    }
  };

  return (
    <StyledShippingContainer data-testid="shipping-information-container-root" size={{ xs: 12 }}>
      {context === 'payment' && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Button variant="contained" className={classes.backButton} onClick={handleBackButton}>
            Back
          </Button>
        </div>
      )}
      <Paper className={classes.paper} sx={{ maxWidth: context === 'account' ? '500px' : 'unset' }}>
        <Grid container spacing={2} className={classes.container}>
          <Grid size={{ xs: 12 }}>
            <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
              {scriptMode || userType === 'doctor' ? 'Your Address Information' : 'Shipping Information'}
            </Typography>
          </Grid>
          {!isEditing && (
            <>
              <Grid size={{ xs: 12 }}>
                <Box className={classes.address}>
                  <Typography textAlign="center">
                    {patientData['street address']}, {patientData.suburb}, {patientData.state} {patientData.postcode}
                  </Typography>
                  <Typography textAlign="center">{patientData.phone}</Typography>
                  <Button
                    color="primary"
                    onClick={() => {
                      setIsEditing(true);
                      if (
                        patientData['street address'] &&
                        patientData.suburb &&
                        patientData.state &&
                        patientData.postcode
                      )
                        setIsUsingGoogleAddress(false);
                    }}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
            </>
          )}
          {isEditing && (
            <>
              {isUsingGoogleAddress && (
                <>
                  <Grid size={{ xs: 12 }} className={classes.inputGrid}>
                    <GoogleAddressAutocomplete
                      disabled={collectingCardInfo}
                      handleChange={handleChange}
                      setIsUsingGoogleAddress={setIsUsingGoogleAddress}
                    />
                  </Grid>
                  <Grid
                    size={{ xs: 12 }}
                    className={classes.inputGrid}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Button fullWidth onClick={() => setIsUsingGoogleAddress(false)}>
                      Manually Enter Address
                    </Button>
                  </Grid>
                </>
              )}
              {!isUsingGoogleAddress && (
                <>
                  <Grid size={{ xs: 12, sm: 6 }} className={classes.inputGrid}>
                    <TextField
                      disabled={collectingCardInfo}
                      label="Street Address"
                      value={patientData['street address'] || ''}
                      onChange={(e) => handleChange(e.target.value, 'street address')}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }} className={classes.inputGrid}>
                    <TextField
                      disabled={collectingCardInfo}
                      label="Suburb"
                      value={patientData.suburb || ''}
                      onChange={(e) => handleChange(e.target.value, 'suburb')}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 3 }} className={classes.inputGrid}>
                    <TextField
                      disabled={collectingCardInfo}
                      label="Postcode"
                      value={patientData.postcode || ''}
                      onChange={(e) => handleChange(e.target.value, 'postcode')}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid size={{ xs: 6, sm: 3 }} className={classes.inputGrid}>
                    <FormControl fullWidth>
                      <InputLabel id="state-selector-label">State</InputLabel>
                      <Select
                        labelId="state-selector-label"
                        id="state-selector"
                        value={patientData.state || ''}
                        label="State"
                        disabled={collectingCardInfo}
                        onChange={(e) => handleChange(e.target.value, 'state')}
                      >
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="TAS">TAS</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }} className={classes.inputGrid}>
                    <TextField
                      disabled={collectingCardInfo}
                      label="Phone Number"
                      value={patientData.phone || ''}
                      onChange={(e) => handleChange(e.target.value, 'phone')}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              {context === 'payment' && (
                <Grid size={{ xs: 12 }}>
                  <Button
                    color="primary"
                    onClick={() => {
                      setIsEditing(false);
                      setIsUsingGoogleAddress(true);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </>
          )}
          {location.pathname === '/purchase' && (formName === 'medicinal cannabis' || formName === 'nicotine') && (
            <>
              <Grid
                size={{ xs: 24, sm: 12 }}
                sx={{ mt: 2, textAlign: 'center', justifyContent: 'center', display: 'flex' }}
              >
                <Box sx={{ width: '300px' }}>
                  <ToggleButtonGroup
                    color="primary"
                    fullWidth
                    disabled={collectingCardInfo}
                    exclusive
                    value={deliveryMode}
                    onChange={(e, value) => {
                      if (value !== null) setDeliveryMode(value);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton className={classes.toggleButton} value="delivery">
                      Delivery
                    </ToggleButton>
                    <ToggleButton className={classes.toggleButton} onClick={handleSelectPickup} value="pickup">
                      Pickup
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid
                size={{ xs: 24, sm: 12 }}
                sx={{
                  mt: 2,
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography fontWeight="bold" color="primary" mb={1}>
                  Dispatch Pharmacy
                </Typography>
                <Box sx={{ width: '300px' }}>
                  <ToggleButtonGroup
                    color="primary"
                    fullWidth
                    disabled={collectingCardInfo}
                    exclusive
                    value={pharmacySelectionMode}
                    onChange={(e, value) => {
                      if (value !== null) setPharmacySelectionMode(value);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton
                      className={classes.toggleButton}
                      onClick={handleSelectAutomatic}
                      disabled={deliveryMode === 'pickup'}
                      value="automatic"
                    >
                      Auto
                    </ToggleButton>
                    <ToggleButton className={classes.toggleButton} onClick={handleSelectManual} value="manual">
                      Manual Selection
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              {defaultSelectedPharmacy && pharmacySelectionMode === 'manual' && !isEditingPharmacy && (
                <Grid size={{ xs: 24, sm: 12 }}>
                  <Typography fontWeight="bold" color="primary">
                    Preferred Pharmacy
                  </Typography>
                  <Typography>{defaultSelectedPharmacy}</Typography>
                  <Button onClick={handleChangeButton}>Change</Button>
                </Grid>
              )}
            </>
          )}
          {pharmacySelectionMode === 'manual' && (!defaultSelectedPharmacy || isEditingPharmacy) && (
            <>
              {filteredPharmacies?.length === 0 && deliveryMode !== 'pickup' && (
                <Grid size={{ xs: 24, sm: 12 }}>
                  <Typography textAlign="center">No pharmacies available for selection</Typography>
                </Grid>
              )}
              {(filteredPharmacies.length > 0 || deliveryMode === 'pickup') && (
                <Grid
                  size={{ xs: 24, sm: 12 }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {selectedPharmacy && (
                    <Box>
                      <Typography fontWeight="bold">
                        {pharmacies.find((pharm) => pharm.id === selectedPharmacy)?.name}
                      </Typography>
                    </Box>
                  )}
                  <SelectPharmacyModal
                    selectedPharmacy={selectedPharmacy}
                    filteredPharmacies={filteredPharmacies}
                    pharmacies={pharmacies}
                    selectedTreatments={selectedTreatments}
                    setSelectedPharmacy={setSelectedPharmacy}
                    setScriptMode={setScriptMode}
                    deliveryMode={deliveryMode}
                    patientInfo={patientInfo}
                    formName={formName}
                    pharmacyStock={pharmacyStock}
                    pickupFromOtherStates={pickupFromOtherStates}
                    setPickupFromOtherStates={setPickupFromOtherStates}
                  />
                </Grid>
              )}
            </>
          )}
          {context !== 'payment' && isEditing && (
            <Grid size={{ xs: 12 }} textAlign="center">
              <Button
                disabled={
                  !(
                    patientData['street address'] &&
                    patientData.suburb &&
                    patientData.state &&
                    patientData.postcode &&
                    patientData.phone
                  )
                }
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsEditing(false);
                  handleSaveButton();
                }}
              >
                Save Details
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </StyledShippingContainer>
  );
};

export default ShippingInformationPresentation;
