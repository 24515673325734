import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Typography, Button } from '@mui/material';

import {
  AcneImage,
  SleepImage,
  AgeingImage,
  HerpesImage,
  NicotineImage,
  HairLossImage,
  AllergiesImage,
  ColdSoresImage,
  WeightLossImage,
  ContraceptivePillImage,
  MedicinalCannabisImage,
  ErectileDysfunctionImage,
} from '../../media/treatments';
import { capitalizeFirstLetterOfEachWord } from '../../utils/constants';

const PREFIX = 'TreatmentThumbnails';

const classes = {
  section: `${PREFIX}-section`,
  treatmentImage: `${PREFIX}-treatmentImage`,
  paper: `${PREFIX}-paper`,
  selected: `${PREFIX}-selected`,
  button: `${PREFIX}-button`,
  heading: `${PREFIX}-heading`,
  divider: `${PREFIX}-divider`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.section}`]: {
    marginBottom: 50,
  },

  [`& .${classes.treatmentImage}`]: {
    width: '100%',
    margin: 'auto',
    padding: '10px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '10px',
    margin: '10px',
    '@media (max-width: 600px)': {
      padding: '5px',
      margin: '5px',
    },
  },

  [`& .${classes.selected}`]: {
    borderWidth: '3px',
    borderColor: '#2AAFBB',
    padding: '10px',
    margin: '10px',
    '@media (max-width: 600px)': {
      padding: '5px',
      margin: '5px',
    },
  },

  [`& .${classes.button}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
    textTransform: 'none',
  },

  [`& .${classes.heading}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.divider}`]: {
    marginBottom: '30px',
  },
}));

const IMAGE_MAPPING = {
  acne: AcneImage,
  ageing: AgeingImage,
  allergies: AllergiesImage,
  'cold sores': ColdSoresImage,
  'contraceptive pill': ContraceptivePillImage,
  'erectile dysfunction': ErectileDysfunctionImage,
  'hair loss': HairLossImage,
  herpes: HerpesImage,
  'medicinal cannabis': MedicinalCannabisImage,
  sleep: SleepImage,
  skincare: AgeingImage, // same image file as ageing
  nicotine: NicotineImage,
  'weight loss': WeightLossImage,
};

const TREATMENT_MAPPING = {
  Ageing: 'Anti-Ageing',
  // Add more mappings here as needed
};

// remove duplicates and sort the treatments array
const getCleanedAndSortedTreatments = (treatments) => [...new Set(treatments || [])].sort();

const getAlternativeText = (treatment, treatmentMappings = TREATMENT_MAPPING) => {
  const mappedTreatment = treatmentMappings[treatment] || treatment;
  return `${mappedTreatment} Treatment`;
};

const Treatment = ({ treatment, handleThumbClick, selected }) => {
  if (!treatment) {
    return null;
  }

  const header = capitalizeFirstLetterOfEachWord(treatment);
  const selectedImage = IMAGE_MAPPING[treatment];
  const alternativeText = getAlternativeText(header);

  const onClickHandler = () => {
    handleThumbClick(treatment);
  };

  return (
    <StyledGrid size={{ xs: 6, sm: 4 }}>
      <Button onClick={onClickHandler} className={classes.button}>
        <Paper className={selected === treatment ? classes.selected : classes.paper}>
          <Typography variant="body1" className={classes.heading} gutterBottom>
            {header}
          </Typography>
          <img src={selectedImage} className={classes.treatmentImage} alt={alternativeText} />
        </Paper>
      </Button>
    </StyledGrid>
  );
};

const TreatmentThumbnails = ({ thumbnailsToDisplay, heading, handleThumbClick, selected }) => {
  if (!Array.isArray(thumbnailsToDisplay) || !thumbnailsToDisplay?.length) {
    return null;
  }

  const cleanedAndSortedTreatments = getCleanedAndSortedTreatments(thumbnailsToDisplay);

  return (
    <StyledGrid container className={classes.section}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5" sx={{ textAlign: 'center' }} gutterBottom>
          {heading}
        </Typography>
        <br />
      </Grid>
      {cleanedAndSortedTreatments.map((treatment) => (
        <Treatment
          treatment={treatment}
          handleThumbClick={handleThumbClick}
          selected={selected || ''}
          classes={classes}
          key={treatment}
        />
      ))}
    </StyledGrid>
  );
};

Treatment.propTypes = {
  treatment: PropTypes.string.isRequired,
  handleThumbClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    section: PropTypes.string,
    treatmentImage: PropTypes.string,
    paper: PropTypes.string,
    selected: PropTypes.string,
    button: PropTypes.string,
    heading: PropTypes.string,
    divider: PropTypes.string,
  }).isRequired,
};

TreatmentThumbnails.propTypes = {
  thumbnailsToDisplay: PropTypes.arrayOf(PropTypes.string).isRequired,
  heading: PropTypes.string.isRequired,
  handleThumbClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

export default TreatmentThumbnails;
