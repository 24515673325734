import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Button, Box, Divider, Paper } from '@mui/material';

import GeneralForm from '../../../../form/standard-forms/general-form';

const PREFIX = 'HealthOverview';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  selectedPaper: `${PREFIX}-selectedPaper`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  notesButton: `${PREFIX}-notesButton`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.gridContainer}`]: {
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 30px 30px 30px',
    width: '90%',
    display: 'block',
    height: '90vh',
    overflow: 'auto',
    '@media (max-width: 600px)': {
      padding: '0px 20px 20px 20px',
      width: '100%',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.selectedPaper}`]: {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#303F56',
    color: 'white',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
  },

  [`& .${classes.backButton}`]: {
    display: 'inline',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.notesButton}`]: {
    display: 'inline',
    padding: 5,
    marginBottom: 10,
    float: 'right',
  },
}));

const HealthOverview = (props) => {
  const { handleModalClose, patientId } = props;

  return (
    <StyledGrid container className={classes.gridContainer}>
      <Paper className={classes.paper}>
        <Grid className={classes.backButtonGrid}>
          <Box className={classes.box}>
            <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
              Close
            </Button>
            {/* <Button className={classes.notesButton} variant="contained" color="primary" onClick={() => { handleModalOpen() }}>Make Note</Button> */}
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
          </Box>
        </Grid>
        <GeneralForm patient={patientId} />
      </Paper>
    </StyledGrid>
  );
};

export default HealthOverview;
