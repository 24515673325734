import React from 'react';
import { styled } from '@mui/material/styles';
import { Modal as MuiModal } from '@mui/material';

// NOTE: There needs to be a better patterm as we break
// multiple components on small iphone devices on edge cases.
const StyledMuiModal = styled(MuiModal)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1rem',
  overflow: 'hidden',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
});

/**
 * Styled modal component
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Flag indicating if the modal is open
 * @param {Object} [props.slotProps] - Slot props for the modal
 * @param {Object} [props.sx] - Styling props
 * @param {React.ReactElement} props.children - Modal content
 * @returns {JSX.Element} Styled modal component
 */
const StyledModal = ({ isOpen = false, slotProps = {}, children, sx, ...props }) => (
  <StyledMuiModal
    data-testid="default-styled-mui-modal"
    open={isOpen}
    slotProps={slotProps}
    closeAfterTransition
    sx={{
      overflow: 'scroll',
      height: '100vh',
      ...sx,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
  </StyledMuiModal>
);

export default StyledModal;
