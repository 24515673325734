import React, { useState, useRef, useEffect } from 'react';
import ConcessionCardsPresentation from './concession-cards-presentation';

const ConcessionCardsContainer = (props) => {
  const { generalPatientInfo, patientId, handleNext, formInputs, setFormInputs, isUpdateDetails } = props;
  const isMounted = useRef(false);

  const concessionCurrentFormObjects = [
    {
      headingText: 'Your Medicare Card Information',
      fieldText: [
        'concession cards.medicare card.firstName',
        'concession cards.medicare card.lastName',
        'concession cards.medicare card.number',
        'concession cards.medicare card.irn',
        'concession cards.medicare card.expiry',
        'concession cards.medicare card.using',
      ],
      elementType: 'medicare card',
      concessionCardType: 'medicare card',
    },
    {
      headingText: 'Your Health Care Card Information',
      fieldText: [
        'concession cards.health care card.firstName',
        'concession cards.health care card.lastName',
        'concession cards.health care card.address1',
        'concession cards.health care card.address2',
        'concession cards.health care card.crn',
        'concession cards.health care card.startDate',
        'concession cards.health care card.expiry',
        'concession cards.health care card.using',
      ],
      elementType: 'health care card',
      concessionCardType: 'health care card',
    },
    {
      headingText: 'Your Pensioner Concession Card Information',
      fieldText: [
        'concession cards.pensioner concession card.firstName',
        'concession cards.pensioner concession card.lastName',
        'concession cards.pensioner concession card.address1',
        'concession cards.pensioner concession card.address2',
        'concession cards.pensioner concession card.crn',
        'concession cards.pensioner concession card.startDate',
        'concession cards.pensioner concession card.expiry',
        'concession cards.pensioner concession card.using',
      ],
      elementType: 'pensioner concession card',
      concessionCardType: 'pensioner concession card',
    },
    {
      headingText: 'Your Commonwealth Senior Card Information',
      fieldText: [
        'concession cards.commonwealth senior health card.firstName',
        'concession cards.commonwealth senior health card.lastName',
        'concession cards.commonwealth senior health card.address1',
        'concession cards.commonwealth senior health card.address2',
        'concession cards.commonwealth senior health card.crn',
        'concession cards.commonwealth senior health card.startDate',
        'concession cards.commonwealth senior health card.expiry',
        'concession cards.commonwealth senior health card.using',
      ],
      elementType: 'commonwealth senior health card',
      concessionCardType: 'commonwealth senior health card',
    },
    {
      headingText: 'Your Veterans Card Information',
      fieldText: [
        'concession cards.veterans card.firstName',
        'concession cards.veterans card.lastName',
        'concession cards.veterans card.fileNumber',
        'concession cards.medicare card.using',
      ],
      elementType: 'veterans card',
      concessionCardType: 'veterans card',
    },
  ];

  useEffect(() => {
    isMounted.current = true;
    // initialize concession cards
    concessionCurrentFormObjects.forEach((currentFormObject) => {
      if (generalPatientInfo.concessionCards) {
        if (currentFormObject.concessionCardType === 'medicare card') {
          setFormInputs((fi) => ({
            ...fi,
            ...{
              [`concession cards.${currentFormObject.concessionCardType}.firstName`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.firstName || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.lastName`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.lastName || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.number`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.number || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.irn`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.irn || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.expiry`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.expiry || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.using`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.using || ''}`,
            },
          }));
        } else if (
          currentFormObject.concessionCardType === 'health care card' ||
          currentFormObject.concessionCardType === 'pensioner concession card' ||
          currentFormObject.concessionCardType === 'commonwealth senior health card'
        ) {
          setFormInputs((fi) => ({
            ...fi,
            ...{
              [`concession cards.${currentFormObject.concessionCardType}.firstName`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.firstName || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.lastName`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.lastName || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.address1`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.address1 || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.address2`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.address2 || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.crn`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.crn || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.startDate`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.startDate || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.expiry`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.expiry || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.using`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.using || ''}`,
            },
          }));
        } else if (currentFormObject.concessionCardType === 'veterans card') {
          setFormInputs((fi) => ({
            ...fi,
            ...{
              [`concession cards.${currentFormObject.concessionCardType}.firstName`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.firstName || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.lastName`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.lastName || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.fileNumber`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.fileNumber || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.expiry`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.expiry || ''}`,
              [`concession cards.${currentFormObject.concessionCardType}.using`]: `${generalPatientInfo.concessionCards[currentFormObject.concessionCardType]?.using || ''}`,
            },
          }));
        }
      }
    });
    return () => {
      isMounted.current = false;
    };
  }, [generalPatientInfo]);

  return (
    <ConcessionCardsPresentation
      isUpdateDetails
      patientId={patientId}
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      concessionCurrentFormObjects={concessionCurrentFormObjects}
      handleNext={handleNext}
    />
  );
};

export default ConcessionCardsContainer;
