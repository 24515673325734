import React from 'react';
import { Button, MenuItem } from '@mui/material';
import { Archive as ArchiveIcon } from '@mui/icons-material';
import { doc, writeBatch, deleteField, increment, getDoc } from 'firebase/firestore';
import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/react';

import { db } from '../../../../../firebase-config';
import { AuthContext } from '../../../../../auth-context';
import { SHIPMENT_TYPES, SUPPORT_ID } from '../../../../../utils/constants';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';
import { ModalContainer } from '../ModalContainer';

const { OUTSTANDING, ARCHIVED } = SHIPMENT_TYPES;

const ARCHIVE_ORDER_MESSAGES = {
  SUCCESS: 'Order archived successfully',
  FAILURE: 'Failure to archive order, please try again',
  PATIENT_DOES_NOT_EXIST: 'Failure to archive order. Patient does not exist',
};

/**
 * @param {Object} props
 * @param {Object} props.order
 * @param {string} props.pharmacyId
 * @param {string} props.userId
 * @param {(message: string) => void} props.snackbar
 * @param {() => void} props.handleClose
 */
const handleArchiveOrder = async ({ order, pharmacyId, userId, snackbar, handleClose }) => {
  const orderId = pharmacyId || SUPPORT_ID;

  const archiveOrderRef = doc(db, 'orders', orderId, ARCHIVED, order.payment);
  const outstandingOrderRef = doc(db, 'orders', orderId, OUTSTANDING, order.payment);
  const patientPurchaseHistoryRef = doc(db, 'patients', order.user, 'purchase_history', order.payment);
  const patientRef = doc(db, 'patients', order.user);

  const patientDoc = (await getDoc(patientRef)).data();

  if (!patientDoc) {
    sentryCaptureMessage(ARCHIVE_ORDER_MESSAGES.PATIENT_DOES_NOT_EXIST, {
      level: 'warning',
      extra: {
        pharmacyId,
        paymentReference: order.payment,
        patientId: order.user,
        issueIn: 'handleArchiveOrder',
      },
    });
    snackbar(ARCHIVE_ORDER_MESSAGES.PATIENT_DOES_NOT_EXIST);

    return;
  }

  // get the unique items of order scriptsArray priceRef
  const uniqueItemsArray = [...new Set(order.scriptsArray.map((item) => item.priceRef))];

  try {
    const batch = writeBatch(db);

    batch.set(archiveOrderRef, { ...order, status: ARCHIVED });
    batch.delete(outstandingOrderRef);
    batch.update(patientPurchaseHistoryRef, {
      ...order,
      status: ARCHIVED,
    });

    const updatePrescriptionObj = uniqueItemsArray.reduce((acc, priceRef) => {
      // Check if the prescription exists in the patient doc
      if (patientDoc.prescriptions?.[order.formName]?.[priceRef]) {
        acc[`prescriptions.${order.formName}.${priceRef}.dispensePending`] = false;
        acc[`prescriptions.${order.formName}.${priceRef}.lastPurchase`] = deleteField();
        acc[`prescriptions.${order.formName}.${priceRef}.remaining`] = increment(1);
      }

      return acc;
    }, {});

    if (Object.keys(updatePrescriptionObj).length > 0) {
      batch.update(patientRef, updatePrescriptionObj);
    }

    await batch.commit();

    snackbar(ARCHIVE_ORDER_MESSAGES.SUCCESS);
    handleClose();
  } catch (error) {
    sentryCaptureException(error, { extra: { order, userId, issueIn: 'archive order' } });
    snackbar(ARCHIVE_ORDER_MESSAGES.FAILURE);
  }
};

const ArchiveOrderModalContent = () => {
  const { user } = React.useContext(AuthContext);
  const { uid: userId } = user || { uid: undefined };
  const { pharmacy, snackbar } = usePharmacyContext();
  const { order, handleDialogue } = useOrderDetailsContext();
  const [isArchiveOrderLoading, setIsArchiveOrderLoading] = React.useState(false);

  const handleModalClose = () => {
    setIsArchiveOrderLoading(false);
    handleDialogue({ isOpen: false, content: null });
  };

  const archiveOrder = async () => {
    setIsArchiveOrderLoading(true);
    await handleArchiveOrder({
      order,
      userId,
      snackbar,
      pharmacyId: pharmacy.id,
      handleClose: handleModalClose,
    }).then(handleModalClose);
  };

  return (
    <ModalContainer
      text="Are you sure you want to archive this order?"
      onClose={handleModalClose}
      handleProceed={archiveOrder}
      loadingComponent={isArchiveOrderLoading}
    />
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal - The function to open the secondary modal.
 */
export const ArchiveOrder = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<ArchiveOrderModalContent />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<ArchiveIcon />}>
        Archive
      </Button>
    </MenuItem>
  );
};
