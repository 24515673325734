import { TEMPLATE_VARIABLES } from './constants';

const TEMPLATE_VARIABLES_KEYS = Object.keys(TEMPLATE_VARIABLES);

/**
 * This regex is used to match template variables in the response.
 *
 * It matches the pattern {{ variableName }} or
 * {{ variableName default='defaultValue' }} where variableName is one of the
 * keys in TEMPLATE_VARIABLES and defaultValue is the default value for that
 * variable.
 *
 * @returns {RegExp} The regex to match template variables.
 */
export const getTemplateVariablesRegex = () =>
  new RegExp(`{{\\s*(${TEMPLATE_VARIABLES_KEYS.join('|')})(?:\\s+default\\s+'([^']*)')?\\s*}}`, 'g');
