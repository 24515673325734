import { SHIPMENT_TYPES } from '../../../utils/constants';
import { isDirector, isPharmacist, isSupport } from '../../../utils/roles';

const { PICKUP_REQUIRED, SHIPMENT_REQUIRED } = SHIPMENT_TYPES;

/**
 * FIXME: This function is extremely hard to maintain and understand. It should be refactored.
 */
export const getShowTools = ({ shipment, userType, isHistorical }) => {
  const userIsDirector = isDirector(userType);
  const userIsPharmacist = isPharmacist(userType);
  const userIsSupport = isSupport(userType);
  const userIsSupportOrDirector = userIsSupport || userIsDirector;
  const userIsPharmacistOrDirector = userIsPharmacist || userIsDirector;

  const isPickupRequired = shipment.status === PICKUP_REQUIRED;
  const isShipmentRequired = shipment.status === SHIPMENT_REQUIRED;

  return {
    addNote: !isHistorical,
    archive: !isHistorical && userIsSupport,
    messagePatient: userIsPharmacist,
    redirectOrder: userIsSupportOrDirector && !isHistorical && (isShipmentRequired || isPickupRequired),
    refundOrder: !isHistorical && userIsPharmacist && !shipment.revertReason,
    returnToPharma: !isHistorical && (userIsDirector || userIsSupport) && shipment.revertReason,
    revertOrder: !isHistorical && userIsPharmacist && !shipment.revertReason,
    substituteItem: !isHistorical && userIsPharmacist && !shipment.revertReason,
    undoMarkComplete: isHistorical && userIsPharmacistOrDirector && !shipment.isRefunded && !shipment.revertReason,
  };
};
