import React from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { Button, Stack, Typography } from '@mui/material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../firebase-config';
import { SHIPMENT_TYPES, CANDOR } from '../../../utils/constants';
import { isPharmacist as getIsPharmacist } from '../../../utils/roles';
import { usePharmacyContext, useAuthContext } from '../hooks';

const { OUTSTANDING } = SHIPMENT_TYPES;

/**
 * @param {Object} props - The props object
 * @param {Order} props.order - The order object
 * @param {() => void} props.closeNotesModal - The close notes modal function
 */
export const OrderDetailsNotesModal = ({ order, closeNotesModal }) => {
  const {
    user: { uid: userId },
    userType,
  } = useAuthContext();

  const { snackbar } = usePharmacyContext();

  const isPharmacist = getIsPharmacist(userType);

  const { notes } = order;

  const handleAcknowledge = async () => {
    try {
      if (!Array.isArray(notes) || !notes?.length || !order.pharmacyInfo?.id) {
        throw new Error('Error Acknowledging Note');
      }

      const outstandingOrderRef = doc(db, 'orders', order.pharmacyInfo.id, OUTSTANDING, order.id);

      const date = Date.now();
      const newNotes = [...notes];
      newNotes[notes.length - 1].read = { ...newNotes[notes.length - 1].read, [userId]: date };

      updateDoc(outstandingOrderRef, { notes: newNotes });
      closeNotesModal();
    } catch (error) {
      sentryCaptureException(error, { extra: { order, issueIn: 'handleAcknowledge' } });
      snackbar('Error Acknowledging Note');
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">New Note From {isPharmacist ? CANDOR : order.pharmacyInfo.name}</Typography>
      {notes?.length && <Typography variant="body1">{notes?.[notes.length - 1].text}</Typography>}
      <Button onClick={handleAcknowledge}>Mark As Read</Button>
    </Stack>
  );
};
