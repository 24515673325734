import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import SlindaPillsImage from '../../../../media/SlindaPills.png';

const SlindaInformation = () => (
  <Grid>
    <Typography variant="h6">About Slinda</Typography>
    <br />
    <Typography variant="body1">
      Slinda is a Progesterone Only Contraceptive Pill (POP).
      <br />
      <br />
      Slinda contains a progesterone known as drospirenone. It is less likely to cause breakthrough bleeding than other
      progesterone only pills.
      <br />
      <br />
      Slinda contains 24 active (hormonal) pills and 4 inactive pills (shown in red).
    </Typography>
    <br />
    <img style={{ width: 310, maxWidth: '80%' }} src={SlindaPillsImage} alt="Slinda Pills" />
    <br />
    <br />
    <Typography variant="body1">When taken perfectly Slinda is 99% effective. Typical use is 95% effective.</Typography>
    <br />
    <Typography variant="h6">How Slinda works</Typography>
    <br />
    <Typography variant="body1">
      Slinda works as a contraceptive by:
      <br />
      - Changing the lining of your uterus to make it less favourable to implantation
      <br />
      - Thickening the cervical mucous to make it harder for sperm to get through
      <br />
      - Preventing ovulation
      <br />
      <br />
      Slinda does not prevent sexually transmitted infections.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Take Slinda at the same time everyday. It’s best to set a reminder until this becomes a habit as missing pills may
      result in an unwanted pregnancy. You can choose the best time for you to take the pill, but aim to be consistent.
      <br />
      <br />
      If you start Slinda on the first day of your period it will be effective immediately. If you start on day 2 or
      later you will need to take 7 days of active pills before you have contraceptive cover. You should use additional
      contraceptives or avoid intercourse during this time. Start with active pills and follow the directions on the
      packet.
      <br />
      <br />
      When you get to the inactive pills take them as you would the active ones and you should experience a withdrawal
      bleed, similar to a period (talk to your Candor GP about skipping this if you would like).
    </Typography>
    <br />
    <Typography variant="h6">What if I miss a pill?</Typography>
    <br />
    <Typography variant="body1">
      If you are less than 24 hours late taking an active pill: Take it as soon as you remember and take the next pill
      at the usual time; contraception will not be affected.
      <br />
      <br />
      If you are more than 24 hours late taking an active pill or you miss more than one:
      <br />
      - Take the missed pill straight away and further pills as usual
      <br />
      - Use condoms for the next 7 days
      <br />
      - Emergency contraception is recommended if you’ve had unprotected sex
      <br />
      <br />
      If you find you’re missing a lot of pills it may be best to consider a different form of contraception. Talk to
      your Candor GP or your regular GP about options.
    </Typography>
    <br />
    <Typography variant="h6">What if I vomit after taking the pill?</Typography>
    <br />
    <Typography variant="body1">
      If you vomit within 2 hours of taking an active pill: take another active pill as soon as possible.
      <br />
      <br />
      If you have severe vomiting or diarrhoea for 24 hours or more: follow the advice as above for missed pills.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Slinda is a commonly used medication and side effects are generally mild. However it is important to be aware of
      any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Breakthrough bleeding
      <br />
      - Menstrual irregularity
      <br />
      - Amenorrhea (absence of periods)
      <br />
      - Breast enlargement or tenderness
      <br />
      - Depression
      <br />
      - Acne
      <br />
      <br />
      Infrequent (0.1 to 1%):
      <br />
      - Nausea
      <br />
      - Vomiting
      <br />
      - Headache
      <br />
      - Dizziness
      <br />
      - Lethargy
      <br />
      <br />
      Rare (less than 0.1%):
      <br />
      - Allergic reaction
      <br />
      - Decreased libido
      <br />
      - Cholestatic jaundice
      <br />
      - Androgenic effects eg. hirsutism, greasy hair
      <br />
      <br />
      Menstrual irregularity:
      <br />
      Commonly progesterone only contraceptives can cause breakthrough or irregular bleeding. This is less likely with
      Slinda but may still occur. If this is inconvenient to you please discuss with your Candor GP about options.
      <br />
      <br />
      Please note further detailed information on Slinda including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Slinda when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. If you discover you are pregnant while taking Slinda it is very unlikely to do harm, however we suggest
      stopping it immediately.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Slinda when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      Progesterone only contraceptives can be used from 6 weeks and are unlikely to affect breastmilk supply.
    </Typography>
  </Grid>
);

export default SlindaInformation;
