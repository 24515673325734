import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const LignocaineInformation = () => (
  <Grid>
    <Typography variant="h6">About Lignocaine</Typography>
    <br />
    <Typography variant="body1">
      Lignocaine is a topical anaesthetic that can be used to decrease the pain and discomfort of genital herpes. This
      can be particularly useful for a first outbreak.
      <br />
      <br />
      Lignocaine does not treat herpes itself, but can improve symptoms so that it can be tolerated.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Lignocaine gel should be applied in a thin layer to genital lesions. Initially you may feel a tingling or
      increased discomfort but this should settle within a couple of minutes. The numbing effect may last up to 4 hours.
      <br />
      <br />
      Lignocaine can be reapplied as needed. We recommend using the lowest effective dose for the shortest amount of
      time.
      <br />
      <br />
      Make sure you wash your hands thoroughly after use.
      <br />
      <br />
      Do not use internally.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Lignocaine is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Application site reactions
      <br />
      - Erythema, itch, burning sensation
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Allergic reaction
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Lignocaine including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use topical Lignocaine when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Yes but we recommend avoidance in the first trimester. Please discuss with your Candor or regular GP prior to use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use topical Lignocaine when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default LignocaineInformation;
