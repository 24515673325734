import React from 'react';
import { Chip, Tooltip } from '@mui/material';

import { TEMPLATE_VARIABLES } from './constants';

/**
 * @param {Object} props
 * @param {string} props.variable - The variable to display in the chip
 * @param {string | null | undefined } props.defaultValue - The default value to display in the chip
 */
export const TemplateChip = ({ variable, defaultValue }) => {
  const templateInfo = TEMPLATE_VARIABLES[variable];

  return (
    <Tooltip title={templateInfo.description || ''}>
      <Chip
        label={defaultValue ? `${templateInfo.label} (default: ${defaultValue})` : templateInfo.label}
        size="small"
        color="primary"
        component="span"
        sx={{
          height: '24px',
          '& .MuiChip-label': {
            fontSize: '0.8rem',
          },
        }}
      />
    </Tooltip>
  );
};
