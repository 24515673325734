import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Typography, Button, CircularProgress, Modal } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PREFIX = 'ModalCard';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  gridItem: `${PREFIX}-gridItem`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
};

const StyledModal = styled('div')(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },

  [`& .${classes.cancelButton}`]: {
    margin: '-35px -45px',
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
    height: 20,
  },

  [`& .${classes.buttonContainer}`]: {
    margin: '20px 0px 20px 0px',
    width: '100%',
    padding: '0px 10px',
  },

  [`& .${classes.button}`]: {
    width: '100%',
    height: '100%',
  },
}));

const ModalCardOptions = (props) => {
  const { text, subtext, handleModalClose, handleModalProceed, loadingComponent } = props;

  return (
    <StyledModal>
      <Grid container className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid className={classes.cancelButtonHolder}>
            <Button className={classes.cancelButton} onClick={handleModalClose} disabled={!!loadingComponent}>
              <CancelRoundedIcon color="primary" />
            </Button>
          </Grid>
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid size={{ xs: 12}} className={classes.gridItem}>
              <Typography variant="h5" align="center" gutterBottom>
                {text}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12}}>
              <Typography variant="body2" align="center" gutterBottom>
                {subtext}
              </Typography>
            </Grid>
            {loadingComponent && (
              <Grid size={{ xs: 12}} className={classes.gridItem}>
                <CircularProgress />
              </Grid>
            )}
            {!loadingComponent && (
              <Grid container spacing={2} className={classes.buttonContainer}>
                <Grid size={{ xs: 6 }}>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={handleModalProceed}>
                    OK
                  </Button>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={handleModalClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </StyledModal>
  );
};

export default ModalCardOptions;

// TODO: refactor all the the components with ModalCardOptions that is a modal in the entire codebase
export const ModalCard = ({ text, subtext, isOpen, handleClose, handleProceed, loadingComponent }) => (
  <Modal open={isOpen}>
    <>
      <ModalCardOptions
        text={text}
        subtext={subtext}
        handleModalClose={handleClose}
        handleModalProceed={handleProceed}
        loadingComponent={loadingComponent}
      />
    </>
  </Modal>
);
