import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const ConcentratedExtractInfo = () => (
  <Grid>
    <Typography variant="h6">Using extracts and concentrates</Typography>
    <br />
    <Typography variant="body1">
      Hash is an extract created when dry flower is sifted and screened through a fine micron mesh, then pressed. The
      outcome is a refined, pliable cannabis concentrate characterised by significantly reduced levels of chlorophyll,
      cellulose, and other non-active plant materials.
      <br />
      <br />
      Shatter is a medicinal cannabis concentrate created when a closed-loop hydrocarbon extraction process is employed
      to carefully extract trichomes from flower. These trichomes are then purified. The result is a vibrant concentrate
      that retains color and flavours.
      <br />
      <br />
      Diamonds are a medicinal cannabis concentrate created when a hydrocarbon extraction process is followed by a
      natural precipitation of crystalline diamonds. Time is the only factor required for the formation of the diamond
      structures.
      <br />
      <br />
      To effectively consume cannabis concentrate products, a vaporization device that is specifically designed for
      concentrates should be used. This provides slightly higher temperatures than usual vaporisers (175°C - 200°C) and
      a distinct bowl layout.
      <br />
      <br />
      Alternatively, concentrates can be “sandwiched” with an existing flower medication to increase the overall
      concentration.
      <br />
      <br />
      Titration is important, especially for new patients. Dosing schedules differ for each patient and should be
      discussed with your doctor. Doses should be increased gradually over time and the effect fully assessed before
      continuing to increase. Note that consuming too much cannabis can result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default ConcentratedExtractInfo;
