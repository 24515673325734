// TODO: FIXME: This is a duplicate of the canned notes component, refactor to use the same component
// NOTE: Also needs a major refactor and clean up as a lot of poor quality code and bad practices are present
// Some work has been started on this. https://trello.com/c/0r4U6cgi/1737-refactor-merge-canned-notes-responses-files-components
import React from 'react';
import { styled } from '@mui/material/styles';
import { getDocs, setDoc, doc, collection, deleteDoc, updateDoc } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Typography, TextField, Button, Modal, Box, Divider, Chip } from '@mui/material';

import { db } from '../../../../firebase-config';
import { AuthContext } from '../../../../auth-context';
import { TemplateChip } from './TemplateChip';
import { TEMPLATE_VARIABLES } from './constants';
import { parseTemplateSegments } from './parseTemplateSegments';

/**
 * @typedef {Object} CannedResponse
 * @property {string | undefined} key - The key of the canned response
 * @property {string | undefined} title - The title of the canned response
 * @property {string | undefined} response - The response of the canned response
 */

const PREFIX = 'CannedResponses';

const classes = {
  paper: `${PREFIX}-paper`,
  paperHolder: `${PREFIX}-paperHolder`,
  grid: `${PREFIX}-grid`,
  modalContainer: `${PREFIX}-modalContainer`,
  modalPaper: `${PREFIX}-modalPaper`,
  formPaper: `${PREFIX}-formPaper`,
  box: `${PREFIX}-box`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
    margin: '10px 10px 0 10px',
    padding: '30px',
    width: '100%',
  },

  [`& .${classes.paperHolder}`]: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    height: '100%',
    marginBottom: '40px',
  },

  [`& .${classes.grid}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.modalContainer}`]: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' },

  [`& .${classes.modalPaper}`]: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '32rem',
    height: '32rem',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.formPaper}`]: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '32rem',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    height: '100%',
  },
}));

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  flexDirextion: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',

  '@media (max-width: 600px)': {
    padding: '1rem',
  },

  [`& .${classes.formPaper}`]: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '32rem',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.modalPaper}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1rem 1rem 1rem',
    height: '100%',
    width: '100%',
    maxWidth: '64rem',
    maxHeight: '64rem',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },
}));

/**
 * @param {Object} props
 * @param {(templateVariableKey: keyof typeof TEMPLATE_VARIABLES) => void} props.handleClickVariable - The function to handle the click of a variable
 */
const AvailableVariables = ({ handleClickVariable }) => (
  <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
    <Typography variant="caption" sx={{ width: '100%' }}>
      Available Variables:
    </Typography>

    {Object.entries(TEMPLATE_VARIABLES).map(([key, info]) => (
      <Chip
        key={key}
        label={info.label}
        size="small"
        variant="outlined"
        title={`{{${key}}} - ${info.description}`}
        onClick={() => handleClickVariable(/** @type {keyof typeof TEMPLATE_VARIABLES} */ (key))}
      />
    ))}
  </Box>
);

export const CannedResponses = () => {
  const [open, setOpen] = React.useState(false);
  const [cannedResponse, setCannedResponse] = React.useState(/** @type {Partial<CannedResponse>} */ ({}));
  const [savedResponses, setSavedResponses] = React.useState(/** @type {Array<CannedResponse>} */ ([]));
  const [showForm, setShowForm] = React.useState(false);
  const [action, setAction] = React.useState('add');
  const [draggedItemIndex, setDraggedItemIndex] = React.useState(-1);
  const { user } = React.useContext(AuthContext);
  const textFieldRef = React.useRef(/** @type {HTMLDivElement | null} */ (null));

  const updateSavedResponse = (response) => {
    updateDoc(doc(db, 'patients', user?.uid, 'cannedResponses', response.key), {
      title: cannedResponse.title,
      response: cannedResponse.response,
    }).then(() => {
      const update = savedResponses.map((res) =>
        res.key === response.key ? { ...res, title: cannedResponse.title, response: cannedResponse.response } : res,
      );
      setSavedResponses(update);
    });
  };

  React.useEffect(() => {
    const getSavedResponses = async () => {
      const querySnapshot = await getDocs(collection(db, 'patients', user?.uid, 'cannedResponses'));
      const result = querySnapshot.docs.map((responseDoc) => ({ ...responseDoc.data(), id: responseDoc.id }));
      const order = result.sort((a, b) => a.order - b.order);
      setSavedResponses(order);
    };
    getSavedResponses();
  }, [user]);

  const addToSavedResponses = () => {
    if (cannedResponse.title && cannedResponse.response) {
      if (action === 'add') {
        if (cannedResponse.response.trim() === '' || cannedResponse.title.trim() === '') return;
        // FIXME: WARNING: STOP - NEVER DO THIS! Don't create a Document ID from a Date.now(), let
        // firestore create a unique ID for you. There are too many issues to note here, on why this is bad.
        const key = Date.now().toString();
        const response = { key, response: cannedResponse.response, title: cannedResponse.title };
        setDoc(doc(db, 'patients', user.uid, 'cannedResponses', key), response);
        setSavedResponses([...savedResponses, response]);
      } else {
        updateSavedResponse(cannedResponse);
      }
      setCannedResponse({});
      setShowForm(false);
    }
  };

  const deleteSavedResponse = (response) => {
    deleteDoc(doc(db, 'patients', user?.uid, 'cannedResponses', response.key)).then(() => {
      setSavedResponses(savedResponses.filter((res) => res.key !== response.key));
    });
  };

  const handleUpdate = (res) => {
    setCannedResponse(res);
    setShowForm(true);
    setAction('update');
  };

  const handleAdd = () => {
    setShowForm(true);
    setAction('add');
  };

  const handleCloseForm = () => {
    setCannedResponse({});
    setShowForm(false);
  };

  const handleDragStart = (e, response, index) => {
    e.dataTransfer.setData('text/plain', index);
    setCannedResponse(response);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    const itemIndex = savedResponses.findIndex((item) => item.key === cannedResponse.key);
    const draggedOverItemIndex = index;
    const newArray = [...savedResponses];
    const temp = newArray[itemIndex];
    newArray[itemIndex] = newArray[draggedOverItemIndex];
    newArray[draggedOverItemIndex] = temp;
    setSavedResponses(newArray);
    setDraggedItemIndex(draggedOverItemIndex);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDraggedItemIndex(-1);
    setCannedResponse({});
    savedResponses.forEach(async (responseDoc, idx) => {
      await updateDoc(doc(db, 'patients', user.uid, 'cannedResponses', responseDoc.key), {
        order: idx,
      });
    });
  };

  /**
   * @param {keyof typeof TEMPLATE_VARIABLES} variable - The variable to handle the click of
   */
  const handleClickVariable = (variable) => {
    const template = `{{${variable}}}`;

    if (!textFieldRef.current) {
      setCannedResponse((prev) => ({
        ...prev,
        response: prev.response ? `${prev.response}${template}` : template,
      }));

      return;
    }

    const input = textFieldRef.current.querySelector('textarea');

    if (!input) {
      setCannedResponse((prev) => ({
        ...prev,
        response: prev.response ? `${prev.response}${template}` : template,
      }));

      return;
    }

    const start = input.selectionStart;
    const end = input.selectionEnd;
    const currentText = cannedResponse.response ?? '';
    const newText = currentText.substring(0, start) + template + currentText.substring(end);

    setCannedResponse((prev) => ({
      ...prev,
      response: newText,
    }));

    setTimeout(() => {
      input.focus();
      input.setSelectionRange(start + template.length, start + template.length);
    }, 0);
  };

  return (
    <StyledGrid size={{ xs: 12, md: 6 }} sx={{ marginBottom: '40px' }}>
      <Grid className={classes.paperHolder}>
        <Paper className={classes.paper}>
          <Box className={classes.box}>
            <Typography variant="h5" align="center" gutterBottom>
              Canned Responses
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Create automatic responses to send to patients
            </Typography>
            <Button variant="contained" sx={{ mt: '1rem' }} onClick={() => setOpen(true)}>
              Manage Canned Responses
            </Button>
            <StyledModal data-testid="canned-responses-modal" open={showForm}>
              <Paper className={classes.formPaper}>
                <Button
                  variant="contained"
                  sx={{ alignSelf: 'start', mb: 2, height: '100%' }}
                  onClick={handleCloseForm}
                >
                  Close
                </Button>
                <Box display="flex" flexDirection="column" gap="8px">
                  <TextField
                    value={cannedResponse.title}
                    onChange={(e) => setCannedResponse({ ...cannedResponse, title: e.target.value })}
                    label="Title"
                    variant="outlined"
                    sx={{ flex: 1 }}
                  />
                  <TextField
                    ref={textFieldRef}
                    value={cannedResponse.response ?? ''}
                    onChange={(e) => setCannedResponse({ ...cannedResponse, response: e.target.value })}
                    label="Canned Response"
                    variant="outlined"
                    multiline
                    rows={4}
                    sx={{ flex: 1 }}
                  />

                  <AvailableVariables handleClickVariable={handleClickVariable} />

                  <Button variant="contained" onClick={addToSavedResponses}>
                    {action === 'add' ? 'Add' : 'Update'}
                  </Button>
                </Box>
              </Paper>
            </StyledModal>
            <StyledModal data-testid="canned-responses-drag-and-drop-modal" open={open} onClose={() => setOpen(false)}>
              <Paper className={classes.modalPaper}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    height: '6rem',
                    padding: '1.5rem 0',
                    position: 'sticky',
                    top: '0',
                    zIndex: 1,
                    backgroundColor: '#fff',
                  }}
                >
                  <Button variant="contained" onClick={() => setOpen(false)}>
                    Close
                  </Button>
                  <Typography textAlign="center" fontWeight="bold" fontSize="1.5rem">
                    Drag & Drop To Reorder
                  </Typography>
                  <Button variant="contained" onClick={handleAdd}>
                    Add
                  </Button>
                </Box>

                {savedResponses.map((response, idx) => (
                  <div
                    draggable
                    key={response.key}
                    onDragStart={(e) => handleDragStart(e, response, idx)}
                    onDragOver={(e) => handleDragOver(e, idx)}
                    onDrop={handleDrop}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: 2,
                      backgroundColor: draggedItemIndex === idx ? '#f0f0f0' : 'white',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Divider
                      sx={{
                        opacity: '0.6',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        alignItems: 'start',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ maxWidth: '100%' }}>
                        <Typography sx={{ p: 1 }} fontWeight="bold" color="primary">
                          {response.title}
                        </Typography>
                        <Typography
                          sx={{
                            p: 3,
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                            gap: '4px',
                            width: '100%',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            minWidth: '0',
                          }}
                        >
                          {parseTemplateSegments(response.response).map((segment) =>
                            segment.type === 'text' ? (
                              <span key={segment.key}>{segment.content}</span>
                            ) : (
                              <TemplateChip
                                key={segment.key}
                                variable={segment.content}
                                defaultValue={segment.defaultValue}
                              />
                            ),
                          )}
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" height="100%" justifyContent="center" gap={1}>
                        <Button variant="outlined" color="primary" onClick={() => handleUpdate(response)}>
                          Update
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => deleteSavedResponse(response)}>
                          Delete
                        </Button>
                      </Box>
                    </div>
                  </div>
                ))}
              </Paper>
            </StyledModal>
          </Box>
        </Paper>
      </Grid>
    </StyledGrid>
  );
};
