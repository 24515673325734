import { styled } from '@mui/material/styles';
import React, { useState, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { SHIPMENT_TYPES } from '../../../../../utils/constants';
import { useOrders } from '../../../hooks/useOrders';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import TrackingItems from './TrackingItems';
import TrackingSummaryModal from './TrackingSummaryModal';
import { sortedShipmentItems, getSummarizedItems, getTrackingCode } from './helpers';

const PREFIX = 'MassAddTrackingModal';

const classes = {
  box: `${PREFIX}-box`,
  saveAllButton: `${PREFIX}-saveAllButton`,
  modalPaper: `${PREFIX}-modalPaper`,
  headerContainer: `${PREFIX}-headerContainer`,
  headerText: `${PREFIX}-headerText`,
};

const RootPaper = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '32rem',
  overflow: 'hidden',
  maxHeight: 'fill-available',

  [`& .${classes.box}`]: {
    maxHeight: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.headerContainer}`]: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '32rem',
    marginBottom: '1rem',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },

  [`& .${classes.headerText}`]: {
    margin: '0 auto',
    flexGrow: 1,
    textAlign: 'center',
  },

  [`& .${classes.saveAllButton}`]: {
    right: 0,
    position: 'absolute',
    top: 0,
    '@media (max-width: 600px)': {
      position: 'relative',
      right: 'auto',
    },
  },
}));

export const MassAddTrackingModal = () => {
  const { pharmacy, catalogue } = usePharmacyContext();
  const [trackingList, setTrackingList] = useState({ isButtonDisabled: true });
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

  const { orders } = useOrders({
    pharmacyId: pharmacy.id,
    isHistorical: false,
  });

  const shipmentRequiredOrders = useMemo(
    () => orders.filter((o) => o.status === SHIPMENT_TYPES.SHIPMENT_REQUIRED),
    [orders],
  );

  const summarizedItems = useMemo(
    () => getSummarizedItems({ shipmentRequiredOrders, catalogue }),
    [shipmentRequiredOrders, catalogue],
  );

  const handleUpdateTracking = ({ shipment, newTracking }) => {
    const { payment: orderId } = shipment || {};
    const tracking = getTrackingCode(shipment);
    // if the tracking code is the same as the original tracking code, remove it from the trackingList
    if (newTracking === tracking) {
      setTrackingList(({ [orderId]: _, isButtonDisabled: __, ...rest }) => ({
        ...rest,
        isButtonDisabled: !Object.keys(rest).length,
      }));
      return;
    }
    // otherwise, update the trackingList
    setTrackingList((prev) => ({ ...prev, [orderId]: { ...shipment, newTracking }, isButtonDisabled: false }));
  };

  return (
    <RootPaper>
      <div className={classes.headerContainer}>
        <Typography variant="h6" color="primary" className={classes.headerText}>
          Bulk Add Tracking
        </Typography>
        <Button
          className={classes.saveAllButton}
          disabled={trackingList.isButtonDisabled}
          onClick={() => setIsSummaryModalOpen(true)}
        >
          Save All
        </Button>
      </div>
      <Box className={classes.box}>
        {sortedShipmentItems(shipmentRequiredOrders).map((shipment) => (
          <TrackingItems
            key={shipment.updated}
            shipment={shipment}
            handleUpdateTracking={handleUpdateTracking}
            summarizedScript={summarizedItems[shipment?.payment] || {}}
            pharmacyData={pharmacy}
          />
        ))}
      </Box>
      <TrackingSummaryModal
        trackingList={trackingList}
        setTrackingList={setTrackingList}
        isSummaryModalOpen={isSummaryModalOpen}
        setIsSummaryModalOpen={setIsSummaryModalOpen}
        summarizedItems={summarizedItems}
        pharmacyData={pharmacy}
      />
    </RootPaper>
  );
};
