import React from 'react';
import { styled } from '@mui/material/styles';
import { doc, collection, writeBatch } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Container, Typography, Button } from '@mui/material';

import { db } from '../../../../../firebase-config';
import createEmailObj from '../../../../../functions/create-email-obj';
import createPatientData from '../../../../../functions/create-patient-data';

const PREFIX = 'GenerateAccountData';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const GenerateAccountData = ({ handleModalClose, handleModalOpen, patientId, generalPatientInfo }) => {
  const handleGenerateAccountData = async () => {
    try {
      handleModalOpen('loading');
      await createPatientData({ userId: patientId, userEmail: generalPatientInfo.email });

      const batch = writeBatch(db);

      const mailRef = doc(collection(db, 'mail'));
      batch.set(mailRef, createEmailObj(generalPatientInfo.email, 'account_error_resolved'));

      await batch.commit();

      handleModalOpen('Result: The account data was generated.');
    } catch (error) {
      // TODO: REPORT TO SENTRY
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid>
            <div>
              <Typography variant="h5" align="center" gutterBottom>
                Generate Account Data
              </Typography>
            </div>
            <br />
            <div>
              <Typography variant="body1" textAlign="center" gutterBottom>
                Manually sets up the account if the automatic generation fails.
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleGenerateAccountData}
              >
                Generate
              </Button>
            </div>
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default GenerateAccountData;
