import React from 'react';
import { Check, ContentCopy, OpenInNew } from '@mui/icons-material';
import { IconButton, Stack, TextField, Typography } from '@mui/material';

const COPY_ID_TIMEOUT_MS = 2000;
const { VITE_APP_TILL_PAYMENTS_PORTAL_URL } = import.meta.env;

/**
 * Generates a URL for a dispute in the Till Payments Portal
 * @param {string} disputeId - The dispute ID
 * @param {string} [defaultText] - The default text to display if the dispute ID is not provided
 * @returns {string} The URL for the dispute
 */
const generateURL = (disputeId, defaultText) =>
  `${VITE_APP_TILL_PAYMENTS_PORTAL_URL}/disputes/${disputeId || defaultText}`;
/**
 * @typedef {Object} ChargebackDisputeIdInputProps
 * @property {string} chargebackDisputeId - The chargeback dispute ID
 * @property {function} handleChargebackDisputeIdChange - The function to handle the chargeback dispute ID change
 */

/**
 * @param {ChargebackDisputeIdInputProps} props
 * @returns {JSX.Element}
 */
export const ChargebackDisputeIdInput = ({ chargebackDisputeId, handleChargebackDisputeIdChange }) => {
  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsCopied(false);
    }, COPY_ID_TIMEOUT_MS);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  const handleCopyChargebackDisputeId = () => {
    if (chargebackDisputeId) {
      navigator.clipboard.writeText(chargebackDisputeId);
      setIsCopied(true);
    }
  };

  const handleOpenInNewTab = () => {
    if (!chargebackDisputeId) {
      return;
    }

    window.open(generateURL(chargebackDisputeId), '_blank');
  };

  const url = generateURL(chargebackDisputeId, '{chargeback-dispute-id}');

  return (
    <Stack direction="column" spacing={1} alignItems="flex-start" width="100%">
      <TextField
        fullWidth
        value={chargebackDisputeId}
        onChange={(e) => handleChargebackDisputeIdChange(e.target.value)}
        label="Chargeback Dispute ID"
        variant="outlined"
        placeholder="E.g. 20240913-b49b3c1161904659aa8f"
      />
      <Stack direction="row" spacing={1} alignItems="center" width="100%" justifyContent="space-between">
        <Typography
          variant="caption"
          sx={{ flexGrow: 1, maxWidth: '90%', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {url}
        </Typography>

        {chargebackDisputeId && (
          <>
            <IconButton size="small" onClick={handleCopyChargebackDisputeId}>
              {isCopied ? <Check sx={{ fontSize: '10px' }} /> : <ContentCopy sx={{ fontSize: '10px' }} />}
            </IconButton>

            <IconButton size="small" onClick={handleOpenInNewTab}>
              <OpenInNew sx={{ fontSize: '10px' }} />
            </IconButton>
          </>
        )}
      </Stack>
    </Stack>
  );
};
