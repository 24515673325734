// Split each name into an array of words. For example, if the name is "John Doe", the array will be ["John", "Doe"].
const splitNames = (names) => names.map((name) => (name || '').split(' '));

// Flatten the splitted names into a single array. For example, if the splitted names are [['Mary', 'Jane'], ['John', 'Doe'], ['Al', 'Jerome']], the flattened names will be ['Mary', 'Jane', 'John', 'Doe', 'Al', 'Jerome'].
const flattenArrays = (arrayOfArrays) => [].concat(...arrayOfArrays);

// Generate uppercase prefixes of a word. For example, if the word is "John", the prefixes are "J", "JO", "JOH", and "JOHN".
const generatePrefixes = (word) => word.split('').map((_, index) => word.substring(0, index + 1));

// Tokenize a list of names into a list of uppercase tokens.
export const tokenizeNames = (...names) => {
  const splittedNames = splitNames(names);

  const flattenedNames = flattenArrays(splittedNames);

  const normalizedNames = flattenedNames.map((name) => name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));

  // Generate uppercase prefixes for each name.
  const tokenizedNames = [...flattenedNames, ...normalizedNames].map((name) => generatePrefixes(name.toUpperCase()));

  // Deduplicate and flatten the tokenized names.
  const result = [...new Set(flattenArrays(tokenizedNames))];
  return result;
};

export const tokenizeSearchInput = (...input) => {
  const splittedNames = splitNames(input);
  return [...new Set(flattenArrays(splittedNames))].map((name) => name.toUpperCase());
};
