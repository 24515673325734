import React from 'react';
import { createParser, parseAsArrayOf, parseAsString, parseAsStringLiteral, useQueryStates } from 'nuqs';

import { CURRENT_ORDERS_CURRENT_STATUS_FILTERS, CURRENT_ORDERS_FILTERS } from '../constants';

/**
 * Validate a simple date string
 * @param {Date} date
 * @returns {boolean}
 */
const isValidDate = (date) => date instanceof Date && !Number.isNaN(date.getTime());

const parseAsOptionalDate = createParser({
  /**
   * Parse a date string from the query params
   * @param {string | undefined} queryValue
   */
  parse(queryValue) {
    const date = queryValue ? new Date(queryValue) : '';
    return date && isValidDate(date) ? date.getTime() : null;
  },
  serialize(value) {
    return value ? value.toString() : '';
  },
});

/**
 * @param {Object} props
 * @param {"current" | "historic"} props.orderType - The order type.
 */
export const useOrderFilters = ({ orderType }) => {
  const keyMap = React.useMemo(() => {
    const today = new Date();
    const DEFAULT_FROM_DATE = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).getTime();
    const DEFAULT_TO_DATE = new Date().getTime();

    const isHistoric = orderType === 'historic';

    return {
      search: parseAsString.withDefault(''),
      sortBy: parseAsStringLiteral(['newest', 'oldest', 'name', 'status', 'noi'])
        .withOptions({ clearOnDefault: false })
        .withDefault('newest'),
      ...(!isHistoric && {
        status: parseAsStringLiteral(CURRENT_ORDERS_FILTERS).withOptions({ clearOnDefault: false }).withDefault(''),
      }),
      ...(!isHistoric && {
        currentStatus: parseAsArrayOf(parseAsStringLiteral(CURRENT_ORDERS_CURRENT_STATUS_FILTERS)).withDefault([]),
      }),
      ...(isHistoric && {
        fromDate: parseAsOptionalDate.withOptions({ clearOnDefault: false }).withDefault(DEFAULT_FROM_DATE),
      }),
      ...(isHistoric && {
        toDate: parseAsOptionalDate.withOptions({ clearOnDefault: false }).withDefault(DEFAULT_TO_DATE),
      }),
    };
  }, [orderType]);

  return useQueryStates(keyMap);
};
