import React from 'react';

import { PharmacyContext } from '../contexts/PharmacyContext';

/**
 * @param {Object} props
 * @param {boolean} [props.enabled] - Whether the context should be enabled
 * @returns {Omit<import('../contexts/PharmacyContext').PharmacyContextValue, 'pharmacy'> & { pharmacy: NonNullable<import('../contexts/PharmacyContext').PharmacyContextValue['pharmacy']> }}
 */
export const usePharmacyContext = ({ enabled = true } = {}) => {
  const context = React.useContext(PharmacyContext);

  if (!enabled) {
    return {
      pharmacy: null,
      loading: false,
      error: null,
      refreshPharmacy: () => {},
    };
  }

  if (!context) {
    throw new Error('usePharmacyContext must be used within a PharmacyProvider');
  }

  if (!context.pharmacy) {
    throw new Error('Pharmacy is not set');
  }

  return {
    ...context,
    pharmacy: context.pharmacy,
  };
};
