import PropTypes from 'prop-types';

const BUTTON_TEXT = {
  UPDATE: 'Save Changes',
  LOADING: 'Loading...',
  DEFAULT: 'Next',
};

export const getFormsButtonText = ({ isUpdateDetails = false, isLoading = false }) => {
  if (isUpdateDetails) return BUTTON_TEXT.UPDATE;
  if (isLoading) return BUTTON_TEXT.LOADING;
  return BUTTON_TEXT.DEFAULT;
};

getFormsButtonText.propTypes = {
  isUpdateDetails: PropTypes.bool,
  isLoading: PropTypes.bool,
};
