import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';
import PopPillsImage from '../../../../media/PopPills.png';

const ProgesteroneOnlyContraceptiveInformation = () => (
  <Grid>
    <Typography variant="h6">About the Progesterone Only Contraceptive Pill (POP)</Typography>
    <br />
    <Typography variant="body1">
      The POP contains progesterone. There are a number of different formulations of progesterone and this will vary
      depending which pill you are prescribed. This information can be found with your prescription.
      <br />
      <br />
      The following information relates to the majority of POP’s. Newer pills including Slinda may have different
      information. Your Candor GP will discuss this with you if appropriate.
      <br />
      <br />
      The POP contains active (hormonal) pills and may look similar to the packet shown in the diagram. You take the
      active pills every day without a break.
    </Typography>
    <br />
    <img style={{ width: 310, maxWidth: '80%' }} src={PopPillsImage} alt="POP Pills" />
    <br />
    <br />
    <Typography variant="body1">
      When taken perfectly the POP is 99.7% effective. Typical use is 91% effective.
    </Typography>
    <br />
    <Typography variant="h6">How the POP works</Typography>
    <br />
    <Typography variant="body1">
      When taken perfectly the POP is 99.7% effective. Typical use is 91% effective.
    </Typography>
    <br />
    <Typography variant="h6">How the POP works</Typography>
    <br />
    <Typography variant="body1">
      The POP works as a contraceptive by:
      <br />
      - Changing the lining of your uterus to make it less favourable to implantation
      <br />
      - Thickening the cervical mucous to make it harder for sperm to get through
      <br />
      - Possibly preventing ovulation
      <br />
      <br />
      The POP does not prevent sexually transmitted infections.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Take your POP at the same time everyday. It’s best to set a reminder until this becomes a habit as missing pills
      may result in an unwanted pregnancy. You can choose the best time for you to take the pill, but aim to be
      consistent. There is a 3 hour window that you must take the POP in for it to be effective.
      <br />
      <br />
      If you start the POP within the first 5 days of your period starting it will be effective immediately. If you
      start after day 5 you will need to take 2 more days of active pills before you have contraceptive cover. You
      should use additional contraceptives or avoid intercourse during this time.
    </Typography>
    <br />
    <Typography variant="h6">What if I miss a pill?</Typography>
    <br />
    <Typography variant="body1">
      If you are less than 3 hours late taking an active pill: Take it as soon as you remember and take the next pill at
      the usual time; contraception will not be affected.
      <br />
      <br />
      If you are more than 3 hours late taking an active pill or you miss more than one:
      <br />
      - Take the missed pill straight away and further pills as usual
      <br />
      - Use condoms for the next 3 days
      <br />
      - Emergency contraception is recommended if you’ve had unprotected sex
      <br />
      <br />
      If you find you’re missing a lot of pills it may be best to consider a different form of contraception. Talk to
      your Candor GP or your regular GP about options.
    </Typography>
    <br />
    <Typography variant="h6">What if I vomit after taking the pill?</Typography>
    <br />
    <Typography variant="body1">
      If you vomit within 2 hours of taking an active pill: take another active pill as soon as possible.
      <br />
      <br />
      If you have severe vomiting or diarrhoea for 24 hours or more: follow the advice as above for missed pills.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      The POP is a commonly used medication and side effects are generally mild. However it is important to be aware of
      any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Breakthrough bleeding
      <br />
      - Menstrual irregularity
      <br />
      - Amenorrhea (absence of periods)
      <br />
      - Breast enlargement or tenderness
      <br />
      - Depression
      <br />
      - Acne
      <br />
      <br />
      Infrequent (0.1 to 1%):
      <br />
      - Nausea
      <br />
      - Vomiting
      <br />
      - Headache
      <br />
      - Dizziness
      <br />
      - Lethargy
      <br />
      <br />
      Rare (less than 0.1%):
      <br />
      - Allergic reaction
      <br />
      - Decreased libido
      <br />
      - Cholestatic jaundice
      <br />
      - Androgenic effects eg. hirsutism, greasy hair
      <br />
      <br />
      <br />
      Menstrual irregularity:
      <br />
      Commonly progesterone only contraceptives can cause breakthrough or irregular bleeding. If this is inconvenient to
      you please discuss with your Candor GP about options.
      <br />
      <br />
      Please note further detailed information on the POP including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use the POP when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. If you discover you are pregnant while taking the POP it is very unlikely to do harm, however we suggest
      stopping it immediately.
    </Typography>
    <br />
    <Typography variant="h6">Can I use the POP when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      Progesterone only contraceptives can be used from 6 weeks and are unlikely to affect breastmilk supply.
    </Typography>
  </Grid>
);

export default ProgesteroneOnlyContraceptiveInformation;
