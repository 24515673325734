import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { doc, writeBatch, collection, arrayUnion } from 'firebase/firestore';

import { db } from '../../../../../firebase-config';
import createEmailObj from '../../../../../functions/create-email-obj';
import { AuthContext } from '../../../../../auth-context';
import { GEN_PATIENT_INFO_PROP_TYPES } from '../../../constants';

const PREFIX = 'CandorReviews';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const GoogleReviewRequests = ({ googleReviewRequests }) => {
  if (!Array.isArray(googleReviewRequests) || !googleReviewRequests?.length) {
    return null;
  }

  return (
    <Table size="small" sx={{ mt: 4 }}>
      <TableHead>
        <TableRow>
          <TableCell>Requests Previously Sent</TableCell>
          <TableCell>Requested At</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {googleReviewRequests.map((request) => (
          <TableRow key={request.email}>
            <TableCell>{request.email}</TableCell>
            <TableCell>{new Date(request.requestedAt).toLocaleString('en-AU')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const CandorReviews = ({ handleModalClose, handleModalOpen, generalPatientInfo }) => {
  const { user } = useContext(AuthContext);

  const handleReviewEmail = async () => {
    handleModalOpen('loading');
    try {
      const date = Date.now();
      const batch = writeBatch(db);
      const mailRef = doc(collection(db, 'mail'));
      batch.set(
        mailRef,
        createEmailObj(
          `${generalPatientInfo.firstName} ${generalPatientInfo.lastName} <${generalPatientInfo.email}>`,
          'candor_reviews',
          {
            patientName: generalPatientInfo.firstName || 'there',
          },
        ),
      );
      batch.update(doc(db, 'patients', generalPatientInfo.id), {
        'reviewsRequested.google': arrayUnion({ email: user?.email, requestedAt: date }),
      });
      batch.set(doc(collection(db, 'patients', generalPatientInfo.id, 'activity')), {
        createdAt: date,
        generalData: true,
        author: 'System',
        text: `${user?.email} requested a Google review`,
      });
      await batch.commit();

      handleModalOpen('Result: Google review request email sent.');
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid>
            <div>
              <Typography variant="h5" align="center" gutterBottom>
                Request Google Review
              </Typography>
            </div>
            <br />
            <div>
              <Typography variant="body1" textAlign="center" gutterBottom>
                Click below to email a review request
              </Typography>
            </div>
            <div>
              <Button variant="contained" color="primary" className={classes.button} onClick={handleReviewEmail}>
                Send Request
              </Button>
            </div>
            <GoogleReviewRequests googleReviewRequests={generalPatientInfo.reviewsRequested?.google} />
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

CandorReviews.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.PropTypes.shape(GEN_PATIENT_INFO_PROP_TYPES).isRequired,
};

GoogleReviewRequests.propTypes = {
  googleReviewRequests: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CandorReviews;
