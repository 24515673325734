import React, { useCallback, useContext, useEffect, useState } from 'react';
import { collection, doc, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../firebase-config';
import { AuthContext } from '../../auth-context';
import { sortPharmaciesByStateAndAlphabetically } from '../../utils/pharmacies-helper';
import ShippingInformationPresentation from './shipping-information-presentation';
import PharmacyInformation from './pharmacy-information/pharmacy-information-container';

const ShippingInformation = ({
  formName,
  scriptMode,
  selectedTreatments,
  context,
  handleSnackbarOpen,
  handleBackButton,
  pharmacyConsentRead,
  setPharmacyConsentRead,
  patientInfo,
  setPreferredPharmacy,
  preferredPharmacy,
  patientId,
  setScriptMode,
  pharmacyStock,
  patientData,
  setPatientData,
  deliveryMode,
  setFilteredPharmacies,
  collectingCardInfo,
  selectedPharmacy,
  setSelectedPharmacy,
  setDeliveryMode,
  pharmacySelectionMode,
  setPharmacySelectionMode,
  filteredPharmacies,
  treatmentTranslator,
}) => {
  const { user, userType } = useContext(AuthContext);

  const [infoModal, setInfoModal] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);
  const [pickupFromOtherStates, setPickupFromOtherStates] = useState(false);
  const openInfoModal = () => {
    setInfoModal(true);
  };

  const formatShippingDetails = (isGeneralInfo) => {
    const shippingDetailsObj = {};
    Object.entries(patientData || {}).forEach((entry) => {
      const [key, value] = entry;
      shippingDetailsObj[`${isGeneralInfo ? 'basic info.' : ''}shipping.${key}`] = value;
    });

    if (selectedPharmacy && pharmacySelectionMode === 'manual') {
      if (deliveryMode === 'delivery') {
        shippingDetailsObj.nextDelivery = selectedPharmacy;
      }
      if (deliveryMode === 'pickup') {
        shippingDetailsObj.nextPickup = selectedPharmacy;
      }
    }

    return shippingDetailsObj;
  };

  const filterPharmacies = useCallback(
    (pharmaciesToBeFiltered, state) => {
      const patientState = state || patientData.state || patientInfo?.shipping?.state;
      const isDelivery = deliveryMode === 'delivery';

      const filtered = pharmaciesToBeFiltered.filter((pharmacy) => {
        const scriptsWithPharmacyArray = [];
        const scriptWithExclusivesArray = [];
        // Check if the patient checkouts with a script that is exclusive to or on file with another pharmacy
        const hasDeterminedScript = selectedTreatments?.some((priceRef) => {
          const script = patientInfo?.prescriptions[formName][priceRef];
          const { exclusive } = treatmentTranslator[priceRef] || {};

          if (script?.pharmacy === pharmacy.name) {
            scriptsWithPharmacyArray.push(script.pharmacy);
          }
          if (exclusive === pharmacy.id) {
            scriptWithExclusivesArray.push(pharmacy.name);
          }

          return (exclusive && exclusive !== pharmacy.id) || (script?.pharmacy && script?.pharmacy !== pharmacy.name);
        });

        if (hasDeterminedScript) {
          return false;
        }
        if (
          pharmacy.hasOrdersBlocked &&
          patientInfo.defaultPharmacy !== pharmacy.name &&
          !scriptsWithPharmacyArray.includes(pharmacy.name) &&
          !scriptWithExclusivesArray.includes(pharmacy.name)
        ) {
          return false;
        }

        if (patientInfo.blockedPharmacies && patientInfo.blockedPharmacies.includes(pharmacy.id)) {
          return false;
        }

        // Check if pharmacy has pickup set to true
        const isPickupEnabled = pharmacy?.pickup;
        if (!isDelivery && !isPickupEnabled) {
          return false;
        }

        // Check if pharmacy is in the same state as patient
        const isSameState = pharmacy?.shipping?.state === patientState;
        if (!isDelivery && !isSameState && !pickupFromOtherStates) {
          return false;
        }

        return true;
      });
      const sortedStatePharmacies = sortPharmaciesByStateAndAlphabetically(filtered);
      setFilteredPharmacies?.(sortedStatePharmacies);
    },
    [
      patientData?.state,
      patientInfo?.shipping?.state,
      patientInfo?.defaultPharmacy,
      patientInfo?.blockedPharmacies,
      patientInfo?.prescriptions,
      deliveryMode,
      setFilteredPharmacies,
      selectedTreatments,
      pickupFromOtherStates,
      formName,
      treatmentTranslator,
    ],
  );

  const closeInfoModal = () => {
    setInfoModal(false);
  };

  const handleChange = (value, key) => {
    if (key === 'state' && pharmacies.length) {
      filterPharmacies(pharmacies, value);
    }
    setPatientData((pd) => {
      const newData = { ...pd };
      if (value) {
        newData[key] = value;
      } else {
        delete newData[key];
      }
      return newData;
    });
  };

  const handleSaveButton = async () => {
    try {
      const uid = patientId || user?.uid;
      const dataUpdate = formatShippingDetails?.();
      const generalInfoDataUpdate = formatShippingDetails?.(true);
      const batch = writeBatch(db);
      if (userType === 'doctor' && !patientId) {
        batch.update(doc(db, 'doctors', uid), dataUpdate);
      } else {
        batch.update(doc(db, 'patients', uid), dataUpdate);
        batch.update(doc(db, 'patients', uid, 'general', 'information'), generalInfoDataUpdate);
      }
      await batch.commit();
      handleSnackbarOpen('Your details were updated');
    } catch (error) {
      console.error(error);
      sentryCaptureException(error, {
        extra: { issueIn: 'ShippingInformation - handleSaveButton', patientId },
      });
      handleSnackbarOpen('Error. Please try again');
    }
  };

  useEffect(() => {
    const fetchPharmacies = async () => {
      const pharmacyDocs = (await getDocs(query(collection(db, 'orders'), where('type', '==', 'pharmacy')))).docs.map(
        (pharm) => ({ ...pharm.data(), id: pharm.id }),
      );
      const allPharmacies = pharmacyDocs.filter((pharmacy) => pharmacy.ordersAccepted.includes(formName));

      // Sort by state
      setPharmacies(allPharmacies.sort((a, b) => (a.shipping.state > b.shipping.state ? 1 : -1)));
      return allPharmacies;
    };

    fetchPharmacies().then((pharms) => filterPharmacies(pharms));
  }, [patientInfo, selectedTreatments, formName, filterPharmacies, deliveryMode]);

  return (
    <>
      {scriptMode ? (
        <PharmacyInformation
          patientInfo={patientInfo}
          handleBackButton={handleBackButton}
          selectedTreatments={selectedTreatments}
          pharmacyConsentRead={pharmacyConsentRead}
          setPharmacyConsentRead={setPharmacyConsentRead}
          collectingCardInfo={collectingCardInfo}
          preferredPharmacy={preferredPharmacy}
          setPreferredPharmacy={setPreferredPharmacy}
          setScriptMode={setScriptMode}
        />
      ) : (
        <ShippingInformationPresentation
          patientInfo={patientInfo}
          context={context}
          handleBackButton={handleBackButton}
          filteredPharmacies={filteredPharmacies || []}
          pharmacies={pharmacies}
          infoModal={infoModal}
          openInfoModal={openInfoModal}
          closeInfoModal={closeInfoModal}
          patientData={patientData}
          handleChange={handleChange}
          handleSaveButton={handleSaveButton}
          formName={formName}
          collectingCardInfo={collectingCardInfo}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          deliveryMode={deliveryMode}
          setDeliveryMode={setDeliveryMode}
          pharmacySelectionMode={pharmacySelectionMode}
          setPharmacySelectionMode={setPharmacySelectionMode}
          selectedTreatments={selectedTreatments}
          setScriptMode={setScriptMode}
          pharmacyStock={pharmacyStock}
          pickupFromOtherStates={pickupFromOtherStates}
          setPickupFromOtherStates={setPickupFromOtherStates}
        />
      )}
    </>
  );
};

export default ShippingInformation;
