import { Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { captureException as sentryCaptureException } from '@sentry/react';
import { doc, updateDoc, query, getDocs, where, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { SHIPMENT_TYPES, getCatalogueId } from '../../../../utils/constants';
import { usePharmacyContext } from '../../hooks/usePharmacyContext';
import { useAuthContext, useOrderDetailsContext, usePharmacyActions } from '../../hooks';
import { EScriptTokenDisplay } from './EScriptTokenDisplay';
import { ScriptDownloadsSections } from './ScriptDownloadsSections';
import { getInitialCheckMessage, isToShowScriptRelatedContents, processUpdatedOrderItems } from './helpers';

const { OUTSTANDING, COMPLETE } = SHIPMENT_TYPES;

/**
 * This component is used to display the script related sections.
 * @param {Object} props - The props for the script related sections.
 * @param {boolean} [props.isSubmit] - Whether the order is submitted.
 * @param {Object} [props.catalogue] - The catalogue.
 * @param {Object[]} [props.uniqueScriptsArray] - The unique scripts array.
 * @param {Object} [props.repeatTokens] - The repeat tokens.
 * @param {Function} [props.setRepeatTokens] - The set repeat tokens function.
 * @returns {React.ReactNode} - The script related sections.
 */
export const ScriptRelatedSections = ({ isSubmit, catalogue, uniqueScriptsArray, repeatTokens, setRepeatTokens }) => {
  const { order: shipment, refreshOrder } = useOrderDetailsContext();
  const { snackbar } = usePharmacyContext();
  const { scriptDownload } = usePharmacyActions();
  const { user } = useAuthContext();
  const [isRecentOrder, setIsRecentOrder] = useState(false);

  const checkMostRecentScript = useCallback(async () => {
    setIsRecentOrder(false);

    try {
      const completedQueryRef = query(
        collection(db, 'orders', user.uid, 'completed'),
        where('user', '==', shipment.user),
      );
      const querySnapshot = await getDocs(completedQueryRef);
      const orders = querySnapshot.docs.map((order) => order.data());
      const sortOrders = orders.sort((orderA, orderB) => orderB.updated - orderA.updated);
      const { payment } = sortOrders[0] || {};

      setIsRecentOrder(payment === shipment.payment);
    } catch (error) {
      setIsRecentOrder(false);
      sentryCaptureException(error, {
        extra: { shipment, userId: user.uid, issueIn: 'checkMostRecentScript' },
      });
      snackbar('An error was encountered while checking the most recent script');
    }
  }, [shipment, user.uid, snackbar]);

  useEffect(() => {
    if (shipment.status === COMPLETE) {
      checkMostRecentScript();
    }
  }, [checkMostRecentScript, shipment.status]);

  const handleSaveScriptToken = async ({ script, isCheckboxChanged }) => {
    const repeatToken = repeatTokens[script.priceRef];
    const initialCheckMessage = getInitialCheckMessage({ repeatToken, script });

    if (!isCheckboxChanged && !script.manualBypassed && initialCheckMessage) {
      snackbar(initialCheckMessage);
      return;
    }

    try {
      const outstandingOrderRef = doc(db, 'orders', user.uid, OUTSTANDING, shipment.payment);

      const updatedOrderItems = processUpdatedOrderItems({
        scriptsArray: shipment.scriptsArray,
        script,
        repeatToken,
        isCheckboxChanged,
      });

      await updateDoc(outstandingOrderRef, { scriptsArray: updatedOrderItems });
      refreshOrder();
      snackbar('Repeat eRx token saved');
    } catch (error) {
      sentryCaptureException(error, {
        extra: { shipment, userId: user.uid, issueIn: 'handleSaveScriptToken' },
      });
      snackbar('An error was encountered while saving the repeat eRx token');
    }
  };

  if (
    !isToShowScriptRelatedContents({
      shipment,
      historical: shipment.status === COMPLETE,
      isRecentOrder,
      isSubmit,
    })
  ) {
    return null;
  }

  return (
    <>
      {Object.keys(catalogue || {}).length !== 0 &&
        uniqueScriptsArray.map((script) => {
          if (!script) {
            return null;
          }

          const id = getCatalogueId(script.priceRef);

          return (
            <Stack direction="column" spacing={2} key={id}>
              {script.scriptOnFile && !script?.eScriptToken && !catalogue[id]?.overTheCounter && (
                <Typography variant="body2" fontWeight="bold">
                  No script attached for {catalogue[id]?.name}. Patient has a repeat on file.
                </Typography>
              )}
              {script?.eScriptToken && !script.refunded && (
                <Typography variant="body2">
                  {catalogue[id]?.name} - eRx Token: <strong>{script?.eScriptToken || ''}</strong>{' '}
                  {script.lastRepeat && <span>(Last Repeat)</span>}
                </Typography>
              )}
              <EScriptTokenDisplay
                script={script}
                isHistorical={shipment.status === COMPLETE}
                repeatToken={repeatTokens[script.priceRef] || ''}
                productName={catalogue[id]?.name || ''}
                setRepeatTokens={setRepeatTokens}
                handleSaveScriptToken={handleSaveScriptToken}
              />
              <ScriptDownloadsSections
                id={id}
                shipment={shipment}
                script={script}
                catalogue={catalogue}
                scriptDownload={scriptDownload}
              />
            </Stack>
          );
        })}
    </>
  );
};
