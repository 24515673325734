import React from 'react';
import { styled } from '@mui/material/styles';
import { doc, updateDoc } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Container, Typography, Button } from '@mui/material';

import { db } from '../../../../../firebase-config';

const PREFIX = 'MarketingUnsubscribe';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const MarketingUnsubscribe = (props) => {
  const { handleModalClose, handleModalOpen, patientId } = props;

  const handleMarketingUnsubscribe = async () => {
    handleModalOpen('loading');
    try {
      await updateDoc(doc(db, 'patients', patientId), { marketingEmails: false });
      handleModalOpen('Result: Patient unsubscribed from marketing emails');
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid>
            <div>
              <Typography variant="h5" align="center" gutterBottom>
                Unsubscribe Patient
              </Typography>
            </div>
            <br />
            <div>
              <Typography variant="body1" textAlign="center" gutterBottom>
                Clicking unsubscribe will unsubscribe the patient from marketing emails
              </Typography>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleMarketingUnsubscribe}
              >
                Unsubscribe
              </Button>
            </div>
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default MarketingUnsubscribe;
