import React, { useContext, useState, useEffect, useMemo } from 'react';
import { doc, updateDoc, deleteField, writeBatch, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { AuthContext } from '../../../../auth-context';
import createEmailObj from '../../../../functions/create-email-obj';
import { isValidMeasurement, isCandor, extractNumericCharacters } from '../../../../utils';

import BasicFormElementPresentation from '../presentation-elements/basic-form-element-presentation';
import { populatePatientSearchTokens } from '../../../patient-management/PatientsPage/patientSearchTokenQuery';

const MIN_FORM_NAME_LENGTH = 1;
const MAX_FORM_VALUE_LENGTH = 5;
const MIN_FORM_ROWS = 3;

// WARNING: There are other definitions of this in our codebase with slight variations
const isNumeric = (value) => /^\d*$/.test(value);

/**
 * Validates the input based on the provided parameters.
 *
 * @param {Object} params - The parameters for validation.
 * @param {string} params.lastFieldTextType - The type of the last field (e.g., "first name", "last name").
 * @param {string} params.trimmedValue - The trimmed value of the input field.
 * @param {number} params.maxRows - The maximum number of rows allowed.
 * @returns {boolean} - Returns `true` if the input is valid; otherwise, `false`.
 */
const isInputValid = ({ lastFieldTextType = '', trimmedValue = '', maxRows }) => {
  // This check is specific to the first and last name fields in the forms
  if (lastFieldTextType === 'first name' || lastFieldTextType === 'last name') {
    return trimmedValue.length <= MIN_FORM_NAME_LENGTH;
  }

  // This check is for the rest of the fields that uses the handleInput function
  return trimmedValue.length <= MAX_FORM_VALUE_LENGTH && maxRows >= MIN_FORM_ROWS;
};

const BasicFormElement = ({
  currentFormObject,
  setFormInputs,
  handleNext,
  formInputs,
  formName,
  index,
  patientId,
  simpleView,
}) => {
  const { user, userType } = useContext(AuthContext);

  const [textValue, setTextValue] = useState('');
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const lastFieldTextType = useMemo(() => {
    const fieldText = currentFormObject?.fieldText;

    // NOTE: We are changing the value from undefined/null to empty ""
    // this may not be the correct choice. Forms behaviour is not well understood.
    if (!fieldText) {
      return '';
    }

    // NOTE: This might sometimes be an Array? :shrug: unsure, but something is breaking.
    if (typeof fieldText !== 'string') {
      return fieldText;
    }

    const segments = fieldText.split('.');

    // Get last segment
    return segments.length > 0 ? segments[segments.length - 1] : '';
  }, [currentFormObject?.fieldText]);

  const validateMeasurement = (value) => {
    if (lastFieldTextType !== 'height' && lastFieldTextType !== 'weight') {
      return;
    }

    if (!isNumeric(value)) {
      return;
    }

    if (!isValidMeasurement({ value, measurementType: lastFieldTextType })) {
      setFormError(true);
      setTextValue(value);
    }
  };

  const handleChange = (value) => {
    setFormError(false);

    setTextValue(value);
  };

  const handleInput = async () => {
    setIsLoading(true);
    const trimmedValue = textValue.trim();

    if (isInputValid({ lastFieldTextType, trimmedValue, maxRows: currentFormObject.maxRows || 0 })) {
      setFormError(true);
      setIsLoading(false);
      return;
    }

    if (textValue !== formInputs[currentFormObject.fieldText]) {
      let targetField = currentFormObject.fieldText;
      const obj = { [targetField]: trimmedValue };
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        if (!isCandor(userType)) obj[`forms.${formName}.step`] = index;
        const effectsFirstName = targetField === 'basic info.first name';
        const effectsLastName = targetField === 'basic info.last name';
        const firstFormCompleted = formInputs['basic info.first form completed'];
        if (!effectsFirstName && !effectsLastName && (!firstFormCompleted || !currentFormObject.notifyDoctor)) {
          updateDoc(doc(db, 'patients', userId, 'general', 'information'), obj);
        } else {
          // For everything else we need to do a batch write
          const batch = writeBatch(db);
          // If first or last name field update the primary doc as well
          if (effectsFirstName || effectsLastName) {
            let strippedField = targetField.replace('basic info.', '');
            strippedField = strippedField.replace(' n', 'N');
            batch.update(doc(db, 'patients', userId), { [strippedField]: trimmedValue });

            // Populate the searchTokens field in the patients collection
            populatePatientSearchTokens(userId);

            batch.update(doc(db, 'patients', userId, 'general', 'information'), obj);
            await batch.commit();
          } else if (firstFormCompleted && currentFormObject.notifyDoctor) {
            // Let GP know that some details were updated if patient has previously been assigned treatment
            targetField = targetField.replace('general health.', '');
            const dataObj = {
              patientName: `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`,
              email: user.email,
              updatedData: `Patient updated ${targetField}`,
            };
            const mailRef = doc(collection(db, 'mail'));
            batch.set(mailRef, createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
            batch.update(doc(db, 'patients', user.uid, 'general', 'information'), obj);
            batch.set(doc(collection(db, 'patients', user.uid, 'activity')), {
              createdAt: Date.now(),
              generalData: false,
              author: 'System',
              text: `Patient updated their ${targetField}${formInputs[currentFormObject.fieldText] === undefined ? '' : ` from: [${formInputs[currentFormObject.fieldText]}]`} to: [${trimmedValue}]`,
            });
            await batch.commit();
          }
        }
      }
      setFormInputs((fi) => ({ ...fi, ...obj }));
    }
    setIsLoading(false);
    handleNext();
  };

  const handleSkip = () => {
    if (textValue !== formInputs[currentFormObject.fieldText]) {
      const { [currentFormObject.fieldText]: _, ...updatedFormInputs } = formInputs;
      setFormInputs(updatedFormInputs);
      updateDoc(doc(db, 'patients', user?.uid, 'general', 'information'), {
        [currentFormObject.fieldText]: deleteField(),
      });
    }
    handleNext();
  };

  useEffect(() => {
    // If the field is empty, return
    const value = formInputs[currentFormObject.fieldText];

    if (!value) {
      return;
    }

    // If the field is not height or weight, set the text value normally
    // TODO: Would recommend we refactor to making height into HEIGHT_IN_CM and weight into WEIGHT_IN_KG
    const isHeightOrWeight = lastFieldTextType === 'height' || lastFieldTextType === 'weight';
    if (!isHeightOrWeight) {
      setTextValue(value);
      return;
    }

    // If the field is height or weight, extract the numeric characters and validate the input
    const numericValue = extractNumericCharacters(value);

    if (!numericValue || !isValidMeasurement({ value: numericValue, measurementType: lastFieldTextType })) {
      setFormError(true);
      setTextValue(numericValue);

      return;
    }

    setTextValue(numericValue);
  }, [currentFormObject, formInputs, lastFieldTextType]);

  return (
    <BasicFormElementPresentation
      isLoading={isLoading}
      formError={formError}
      handleSkip={handleSkip}
      simpleView={simpleView}
      currentFormObject={currentFormObject}
      inputValue={textValue}
      handleInput={handleInput}
      handleChange={handleChange}
      validateMeasurement={validateMeasurement}
    />
  );
};

export default BasicFormElement;
