import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const OlopatadineInformation = () => (
  <Grid>
    <Typography variant="h6">About Olopatadine Eye Drops</Typography>
    <br />
    <Typography variant="body1">
      Olopatadine eye drops are antihistamines that are used to help treat allergic conjunctivitis and ocular symptoms
      associated with allergic rhinitis (hayfever). They act to reduce the effects of histamine which in turn decreases
      itch, watery eyes and redness. Olopatadine also stabilises mast cells which can prevent allergy symptoms from
      occurring.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Dosage: one drop to each eye twice a day as needed.
      <br />
      <br />
      Wash hands thoroughly and shake bottle before use. Holding the bottle upside down, tilt your head back and gently
      pull the lower eyelid down. Release one drop onto the eye and close your eye. While your eye is closed, place your
      index finger against the inside corner of your eye towards your nose to prevent the medication draining through
      the tear duct. This will help reduce any unpleasant taste you may experience. Wipe any spillage away with a
      tissue. Repeat on the other side as needed.
      <br />
      <br />
      It is important to be careful not to touch the eyelids or surrounding skin with the dropper tip to avoid
      contaminating the bottle and solution.
      <br />
      <br />
      Caution is advised for contact lens wearers. The preservative in the solution may be absorbed by soft contact
      lenses. If you wear contact lenses it is recommended you wait at least 15 minutes after use to reinsert them.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Olopatadine is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      <br />
      - Stinging
      <br />
      - Mild eye irritation
      <br />
      - Headache
      <br />
      - Hyperaemia
      <br />
      - Keratitis
      <br />
      - Dry eyes
      <br />
      - Unpleasant taste
      <br />
      <br />
      It is normal to experience a slight burning sensation shortly after using the eye drops. If this persists or is
      very uncomfortable, seek medical advice.
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Olopatadine including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Olopatadine when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Yes, however we recommend discussing with your Candor GP prior to use to ensure the benefits outweigh the risks.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Olopatadine when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default OlopatadineInformation;
