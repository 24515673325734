import React from 'react';

/**
 * @typedef {Object} PharmacyContextValue
 * @property {Pharmacy | null} pharmacy
 * @property {boolean} loading
 * @property {Object} error
 * @property {() => void} refreshPharmacy
 * @property {Object} catalogue
 * @property {boolean} catalogueLoading
 * @property {Object} catalogueError
 * @property {({isOpen, content}: {isOpen: boolean, content: React.ReactNode}) => void} handleDialogue
 * @property {(message: string) => void} snackbar
 */
export const PharmacyContext = React.createContext({
  pharmacy: /** @type {Pharmacy | null} */ (null),
  loading: false,
  error: /** @type {Error | null} */ (null),
  refreshPharmacy: () => {},
  catalogue: /** @type {Object} */ ({}),
  catalogueLoading: false,
  catalogueError: /** @type {Error | null} */ (null),
  // eslint-disable-next-line no-unused-vars
  handleDialogue: (/** @type {{isOpen: boolean, content: React.ReactNode}} */ { isOpen, content }) => {},
  // eslint-disable-next-line no-unused-vars
  snackbar: (/** @type {string} */ msg) => {},
});
