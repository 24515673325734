import React from 'react';
import moment from 'moment';
import { Button, MenuItem } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { Restore as RestoreIcon } from '@mui/icons-material';
import { doc, writeBatch, increment } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import {
  getCatalogueId,
  SHIPMENT_TYPES,
  RATE_BEFORE_CHANGE_DATE,
  RATE_AFTER_CHANGE_DATE,
  SHIPPING_CHARGE_CHANGE_DATE_UNIX_TIMESTAMP_MS,
} from '../../../../../utils/constants';
import { AuthContext } from '../../../../../auth-context';
import { db, functions as firebaseFunctions } from '../../../../../firebase-config';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';
import { ModalContainer } from '../ModalContainer';

const { COMPLETED, OUTSTANDING } = SHIPMENT_TYPES;

const undoMarkComplete = httpsCallable(firebaseFunctions, 'undoMarkComplete');

const weekRange = (time) => {
  const now = moment();
  const startWeek = now.clone().startOf('week').valueOf();

  return time > startWeek;
};

/**
 * @param {Object} props - The props object.
 * @param {Order} props.order - The order object.
 * @param {Pharmacy} props.pharmacy - The pharmacy object.
 * @param {Object} props.catalogueList - The catalogue list object.
 * @param {string} props.userId - The user ID.
 * @param {Function} props.handleAllModalClose - The function to handle all modal close.
 * @param {Function} props.snackbar - The function to handle snackbar.
 */
const handleUndoMarkComplete = async ({ order, pharmacy, catalogueList, userId, handleAllModalClose, snackbar }) => {
  if (!Object.keys(order)?.length) {
    throw new Error('No Shipment data.');
  }

  try {
    const orderOutstandingRef = doc(db, 'orders', userId, OUTSTANDING, order?.payment);
    const orderCompletedRef = doc(db, 'orders', userId, COMPLETED, order?.payment);
    const genInfoOrderRef = doc(db, 'orders', userId, 'information', 'general');
    const { scriptsArray, updated, shipping, isPickup } = order;
    const withinWeek = weekRange(updated);

    const response = await undoMarkComplete({
      ...order,
      applyBrandingFees: withinWeek ? false : pharmacy?.applyBrandingFees || false,
    });

    if (response?.data?.error) {
      throw new Error(response.data.error);
    }

    const { shipment: updatedShipment, message: messageData, gst } = response.data;

    const batch = writeBatch(db);

    // updates remittance refund for orders not within the week
    if (!withinWeek) {
      let refunds = 0;
      let brandingFees = 0;

      scriptsArray.forEach((script) => {
        if (!script?.refunded) {
          refunds += script?.substitute?.price || script.paidAmount;
        }

        if (!pharmacy?.applyBrandingFees) {
          return;
        }

        const product = catalogueList[getCatalogueId(script.priceRef)] || {};

        if (product?.exclusive && product.exclusive === pharmacy.id && product.wholesale) {
          brandingFees += product.price - product.wholesale;
        }
      });

      if (!shipping.refunded && !isPickup) {
        refunds +=
          updated > SHIPPING_CHARGE_CHANGE_DATE_UNIX_TIMESTAMP_MS ? RATE_AFTER_CHANGE_DATE : RATE_BEFORE_CHANGE_DATE;
      }

      // updates refunds field
      const total = refunds - brandingFees * (gst || 1);
      if (total) {
        batch.update(genInfoOrderRef, { refunds: increment(total) });
      }
    }

    batch.set(orderOutstandingRef, updatedShipment); // add outstanding order
    batch.delete(orderCompletedRef); // delete completed order

    await batch.commit();
    snackbar(messageData);
    handleAllModalClose();
  } catch (error) {
    sentryCaptureException(error, { extra: { order, patientId: order.user, issueIn: 'undoMarkComplete' } });
    snackbar(error.message);
  }
};

const UndoMarkCompleteModal = () => {
  const { user } = React.useContext(AuthContext);
  const { pharmacy, catalogue, snackbar } = usePharmacyContext();
  const { order, handleDialogue } = useOrderDetailsContext();
  const [isUndoMarkCompleteLoading, setIsUndoMarkCompleteLoading] = React.useState(false);

  const handleModalClose = () => {
    setIsUndoMarkCompleteLoading(false);
    handleDialogue({ isOpen: false, content: null });
  };

  const undoMarkCompleteFunc = async () => {
    setIsUndoMarkCompleteLoading(true);
    await handleUndoMarkComplete({
      order,
      pharmacy,
      snackbar,
      userId: user?.uid,
      catalogueList: catalogue,
      handleAllModalClose: handleModalClose,
    }).then(handleModalClose);
  };

  return (
    <ModalContainer
      text="Are you sure you want to undo this order?"
      subtext="This will revert the order back to the outstanding status."
      onClose={handleModalClose}
      handleProceed={undoMarkCompleteFunc}
      loadingComponent={isUndoMarkCompleteLoading}
    />
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal
 */
export const UndoMarkComplete = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<UndoMarkCompleteModal />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<RestoreIcon />}>
        Undo Mark Complete
      </Button>
    </MenuItem>
  );
};
