import { SHIPMENT_TYPES } from '../../../utils/constants';
import { getTrackingCode } from '../components/OrderDetailsToolsButton/MassAddTracking/helpers';
import { ORDER_CURRENT_STATUS } from '../constants';

/**
 * @param {Object} params
 * @param {Order} params.order - The order
 * @param {boolean} params.isAwaitingPayment - Whether the order is awaiting payment
 * @param {boolean} params.isEscriptValid - Whether the order has a valid escript
 * @returns {boolean} - Whether the order is ready for pickup
 */
const isReadyForPickup = ({ order, isAwaitingPayment, isEscriptValid }) => {
  // The shipment status needs to be pickup required
  if (order.status !== SHIPMENT_TYPES.PICKUP_REQUIRED) {
    return false;
  }

  // The user needs to be verified
  if (!order.userVerified) {
    return false;
  }

  // The order needs to have no more awaiting payments
  if (isAwaitingPayment) {
    return false;
  }

  // The order needs to have a valid eScript
  if (!isEscriptValid) {
    return false;
  }

  // The order needs to have no revert reason
  return !order.revertReason;
};

/**
 * @param {Object} params
 * @param {Order} params.order - The order
 * @returns {boolean} - Whether the order is ready for shipment
 */
const isReadyForShipment = ({ order }) => !!getTrackingCode(order) && !order.revertReason;

/**
 *
 * @param {Object} params
 * @param {Order} params.order - The order
 * @param {boolean} params.isAwaitingPayment - Whether the order is awaiting payment
 * @param {boolean} params.isEscriptValid - Whether the order has a valid eScript
 * @returns {OrderCurrentStatus | null} - The current status of the order
 */
export const getOrderCurrentStatus = ({ order, isAwaitingPayment, isEscriptValid }) => {
  if (
    order.status === SHIPMENT_TYPES.PICKUP_REQUIRED &&
    isReadyForPickup({ order, isAwaitingPayment, isEscriptValid })
  ) {
    return ORDER_CURRENT_STATUS.READY_FOR_PICKUP;
  }

  if (order.status === SHIPMENT_TYPES.SHIPMENT_REQUIRED && isReadyForShipment({ order })) {
    return ORDER_CURRENT_STATUS.READY_TO_SHIP;
  }

  return null;
};
