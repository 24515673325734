import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  Box,
  Divider,
  TextField,
  TableContainer,
  Table,
  TableBody,
} from '@mui/material';
import { getDoc, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { captureException as sentryCaptureException } from '@sentry/react';

import { TableRowDetails } from './approve-pharmacy';
import { db, functions } from '../../../../../firebase-config';

const PREFIX = 'ApproveDoctor';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  confirmButton: `${PREFIX}-confirmButton`,
  input: `${PREFIX}-input`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.confirmButton}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.input}`]: {
    display: 'flex',
  },
}));

const updateUserEmail = httpsCallable(functions, 'updateUserEmail');
const createDoctorAccount = httpsCallable(functions, 'createDoctorAccount');

const processConfirmation = async ({ handleModalOpen, patientId, email, alreadyUsingCandorEmail }) => {
  try {
    if (!email) {
      handleModalOpen('Error: Please fill in the required fields.');
      return;
    }
    // generalPatientInfo cannot be used here because it is somehow mutated
    const doctorData = (await getDoc(doc(db, 'patients', patientId))).data();
    const { isDoctor, email: oldEmail, ...alteredData } = doctorData || {};
    if (!isDoctor || !oldEmail) {
      throw new Error(`Incorrect / missing data, doctor account cannot be processed. Patient ID: ${patientId}`);
    }

    handleModalOpen('loading');
    const payload = { ...alteredData, email, type: 'doctor', oldEmail };

    // If the account was created with a candor.org email we do not need to update the email
    if (email && !alreadyUsingCandorEmail) {
      const { data: updateUserEmailResponse } = (await updateUserEmail({ oldEmail, newEmail: email })) || {};
      if (updateUserEmailResponse?.error) {
        throw new Error(updateUserEmailResponse?.error);
      }
    }

    const { data: createDoctorAccountResponse = {} } = (await createDoctorAccount({ id: patientId, payload })) || {};
    if (createDoctorAccountResponse?.error) {
      throw new Error(createDoctorAccountResponse?.error);
    }

    handleModalOpen(`Result: ${createDoctorAccountResponse?.message}`);
  } catch (error) {
    const sentryErrorId = await sentryCaptureException(error, {
      extra: { patientId, issueIn: 'doctor account approval' },
    });
    handleModalOpen(`Error: The doctor could not be approved. Sentry Error ID: ${sentryErrorId}`);
  }
};

const ApproveDoctor = ({ handleModalClose, handleModalOpen, generalPatientInfo: doctorData }) => {
  const alreadyUsingCandorEmail = doctorData.email.endsWith('@candor.org');
  const [email, setEmail] = useState(alreadyUsingCandorEmail ? doctorData.email : '');
  const [isSubmit, setIsSubmit] = useState(false);

  const handleConfirm = async () => {
    setIsSubmit(true);
    await processConfirmation({
      handleModalOpen,
      patientId: doctorData.id,
      email,
      alreadyUsingCandorEmail,
    });
  };

  const handleEmail = ({ target }) => {
    setEmail(target?.value);
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
              <Divider
                sx={{
                  opacity: '0.6',
                }}
              />
            </Box>
          </Grid>
          <Grid>
            <Typography fontWeight="bold" textAlign="center" paddingTop="20px">
              Account Information
            </Typography>
            <Box paddingTop="20px" paddingBottom="20px">
              <TableContainer style={{ marginBottom: 20 }}>
                <Table aria-label="simple table">
                  <colgroup>
                    <col width="60%" />
                    <col width="40%" />
                  </colgroup>
                  <TableBody>
                    <TableRowDetails label="Email" value={doctorData.email} />
                    <TableRowDetails label="Name" value={doctorData?.name} />
                    <TableRowDetails label="Date of Birth" value={doctorData?.dob} />
                    <TableRowDetails label="Phone" value={doctorData?.shipping?.phone} />
                    <TableRowDetails label="Street" value={doctorData?.shipping?.street} />
                    <TableRowDetails label="Suburb" value={doctorData?.shipping?.suburb} />
                    <TableRowDetails label="State" value={doctorData?.shipping?.state} />
                    <TableRowDetails label="Postcode" value={doctorData?.shipping?.postcode} />
                    <TableRowDetails label="Qualifications (Abbreviated)" value={doctorData?.qualifications} />
                    <TableRowDetails label="Account Name" value={doctorData?.accountName} />
                    <TableRowDetails label="BSB" value={doctorData?.bsb} />
                    <TableRowDetails label="Account Number" value={doctorData?.accountNumber} />
                    <TableRowDetails label="Trading As" value={doctorData?.tradingAs} />
                    <TableRowDetails label="ABN" value={doctorData?.abn} />
                    <TableRowDetails label="AHPRA Number" value={doctorData?.ahpraNumber} />
                    <TableRowDetails
                      label="Professional Membership Number"
                      value={doctorData?.professionalMembershipNumber}
                    />
                    <TableRowDetails label="HPI" value={doctorData?.hpi} />
                    <TableRowDetails label="Prescriber Number" value={doctorData?.prescriberNumber} />
                    <TableRowDetails label="Candor Email">
                      <TextField
                        size="small"
                        type="text"
                        name="email"
                        variant="outlined"
                        value={email}
                        onChange={handleEmail}
                        className={classes.input}
                        error={!email && isSubmit}
                        helperText={!email && isSubmit && 'Please enter a candor email.'}
                        disabled={alreadyUsingCandorEmail}
                      />
                    </TableRowDetails>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Box className={classes.confirmButton}>
            <Button variant="contained" color="primary" onClick={handleConfirm} disabled={!email}>
              Confirm
            </Button>
          </Box>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

ApproveDoctor.propTypes = {
  handleModalOpen: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.shape({
    abn: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    ahpraNumber: PropTypes.string.isRequired,
    bsb: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    hpi: PropTypes.string.isRequired,
    isDoctor: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prescriberNumber: PropTypes.string.isRequired,
    professionalMembershipNumber: PropTypes.string.isRequired,
    qualifications: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      phone: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      suburb: PropTypes.string.isRequired,
      postcode: PropTypes.string.isRequired,
    }).isRequired,
    tradingAs: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string,
    uid: PropTypes.string,
  }).isRequired,
};

export default ApproveDoctor;
