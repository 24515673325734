import React from 'react';
import { RefreshRounded } from '@mui/icons-material';
import { Container, Typography, CircularProgress, Alert, Stack, IconButton } from '@mui/material';

import { isPatient } from '../../../utils/roles';
import { PharmacyCard } from '../components/PharmacyCard';
import { PharmacyFilters } from '../components/PharmacyFilters';
import { usePharmacyDirectory, useAuthContext } from '../hooks';

const Loading = () => (
  <Container sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress />
  </Container>
);

/**
 * @param {Object} props
 * @param {Pharmacy[]} props.pharmacies The list of pharmacies to display
 * @param {boolean} props.isLoading Whether the pharmacies are loading
 */
const MainContent = ({ pharmacies, isLoading }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (pharmacies.length === 0) {
    return <Typography color="text.secondary">No pharmacies found matching your criteria.</Typography>;
  }

  return pharmacies.map((pharmacy) => <PharmacyCard key={pharmacy.id} pharmacy={pharmacy} />);
};

/**
 * Main pharmacy directory component that displays the list of pharmacies
 * and handles filtering and viewing details
 */
export const PharmaciesPage = () => {
  const { pharmacies, allPharmacies, isLoading, error, filters, setFilters, refreshPharmacies } =
    usePharmacyDirectory();
  const { userType } = useAuthContext();

  const userIsPatient = isPatient(userType);

  if (error) {
    return (
      <Container>
        <Alert severity="error">Failed to load pharmacies. Please try again later.</Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Pharmacies
        </Typography>

        {!userIsPatient && (
          <IconButton onClick={refreshPharmacies}>
            <RefreshRounded />
          </IconButton>
        )}
      </Stack>

      {!userIsPatient && (
        <PharmacyFilters allPharmacies={allPharmacies} filters={filters} onFiltersChange={setFilters} />
      )}

      <MainContent isLoading={isLoading} pharmacies={pharmacies} />
    </Container>
  );
};
