import React from 'react';
import { Modal } from '@mui/material';
import PropTypes from 'prop-types';

const ModalContainer = ({ isOpen = false, onClose, children, sx }) => (
  <Modal open={isOpen} onClose={onClose} sx={sx || {}}>
    <>{children}</>
  </Modal>
);

ModalContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  sx: PropTypes.shape({}),
};

export default ModalContainer;
