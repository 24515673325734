import React from 'react';
import { doc, writeBatch, collection } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';
import { ReplyAllRounded as ReplyAllRoundedIcon } from '@mui/icons-material';
import { Button, MenuItem, TextField, Stack, Typography } from '@mui/material';

import { db } from '../../../../../firebase-config';
import createEmailObj from '../../../../../functions/create-email-obj';
import { SHIPMENT_TYPES, SUPPORT_EMAIL_ADDRESS } from '../../../../../utils/constants';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';

const { OUTSTANDING } = SHIPMENT_TYPES;

const mailRef = doc(collection(db, 'mail'));
const EMAIL_TEMPLATE = 'order_requires_attention';

const REVERT_ORDER_MESSAGES = {
  SUCCESS: 'Order was reverted successfully',
  FAILURE: 'Failure to revert this order, please try again',
};

/**
 * @param {Object} props
 * @param {Order} props.order
 * @param {string} props.revertReason
 * @param {string} props.userId
 * @param {(message: string) => void} props.snackbar
 * @param {() => void} props.handleAllModalClose
 * @param {(value: boolean) => void} props.setIsRevertOrderLoading
 */
const handleRevertOrder = async ({
  order,
  revertReason,
  userId,
  snackbar,
  handleAllModalClose,
  setIsRevertOrderLoading,
}) => {
  try {
    setIsRevertOrderLoading(true);

    const batch = writeBatch(db);
    batch.update(doc(db, 'orders', userId, OUTSTANDING, order.payment), { revertReason });
    batch.set(
      mailRef,
      createEmailObj(SUPPORT_EMAIL_ADDRESS, EMAIL_TEMPLATE, {
        pharmacyName: order.pharmacyInfo?.name,
        patientName: order?.name,
      }),
    );
    await batch.commit();
    snackbar(REVERT_ORDER_MESSAGES.SUCCESS);
    handleAllModalClose();
  } catch (error) {
    sentryCaptureException(error, { extra: { order, userId, issueIn: 'revert order' } });
    snackbar(REVERT_ORDER_MESSAGES.FAILURE);
    setIsRevertOrderLoading(false);
  }
};

const RevertOrderModalContent = () => {
  const { user } = useAuthContext();
  const { uid: userId } = user || { uid: undefined };
  const { snackbar } = usePharmacyContext();
  const { order, handleDialogue } = useOrderDetailsContext();

  const [revertReason, setRevertReason] = React.useState('');
  const [isRevertOrderLoading, setIsRevertOrderLoading] = React.useState(false);

  const handleModalClose = () => {
    handleDialogue({ isOpen: false, content: null });
  };

  const handleAllModalClose = () => {
    setIsRevertOrderLoading(false);
    handleDialogue({ isOpen: false, content: null });
  };

  const revertOrder = async () => {
    if (!order) {
      return;
    }

    await handleRevertOrder({
      order,
      userId,
      revertReason,
      snackbar,
      handleAllModalClose,
      setIsRevertOrderLoading,
    });
  };

  return (
    <Stack direction="column" gap={2} width="50vw" maxWidth="100%">
      <Typography variant="h6" sx={{ mb: 2 }} color="primary">
        Send Back To Candor
      </Typography>

      <TextField
        minRows={2}
        multiline
        placeholder="Reason for sending back to Candor"
        variant="outlined"
        fullWidth
        onChange={(e) => {
          setRevertReason(e.target.value);
        }}
        value={revertReason || ''}
      />

      <Stack direction="column" gap={2} alignItems="center" width="100%" justifyContent="flex-end">
        <Button
          fullWidth
          onClick={revertOrder}
          disabled={!revertReason}
          loading={isRevertOrderLoading}
          variant="contained"
        >
          Send Back
        </Button>
        <Button onClick={handleModalClose}>Cancel</Button>
      </Stack>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal
 */
export const RevertOrder = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<RevertOrderModalContent />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<ReplyAllRoundedIcon />}>
        Send Back To Candor
      </Button>
    </MenuItem>
  );
};
