import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import MedicareCardFormElement from '../form/horizontal-form-elements/container-elements/medicare-card-form-element';
import HealthCardFormElement from '../form/horizontal-form-elements/container-elements/health-card-form-elements';
import VeteranCardFormElement from '../form/horizontal-form-elements/container-elements/veteran-card-form-element';

const PREFIX = 'ConcessionCardsPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  concessionCardPaper: `${PREFIX}-concessionCardPaper`,
  updateDetailsBasic: `${PREFIX}-updateDetailsBasic`,
  input: `${PREFIX}-input`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  inputContainer: `${PREFIX}-inputContainer`,
  button: `${PREFIX}-button`,

  gridWrapper: `${PREFIX}-gridWrapper`,
};

const StyledContainer = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      width: '100%',
      padding: '1rem',
    },
  },

  [`& .${classes.concessionCardPaper}`]: {
    padding: '0px 20px 40px 20px',
    width: '85%',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      width: '100%',
      padding: '1rem',
    },
  },

  [`& .${classes.updateDetailsBasic}`]: {
    padding: '0 10px 0 25px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.accountGridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 0',
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.inputContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto 10px auto',
    width: '140px',
    display: 'block',
  },
}));

const ConcessionCardsPresentation = (props) => {
  const { formInputs, handleNext, concessionCurrentFormObjects, patientId, setFormInputs, isUpdateDetails } = props;

  return (
    <>
      {concessionCurrentFormObjects.map((currentFormObject, ind) => {
        if (currentFormObject.elementType === 'medicare card') {
          return (
            <Grid key={currentFormObject.elementType} className={classes.accountGridContainer}>
              <MedicareCardFormElement
                isUpdateDetails
                patientId={patientId}
                currentFormObject={currentFormObject}
                handleNext={handleNext}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </Grid>
          );
        }
        if (currentFormObject.elementType === 'health care card') {
          return (
            <Grid key={currentFormObject.elementType} className={classes.accountGridContainer}>
              <HealthCardFormElement
                isUpdateDetails
                patientId={patientId}
                currentFormObject={currentFormObject}
                handleNext={handleNext}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </Grid>
          );
        }
        if (currentFormObject.elementType === 'pensioner concession card') {
          return (
            <Grid key={currentFormObject.elementType} className={classes.accountGridContainer}>
              <HealthCardFormElement
                isUpdateDetails
                patientId={patientId}
                currentFormObject={currentFormObject}
                handleNext={handleNext}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </Grid>
          );
        }
        if (currentFormObject.elementType === 'commonwealth senior health card') {
          return (
            <Grid key={currentFormObject.elementType} className={classes.accountGridContainer}>
              <HealthCardFormElement
                isUpdateDetails
                patientId={patientId}
                currentFormObject={currentFormObject}
                handleNext={handleNext}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </Grid>
          );
        }
        if (currentFormObject.elementType === 'veterans card') {
          return (
            <Grid key={currentFormObject.elementType} className={classes.accountGridContainer}>
              <VeteranCardFormElement
                isUpdateDetails
                patientId={patientId}
                currentFormObject={currentFormObject}
                handleNext={handleNext}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </Grid>
          );
        }
        return null;
      })}
    </>
  );
};

export default ConcessionCardsPresentation;
