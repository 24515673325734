export const getPharmacyStateByName = (data) => {
  switch (data) {
    case 'NSW':
      return 'New South Wales';
    case 'VIC':
      return 'Victoria';
    case 'QLD':
      return 'Queensland';
    case 'SA':
      return 'South Australia';
    case 'WA':
      return 'Western Australia';
    case 'TAS':
      return 'Tasmania';
    case 'NT':
      return 'Northern Territory';
    case 'ACT':
      return 'Australian Capital Territory';
    default:
      return 'Non Pharmacy';
  }
};

const NON_PHARMACY = 'Non Pharmacy';

export const sortPharmaciesByStateAndAlphabetically = (data) => {
  // map data and replace item shipping state by getPharmacyStateByName
  // sort data by name
  const formattedandSortedData = data
    .map((item) => {
      if (item.shipping?.state && item.type === 'pharmacy') {
        const state = getPharmacyStateByName(item.shipping?.state);
        return { ...item, shipping: { ...item.shipping, state } };
      }
      return { ...item, shipping: { ...item.shipping, state: NON_PHARMACY } };
    })
    .sort((a, b) => a.name?.toLowerCase().localeCompare(b.name?.toLowerCase()));

  // A priority map so we have a basis for sorting
  const orderPriority = formattedandSortedData
    .map((o) => o.shipping?.state)
    .sort((a, b) => {
      if (a === NON_PHARMACY) return -1;
      if (b === NON_PHARMACY) return 1;
      return b.localeCompare(a);
    })
    .reduce((acc, state, idx) => {
      if (!acc[state]) return { ...acc, [state]: idx };
      return acc;
    }, {});

  // Now do the sort based on the priorty map created:
  return [...formattedandSortedData].sort(
    (a, b) => orderPriority[b.shipping?.state] - orderPriority[a.shipping?.state],
  );
};
