import React from 'react';
import { Button, MenuItem } from '@mui/material';
import { doc, deleteField, updateDoc } from 'firebase/firestore';
import { KeyboardReturn as KeyboardReturnIcon } from '@mui/icons-material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../../../firebase-config';
import { SHIPMENT_TYPES, SUPPORT_ID } from '../../../../../utils/constants';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';
import { ModalContainer } from '../ModalContainer';

const { OUTSTANDING } = SHIPMENT_TYPES;

const RETURN_TO_PHARMA_MESSAGES = {
  SUCCESS: 'Order returned to pharmacy successfully',
  FAILURE: 'Failure to return this order, please try again',
};

const ReturnToPharmaModalContent = () => {
  const { order, handleDialogue } = useOrderDetailsContext();
  const { pharmacy, snackbar } = usePharmacyContext();

  const [isReturnToPharmaLoading, setIsReturnToPharmaLoading] = React.useState(false);
  const orderId = pharmacy?.id || SUPPORT_ID;

  const outStandingOrderRef = doc(db, 'orders', orderId, OUTSTANDING, order?.payment);

  const handleModalClose = () => {
    setIsReturnToPharmaLoading(false);
    handleDialogue({ isOpen: false, content: null });
  };

  const handleAllModalClose = () => {
    handleDialogue({ isOpen: false, content: null });
  };

  const returnToPharmaOrder = async () => {
    setIsReturnToPharmaLoading(true);

    try {
      await updateDoc(outStandingOrderRef, { revertReason: deleteField() });
      snackbar(RETURN_TO_PHARMA_MESSAGES.SUCCESS);
      handleAllModalClose();
    } catch (error) {
      sentryCaptureException(error, { extra: { order, userId: orderId, issueIn: 'return to pharmacy order' } });
      snackbar(RETURN_TO_PHARMA_MESSAGES.FAILURE);
    } finally {
      handleModalClose();
    }
  };

  return (
    <ModalContainer
      text="Are you sure you want to return this to phamacy?"
      onClose={handleModalClose}
      handleProceed={returnToPharmaOrder}
      loadingComponent={isReturnToPharmaLoading}
    />
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal
 */
export const ReturnToPharma = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<ReturnToPharmaModalContent />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<KeyboardReturnIcon />}>
        Return To Pharma
      </Button>
    </MenuItem>
  );
};
