import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const MounjaroInformation = () => (
  <Grid>
    <Typography variant="h6">Mounjaro</Typography>
    <br />
    <Typography variant="body1">
      Mounjaro (tirzepatide) is an injectable medication used to assist with weight loss.
    </Typography>
    <br />
    <Typography variant="h6">Warnings</Typography>
    <br />
    <Typography variant="body1">
      Mounjaro may cause tumors in the thyroid, including thyroid cancer. Watch for possible symptoms, such as a lump or
      swelling in the neck, hoarseness, trouble swallowing, or shortness of breath. If you have any of these symptoms,
      tell your healthcare provider.
      <br />
      <br />
      - Do not use Mounjaro if you or any of your family have ever had a type of thyroid cancer called medullary thyroid
      carcinoma (MTC).
      <br />
      - Do not use Mounjaro if you have Multiple Endocrine Neoplasia syndrome type 2 (MEN 2).
      <br />
      - Do not use Mounjaro if you are allergic to it or any of the ingredients in Mounjaro.
      <br />
      <br />
      Mounjaro should not be used in patients with:
      <br />
      <br />- Pancreatitis or inflammation of the pancreas
      <br />- Type 1 diabetes
      <br />
      <br />
      Birth control pills may not work as well while using Mounjaro. Talk to your doctor if you take the contraceptive
      pill. They may recommend additional contraception or changing contraception while using Mounjaro.
      <br />
      <br />
      It is not known if Mounjaro will harm your unborn baby or pass into your breast milk. It is not recommended to use
      Mounjaro if pregnant, breastfeeding or trying to conceive.
    </Typography>
    <br />
    <Typography variant="h6">Serious side effects</Typography>
    <br />
    <Typography variant="body1">
      Mounjaro may cause serious side effects including:
      <br />
      <br />- Inflammation of the pancreas (pancreatitis). Stop using Mounjaro and call your healthcare provider right
      away if you have severe pain in your stomach area (abdomen) that will not go away, with or without vomiting. You
      may feel the pain from your abdomen to your back.
      <br />- Low blood sugar (hypoglycemia). Your risk for getting low blood sugar may be higher if you use Mounjaro
      with another medicine that can cause low blood sugar, such as a sulfonylurea or insulin. Signs and symptoms of low
      blood sugar may include dizziness or light-headedness, sweating, confusion or drowsiness, headache, blurred
      vision, slurred speech, shakiness, fast heartbeat, anxiety, irritability, or mood changes, hunger, weakness and
      feeling jittery.
      <br />- Serious allergic reactions. Stop using Mounjaro and get medical help right away if you have any symptoms
      of a serious allergic reaction, including swelling of your face, lips, tongue or throat, problems breathing or
      swallowing, severe rash or itching, fainting or feeling dizzy, and very rapid heartbeat.
      <br />- Kidney problems (kidney failure). In people who have kidney problems, diarrhea, nausea, and vomiting may
      cause a loss of fluids (dehydration), which may cause kidney problems to get worse. It is important for you to
      drink fluids to help reduce your chance of dehydration.
      <br />- Severe stomach problems. Stomach problems, sometimes severe, have been reported in people who use
      Mounjaro. Tell your healthcare provider if you have stomach problems that are severe or will not go away.
      <br />- Changes in vision. Tell your healthcare provider if you have changes in vision during treatment with
      Mounjaro.
      <br />- Gallbladder problems. Gallbladder problems have happened in some people who use Mounjaro. Tell your
      healthcare provider right away if you get symptoms of gallbladder problems, which may include pain in your upper
      stomach (abdomen), fever, yellowing of skin or eyes (jaundice), and clay-colored stools.
    </Typography>
    <br />
    <Typography variant="h6">Common side effects</Typography>
    <br />
    <Typography variant="body1">
      The most common side effects of Mounjaro include nausea, diarrhea, decreased appetite, vomiting, constipation,
      indigestion, and stomach (abdominal) pain
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      - Read the Instructions for Use that come with Mounjaro.
      <br />
      - Use Mounjaro exactly as your healthcare provider says.
      <br />
      - Draw up the medication using a sterile syringe
      <br />- Mounjaro is injected under the skin (subcutaneously) of your stomach (abdomen), thigh, or upper arm.
      <br />- Use Mounjaro 1 time each week, at any time of the day.
      <br />- Do not mix insulin and Mounjaro together in the same injection.
      <br />- You may give an injection of Mounjaro and insulin in the same body area (such as your stomach area), but
      not right next to each other.
      <br />- Change (rotate) your injection site with each weekly injection. Do not use the same site for each
      injection.
      <br />- Dispose of sharps appropriately. Do not reuse syringes.
    </Typography>
  </Grid>
);

export default MounjaroInformation;
