import React from 'react';
import { Grid2 as Grid, Typography, Button, Stack } from '@mui/material';

/**
 * @param {Object} props
 * @param {string} props.text
 * @param {React.ReactNode | undefined} [props.subtext='']
 * @param {string} [props.confirmText='Confirm']
 * @param {string} [props.cancelText='Cancel']
 * @param {() => void} props.onClose
 * @param {() => void} props.handleProceed
 * @param {boolean} [props.loadingComponent=false]
 */
export const ModalContainer = ({
  text,
  subtext = '',
  onClose,
  handleProceed,
  loadingComponent = false,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => (
  <Grid container>
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5" align="center" gutterBottom>
          {text}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography variant="body2" align="center" gutterBottom component="div">
          {subtext}
        </Typography>
      </Grid>
      <Stack direction="row" gap={2} justifyContent="flex-end" width="100%" alignItems="center" pt={2}>
        <Button variant="outlined" color="primary" onClick={handleProceed} loading={loadingComponent}>
          {confirmText}
        </Button>
        <Button variant="outlined" color="error" onClick={onClose}>
          {cancelText}
        </Button>
      </Stack>
    </Grid>
  </Grid>
);
