import React from 'react';

import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

/**
 * Renders an error alert banner with a customizable message.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {boolean} props.isToShow - Determines whether the alert banner should be displayed. If `false`, the component returns `null`.
 * @param {string} props.alertMessage - The message to display within the alert banner.
 *
 * @returns {React.ReactElement|null} A styled error alert banner if `isToShow` is `true`, otherwise `null`.
 *
 * @example
 * // Example usage:
 * <AlertBanner
 *   isToShow={true}
 *   alertMessage="An error occurred. Please try again."
 * />
 */
const AlertBanner = ({ isToShow, alertMessage }) => {
  if (!isToShow) {
    return null;
  }

  return (
    <Alert variant="outlined" severity="error" sx={{ color: '#EB8382', borderColor: '#EB8382' }}>
      {alertMessage}
    </Alert>
  );
};

AlertBanner.propTypes = {
  isToShow: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string.isRequired,
};

export default AlertBanner;
