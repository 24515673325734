import {
  Grid2 as Grid,
  Radio,
  Button,
  MenuItem,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import React from 'react';
import { httpsCallable } from 'firebase/functions';
import { captureException, captureMessage } from '@sentry/react';
import { doc, collection, writeBatch, getDoc } from 'firebase/firestore';
import { Redo as RedoIcon } from '@mui/icons-material';

import { db, functions } from '../../../../../firebase-config';
import { getCatalogueId } from '../../../../../utils/constants';
import { sortPharmaciesByStateAndAlphabetically } from '../../../../../utils/pharmacies-helper';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { usePharmacyDirectory } from '../../../hooks/usePharmacyDirectory';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';

const transferStorageFiles = httpsCallable(functions, 'transferStorageFiles');

const PHARMACY_NO_STOCK = ' - Pharmacy does not have required stock';
const EMAIL_TEMPLATES = {
  PHARMACY_INTRO: 'pharmacy_introduction',
  PHARMACY_UPDATE: 'pharmacy_update_notice',
  ORDER_REMOVED: 'order_removed_from_portal',
};

const mailRef1 = doc(collection(db, 'mail'));
const mailRef2 = doc(collection(db, 'mail'));
const mailRef3 = doc(collection(db, 'mail'));

const getItemsArray = ({ shipment, catalogue }) =>
  shipment.scriptsArray.map(({ priceRef }) => {
    const catalogueItem = catalogue[getCatalogueId(priceRef)];
    return catalogueItem?.item || 'Unknown Item';
  });

const getIsSomeHasNoStock = ({ scriptsArray, catalogue, pharmacyId }) =>
  scriptsArray.some((script) => {
    const catalogueItem = catalogue[getCatalogueId(script.priceRef)];
    return !catalogueItem?.stock?.[pharmacyId];
  });

const hasStateHeader = ({ index, sortedPharmacies }) =>
  sortedPharmacies[index]?.shipping?.state !==
  sortedPharmacies?.[index - 1 >= 0 ? index - 1 : sortedPharmacies.length - 1]?.shipping?.state;

const processRedirectOrder = async ({
  availablePharmacies,
  catalogue,
  currentPharmacyId,
  order,
  handleModalClose,
  setIsRedirectOrderLoading,
  snackbar,
  selectedPharmacy,
  patientData,
}) => {
  try {
    setIsRedirectOrderLoading(true);
    const date = Date.now();
    const batch = writeBatch(db);

    const outstandingSelectedRef = doc(db, 'orders', selectedPharmacy, 'outstanding', order.payment);
    const outstandingCurrentRef = doc(db, 'orders', currentPharmacyId, 'outstanding', order.payment);
    const patientRef = doc(db, 'patients', order.user);
    const patientActivityRef = doc(collection(db, 'patients', order.user, 'activity'));
    const patientPurchaseRef = doc(db, 'patients', order.user, 'purchase_history', order.payment);
    const patientConversationRef = doc(db, 'conversations', `${order.user}:${selectedPharmacy}`);
    const shipment = { ...order, updated: date };

    const orderPrices = [];
    const oldPharmacyEmail = shipment.pharmacyInfo.email;
    const {
      email: selectedPharmacyEmail = '',
      name: selectedPharmacyName = '',
      phone: selectedPharmacyPhone = '',
      shipping: selectedPharmacyShipping = {
        street: '',
        suburb: '',
      },
    } = availablePharmacies[selectedPharmacy];
    delete shipment.revertReason;

    const emailData = {
      patientName: shipment.name.split(' ')[0],
      pharmacyEmail: selectedPharmacyEmail,
      pharmacyName: selectedPharmacyName,
      pharmacyPhone: selectedPharmacyPhone,
    };

    const promises = shipment.scriptsArray.map(async (script) => {
      const price = script.priceRef;
      const catalogueId = getCatalogueId(price);
      const catalogueRef = doc(db, 'catalogue', catalogueId);
      const stock = catalogue[catalogueId]?.stock || {};
      const { remaining = 0, originalRemaining = 0 } = patientData.prescriptions?.[shipment.formName]?.[price] || {};

      // Update stock for both pharmacies
      const stockUpdateBatch = writeBatch(db);
      stockUpdateBatch.update(catalogueRef, { [`stock.${currentPharmacyId}`]: (stock?.[currentPharmacyId] || 0) + 1 });
      stockUpdateBatch.update(catalogueRef, { [`stock.${selectedPharmacy}`]: (stock?.[selectedPharmacy] || 0) - 1 });
      await stockUpdateBatch.commit();

      orderPrices.push(price);
      if (script.eScriptToken) {
        return script;
      }
      if (orderPrices.filter((item) => item === price).length === 1) {
        try {
          const oldScriptPath = `pharmacy/${currentPharmacyId}/prescriptions/${shipment.user}/${price}`;
          const newScriptPath = `pharmacy/${selectedPharmacy}/prescriptions/${shipment.user}/${price}`;
          await transferStorageFiles({ oldPath: oldScriptPath, newPath: newScriptPath, action: 'move' });
          if (shipment.formName === 'medicinal cannabis') {
            const oldTgaPath = `pharmacy/${currentPharmacyId}/tga/${shipment.user}/${price}`;
            const newTgaPath = `pharmacy/${selectedPharmacy}/tga/${shipment.user}/${price}`;
            await transferStorageFiles({ oldPath: oldTgaPath, newPath: newTgaPath, action: 'move' });
          }
        } catch (error) {
          captureMessage('Failure to transfer script/tga files', {
            level: 'warning',
            extra: { shipment, patientId: shipment.user, issueIn: 'redirectOrder' },
          });
        }
        return {
          ...script,
          scriptOnFile: false,
          transfer: shipment.pharmacyInfo.name,
          remainingCount: remaining + 1,
          ...(originalRemaining && { originalRemaining }),
        };
      }
      return {
        ...script,
        scriptOnFile: false,
        transfer: shipment.pharmacyInfo.name,
        remainingCount: remaining + 1,
        ...(originalRemaining && { originalRemaining }),
      };
    });

    try {
      // Await Promise.all here so we wait for all promises to finish
      shipment.scriptsArray = await Promise.all(promises);
    } catch (error) {
      captureException(error, { extra: { shipment, issueIn: 'redirectOrder' } });
    }

    const activityData = {
      createdAt: date,
      generalData: true,
      author: 'System',
      text: `Patient prescription for ${getItemsArray({ shipment, catalogue }).join(' & ')} was redirected to ${
        emailData.pharmacyName
      }`,
    };

    shipment.pharmacyInfo = {
      id: selectedPharmacy,
      name: selectedPharmacyName,
      email: selectedPharmacyEmail,
      phone: selectedPharmacyPhone,
      address: `${selectedPharmacyShipping.street} ${selectedPharmacyShipping.suburb}`,
    };

    batch.set(mailRef1, {
      to: `${shipment.name} <${shipment.email}>`,
      cc: selectedPharmacyEmail,
      template: { name: EMAIL_TEMPLATES.PHARMACY_INTRO, data: emailData },
    });
    batch.set(mailRef2, {
      to: `${shipment.name} <${shipment.email}>`,
      template: { name: EMAIL_TEMPLATES.PHARMACY_UPDATE, data: { patientName: emailData.patientName } },
    });
    batch.set(mailRef3, {
      to: oldPharmacyEmail,
      template: { name: EMAIL_TEMPLATES.ORDER_REMOVED, data: { patientName: shipment.name } },
    });
    batch.set(outstandingSelectedRef, shipment);
    batch.set(patientActivityRef, activityData);
    batch.update(patientPurchaseRef, {
      pharmacyInfo: {
        id: selectedPharmacy,
        email: selectedPharmacyEmail,
        name: selectedPharmacyName,
        phone: selectedPharmacyPhone,
        address: `${selectedPharmacyShipping.street}, ${selectedPharmacyShipping.suburb}`,
      },
    });

    const userDocUpdateObj = shipment.scriptsArray.reduce((acc, script) => {
      const userDocPath = `prescriptions.${shipment.formName}.${script.priceRef}.pharmacy`;

      if (acc[userDocPath] || script.eScriptToken) {
        return acc;
      }

      return { ...acc, [userDocPath]: selectedPharmacyName };
    }, {});

    const doesConversationExist = (await getDoc(patientConversationRef)).exists();

    if (!doesConversationExist) {
      batch.set(patientConversationRef, {
        online: true,
        participant1: {
          id: shipment.user,
          email: shipment.email,
          name: shipment.name,
          notifications: 0,
          role: 'patient',
        },
        participant2: {
          id: selectedPharmacy,
          email: selectedPharmacyEmail,
          name: selectedPharmacyName,
          notifications: 0,
          role: 'pharmacist',
        },
        reference: `${shipment.user}:${selectedPharmacy}`,
        updated: date,
      });
    }

    batch.update(patientRef, userDocUpdateObj);
    batch.delete(outstandingCurrentRef);

    await batch.commit();

    snackbar('Order Redirected Successfully');
    handleModalClose();
  } catch (error) {
    handleModalClose();
    captureException(error, { extra: { shipment: order, issueIn: 'redirectOrder' } });
    snackbar('Failed to redirect order. Please try again.');
  } finally {
    setIsRedirectOrderLoading(false);
  }
};

const RedirectOrderModalContent = () => {
  const { catalogue, pharmacy, snackbar } = usePharmacyContext();
  const { order, handleDialogue } = useOrderDetailsContext();
  const { pharmacies } = usePharmacyDirectory({ pharmaciesOnly: true });
  const [selectedPharmacy, setSelectedPharmacy] = React.useState('');
  const [availablePharmacies, setAvailablePharmacies] = React.useState({});
  const [sortedPharmacies, setSortedPharmacies] = React.useState([]);
  const [patientData, setPatientData] = React.useState({});
  const [isRedirectOrderLoading, setIsRedirectOrderLoading] = React.useState(false);
  const [showOnlyWithStock, setShowOnlyWithStock] = React.useState(false);

  const handleModalClose = () => {
    handleDialogue({ isOpen: false, content: null });
  };

  const handleRedirectButton = async () => {
    await processRedirectOrder({
      order,
      catalogue,
      patientData,
      selectedPharmacy,
      availablePharmacies,
      currentPharmacyId: pharmacy?.id || '',
      snackbar,
      handleModalClose,
      setIsRedirectOrderLoading,
    });
  };

  const getPharmacies = React.useCallback(async () => {
    if (!order) {
      return;
    }

    const patientDoc = (await getDoc(doc(db, 'patients', order.user))).data();

    const tempAvailablePharmacies = pharmacies.reduce((acc, pharma) => {
      // remove the current pharmacy from the list
      if (pharma.id === pharmacy?.id) {
        return acc;
      }

      return { ...acc, [pharma.id]: pharma };
    }, {});

    const tempSortedStatePharmacies = sortPharmaciesByStateAndAlphabetically(Object.values(tempAvailablePharmacies));

    setAvailablePharmacies(tempAvailablePharmacies);
    setSortedPharmacies(tempSortedStatePharmacies);
    setPatientData(patientDoc);
  }, [order, pharmacies, pharmacy?.id, setAvailablePharmacies, setSortedPharmacies, setPatientData]);

  React.useEffect(() => {
    if (!order || !pharmacies) {
      return;
    }

    getPharmacies();
  }, [getPharmacies, order, pharmacies]);

  if (!order || !pharmacies) {
    return null;
  }

  return (
    <Stack direction="column" gap={2} width="100%" alignItems="center" justifyContent="center" minWidth="500px">
      <Grid size={{ xs: 12 }} width="100%">
        <FormControl fullWidth>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }} color="primary">
            Redirect Order
          </Typography>

          <FormControlLabel
            control={<Switch checked={showOnlyWithStock} onChange={(e) => setShowOnlyWithStock(e.target.checked)} />}
            label="Only show pharmacies with stock"
            sx={{ mb: 2 }}
          />

          <RadioGroup
            onChange={(e) => {
              setSelectedPharmacy(e.target.value);
            }}
            value={selectedPharmacy || ''}
          >
            {sortedPharmacies?.map(({ id: pharmacyId, name: pharmacyName, shipping }, index) => {
              if (pharmacyId === pharmacy) {
                return null;
              }

              const isSomeHasNoStock = getIsSomeHasNoStock({
                scriptsArray: order.scriptsArray,
                catalogue,
                pharmacyId,
              });

              // Skip pharmacies without stock if filter is enabled
              if (showOnlyWithStock && isSomeHasNoStock) {
                return null;
              }

              const label = `${pharmacyName}${isSomeHasNoStock ? PHARMACY_NO_STOCK : ''}`;

              return (
                <React.Fragment key={pharmacyId}>
                  {hasStateHeader({ index, sortedPharmacies }) && (
                    <Typography variant="body1" color="primary">
                      {shipping?.state}
                    </Typography>
                  )}
                  <FormControlLabel value={pharmacyId} control={<Radio />} label={label} />
                </React.Fragment>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button
          disabled={!selectedPharmacy}
          variant="contained"
          color="primary"
          onClick={handleRedirectButton}
          loading={isRedirectOrderLoading}
        >
          Redirect Order
        </Button>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button onClick={handleModalClose}>Cancel</Button>
      </Grid>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal
 */
export const RedirectOrder = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<RedirectOrderModalContent />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<RedoIcon />}>
        Redirect Order
      </Button>
    </MenuItem>
  );
};
