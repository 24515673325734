import {
  Paper,
  CircularProgress,
  Stack,
  Chip,
  Box,
  useTheme,
  Typography,
  IconButton,
  Divider,
  Button,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseRounded } from '@mui/icons-material';
import { parseAsString, useQueryState } from 'nuqs';

import { SHIPMENT_TYPES } from '../../../utils/constants';
import { parseStatus } from '../helpers';
import { STATUS_ICON } from '../constants';
import { useOrders, useAuthContext } from '../hooks';
import { OrderList } from './OrderList';
import { OrderFilters } from './OrderFilters';
import { OrderDetailsModal } from './OrderDetailsModal';
import { parseCurrentStatus } from '../helpers/parseCurrentStatus';
import { OrderDetailsToolsButton } from './OrderDetailsToolsButton';
import { OrderExportCSV } from './OrderDetailsToolsButton/OrderExportCSV';
import { PickingManifest } from './OrderDetailsToolsButton/PickingManifest';
import { MassAddTracking } from './OrderDetailsToolsButton/MassAddTracking/MassAddTracking';
import { AusPostMerchantPortalCsvExporter } from './OrderDetailsToolsButton/ExportOrdersToCSV/AusPostMerchantPortalCsvExporter';

/**
 * @param {Object} props
 * @param {ExtendedOrder[]} props.orders - The orders.
 * @param {import('./OrderFilters').Filters} props.filters - The filters.
 * @returns {ExtendedOrder[]} - The filtered orders.
 */
const getFilteredOrders = ({ orders, filters }) => {
  const filtered = orders.filter((order) => {
    let shouldInclude = true;

    if (filters.search) {
      shouldInclude = order.name.toLowerCase().includes(filters.search.toLowerCase());
    }

    if (filters.status) {
      shouldInclude = shouldInclude && order.status === filters.status;
    }

    if (filters.currentStatus && filters.currentStatus.length > 0) {
      shouldInclude = shouldInclude && filters.currentStatus.includes(order.currentStatus);
    }

    return shouldInclude;
  });

  if (filters.sortBy) {
    filtered.sort((a, b) => {
      switch (filters.sortBy) {
        case 'newest':
          return b.updated - a.updated;
        case 'oldest':
          return a.updated - b.updated;
        case 'name':
          return (a.name ?? '').localeCompare(b.name ?? '');
        case 'status':
          return (a.status ?? '').localeCompare(b.status ?? '');
        case 'noi':
          return (b.scriptsArray?.length ?? 0) - (a.scriptsArray?.length ?? 0);
        default:
          return b.updated - a.updated;
      }
    });
  }

  return filtered;
};

const getStatusIcon = (status) => {
  const Icon = STATUS_ICON[status];

  if (!Icon) {
    return undefined;
  }

  return <Icon sx={{ px: '0.1rem !important' }} />;
};

/**
 * @param {Object} props
 * @param {boolean} props.isHistorical - Whether the orders are historical.
 * @param {string} props.activeStatus - The active status.
 * @param {Object} props.statusCounts - The status counts.
 * @param {Function} props.setFilters - The filters setter.
 * @param {number} props.numberOfOrders - The number of orders.
 * @param {number} props.numberOfFilteredOrders - The number of filtered orders.
 * @returns {React.ReactNode} - The status count section.
 */
const StatusCountSection = ({
  isHistorical,
  activeStatus,
  statusCounts,
  setFilters,
  numberOfOrders,
  numberOfFilteredOrders,
}) => {
  if (isHistorical) {
    return null;
  }

  /**
   * @param {string} status - The status to filter by.
   */
  const handleFilter = (status) => {
    setFilters((prevFilters) => ({ ...prevFilters, status }));
  };

  const handleClearFilters = () => {
    setFilters((prevFilters) => ({ ...prevFilters, status: null }));
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      <Typography variant="caption" color="text.secondary">
        Total:{' '}
        {numberOfOrders === numberOfFilteredOrders ? numberOfOrders : `${numberOfFilteredOrders} of ${numberOfOrders}`}
      </Typography>
      {Object.entries(statusCounts).map(([status, count]) => (
        <Chip
          key={`${status}-${activeStatus}`}
          label={`${count} ${parseStatus(status)}${count > 1 && status !== SHIPMENT_TYPES.SCRIPT_ONLY ? 's' : ''}`}
          icon={getStatusIcon(status)}
          color="primary"
          variant={activeStatus === status ? 'filled' : 'outlined'}
          size="small"
          sx={{ textTransform: 'capitalize' }}
          onClick={() => handleFilter(status)}
        />
      ))}

      {activeStatus && (
        <IconButton size="small" onClick={handleClearFilters}>
          <CloseRounded sx={{ fontSize: 16 }} />
        </IconButton>
      )}
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {OrderCurrentStatus[] | undefined} props.currentStatuses - The current statuses.
 * @param {React.Dispatch<React.SetStateAction<import('./OrderFilters').Filters>>} props.setFilters - The filters setter.
 * @returns {React.ReactNode} - The current status count section.
 */
const CurrentStatusCountSection = ({ currentStatuses = [], setFilters }) => {
  /**
   * Remove
   * @param {OrderCurrentStatus} status - The status to remove.
   */
  const handleRemoveStatus = (status) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      currentStatus: prevFilters.currentStatus.filter((s) => s !== status),
    }));
  };

  if (!currentStatuses || currentStatuses.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
      {currentStatuses.map((status) => (
        <Chip
          key={status}
          label={parseCurrentStatus(status)}
          size="small"
          color="primary"
          sx={{ fontSize: 12 }}
          onDelete={() => handleRemoveStatus(status)}
        />
      ))}
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {ExtendedOrder[]} props.filteredOrders - The filtered orders.
 * @param {React.Dispatch<React.SetStateAction<import('./OrderFilters').Filters>>} props.setFilters - The filters setter.
 * @param {OrderCurrentStatus[] | undefined} props.currentStatuses - The current statuses.
 * @returns {React.ReactNode} - The orders count section.
 */
const OrdersCount = ({ filteredOrders, setFilters, currentStatuses = [] }) => {
  /**
   * @param {OrderCurrentStatus} status - The status to filter by.
   */
  const handleClick = (status) => {
    setFilters((prevFilters) => ({ ...prevFilters, currentStatus: [status] }));
  };

  const statusCounts = filteredOrders.reduce((acc, order) => {
    if (!order.currentStatus) {
      return acc;
    }

    acc[order.currentStatus] = (acc[order.currentStatus] || 0) + 1;

    return acc;
  }, /** @type {Record<OrderCurrentStatus, number>} */ ({}));

  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
      {Object.entries(statusCounts).map(([status, count]) => {
        if (count === 0) {
          return null;
        }

        if (currentStatuses?.includes(/** @type {OrderCurrentStatus} */ (status))) {
          return null;
        }

        return (
          <React.Fragment key={status}>
            <Button
              variant="text"
              size="small"
              key={status}
              sx={{ textTransform: 'capitalize' }}
              onClick={() => handleClick(/** @type {OrderCurrentStatus} */ (status))}
            >
              {count} {parseCurrentStatus(/** @type {OrderCurrentStatus} */ (status)).toLowerCase()}
            </Button>
            <Divider orientation="vertical" flexItem sx={{ '&:last-child': { display: 'none' } }} />
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {boolean} props.isHistorical - Whether the orders are historical.
 * @param {import('./OrderFilters').Filters} props.filters - The filters.
 * @param {string} props.pharmacyId - The pharmacy ID.
 * @returns {React.ReactNode} - The tools section.
 */
const ToolsSection = ({ isHistorical, filters, pharmacyId }) => {
  const { user } = useAuthContext();
  const isShipmentRequired = filters.status === SHIPMENT_TYPES.SHIPMENT_REQUIRED;
  const canViewTools = pharmacyId === user?.uid;

  if (!canViewTools || isHistorical) {
    return null;
  }

  return (
    <OrderDetailsToolsButton>
      <PickingManifest orderType={filters.status} />
      {isShipmentRequired && (
        <>
          <OrderExportCSV />
          <AusPostMerchantPortalCsvExporter />
          <MassAddTracking />
        </>
      )}
    </OrderDetailsToolsButton>
  );
};

/**
 * @param {Object} props
 * @param {string} props.pharmacyId - The pharmacy ID.
 * @param {boolean} props.isHistorical - Whether the orders are historical.
 * @param {import('./OrderFilters').Filters} props.filters - The filters.
 * @param {React.Dispatch<React.SetStateAction<import('./OrderFilters').Filters>>} props.setFilters - The filters setter.
 * @returns {React.ReactNode} - The orders component.
 */
export const Orders = ({ pharmacyId, isHistorical, filters, setFilters }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useQueryState('orderId', parseAsString);
  const listRef = React.useRef(/** @type {HTMLUListElement | null} */ (null));

  const { orders, loading, error } = useOrders({
    pharmacyId,
    isHistorical,
    fromDate: filters.fromDate,
    toDate: filters.toDate,
  });

  const filteredOrders = React.useMemo(() => getFilteredOrders({ orders, filters }), [orders, filters]);

  const { selectedOrder, orderIdx } = React.useMemo(() => {
    const selectedOrderIdx = filteredOrders.findIndex((order) => order.id === orderId);
    const selected = selectedOrderIdx !== -1 ? filteredOrders[selectedOrderIdx] : null;

    return {
      selectedOrder: selected,
      orderIdx: selectedOrderIdx,
    };
  }, [filteredOrders, orderId]);

  const handleViewOrder = React.useCallback(
    /**
     * @param {string} selectedOrderId - The order ID.
     */
    (selectedOrderId) => {
      navigate(`/pharmacies/${pharmacyId}/orders/${selectedOrderId}`);
    },
    [navigate, pharmacyId],
  );

  const handleQuickView = React.useCallback(
    /**
     * @param {string} selectedOrderId - The order ID.
     */
    (selectedOrderId) => {
      setOrderId(selectedOrderId);
    },
    [setOrderId],
  );

  const handleCloseModal = React.useCallback(
    (orderIndex) => {
      setOrderId(null);

      const element = listRef.current?.getElementsByTagName('li')[orderIndex];
      element?.setAttribute('active', 'true');
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // clear active attribute after 2 seconds
      setTimeout(() => {
        element?.removeAttribute('active');
      }, 1000);
    },
    [setOrderId],
  );

  const handlePrevious = React.useCallback(() => {
    const currentIndex = filteredOrders.findIndex((order) => order.id === orderId);
    setOrderId(filteredOrders[currentIndex - 1].id);
  }, [filteredOrders, orderId, setOrderId]);

  const handleNext = React.useCallback(() => {
    const currentIndex = filteredOrders.findIndex((order) => order.id === orderId);
    setOrderId(filteredOrders[currentIndex + 1].id);
  }, [filteredOrders, orderId, setOrderId]);

  const statusCounts = React.useMemo(
    () =>
      orders.reduce((acc, order) => {
        acc[order.status] = (acc[order.status] || 0) + 1;

        return acc;
      }, {}),
    [orders],
  );

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ py: 4 }}>
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return <div>Error loading orders {error.message}</div>;
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: theme.palette.background.paper,
          py: 2,
        }}
      >
        <OrderFilters isHistorical={isHistorical} filters={filters} onFiltersChange={setFilters} orders={orders} />

        {!isHistorical && (
          <>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" p={1} height={42}>
              <StatusCountSection
                isHistorical={isHistorical}
                activeStatus={filters.status}
                statusCounts={statusCounts}
                setFilters={setFilters}
                numberOfOrders={orders.length}
                numberOfFilteredOrders={filteredOrders.length}
              />

              <ToolsSection isHistorical={isHistorical} filters={filters} pharmacyId={pharmacyId} />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
              <CurrentStatusCountSection currentStatuses={filters.currentStatus} setFilters={setFilters} />

              <OrdersCount
                filteredOrders={filteredOrders}
                currentStatuses={filters.currentStatus}
                setFilters={setFilters}
              />
            </Stack>
          </>
        )}
      </Box>
      <OrderList
        isHistorical={isHistorical}
        listRef={listRef}
        orders={filteredOrders}
        onViewOrder={handleViewOrder}
        onQuickView={handleQuickView}
      />
      {selectedOrder && (
        <OrderDetailsModal
          onClose={() => handleCloseModal(orderIdx)}
          order={selectedOrder}
          orderIdx={orderIdx}
          numberOfOrders={filteredOrders.length}
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      )}
    </Paper>
  );
};
