import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { FileDownload } from '@mui/icons-material';

import { SHIPMENT_TYPES } from '../../../../../utils/constants';
import { useOrders } from '../../../hooks/useOrders';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { ExportModal } from './ExportModal';
import { useExportCsv } from './useExportCsv';

export const AusPostMerchantPortalCsvExporter = () => {
  const { pharmacy, handleDialogue } = usePharmacyContext();
  const { orders } = useOrders({
    pharmacyId: pharmacy.id,
    isHistorical: false,
  });

  const shipmentRequiredOrders = useMemo(
    () => orders.filter((o) => o.status === SHIPMENT_TYPES.SHIPMENT_REQUIRED),
    [orders],
  );

  const exportCsv = useExportCsv(pharmacy);

  const openModal = () => {
    handleDialogue({
      isOpen: true,
      content: (
        <ExportModal
          onExport={(signatureRequired) => {
            exportCsv({
              shipmentRequiredOrders,
              signatureRequired,
            });
          }}
          onClose={() => handleDialogue({ isOpen: false, content: null })}
        />
      ),
    });
  };

  return (
    <Button startIcon={<FileDownload />} onClick={openModal}>
      Export AusPost CSV - Merchant Portal
    </Button>
  );
};
