import { styled } from '@mui/material/styles';
import { Grid2 as Grid } from '@mui/material';

const PREFIX = 'PharmacistSection';

export const classes = {
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`,
  saveButton: `${PREFIX}-saveButton`,
  downloadLink: `${PREFIX}-downloadLink`,
  link: `${PREFIX}-link`,
  notifButton: `${PREFIX}-notifButton`,
  eScriptCheckbox: `${PREFIX}-eScriptCheckbox`,
};

export const StyledGrid = styled(Grid)(() => ({
  gap: 16,
  display: 'flex',
  flexDirection: 'column',
  [`& .${classes.textField}`]: {
    margin: '0px 10px 20px 0px',
    width: 300,
  },

  [`& .${classes.button}`]: {
    width: 220,
  },

  [`& .${classes.saveButton}`]: {
    padding: 7,
    width: 100,
    marginLeft: 10,
  },

  [`& .${classes.downloadLink}`]: {
    textTransform: 'none',
    marginBottom: '10px',
  },

  [`& .${classes.link}`]: {
    fontWeight: 'bold',
    textDecoration: 'none',
    position: 'relative',
    bottom: 8,
    marginBottom: 20,
  },

  [`& .${classes.notifButton}`]: {
    width: 'fit-content',
  },

  [`& .${classes.pickupButton}`]: {
    width: 'fit-content',
  },

  [`& .${classes.eScriptCheckbox}`]: {
    display: 'block',
    marginTop: 8,
  },
}));
