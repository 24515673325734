import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Typography, TextField, Button, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import { AuthContext } from '../../../auth-context';

const PREFIX = 'PharmacyInformationPresentation';

const classes = {
  container: `${PREFIX}-container`,
  backButton: `${PREFIX}-backButton`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  termsText: `${PREFIX}-termsText`,
  button: `${PREFIX}-button`,
  inputGrid: `${PREFIX}-inputGrid`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.backButton}`]: {
    padding: 5,
    marginLeft: 10,
  },

  [`& .${classes.paper}`]: {
    margin: '10px 10px 40px 10px',
    padding: '30px',
  },

  [`& .${classes.heading}`]: {
    marginBottom: 20,
  },

  [`& .${classes.termsText}`]: {
    marginBottom: 20,
  },

  [`& .${classes.button}`]: {
    margin: 'auto',
  },

  [`& .${classes.inputGrid}`]: {
    padding: '20px 10px',
    '@media (max-width: 567px)': {
      padding: '10px 0px',
    },
  },
}));

const PharmacyInformationPresentation = ({
  pharmacyConsentRead,
  setPharmacyConsentRead,
  preferredPharmacy,
  handleChange,
  handleBackButton,
  collectingCardInfo,
  setScriptMode,
}) => {
  const { userType } = useContext(AuthContext);

  return (
    <StyledGrid>
      <Button variant="contained" className={classes.backButton} onClick={handleBackButton}>
        {userType === 'director' ? 'Cancel' : 'Back'}
      </Button>
      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.container}>
          <Grid size={{ xs: 12 }}>
            <Typography className={classes.heading} variant="h5" gutterBottom>
              Pharmacy Information
            </Typography>
          </Grid>
          <Grid size={{ sm: 6 }} className={classes.inputGrid}>
            <TextField
              disabled={collectingCardInfo}
              error={preferredPharmacy.name?.trim() === ''}
              label="Pharmacy Name"
              required
              value={preferredPharmacy.name || ''}
              onChange={(e) => handleChange(e.target.value, 'name')}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid size={{ sm: 6 }} className={classes.inputGrid}>
            <TextField
              disabled={collectingCardInfo}
              error={preferredPharmacy.phone?.trim() === ''}
              label="Phamacy Phone Number"
              required
              value={preferredPharmacy.phone || ''}
              onChange={(e) => handleChange(e.target.value, 'phone')}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid size={{ sm: 12 }} className={classes.inputGrid}>
            <TextField
              disabled={collectingCardInfo}
              error={preferredPharmacy.address?.trim() === ''}
              label="Phamacy Address"
              required
              value={preferredPharmacy.address || ''}
              onChange={(e) => handleChange(e.target.value, 'address')}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid size={{ xs: 9 }}>
            {userType !== 'director' && !collectingCardInfo && (
              <Typography className={classes.termsText} variant="body1" gutterBottom>
                <Checkbox
                  disabled={
                    !preferredPharmacy?.name ||
                    preferredPharmacy.name?.trim() === '' ||
                    !preferredPharmacy?.phone ||
                    preferredPharmacy.phone?.trim() === '' ||
                    !preferredPharmacy?.address ||
                    preferredPharmacy.address?.trim() === ''
                  }
                  checked={pharmacyConsentRead}
                  onClick={() => setPharmacyConsentRead(!pharmacyConsentRead)}
                />
                I consent to have Candor contact this pharmacy and provide information to them regarding my prescription
              </Typography>
            )}
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button onClick={() => setScriptMode(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Paper>
    </StyledGrid>
  );
};

PharmacyInformationPresentation.propTypes = {
  pharmacyConsentRead: PropTypes.bool.isRequired,
  setPharmacyConsentRead: PropTypes.func.isRequired,
  preferredPharmacy: PropTypes.shape({}),
  handleChange: PropTypes.func.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  collectingCardInfo: PropTypes.bool.isRequired,
  setScriptMode: PropTypes.func.isRequired,
};

export default PharmacyInformationPresentation;
