import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Grid2 as Grid, Paper, Container, Button, Box, Divider, TextField } from '@mui/material';
import { getDoc, doc, writeBatch } from 'firebase/firestore';

import { db } from '../../../../../firebase-config';

const PREFIX = 'UpdateDoctorDetails';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  saveButton: `${PREFIX}-saveButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.saveButton}`]: {
    display: 'block',
    margin: 'auto',
  },
}));

const UpdateDoctorDetails = (props) => {
  const { handleModalClose, handleModalOpen, patientId } = props;

  const isMounted = useRef(false);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [prescriberNumber, setPrescriberNumber] = useState('');
  const [qualifications, setQualifications] = useState('');

  const handleUpdateDoctorDetails = async () => {
    handleModalOpen('loading');
    try {
      const batch = writeBatch(db);
      const data = {
        name,
        prescriberNumber,
        qualifications,
        'shipping.phone': phone,
      };
      batch.update(doc(db, 'doctors', patientId), data);
      batch.update(doc(db, 'patients', patientId), data);
      await batch.commit();

      handleModalOpen('Result: Details updated');
    } catch (error) {
      const sentryErrorId = await sentryCaptureException(error, {
        extra: { patientId, issueIn: 'updateDoctorDetails' },
      });
      handleModalOpen(`Error: Failure to update doctor details. Sentry Error ID: ${sentryErrorId}`);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    async function getData() {
      const doctorData = (await getDoc(doc(db, 'doctors', patientId))).data();
      if (isMounted.current) {
        setName(doctorData?.name);
        setPhone(doctorData?.shipping?.phone);
        setPrescriberNumber(doctorData?.prescriberNumber);
        setQualifications(doctorData?.qualifications);
      }
    }
    getData();
    return () => {
      isMounted.current = false;
    };
  }, [patientId]);

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
              <Divider
                sx={{
                  opacity: '0.6',
                }}
              />
            </Box>
          </Grid>
          <Grid>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              placeholder="Full Name"
              label="Full Name"
              className={classes.input}
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
            />
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              placeholder="Phone"
              label="Phone"
              className={classes.input}
              onChange={(e) => setPhone(e.target.value)}
              value={phone || ''}
            />
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              placeholder="Prescriber Number"
              label="Prescriber Number"
              className={classes.input}
              onChange={(e) => setPrescriberNumber(e.target.value)}
              value={prescriberNumber || ''}
            />
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              placeholder="Qualifications"
              label="Qualifications"
              className={classes.input}
              onChange={(e) => setQualifications(e.target.value)}
              value={qualifications || ''}
            />
          </Grid>
          <Button variant="contained" className={classes.saveButton} onClick={handleUpdateDoctorDetails}>
            Save Changes
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default UpdateDoctorDetails;
