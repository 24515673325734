import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const AciclovirInformation = () => (
  <Grid>
    <Typography variant="h6">About Aciclovir</Typography>
    <br />
    <Typography variant="body1">
      Aciclovir is an antiviral medication used to treat Herpes Simplex Virus (HSV) causing cold sores.
      <br />
      <br />
      Aciclovir prevents the virus from replicating and can help minimise symptoms or prevent attacks. It is important
      to know Aciclovir does not cure HSV, but it does make outbreaks much less severe.
      <br />
      <br />
      Aciclovir also helps decrease how likely you are to transmit HSV to other people. While it helps prevent
      transmission we still recommend you take precautions to avoid spreading HSV, in particular, avoiding intimate
      contact when you have an outbreak.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      It is important to start using Aciclovir as soon as you notice symptoms. This will prevent the virus from
      replicating much more effectively.
      <br />
      <br />
      Apply a thin layer to the lesion every 4 hours while awake (5 times a day). Be careful not to apply to healthy
      surrounding skin. Avoid application to the inside of the mouth or eyes.
      <br />
      <br />
      Topical Aciclovir should not be used to treat genital herpes.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Aciclovir is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Dry flaking skin
      <br />
      - Transient stinging or burning
      <br />
      <br />
      Infrequent (0.1 - 1%)
      <br />
      - Erythema
      <br />
      - Itch
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Allergic dermatitis
      <br />
      - Hypersensitivity reactions
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Aciclovir including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use topical Aciclovir when pregnant?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
    <br />
    <Typography variant="h6">Can I use topical Aciclovir when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default AciclovirInformation;
