import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { httpsCallable } from 'firebase/functions';
import { Box, Button, CircularProgress, Grid2 as Grid, Paper, Typography } from '@mui/material';

import { functions } from '../../../../../firebase-config';
import { GEN_PATIENT_INFO_PROP_TYPES } from '../../../constants';
import trimStringsInObject from '../../../../../utils/trimStringsInObj';

const formatDate = (dateString) =>
  new Date(dateString).toLocaleDateString('en-AU', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour12: true,
  });

const InterpretedError = ({ error, generalPatientInfo }) => {
  const { parameter, value, message } = error;
  return (
    <Box>
      {parameter && value && (
        <Typography textAlign="center" color="error">
          {parameter}: {value}
        </Typography>
      )}
      <Typography textAlign="center" variant="body1">
        {message}
      </Typography>
      <Box sx={{ maxWidth: '32rem' }}>
        {parameter === 'cityCode' && (
          <Typography textAlign="center" variant="body1">
            Please check if the spelling of the patient&apos;s city is correct or if the city is in the{' '}
            <strong>{generalPatientInfo.shipping.postcode}</strong> postcode.
          </Typography>
        )}
      </Box>
      {!parameter && !value && !message && (
        <Typography textAlign="center" variant="body1">
          {JSON.stringify(error).replaceAll('"', '')}
        </Typography>
      )}
    </Box>
  );
};

InterpretedError.propTypes = {
  error: PropTypes.shape({
    parameter: PropTypes.string,
    value: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  generalPatientInfo: PropTypes.shape(GEN_PATIENT_INFO_PROP_TYPES).isRequired,
};

const AddToMediRecords = ({ handleModalClose, generalPatientInfo }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [mediRecords, setMediRecords] = useState(generalPatientInfo.mediRecords || null);

  const handleAddToMediRecords = async (currentMode) => {
    setLoading(true);
    const body = { patient: generalPatientInfo, mediRecordsMode: currentMode || 'create' };
    const { data } = await httpsCallable(functions, 'addToMediRecords')(trimStringsInObject(body));
    if (data?.error) {
      setError(data.error);
    } else {
      setMediRecords(data?.mediRecords);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <Paper
        sx={{ p: 4, gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
        {!mediRecords?.id && (
          <>
            <Typography variant="h5">Add the Patient Data to MediRecords</Typography>
            <Typography variant="body1">This will allow the patient to use eRx Script features.</Typography>
            {loading && <CircularProgress />}
            {!loading && (
              <Button variant="contained" color="primary" onClick={() => handleAddToMediRecords()}>
                Proceed
              </Button>
            )}
            {error && (
              <Typography fontWeight="bold" color="error">
                An error has occured
              </Typography>
            )}
            {error &&
              error?.errors?.map((err) => (
                <Typography key={`${err.parameter}-${err.value}`} variant="body1">
                  <InterpretedError error={err} generalPatientInfo={generalPatientInfo} />
                </Typography>
              ))}
            {error && !error?.errors?.length && (
              <Typography variant="body1">{JSON.stringify(error).replaceAll('"', '')}</Typography>
            )}
          </>
        )}
        {mediRecords?.id && (
          <>
            <Typography variant="body1" color="primary">
              Patient data has been uploaded to MediRecords
            </Typography>
            <Grid container spacing={2} width="500px">
              <Grid size={{ xs: 4 }}>
                <Typography variant="body1" fontWeight="bold">
                  MediRecords ID
                </Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <Typography variant="body1">{mediRecords.id}</Typography>
              </Grid>
              <Grid size={{ xs: 4 }}>
                <Typography variant="body1" fontWeight="bold">
                  Last Data Sync
                </Typography>
              </Grid>
              <Grid size={{ xs: 8 }}>
                <Typography variant="body1">{formatDate(mediRecords.lastDataSync)}</Typography>
              </Grid>
            </Grid>
            {loading && <CircularProgress />}
            {!loading && (
              <Button variant="contained" color="primary" onClick={() => handleAddToMediRecords('update')}>
                Re-Sync
              </Button>
            )}
          </>
        )}
        <Button color="primary" onClick={handleModalClose}>
          Close
        </Button>
      </Paper>
    </Box>
  );
};

AddToMediRecords.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.shape(GEN_PATIENT_INFO_PROP_TYPES).isRequired,
};

export default AddToMediRecords;
