import {
  getCatalogueId,
  AUSPOST_BASE_TRACKING_URL,
  STAR_TRACK_BASE_TRACKING_URL,
  AUSPOST,
  STAR_TRACK,
  TREATMENT_TYPES_INVERTED,
  TREATMENT_TYPES,
} from '../../../../../utils/constants';

const { MEDICINAL_CANNABIS } = TREATMENT_TYPES;

export const SHIPMENT_PROVIDER_FIELDS = {
  ...TREATMENT_TYPES,
  OVER_THE_COUNTER: 'over the counter',
};

export const SHIPMENT_PROVIDER_FIELDS_INVERTED = Object.entries(SHIPMENT_PROVIDER_FIELDS).reduce(
  (acc, [key, value]) => ({ ...acc, [value]: key }),
  {},
);

export const SHIPMENT_PROVIDER = {
  AUSPOST: 'Australia Post',
  STAR_TRACK: 'StarTrack',
};

const getSummarizedScriptItems = ({ scriptsArray, catalogue = {} }) => {
  // Exit early if scriptsArray is not an array or is empty
  if (!Array.isArray(scriptsArray) || !scriptsArray.length) {
    // Error processing summarized items: scriptsArray is not an array or is empty
    return {};
  }

  // Ensure catalogue is an object
  if (typeof catalogue !== 'object' || catalogue === null) {
    // Error processing summarized items: catalogue is not an object
    return {};
  }

  // Proceed with the assumption that scriptsArray is a non-empty array and catalogue is a valid object
  return scriptsArray.reduce((acc, script) => {
    const catalogueId = getCatalogueId(script.priceRef);
    const { item } = catalogue[catalogueId] || {}; // productData

    if (!item) {
      return acc;
    }

    return { ...acc, [item]: (acc[item] || 0) + 1 };
  }, {});
};

export const getSummarizedItems = ({ shipmentRequiredOrders, catalogue }) => {
  if (!Array.isArray(shipmentRequiredOrders) || !shipmentRequiredOrders.length) {
    // Error processing summarized items: scriptsArray is not an array or is empty
    return {};
  }

  return shipmentRequiredOrders.reduce(
    (acc, { scriptsArray, payment: orderId }) => ({
      ...acc,
      [orderId]: getSummarizedScriptItems({ scriptsArray, catalogue }),
    }),
    {},
  );
};

export const sortedShipmentItems = (shipmentRequiredOrders) =>
  [...shipmentRequiredOrders].sort((a, b) => (b.updated || 0) - (a.updated || 0));

// Orders before 17Oct2024 will not have a tracking object.
// FUTURE: Migrate old track data to new format, once this is done, remove this function.
// and update the function that uses this to use the new format.
export const getTrackingCode = ({ tracking }) => {
  if (typeof tracking === 'string') {
    return tracking;
  }

  if (typeof tracking === 'object') {
    return tracking.trackingCode;
  }

  return '';
};

export const isAllItemsOTC = ({ scriptsArray }) => {
  if (!Array.isArray(scriptsArray) || !scriptsArray[0]) {
    return false;
  }
  return scriptsArray.every(({ overTheCounter }) => overTheCounter);
};

export const getBaseTrackingUrl = ({ shipment, shipmentProvider }) => {
  const { formName } = shipment || {};

  // for shipments with all OTC items
  if (isAllItemsOTC(shipment)) {
    return shipmentProvider?.OVER_THE_COUNTER === STAR_TRACK ? STAR_TRACK_BASE_TRACKING_URL : AUSPOST_BASE_TRACKING_URL;
  }

  // for pharmacies without shipmentProvider set
  if (!shipmentProvider?.[TREATMENT_TYPES_INVERTED[formName]]) {
    return formName === MEDICINAL_CANNABIS ? STAR_TRACK_BASE_TRACKING_URL : AUSPOST_BASE_TRACKING_URL;
  }

  return shipmentProvider[TREATMENT_TYPES_INVERTED[formName]] === STAR_TRACK
    ? STAR_TRACK_BASE_TRACKING_URL
    : AUSPOST_BASE_TRACKING_URL;
};

export const processTrackingLink = ({ shipment, trackingCode }) => {
  const { tracking, formName } = shipment;
  // If tracking is a string, use AUSPOST_BASE_TRACKING_URL
  if (typeof tracking === 'string') {
    return `${AUSPOST_BASE_TRACKING_URL}${trackingCode}`;
  }

  // edge case, if tracking is an object but does not have a provider, use the default config
  if (typeof tracking === 'object' && !tracking.shippedBy) {
    const BASE_TRACKING_URL =
      formName === MEDICINAL_CANNABIS ? STAR_TRACK_BASE_TRACKING_URL : AUSPOST_BASE_TRACKING_URL;
    return `${BASE_TRACKING_URL}${trackingCode}`;
  }

  if (typeof tracking === 'object') {
    const { shippedBy } = tracking;
    const BASE_TRACKING_URL = shippedBy === STAR_TRACK ? STAR_TRACK_BASE_TRACKING_URL : AUSPOST_BASE_TRACKING_URL;
    return `${BASE_TRACKING_URL}${trackingCode}`;
  }

  return '';
};

export const getShipmentProvider = ({ pharmacyData: { shipmentProvider } = {}, shipment }) => {
  if (isAllItemsOTC(shipment)) {
    return shipmentProvider?.OVER_THE_COUNTER || AUSPOST;
  }

  const { formName } = shipment || {};

  const provider = shipmentProvider?.[TREATMENT_TYPES_INVERTED?.[formName]];
  if (!provider) {
    // uncomment in the future if default for medicinal cannabis will be enforced.
    // return formName === MEDICINAL_CANNABIS ? STAR_TRACK : AUSPOST;
    return AUSPOST;
  }

  return provider;
};

export const getShippedBy = ({ pharmacyData, shipment }) => {
  if (typeof shipment?.tracking === 'string') {
    return SHIPMENT_PROVIDER.AUSPOST;
  }

  if (shipment?.tracking?.shippedBy) {
    return SHIPMENT_PROVIDER[shipment.tracking.shippedBy];
  }
  return SHIPMENT_PROVIDER[getShipmentProvider({ pharmacyData, shipment })] || SHIPMENT_PROVIDER.AUSPOST;
};

export const getUpdateTracking = ({ trackingCode, pharmacyData, shipment }) => {
  const date = Date.now();
  const { tracking } = shipment;
  const shippedBy = getShipmentProvider({ pharmacyData, shipment });
  return {
    // update firestore
    UPSERT_FIRESTORE_TRACKING: {
      [`tracking.trackingCode`]: trackingCode,
      [`tracking.shippedBy`]: shippedBy,
      [`tracking.updatedAt`]: date,
      [`tracking.updatedBy`]: pharmacyData.id,
      ...(!tracking?.createdBy && {
        [`tracking.createdAt`]: date,
        [`tracking.createdBy`]: pharmacyData.id,
      }),
    },
    // update state
    UPSERT_STATE_TRACKING: {
      trackingCode,
      shippedBy,
      updatedAt: date,
      updatedBy: pharmacyData.id,
      ...(!tracking?.createdBy && {
        createdAt: date,
        createdBy: pharmacyData.id,
      }),
    },
  };
};
