import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Stack,
  CircularProgress,
  Grid2 as Grid,
  Chip,
} from '@mui/material';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { isCandor } from '../../../utils';
import { PHARMACY } from '../../../utils/constants';
import { isDirector, isPatient, isSupport } from '../../../utils/roles';
import { CopyButton } from '../../../components/ui/CopyButton';
import { PHARMACY_TYPES } from '../constants';
import { getPharmacyAddressAsText } from '../helpers';
import { useAuthContext, usePharmacyActions, usePharmacyContext } from '../hooks';

/**
 * @param {Object} props
 * @param {PharmacyType} props.storeType - The store type
 * @param {string} props.userType - The user type
 * @returns {Object}
 */
const getPharmacyActionButtonsRenderLogic = ({ storeType, userType }) => {
  // NOTE: storeType is a field inside the order collection
  // userType is in the auth
  const hasSupportCredentials = isDirector(userType) || isSupport(userType);

  return {
    canViewActionButtons: storeType === PHARMACY || (isSupport(storeType) && hasSupportCredentials),
    canViewRefundAction: hasSupportCredentials && !isSupport(storeType),
    canViewBlockAction: hasSupportCredentials && !isSupport(storeType),
  };
};

/**
 * Component for displaying the block action for a pharmacy
 * @param {Object} props
 * @param {Pharmacy} props.pharmacy - The pharmacy object
 * @param {() => void} [props.onPharmacyUpdate] - Callback function to update the pharmacy
 * @param {boolean} props.canViewAction - Whether the block action should be visible
 */
const BlockAction = ({ pharmacy, onPharmacyUpdate, canViewAction }) => {
  const { togglePharmacyBlock, loading: actionLoading } = usePharmacyActions();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleBlockToggle = async () => {
    await togglePharmacyBlock({ blocked: !pharmacy.hasOrdersBlocked });
    onPharmacyUpdate?.();
  };

  if (!canViewAction) {
    return null;
  }

  if (!isOpen) {
    return (
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        {pharmacy.hasOrdersBlocked ? 'Start Unblock Orders Process' : 'Start Block Orders Process'}
      </Button>
    );
  }

  return (
    <Stack direction="row" gap={2} width="100%" justifyContent="space-between">
      <Button
        variant="contained"
        color={pharmacy.hasOrdersBlocked ? 'success' : 'error'}
        onClick={handleBlockToggle}
        disabled={actionLoading}
        sx={{ minWidth: 'fit-content' }}
      >
        {pharmacy.hasOrdersBlocked ? 'Unblock Orders' : 'Block Orders'}
      </Button>
      <Button fullWidth variant="contained" onClick={() => setIsOpen(false)}>
        Cancel
      </Button>
    </Stack>
  );
};

/**
 * Component for displaying the view orders actions for a pharmacy
 * @param {Object} props
 * @param {Pharmacy} props.pharmacy - The pharmacy object
 * @param {string} props.userType - The user type
 */
const ViewOrdersActions = ({ pharmacy, userType }) => {
  const navigate = useNavigate();

  const handleViewOrder = (type) => {
    navigate(`/pharmacies/${pharmacy.id}/orders?type=${type}`);
  };

  if (!isCandor(userType)) {
    return null;
  }

  return (
    <Stack direction="column" alignItems="flex-end" gap={1} justifyContent="center" flex={1}>
      <Typography
        variant="body2"
        fontWeight="bold"
        textAlign="right"
        minWidth="fit-content"
        sx={{ textWrap: 'nowrap' }}
      >
        View Orders
      </Typography>
      <Button size="small" fullWidth variant="outlined" onClick={() => handleViewOrder('current')}>
        Current
      </Button>
      <Button size="small" fullWidth variant="outlined" onClick={() => handleViewOrder('historic')}>
        Historic
      </Button>
    </Stack>
  );
};

/**
 * Component for displaying the refund action for a pharmacy
 * @param {Object} props
 * @param {boolean} props.canViewAction - Whether the refund action should be visible
 */
const RefundAction = ({ canViewAction }) => {
  const [refundAmount, setRefundAmount] = React.useState('0');
  const [isOpen, setIsOpen] = React.useState(false);
  const { handleRefund, loading: actionLoading } = usePharmacyActions();

  const handleRefundSubmit = async () => {
    await handleRefund({ amount: Number(refundAmount) });
    setRefundAmount('0');
  };

  if (!canViewAction) {
    return null;
  }

  if (!isOpen) {
    return (
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Issue a Refund
      </Button>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="stretch" justifyContent="space-between">
      <TextField
        label="Refund Amount"
        type="number"
        size="small"
        value={refundAmount}
        onChange={(e) => setRefundAmount(e.target.value)}
        sx={{ flex: 3 }}
      />
      <Button
        variant="contained"
        onClick={handleRefundSubmit}
        disabled={actionLoading || !Number(refundAmount)}
        sx={{ height: 'auto', flex: 1 }}
      >
        Issue Refund
      </Button>
      <Button variant="contained" onClick={() => setIsOpen(false)}>
        Cancel
      </Button>
    </Stack>
  );
};

/**
 * Component for displaying the software fee action for a pharmacy
 * @param {Object} props
 * @param {Pharmacy} props.pharmacy - The pharmacy object
 * @param {() => void} [props.onPharmacyUpdate] - Callback function to update the pharmacy
 * @param {string} props.userType - The user type
 */
const SoftwareFeeAction = ({ pharmacy, onPharmacyUpdate, userType }) => {
  const [softwareFee, setSoftwareFee] = React.useState(pharmacy.software ? String(pharmacy.software) : '0');
  const { updateSoftwareFee, loading: actionLoading } = usePharmacyActions();

  const isPharmacy = pharmacy.type === PHARMACY_TYPES.PHARMACY;

  const handleSoftwareFeeUpdate = async () => {
    await updateSoftwareFee({ fee: Number(softwareFee) });
    onPharmacyUpdate?.();
  };

  if (!isDirector(userType) || !isPharmacy) {
    return null;
  }

  return (
    <Stack direction="row" gap={2} alignItems="stretch" justifyContent="space-between">
      <TextField
        label="Software Fee"
        type="number"
        size="small"
        value={softwareFee}
        onChange={(e) => setSoftwareFee(e.target.value)}
        sx={{ flex: 3 }}
      />
      <Button
        variant="contained"
        onClick={handleSoftwareFeeUpdate}
        disabled={actionLoading || !Number(softwareFee) || softwareFee === String(pharmacy.software)}
        sx={{ height: 'auto', flex: 1 }}
      >
        Update Fee
      </Button>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {boolean} [props.isHidden] - Whether the component should be hidden
 * @param {string} props.label - The label for the component
 * @param {string} props.text - The text for the component
 * @param {boolean} [props.copyable] - Whether the text should be copyable
 */
const DetailComponent = ({ label, text, copyable = false, isHidden = false }) => {
  if (isHidden) {
    return null;
  }

  return (
    <Grid container columnGap={2} alignItems="center">
      <Grid size={{ xs: 10, sm: 2.5 }} minWidth="fit-content">
        <Typography variant="body2" fontWeight="bold">
          {label}
        </Typography>
      </Grid>
      <Grid>
        {copyable ? (
          <CopyButton noHighlight textToCopy={text} sx={{ width: 'fit-content' }}>
            <Typography variant="body2">{text}</Typography>
          </CopyButton>
        ) : (
          <Typography variant="body2">{text}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

const AcceptedOrdersSection = ({ pharmacy, userType }) => {
  if (!pharmacy.ordersAccepted || pharmacy.ordersAccepted.length === 0 || !isCandor(userType)) {
    return null;
  }

  return (
    <Stack direction="column" gap={1} py={2}>
      <Stack direction="row" gap={2} width="100%" justifyContent="space-between">
        <Typography variant="body1">Orders Accepted</Typography>
        <Chip
          size="small"
          label={pharmacy.hasOrdersBlocked ? 'Orders Blocked' : 'Orders Accepted'}
          color={pharmacy.hasOrdersBlocked ? 'error' : 'success'}
        />
      </Stack>

      <List dense sx={{ width: '100%' }}>
        {pharmacy.ordersAccepted.map((order, index) => (
          <ListItem
            divider={index !== pharmacy.ordersAccepted.length - 1}
            key={order}
            secondaryAction={
              <Link to={`/pharmacies/${pharmacy.id}/stock-levels?healthCategory=${order}`}>
                <Button size="small">View Stock</Button>
              </Link>
            }
          >
            <ListItemText>{order}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {Pharmacy} props.pharmacy - The pharmacy object
 * @param {boolean} [props.loading] - Whether the pharmacy is loading
 * @param {Error | null} [props.error] - Whether the pharmacy has an error
 * @param {(() => void) | undefined} [props.refreshPharmacy] - Callback function to refresh the pharmacy
 */
const PharmacyDetailsPageChild = ({ pharmacy, loading = false, error = null, refreshPharmacy }) => {
  const { userType } = useAuthContext();

  if (loading) {
    return (
      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" height="100dvh">
        <CircularProgress />
      </Stack>
    );
  }

  if (error || !pharmacy) {
    return <div>Error loading pharmacy details</div>;
  }

  if (!pharmacy) {
    return null;
  }

  // NOTE: minimal changes
  // might be replaced/depreciated soon
  const { canViewActionButtons, canViewRefundAction, canViewBlockAction } = getPharmacyActionButtonsRenderLogic({
    storeType: pharmacy.type,
    userType,
  });

  const shouldHidePhoneAndAddress =
    pharmacy.type === PHARMACY_TYPES.SUPPORT || pharmacy.type === PHARMACY_TYPES.SUPPLIER;

  return (
    <div>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Stack direction="column" gap={1} width="100%">
            <DetailComponent label="Name" text={pharmacy.name} />
            <DetailComponent isHidden={isPatient(userType)} label="Orders Email" text={pharmacy.email} copyable />
            <DetailComponent
              isHidden={isPatient(userType) || shouldHidePhoneAndAddress}
              label="Phone"
              text={pharmacy.phone}
              copyable
            />
            <DetailComponent
              isHidden={isPatient(userType) || !pharmacy.contactPerson}
              label="Contact Person"
              text={pharmacy.contactPerson}
            />
            <DetailComponent
              label="Address"
              isHidden={shouldHidePhoneAndAddress}
              text={getPharmacyAddressAsText({ shipping: pharmacy.shipping }) ?? 'No address provided'}
            />
          </Stack>

          {canViewActionButtons && <ViewOrdersActions pharmacy={pharmacy} userType={userType} />}
        </Stack>

        <AcceptedOrdersSection pharmacy={pharmacy} userType={userType} />

        <SoftwareFeeAction pharmacy={pharmacy} onPharmacyUpdate={refreshPharmacy} userType={userType} />

        {canViewActionButtons && (
          <Stack direction="column" gap={2} width="100%" maxWidth="sm" alignSelf="center">
            <RefundAction canViewAction={canViewRefundAction} />
            <BlockAction pharmacy={pharmacy} onPharmacyUpdate={refreshPharmacy} canViewAction={canViewBlockAction} />
          </Stack>
        )}
      </Stack>
    </div>
  );
};

/**
 * @param {Object} props
 * @param {Pharmacy} [props.pharmacy] - The pharmacy object
 * @returns {JSX.Element}
 */
export const PharmacyDetailsPage = ({ pharmacy: defaultPharmacy }) => {
  const { pharmacy, loading, error, refreshPharmacy } = usePharmacyContext({ enabled: !defaultPharmacy });

  if (defaultPharmacy) {
    return <PharmacyDetailsPageChild pharmacy={defaultPharmacy} />;
  }

  return (
    <PharmacyDetailsPageChild pharmacy={pharmacy} loading={loading} error={error} refreshPharmacy={refreshPharmacy} />
  );
};
