import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { doc, onSnapshot } from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { captureException as sentryCaptureException, captureMessage as sentryCaptureMessage } from '@sentry/react';

import { db, functions } from '../../../firebase-config';
import { AuthContext } from '../../../auth-context';
import PaymentContext from '../PaymentContext';
import { scriptPriceTranslator } from '../../../utils/form-translators';
import { CustomizableSnackbar } from '../../layout';
import TillPaymentForm from './TillPaymentForm';
import { getPaymentSuccessUrl } from '../paymentHelpers';

const BORDER_RED = '1px solid #d32f2f';

const getOrderBody = ({ isScriptMode, selectedTreatments, formName, promoCode, isPickup, transactionToken }) => {
  const priceIds = isScriptMode ? [scriptPriceTranslator[formName]] : selectedTreatments;
  const cleanedProductIds = priceIds.map((id) => id.replace('price_', ''));
  const orderRequestedAt = Date.now();

  return {
    updated: orderRequestedAt,
    productIds: cleanedProductIds,
    discountCode: promoCode,
    orderRequestedAt,
    isPickup,
    formName,
    transactionToken,
  };
};

const handleTillPaymentFormSubmitError = ({ error, paymentData, setErrorMessage, user }) => {
  sentryCaptureException(error, {
    extra: { userId: user?.uid, issueIn: 'TillCreateOrder', paymentData },
  });
  setErrorMessage(error.toString());
};

/**
 * TillPayment component handles the payment process using Till Payments.
 *
 * @component
 * @returns {JSX.Element} The TillPayment component.
 */
const TillPayment = () => {
  const { user } = useContext(AuthContext);
  const {
    selectedTreatments,
    isPickup,
    scriptMode: isScriptMode,
    formName,
    promoCode,
    reference: customPaymentReference,
  } = useContext(PaymentContext);
  const [paymentJs, setPaymentJs] = useState(null);
  const [orderDocId, setOrderDocId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPaymentJsLoading, setIsPaymentJsLoading] = useState(true);
  const [isPaymentJsLoadFailed, setIsPaymentJsLoadFailed] = useState(false);
  const [cardType, setCardType] = useState(null);

  // Form fields
  const [cardHolderName, setCardHolderName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  // Form errors
  const [cardHolderNameError, setCardHolderNameError] = useState('');
  const [monthError, setMonthError] = useState('');
  const [yearError, setYearError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');

  const navigate = useNavigate();

  const onPaymentJsInit = useCallback((payment) => {
    const isCardNumberFocused = false;
    const isCvvFocused = false;

    // Autofill Payment
    payment.enableAutofill();
    payment.onAutofill((data) => {
      setCardHolderName(data?.card_holder);
      setMonth(data?.month);
      setYear(data?.year);
    });

    // Styles
    const numberCvvStyles = {
      height: '2.5rem',
      padding: '10px',
      background: 'transparent',
      borderColor: '#0000003b',
      borderWidth: '1px',
      borderRadius: '5px',
      fontSize: '16px',
      fontFamily: 'Roboto !important',
      fontWeight: '400 !important',
      color: '#303F56 !important',
    };

    const borderFocusStyle = {
      border: '2px solid #2aafbb',
      outline: 'unset',
    };

    const borderHoverStyle = {
      borderColor: '0000003b',
    };

    payment.setCvvStyle({
      'text-align': 'right',
    });

    payment.setNumberStyle(numberCvvStyles);
    payment.setCvvStyle(numberCvvStyles);

    // Focus event listeners
    payment.numberOn('focus', () => {
      payment.setNumberStyle(borderFocusStyle);
    });

    payment.numberOn('blur', () => {
      payment.setNumberStyle({ border: '1px solid #0000003b' });
    });

    payment.cvvOn('focus', () => {
      payment.setCvvStyle(borderFocusStyle);
    });

    payment.cvvOn('blur', () => {
      payment.setCvvStyle({ border: '1px solid #0000003b' });
    });

    // Hover events
    payment.numberOn('mouseover', () => {
      if (!isCardNumberFocused) {
        return;
      }
      payment.setNumberStyle(borderHoverStyle);
    });

    payment.numberOn('mouseout', () => {
      if (!isCardNumberFocused) {
        return;
      }
      payment.setNumberStyle(numberCvvStyles);
    });

    payment.cvvOn('mouseover', () => {
      if (!isCvvFocused) {
        return;
      }
      payment.setCvvStyle(borderHoverStyle);
    });

    payment.cvvOn('mouseout', () => {
      if (!isCvvFocused) {
        return;
      }
      payment.setCvvStyle(numberCvvStyles);
    });

    // Input event listeners
    payment.numberOn('input', (data) => {
      setCardType(data.cardType || null);
      setCardNumberError('');

      // Throw an error if the card is a valid amex card
      if (data.cardType === 'amex' && data.validNumber) {
        payment.setNumberStyle({ border: BORDER_RED });
        setCardNumberError('Sorry, we only accept Visa and Mastercard.');
        return;
      }

      if (data.validNumber) {
        payment.setNumberStyle(borderFocusStyle);
        setCardNumberError('');
      } else {
        payment.setNumberStyle({ border: BORDER_RED });
      }
    });

    payment.cvvOn('input', (data) => {
      setCvvError('');
      if (data.validCvv) {
        payment.setCvvStyle(borderFocusStyle);
        setCvvError('');
      } else {
        payment.setCvvStyle({ border: BORDER_RED });
      }
    });

    setPaymentJs(payment);
    setIsPaymentJsLoading(false);
  }, []);

  const initializePaymentForm = useCallback(async () => {
    const paymentJSsrc = import.meta.env.VITE_APP_TILL_PAYMENTS_JS_BUNDLE_URL;
    const tillPublicKey = import.meta.env.VITE_APP_TILL_PAYMENTS_PUBLIC_INTEGRATION_KEY;
    const script = document.createElement('script');
    script.setAttribute('src', paymentJSsrc);
    script.setAttribute('data-main', 'payment-js');
    script.onload = () => {
      try {
        const newPaymentJs = new window.PaymentJs();
        newPaymentJs.init(tillPublicKey, 'number_div', 'cvv_div', onPaymentJsInit);
        setPaymentJs(newPaymentJs);
      } catch (error) {
        setIsPaymentJsLoadFailed(true);
        sentryCaptureException(error, {
          extra: { userId: user?.uid, issueIn: 'Issue in initializing Till PaymentJs script', formName },
        });
      }
    };

    // If script fails to load
    // Update this in the future if patients are having issues with the script and are taking too long to load
    script.onerror = () => {
      setIsPaymentJsLoading(false);
      setIsPaymentJsLoadFailed(true);
      sentryCaptureMessage('Failed to load Till PaymentJs script', {
        level: 'warning',
        extra: { userId: user?.uid, formName },
      });
    };

    document.body.appendChild(script);
  }, [onPaymentJsInit, user?.uid, formName]);

  const handleTillPaymentErrors = (errors = []) => {
    if (!errors.length) {
      return;
    }
    setSnackbarMessage(errors.map((error) => error.message).join('. '));
    setIsSnackbarOpen(true);
    setIsSubmitting(false);
    errors.forEach((errorObj) => {
      switch (errorObj.attribute) {
        case 'card_holder':
          setCardHolderNameError(errorObj.message);
          break;
        case 'number':
          paymentJs.setNumberStyle({ border: BORDER_RED });
          setCardNumberError(errorObj.message);
          break;
        case 'month':
          setMonthError(errorObj.message);
          break;
        case 'year':
          setYearError(errorObj.message);
          break;
        case 'cvv':
          paymentJs.setCvvStyle({ border: BORDER_RED });
          setCvvError(errorObj.message);
          break;
        default:
          break;
      }
    });
  };

  const handlePaymentFormSubmit = async () => {
    const paymentData = {
      card_holder: cardHolderName,
      month,
      year,
    };

    try {
      setErrorMessage('');
      setIsSubmitting(true);
      const transactionToken = await new Promise((resolve) => {
        paymentJs.tokenize(
          paymentData,
          (token) => {
            resolve(token);
          },
          (errors) => {
            handleTillPaymentErrors(errors);
          },
        );
      });

      if (!transactionToken) {
        throw new Error('Transaction token not provided. Please refresh the page and try again.');
      }

      const orderBody = customPaymentReference
        ? { transactionToken, customPaymentReference }
        : getOrderBody({
            isScriptMode,
            selectedTreatments,
            formName,
            promoCode,
            isPickup,
            transactionToken,
          });

      // Create order request
      const { data: responseData } = await httpsCallable(functions, 'tillCreateOrder')(orderBody);
      const { orderId, orderResponse, error } = responseData;

      if (!orderResponse) {
        throw new Error('No response data returned from Till API.');
      }

      if (orderResponse.errors) {
        const { adapterMessage, errorMessage: message, errorCode } = orderResponse.errors[0];
        const tillErrorMessage = message || 'Payment was not successful.';
        const tillAdapterMessage = adapterMessage ? ` (${adapterMessage})` : '';

        if (errorCode === 1002 && adapterMessage === 'invalid or missing parameter') {
          setCardHolderNameError('Please double check your cardholder name.');
        }

        throw new Error(`${tillErrorMessage}${tillAdapterMessage}. Please try again.`);
      }

      // Note: this is after orderResponse.errors as a catch all -- need to speak with the rest of the team cc @cfnelson
      if (error) {
        throw new Error(error);
      }

      setOrderDocId(orderId);
    } catch (error) {
      handleTillPaymentFormSubmitError({ error, paymentData, setErrorMessage, user });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInput = (e, field) => {
    const { value } = e.target;
    switch (field) {
      case 'cardHolderName':
        setCardHolderName(value);
        setCardHolderNameError('');
        break;
      case 'month':
        setMonth(value);
        setMonthError('');
        break;
      case 'year':
        setYear(value);
        setYearError('');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initializePaymentForm();
    try {
      if (!user) {
        return () => {};
      }

      if (!orderDocId) {
        return () => {};
      }
      // Subscribe to the document for changes
      return onSnapshot(doc(db, 'patients', user.uid, 'tillOrderAttempts', orderDocId), (docSnapshot) => {
        const data = docSnapshot.data();
        setErrorMessage('');
        if (data?.webhook?.result === 'OK') {
          const paymentSuccessRoute = getPaymentSuccessUrl({
            productName: data?.orderRequest.items?.[0]?.name,
            isScriptMode,
          });

          navigate(paymentSuccessRoute);
        }
        if (data?.webhook?.result === 'ERROR') {
          const tillErrorMessage = data?.webhook?.message || 'Payment was not successful.';
          const tillAdapterMessage = data?.webhook?.adapterMessage ? ` (${data?.webhook?.adapterMessage})` : '';

          if (data?.webhook?.errorCode === 1002 && data?.webhook?.adapterMessage === 'invalid or missing parameter') {
            setCardHolderNameError('Please double check the card holder name.');
          }

          throw new Error(`${tillErrorMessage}${tillAdapterMessage}. Please try again.`);
        }
      });
    } catch (error) {
      sentryCaptureException(error, {
        extra: { userId: user?.uid, orderDocId },
      });
      setErrorMessage(error.toString());
      setIsPaymentJsLoading(false);
      return () => {};
    }
  }, [
    formName,
    isPickup,
    isScriptMode,
    promoCode,
    selectedTreatments,
    user,
    orderDocId,
    navigate,
    initializePaymentForm,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        p: '20px',
        gap: '10px',
      }}
    >
      <TillPaymentForm
        isSubmitting={isSubmitting}
        handlePaymentFormSubmit={handlePaymentFormSubmit}
        cardHolderName={cardHolderName}
        handleInput={handleInput}
        month={month}
        year={year}
        cardType={cardType}
        cardHolderNameError={cardHolderNameError}
        monthError={monthError}
        yearError={yearError}
        cvvError={cvvError}
        cardNumberError={cardNumberError}
        errorMessage={errorMessage}
        isPaymentJsLoading={isPaymentJsLoading}
        isPaymentJsLoadFailed={isPaymentJsLoadFailed}
        paymentJs={paymentJs}
      />
      <CustomizableSnackbar
        snackbarOpen={isSnackbarOpen}
        setSnackbarOpen={setIsSnackbarOpen}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default TillPayment;
