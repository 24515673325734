import { AUSPOST_CODES } from './AusPostShippingCodes';

/**
 * AusPost Merchant Portal CSV Row Headers
 * https://auspost.com.au/business/parcel-send-help-and-support/shipments/bulk-importing-shipments-with-csv-files/create-your-csv-file#templates
 * FUTURE: organise this more to indicate which ones are mandatory and which are optional
 * FUTURE: StarTrack uses the same headers, but different mandatory fields
 */
export const CSV_HEADERS = [
  'Row type',
  'Sender account',
  'Payer account',
  'Recipient contact name',
  'Recipient business name',
  'Recipient address line 1',
  'Recipient address line 2',
  'Recipient address line 3',
  'Recipient suburb',
  'Recipient state',
  'Recipient postcode',
  'Send tracking email to recipient',
  'Recipient email address',
  'Recipient phone number',
  'Delivery/special instruction 1',
  'Special instruction 2',
  'Special instruction 3',
  'Sender reference 1',
  'Sender reference 2',
  'Product id',
  'Authority to leave',
  'Safe drop',
  'Quantity',
  'Packaging type',
  'Weight',
  'Length',
  'Width',
  'Height',
  'Parcel contents',
  'Transit cover value',
];

// NOTE: Unsure why 4KG was selected as defualt?
// Warning this is set with magic number in OrderExportCSV.jsx line 90
const DEFAULT_WEIGHT = '4'; // IN KG.

export const DEFAULT_VALUES = {
  PRODUCT_ID: AUSPOST_CODES.AUSPOST_EXPRESS_SIGNATURE_3J55,
  WEIGHT: DEFAULT_WEIGHT, // in KG NOTE: This was done to match the default f
  CONTENTS: 'Pharmacy Items', // FUTURE: Decide what should be stated here for the contents of packages
  UNKNOWN: 'UNKNOWN',
};
