import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Container, TextField, Button, Box, Chip, IconButton, Collapse, Alert } from '@mui/material';
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import { captureException as sentryCaptureException } from '@sentry/react';
import { db, auth } from '../../../../../firebase-config';
import { AuthContext } from '../../../../../auth-context';
import CustomizableSnackbar from '../../../../layout/snackbar';

const PREFIX = 'AddNote';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  saveButton: `${PREFIX}-saveButton`,
  arrowIcon: `${PREFIX}-arrowIcon`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.saveButton}`]: {
    display: 'block',
    margin: '0px auto',
  },

  [`& .${classes.arrowIcon}`]: {
    color: '#2AAFBB',
    width: 25,
    height: 25,
    marginBottom: 10,
  },
}));

const AddNote = (props) => {
  const { handleModalClose, handleModalOpen, patientId, formName } = props;

  const responseRef = useRef(null);
  const { user, userType } = useContext(AuthContext);

  const [noteText, setNoteText] = useState('');
  const [savedResponses, setSavedResponses] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (value) => {
    setNoteText(value);
  };

  const handleAddNote = async () => {
    if (!formName) {
      handleModalOpen({ componentName: 'loading' });
    }

    if (!noteText.trim().length > 0) {
      return;
    }

    try {
      const docRef = doc(collection(db, 'patients', patientId, 'activity'));
      await setDoc(docRef, {
        // TODO: Should be happening serverside
        createdAt: Date.now(),
        generalData: userType === 'support' || userType === 'director',
        // TODO: Activity Logs improvement -> Add authorId
        author: auth?.currentUser?.email,
        text: noteText,
      });
      setNoteText('');
      if (!formName) {
        handleModalOpen({ componentName: 'Result: Note added to activity log' });
      }
      setSnackbarOpen(true);
    } catch (error) {
      sentryCaptureException(error, {
        extra: {
          patientId,
          text: noteText,
          author: auth?.currentUser?.email,
        },
      });
      handleModalOpen({ componentName: 'Error: Something went wrong. Contact your team leader.' });
    }
  };

  const getSavedResponses = useCallback(async () => {
    if (user) {
      const querySnapshot = await getDocs(collection(db, 'patients', user?.uid, 'cannedNotes'));
      const result = querySnapshot.docs.map((responseDoc) => responseDoc.data());
      const order = result.sort((a, b) => a.order - b.order);
      setSavedResponses(order);
    }
  }, [user]);

  const handleUpIcon = () => {
    if (responseRef.current) {
      responseRef.current.scrollTop -= 40;
    }
  };

  const handleDownIcon = () => {
    if (responseRef.current) {
      responseRef.current.scrollTop += 40;
    }
  };

  useEffect(() => {
    getSavedResponses();
  }, [getSavedResponses]);

  return (
    <StyledContainer maxWidth="md">
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box className={classes.box}>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
            </Box>
          </Grid>
          <Grid size={{ xs: 12}} className={classes.gridItem}>
            <TextField
              type="text"
              variant="outlined"
              multiline
              fullWidth
              rows={18}
              className={classes.input}
              onChange={(e) => handleChange(e.target.value)}
              value={noteText || ''}
            />
            <Collapse in={snackbarOpen}>
              <Alert severity="success"> Note Added </Alert>
            </Collapse>
          </Grid>
          {/* QUESTION: Unsure what's the purpose of this Box component */}
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 1, height: 72 }}>
            <Box ref={responseRef} sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, overflow: 'hidden' }}>
              {savedResponses.map((response, idx) => (
                <Chip
                  id={`key_${response.key}`}
                  color="primary"
                  sx={{ whiteSpace: 'pre-line' }}
                  key={`key_${response.key}`}
                  label={response.title}
                  onClick={() => handleChange(response.response)}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton onClick={handleUpIcon} component="label" sx={{ padding: 0 }} size="large">
                <ArrowDropDownCircleRoundedIcon style={{ transform: 'scaleY(-1)' }} className={classes.arrowIcon} />
              </IconButton>
              <IconButton onClick={handleDownIcon} component="label" sx={{ padding: 0 }} size="large">
                <ArrowDropDownCircleRoundedIcon className={classes.arrowIcon} />
              </IconButton>
            </Box>
          </Box>
          <Button
            disabled={!noteText.trim().length > 0}
            className={classes.saveButton}
            variant="contained"
            component="button"
            onClick={handleAddNote}
          >
            Save Note
          </Button>
        </Paper>
      </Grid>
      <CustomizableSnackbar message="Note Added" snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} />
    </StyledContainer>
  );
};

export default AddNote;
