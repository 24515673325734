import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Container, Typography, Button, TextField, Tabs, Tab } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';

import { db } from '../../../../../firebase-config';
import { AlertBanner } from '../../../../layout';
import RadioFormElement from '../../../../form/horizontal-form-elements/container-elements/radio-form-element';
import DateFormElement from '../../../../form/horizontal-form-elements/container-elements/date-form-element';
import ShippingInformation from '../../../../shipping-information/shipping-information-container';
import ConcessionCardsContainer from '../../../../concession-cards/concession-cards-container';
import { populatePatientSearchTokens } from '../../../PatientsPage/patientSearchTokenQuery';

const PREFIX = 'UpdateBasicDetails';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  updateDetailsBasic: `${PREFIX}-updateDetailsBasic`,
  input: `${PREFIX}-input`,
  StickyHeader: `${PREFIX}-StickyHeader`,
  backButton: `${PREFIX}-backButton`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  inputContainer: `${PREFIX}-inputContainer`,
  button: `${PREFIX}-button`,
  containerShipping: `${PREFIX}-containerShipping`,
  content: `${PREFIX}-content`,
};

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  overflow: 'hidden',

  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '1rem 0',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.gridContainer}`]: {
    height: '100%',
    width: '100%',
    minHeight: '60vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#f5f5f5',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '2rem',

    maxWidth: '500px',

    '@media (max-width: 600px)': {
      padding: '1rem',
    },
  },

  [`& .${classes.updateDetailsBasic}`]: {
    width: '100%',
    padding: '0 ',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px 40px 0px',
  },

  [`& .${classes.StickyHeader}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
  },

  [`& .${classes.backButton}`]: {
    display: 'flex',
    padding: '0.5rem',
    margin: 10,
  },

  [`& .${classes.accountGridContainer}`]: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',

    '@media (max-width: 600px)': {
      padding: '1rem 0.25rem',
    },
  },

  [`& .${classes.inputContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto 10px auto',
    width: '140px',
    display: 'block',
  },

  [`& .${classes.containerShipping}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 0',
  },
}));

const LegalName = ({ type, value, setValue }) => {
  if (type !== 'firstName' && type !== 'lastName') {
    return null;
  }

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Legal {type === 'firstName' ? 'First' : 'Last'} Name
      </Typography>
      <TextField
        type="text"
        variant="outlined"
        fullWidth
        className={classes.input}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
    </>
  );
};

LegalName.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

const isLegalNameValid = ({ trimmedLegalFirstName, trimmedLegalLastName, generalPatientInfo, setErrorMessage }) => {
  if (
    trimmedLegalFirstName === generalPatientInfo.legalFirstName &&
    trimmedLegalLastName === generalPatientInfo.legalLastName
  ) {
    setErrorMessage('No changes detected');
    return false;
  }

  if (trimmedLegalFirstName === '' || trimmedLegalLastName === '') {
    setErrorMessage('Legal first and last names cannot be empty');
    return false;
  }

  return true;
};

const UpdateBasicDetails = ({ handleModalClose, handleModalOpen, generalPatientInfo, patientId }) => {
  const [tabValue, setTabValue] = useState(0);
  const [isLegalNameError, setIsLegalNameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [legalFirstName, setLegalFirstName] = useState(generalPatientInfo?.legalFirstName || '');
  const [legalLastName, setLegalLastName] = useState(generalPatientInfo?.legalLastName || '');
  const [patientShippingData, setPatientShippingData] = useState(generalPatientInfo?.shipping || {});
  const [formInputs, setFormInputs] = useState({
    'basic info.dob': generalPatientInfo?.dob || '',
    'basic info.sex': generalPatientInfo?.sex || '',
  });

  const handleSave = async () => {
    const trimmedLegalFirstName = legalFirstName.trim();
    const trimmedLegalLastName = legalLastName.trim();

    if (!isLegalNameValid({ trimmedLegalFirstName, trimmedLegalLastName, generalPatientInfo, setErrorMessage })) {
      setIsLegalNameError(true);
      return;
    }

    setIsLegalNameError(false);
    handleModalOpen('loading');
    try {
      await updateDoc(doc(db, 'patients', patientId), {
        legalFirstName: trimmedLegalFirstName,
        legalLastName: trimmedLegalLastName,
      });

      // Populate the searchTokens field in the patients collection
      populatePatientSearchTokens(patientId);

      handleModalOpen('Result: Details updated');
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  const handleUpdateBasicDetails = async (data) => {
    handleModalOpen('loading');
    try {
      if (data && data.split('error')[1]) {
        handleModalOpen(`Result: ${data}`);
        return;
      }

      handleModalOpen('Result: Details updated');
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  const currentFormObjects = [
    { headingText: 'Sex', fieldText: 'basic info.sex', options: ['Male', 'Female'], elementType: 'radio' },
    { headingText: 'Date Of Birth', fieldText: 'basic info.dob', elementType: 'date' },
  ];

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <StyledContainer className={classes.paper}>
      <Paper className={classes.gridContainer}>
        <div className={classes.StickyHeader}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
              Close
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Patient Details">
              <Tab label="Basic Details" id="update-details-tab-1" aria-controls="update-details-tabpanel-1" />
              <Tab label="Concession Cards" id="update-details-tab-2" aria-controls="update-details-tabpanel-2" />
            </Tabs>
          </div>
        </div>

        <div className={classes.content}>
          <div
            role="tabpanel"
            hidden={tabValue !== 0}
            id="update-details-tabpanel-1"
            aria-labelledby="update-details-tab-1"
            style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '0 0 2rem 0' }}
          >
            {tabValue === 0 && (
              <>
                <Container maxWidth="lg" className={classes.updateDetailsBasic}>
                  <Grid container spacing={0} className={classes.accountGridContainer}>
                    <Paper className={classes.paper}>
                      <form>
                        <LegalName type="firstName" value={legalFirstName} setValue={setLegalFirstName} />
                        <LegalName type="lastName" value={legalLastName} setValue={setLegalLastName} />
                        <AlertBanner isToShow={isLegalNameError} alertMessage={errorMessage} />
                        <Button className={classes.button} variant="contained" color="primary" onClick={handleSave}>
                          Save Changes
                        </Button>
                      </form>
                    </Paper>
                  </Grid>
                </Container>
                {currentFormObjects.map((currentFormObject) => {
                  if (currentFormObject.elementType === 'radio') {
                    return (
                      <Grid
                        key={`${currentFormObject.headingText}-${currentFormObject.fieldText}`}
                        className={classes.accountGridContainer}
                      >
                        <RadioFormElement
                          isUpdateDetails
                          patientId={patientId}
                          currentFormObject={currentFormObject}
                          handleNext={handleUpdateBasicDetails}
                          formInputs={formInputs}
                          setFormInputs={setFormInputs}
                        />
                      </Grid>
                    );
                  }
                  return (
                    <Grid
                      key={`${currentFormObject.headingText}-${currentFormObject.fieldText}`}
                      className={classes.accountGridContainer}
                    >
                      <DateFormElement
                        isUpdateDetails
                        patientId={patientId}
                        currentFormObject={currentFormObject}
                        handleNext={handleUpdateBasicDetails}
                        formInputs={formInputs}
                        setFormInputs={setFormInputs}
                      />
                    </Grid>
                  );
                })}
                <Grid className={classes.containerShipping}>
                  <ShippingInformation
                    context="account"
                    handleSnackbarOpen={handleUpdateBasicDetails}
                    patientData={patientShippingData}
                    setPatientData={setPatientShippingData}
                    patientId={patientId}
                  />
                </Grid>
              </>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={tabValue !== 1}
            id="update-details-tabpanel-2"
            aria-labelledby="update-details-tab-2"
            style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '0 0 2rem 0' }}
          >
            {tabValue === 1 && (
              <ConcessionCardsContainer
                isUpdateDetails
                generalPatientInfo={generalPatientInfo}
                patientId={patientId}
                handleNext={handleUpdateBasicDetails}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            )}
          </div>
        </div>
      </Paper>
    </StyledContainer>
  );
};

UpdateBasicDetails.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  // NOTE: Add more fields as needed in the future
  generalPatientInfo: PropTypes.shape({
    legalFirstName: PropTypes.string,
    legalLastName: PropTypes.string,
    dob: PropTypes.string,
    sex: PropTypes.string,
    shipping: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  patientId: PropTypes.string.isRequired,
};

export default UpdateBasicDetails;
