import React from 'react';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../../../firebase-config';
import { useAuthContext } from './useAuthContext';

/**
 * Ensures that the pharmacy is visible to the user
 * @param {object} params - The parameters
 * @param {Pharmacy | import('@firebase/firestore').DocumentData} params.pharmacy - The pharmacy to check
 * @param {string} params.userType - The type of user
 * @returns {boolean} Whether the pharmacy is visible to the user
 */
const ensurePharmacyIsVisible = ({ pharmacy, userType }) => {
  if (userType === 'supplier') {
    return pharmacy.type === 'pharmacy';
  }

  if (userType === 'patient') {
    return pharmacy.type === 'pharmacy' && !pharmacy.hasOrdersBlocked;
  }

  return true;
};

export const usePharmacy = (pharmacyId) => {
  const { userType } = useAuthContext();
  const [pharmacy, setPharmacy] = React.useState(/** @type {Pharmacy | null} */ (null));
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(/** @type {Error | null} */ (null));

  React.useEffect(() => {
    const fetchPharmacy = async () => {
      try {
        setLoading(true);
        const pharmacyDoc = await getDoc(doc(db, 'orders', pharmacyId));
        if (pharmacyDoc.exists() && ensurePharmacyIsVisible({ pharmacy: pharmacyDoc.data(), userType })) {
          setPharmacy({ id: pharmacyDoc.id, ...pharmacyDoc.data() });
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPharmacy();
  }, [pharmacyId, userType]);

  const refreshPharmacy = React.useCallback(() => {
    setLoading(true);

    const fetchPharmacy = async () => {
      const pharmacyDoc = await getDoc(doc(db, 'orders', pharmacyId));
      if (pharmacyDoc.exists()) {
        setPharmacy({ id: pharmacyDoc.id, ...pharmacyDoc.data() });
      }
      setLoading(false);
    };

    fetchPharmacy();
  }, [setLoading, pharmacyId]);

  return { pharmacy, loading, error, refreshPharmacy };
};
