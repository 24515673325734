import {
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Chip,
  Stack,
  useTheme,
  useMediaQuery,
  alpha,
  Divider,
  Tooltip,
} from '@mui/material';
import React from 'react';
import moment from 'moment';
import { Visibility, Launch, ShoppingBasketRounded, LocalShippingRounded } from '@mui/icons-material';

import { isCandor } from '../../../utils';
import { SHIPMENT_TYPES } from '../../../utils/constants';
import { parseStatus } from '../helpers';
import { STATUS_ICON } from '../constants';
import { useAuthContext, useOrderFilters } from '../hooks';
import { parseCurrentStatus } from '../helpers/parseCurrentStatus';
import { SendNotificationToPatient } from './PharmacistSection/SendNotificationToPatient';

const { COMPLETE, PICKUP_REQUIRED, SHIPMENT_REQUIRED, SCRIPT_ONLY } = SHIPMENT_TYPES;

/**
 * @param {ExtendedOrder} order - The order
 * @returns {string} - The time text
 */
const timeText = (order) => {
  const isCompleted = order.status === COMPLETE;
  const isPickupRequired = order.status === PICKUP_REQUIRED;
  const isShipmentRequired = order.status === SHIPMENT_REQUIRED;
  const isScriptOnly = order.status === SCRIPT_ONLY;

  if (isScriptOnly || isShipmentRequired || isPickupRequired) {
    return 'Ordered: ';
  }

  if (isCompleted) {
    return 'Completed: ';
  }

  return '';
};

const getStatusIcon = (order) => {
  if (order.status === SHIPMENT_TYPES.COMPLETE) {
    return order.isPickup
      ? { Icon: ShoppingBasketRounded, label: 'Pickup' }
      : { Icon: LocalShippingRounded, label: 'Shipment' };
  }

  const Icon = STATUS_ICON[order.status];

  if (!Icon) {
    return undefined;
  }

  return { Icon, label: parseStatus(order.status) };
};

/**
 * @param {Object} props
 * @param {ExtendedOrder} props.order - The order
 * @returns {JSX.Element} - The secondary information
 */
const SecondaryInformation = ({ order }) => {
  const { Icon, label } = getStatusIcon(order) || {};

  return (
    <Stack direction="column" alignItems="flex-start" gap={1}>
      <Typography variant="body2" color="text.secondary">
        {`${timeText(order)}${new Date(order.updated).toLocaleString()}`} ({moment(order.updated).fromNow()})
      </Typography>

      <Stack direction="row" alignItems="center" gap={1}>
        {!!Icon && (
          <Tooltip title={label} slotProps={{ tooltip: { sx: { textTransform: 'capitalize' } } }}>
            <Icon sx={{ fontSize: 16 }} />
          </Tooltip>
        )}

        {!!order.scriptsArray && (
          <>
            <Divider orientation="vertical" flexItem />
            <Typography variant="body2" color="text.secondary">
              {`${order.scriptsArray.length} item${order.scriptsArray.length > 1 ? 's' : ''}`}
            </Typography>
          </>
        )}

        {!!order.revertReason && (
          <>
            <Divider orientation="vertical" flexItem />
            <Tooltip title={order.revertReason}>
              <Typography variant="body2" color="error">
                Reverted
              </Typography>
            </Tooltip>
          </>
        )}

        <Divider orientation="vertical" flexItem sx={{ '&:last-child': { display: 'none' } }} />

        {order.currentStatus && (
          <>
            <CurrentOrderStatus order={order} />
            <SendNotificationToPatient variant="icon" order={order} />
          </>
        )}
      </Stack>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {ExtendedOrder} props.order - The order
 */
const CurrentOrderStatus = ({ order }) => {
  if (!order.currentStatus) {
    return null;
  }

  return (
    <Chip
      label={parseCurrentStatus(order.currentStatus)}
      color="primary"
      variant="outlined"
      size="small"
      sx={{ fontSize: 10, height: 'fit-content', width: 'fit-content', py: '0.2rem' }}
    />
  );
};

/**
 * Component for displaying a list of orders
 * @param {Object} props
 * @param {boolean} props.isHistorical - Whether the orders are historical
 * @param {React.RefObject<HTMLUListElement>} props.listRef - Reference to the list element
 * @param {Array<ExtendedOrder>} props.orders - List of orders to display
 * @param {(orderId: string) => void} props.onViewOrder - Callback function to view order details
 * @param {(orderId: string) => void} props.onQuickView - Callback function to quick view order
 * @returns {JSX.Element}
 */
const OrderListChild = ({ isHistorical, listRef, orders, onViewOrder, onQuickView }) => {
  const theme = useTheme();
  const { userType } = useAuthContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [, setFilters] = useOrderFilters({ orderType: isHistorical ? 'historic' : 'current' });

  /**
   * Handle name click
   * @param {string} orderName - The order name
   */
  const handleOnNameClick = (orderName) => {
    setFilters((f) => ({ ...f, search: orderName }));
  };

  return (
    <List ref={listRef} component="ul">
      {orders.map((order, index) => (
        <ListItem
          key={order.id}
          divider={index < orders.length - 1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: order.revertReason ? alpha(theme.palette.error.main, 0.1) : 'transparent',
            gap: 1,
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            '&[active="true"]': {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        >
          <ListItemText
            slotProps={{
              primary: {
                component: 'div',
              },
              secondary: {
                component: 'div',
              },
            }}
            primary={
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                gap={{ xs: 0, sm: 1 }}
              >
                <Button
                  variant="text"
                  size="small"
                  onClick={() => handleOnNameClick(order.name || order.pharmacyInfo?.name)}
                  sx={{
                    textTransform: 'none',
                    mb: 0.5,
                    p: 0,
                    justifyContent: 'flex-start',
                  }}
                >
                  {order.name || order.pharmacyInfo?.name}
                </Button>
              </Stack>
            }
            secondary={<SecondaryInformation order={order} />}
          />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 1, sm: 2 }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => onQuickView(order.id)}
              color={order.revertReason ? 'error' : 'primary'}
            >
              {isMobile ? <Visibility fontSize="small" /> : 'Quick View'}
            </Button>

            {isCandor(userType) && (
              <Button
                variant="outlined"
                size="small"
                onClick={() => onViewOrder(order.id)}
                color={order.revertReason ? 'error' : 'primary'}
              >
                {isMobile ? <Launch fontSize="small" /> : 'View Details'}
              </Button>
            )}
          </Stack>
        </ListItem>
      ))}
    </List>
  );
};

export const OrderList = React.memo(OrderListChild);
