import React from 'react';
import { parseAsStringEnum, useQueryState } from 'nuqs';
import { Stack, CircularProgress, Tab, Tabs } from '@mui/material';

import { Orders } from '../components/Orders';
import { useOrderFilters } from '../hooks/useOrderFilters';
import { usePharmacyContext } from '../hooks/usePharmacyContext';

/**
 * @param {Object} props
 * @param {"current" | "historic"} [props.tab] - The tab to show
 * @returns {JSX.Element}
 */
export const PharmacyOrdersPage = ({ tab }) => {
  const [orderType, setOrderType] = useQueryState(
    'type',
    parseAsStringEnum(['current', 'historic']).withDefault(tab ?? 'current'),
  );

  const { pharmacy, loading: pharmacyLoading, error: pharmacyError } = usePharmacyContext();
  const [filters, setFilters] = useOrderFilters({ orderType });

  /**
   * Handle tab change
   * @param {React.ChangeEvent<unknown>} _ The event.
   * @param {"current" | "historic"} newOrderType The new order type.
   */
  const handleTabChange = async (_, newOrderType) => {
    await setOrderType(newOrderType);
    await setFilters(null);
  };

  if (pharmacyLoading) {
    return (
      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" height="100dvh">
        <CircularProgress />
      </Stack>
    );
  }

  if (pharmacyError || !pharmacy) {
    return <div>Error loading pharmacy details</div>;
  }

  return (
    <Stack direction="column" spacing={2} maxWidth="md" width="100%">
      {!tab && (
        <Tabs variant="fullWidth" onChange={handleTabChange} value={orderType} sx={{ mb: 2 }}>
          <Tab label="Current" value="current" />
          <Tab label="Historic" value="historic" />
        </Tabs>
      )}

      <Orders
        pharmacyId={pharmacy.id}
        isHistorical={orderType === 'historic'}
        filters={filters}
        setFilters={setFilters}
      />
    </Stack>
  );
};
