import React, { useState, useMemo } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, Typography, Button, Stack } from '@mui/material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { functions } from '../../../firebase-config';
import { isPharmacist as getIsPharmacist } from '../../../utils/roles';
import { SUBSTITUTION_PAYMENT_MODE, cleanedArray, getCatalogueId, getUserTypeValidity } from '../../../utils/constants';
import { usePharmacyContext, useAuthContext } from '../hooks';

const FLAT_SHIPPING_FEE = 15;

const handleInStoreSubstitutionPayment = httpsCallable(functions, 'handleInStoreSubstitutionPayment');
const validUsers = ['support', 'director', 'pharmacist'];

const PAYMENT_REQUIRED_SECTION_MESSAGES = {
  MARK_AS_PAID: 'Mark as paid',
  FAILED_TO_MARK_AS_PAID: 'Failed to mark payment as paid',
};

const getFormattedDate = (date) => new Date(date).toLocaleDateString('en-AU', { dateStyle: 'medium' });

const getSubstituteDetails = ({ substitute: { mode, priceDifferencePaymentDate }, priceDifference }) => {
  if (mode === SUBSTITUTION_PAYMENT_MODE.IN_STORE) {
    return 'Mark as paid';
  }

  return `Collected Price Difference of $${priceDifference} on ${getFormattedDate(priceDifferencePaymentDate)}`;
};

// get products name, quanty and total price display
const getSimplifiedItems = (items) => {
  const cleanedItems = Array.isArray(items) ? cleanedArray(items) : [];
  if (!cleanedItems.length) {
    return [];
  }

  return cleanedItems
    .reduce((acc, item) => {
      const { priceRef, paidAmount, substitute, refunded = false } = item;
      const foundIndex = acc.findIndex((element) => element.priceRef === priceRef);

      if (foundIndex === -1) {
        const newItem = {
          priceRef,
          paidAmount,
          refunded,
          substitute,
          qty: 1,
          data: item,
          refundedQty: refunded ? 1 : 0,
        };

        return [...acc, newItem];
      }
      return acc.map((element, index) => {
        if (index !== foundIndex) {
          return element;
        }

        return {
          ...element,
          qty: element.qty + 1,
          refundedQty: refunded ? element.refundedQty + 1 : element.refundedQty,
          refunded: refunded || element.refunded || false,
        };
      });
    }, [])
    .sort((a, b) => {
      // Put items with substitutes at the bottom
      if (a.substitute && !b.substitute) {
        return 1;
      }

      if (!a.substitute && b.substitute) {
        return -1;
      }

      return 0;
    });
};

const AwaitingSubstitutionPayment = ({
  shipment,
  priceDifference,
  awaitingPaymentReference,
  originalProduct,
  substitute,
  snackbar,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userType } = useAuthContext();
  const { user: patientId } = shipment;
  const isValidUser = getUserTypeValidity({ userType, validUsers });

  const handleMarkAsPaid = async () => {
    setIsLoading(true);
    try {
      // Mark as paid
      await handleInStoreSubstitutionPayment({
        userId: patientId,
        reference: awaitingPaymentReference,
        order: {
          mode: SUBSTITUTION_PAYMENT_MODE.IN_STORE,
        },
        shipment,
        originalProduct,
        substitute,
      });

      // FIXME: This is a temporary fix. We need to fix the UI update issue with the order page
      window?.location?.reload();
      snackbar(PAYMENT_REQUIRED_SECTION_MESSAGES.MARK_AS_PAID);
    } catch (error) {
      sentryCaptureException(error, { extra: { shipment, issueIn: 'substitution mark as paid' } });
      snackbar(PAYMENT_REQUIRED_SECTION_MESSAGES.FAILED_TO_MARK_AS_PAID);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography color="secondary" variant="caption">
        Awaiting Payment For {`$${priceDifference}`}
      </Typography>
      {isValidUser && (
        <Button size="small" onClick={handleMarkAsPaid} loading={isLoading}>
          Mark As Paid
        </Button>
      )}
    </Box>
  );
};

/**
 * @param {Object} props - The props object
 * @param {boolean} props.isPharmacist - Whether the user is a pharmacist
 * @param {Order} props.order - The order object
 * @param {Record<string, import('./PharmacyStockPreview').CatalogueItem>} props.catalogue - The catalogue object
 * @param {string} props.priceRef - The price reference
 * @param {number} props.paidAmount - The paid amount
 * @param {number} props.qty - The quantity
 * @param {Object} props.data - The data object
 * @param {boolean} props.refunded - Whether the product has been refunded
 * @param {number} props.refundedQty - The refunded quantity
 * @param {Object} props.substitute - The substitute object
 * @param {(message: string) => void} props.snackbar - The snackbar function
 * @returns {JSX.Element} - The ItemDetail component
 */
const ItemDetail = ({
  isPharmacist,
  order,
  catalogue,
  priceRef,
  paidAmount,
  qty,
  data,
  refunded,
  refundedQty,
  substitute,
  snackbar,
}) => {
  const product = catalogue[getCatalogueId(priceRef)];
  const price = paidAmount || product.price;
  const totalAmount = price * (qty || 1);
  const partiallyRefunded = refundedQty < qty;
  const priceDifference = Math.abs((substitute?.priceDifference || 0) * (qty || 1));
  const earlyDispenseApprovedBy = data?.earlyDispenseApprovedBy || null;

  return (
    <React.Fragment key={priceRef}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        gap={{ xs: 0, md: 2 }}
      >
        <Typography variant="body1" sx={{ textDecoration: substitute ? 'line-through' : '' }}>
          {isPharmacist ? `$${totalAmount} - ` : ''}
          {product?.item} {qty > 1 && <span style={{ fontWeight: 'bold' }}>(Quantity: {qty})</span>}
        </Typography>
        {refundedQty > 0 && (
          <Typography variant="body2" color="secondary" sx={{ ml: 0 }}>
            {partiallyRefunded ? 'Partially' : ''} Refunded {getFormattedDate(refunded)}
            {partiallyRefunded ? ` (${refundedQty} of ${qty})` : ''}
          </Typography>
        )}
      </Stack>

      {substitute && (
        <Typography color="primary" sx={{ mb: !substitute.refund ? 0 : 1 }}>
          {isPharmacist ? `$${substitute.price * (qty || 1)} - ` : ''} {substitute.item || substitute.name} (Quantity:{' '}
          {qty}) (Substitute)
        </Typography>
      )}

      {substitute && !substitute?.isPaid && (
        <AwaitingSubstitutionPayment
          shipment={order}
          priceDifference={priceDifference}
          awaitingPaymentReference={substitute.reference}
          originalProduct={product}
          substitute={substitute}
          snackbar={snackbar}
        />
      )}

      {substitute?.isPaid && substitute?.priceDifferencePaymentDate && (
        <Typography color="success" variant="caption" sx={{ mb: 1, position: 'relative' }}>
          {getSubstituteDetails({ substitute, priceDifference })}
        </Typography>
      )}

      {earlyDispenseApprovedBy && (
        <Typography variant="body1" sx={{ fontStyle: 'italic', my: 0.5, position: 'relative' }}>
          Early dispense was approved by {earlyDispenseApprovedBy}
        </Typography>
      )}
    </React.Fragment>
  );
};

/**
 * @param {Object} props - The props object
 * @param {Order} props.order - The order object
 * @param {Record<string, import('./PharmacyStockPreview').CatalogueItem>} props.catalogue - The catalogue object
 */
export const ItemsDetail = ({ order, catalogue }) => {
  const { snackbar } = usePharmacyContext();
  const { userType } = useAuthContext();
  const isPharmacist = getIsPharmacist(userType);

  const { scriptsArray: scripts, isPickup, shipping } = order;

  const { refunded: shippingRefunded } = shipping || {};

  const simplifiedItems = getSimplifiedItems(scripts);
  const isCatalogueEmpty = !Object.keys(catalogue || {}).length;

  const totalShippingFee = useMemo(
    () => FLAT_SHIPPING_FEE + (scripts?.reduce((acc, script) => acc + (script?.extraPostage || 0), 0) || 0),
    [scripts],
  );

  if (isCatalogueEmpty && !simplifiedItems.length) {
    return null;
  }

  return (
    <Stack direction="column">
      <Typography variant="body1" style={{ fontWeight: 600 }}>
        Details
      </Typography>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        {!isPickup && isPharmacist && <Typography variant="body1">${totalShippingFee} Shipping Flat Rate</Typography>}

        {shippingRefunded && (
          <Typography variant="body2" color="secondary" sx={{ ml: 0 }}>
            {isPickup ? 'Shipping' : ''} Refunded {getFormattedDate(shippingRefunded)}
          </Typography>
        )}
      </Stack>

      {!isCatalogueEmpty &&
        simplifiedItems.map(({ priceRef, paidAmount, qty, data, refunded, refundedQty, substitute }, index) => {
          const key = priceRef ?? index;

          return (
            <ItemDetail
              key={key}
              isPharmacist={isPharmacist}
              order={order}
              catalogue={catalogue}
              priceRef={priceRef}
              paidAmount={paidAmount}
              qty={qty}
              data={data}
              refunded={refunded}
              refundedQty={refundedQty}
              substitute={substitute}
              snackbar={snackbar}
            />
          );
        })}
    </Stack>
  );
};
