import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Box, Stack } from '@mui/material';

import { isCandor, isPatient, isSupplier } from '../../../utils/roles';
import { useAuthContext } from '../hooks';
import { PHARMACY_TYPES } from '../constants';
import { PharmacyDetailsModal } from './PharmacyDetailsModal';

const CustomCard = ({ quickView, pharmacy, children }) => {
  const [isQuickViewOpen, setIsQuickViewOpen] = React.useState(false);

  const handleQuickView = () => {
    if (quickView) {
      setIsQuickViewOpen(true);
    }
  };

  const handleCloseQuickView = (e) => {
    e.stopPropagation();
    setIsQuickViewOpen(false);
  };

  if (quickView) {
    return (
      <Card sx={{ mb: 2 }} onClick={handleQuickView}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack direction="column" alignItems="flex-start">
            {children}
          </Stack>
          <Button onClick={handleQuickView}>View Details</Button>
        </CardContent>
        <PharmacyDetailsModal pharmacy={pharmacy} isOpen={isQuickViewOpen} onClose={handleCloseQuickView} />
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

/**
 * Card component to display pharmacy information
 * @param {Object} props - The props object
 * @param {Pharmacy} props.pharmacy - The pharmacy object
 */
export const PharmacyCard = ({ pharmacy }) => {
  const navigate = useNavigate();
  const { userType } = useAuthContext();

  const userIsPatient = isPatient(userType);
  const userIsSupplier = isSupplier(userType);
  const onlyShowQuickView = userIsPatient || userIsSupplier;

  return (
    <CustomCard quickView={onlyShowQuickView} pharmacy={pharmacy}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="div">
          {pharmacy.name}
        </Typography>
        <Box>
          {!onlyShowQuickView && (
            <Button variant="contained" onClick={() => navigate(`/pharmacies/${pharmacy.id}`)}>
              View Details
            </Button>
          )}
        </Box>
      </Box>

      <Typography color="text.secondary" gutterBottom>
        {pharmacy.shipping?.suburb ? `${pharmacy.shipping?.suburb}, ` : ''}
        {pharmacy.shipping?.state}
      </Typography>

      {pharmacy.ordersAccepted?.length > 0 && isCandor(userType) && pharmacy.type !== PHARMACY_TYPES.SUPPLIER && (
        <Typography variant="body2" color="text.secondary">
          Orders Accepted: {pharmacy.ordersAccepted.join(', ')}
        </Typography>
      )}
    </CustomCard>
  );
};

PharmacyCard.propTypes = {
  pharmacy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      suburb: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
    ordersAccepted: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string.isRequired,
  }).isRequired,
};
