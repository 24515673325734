import React, { useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  TextField as Input,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import MastercardImage from '../../../media/acceptedPayments/Mastercard_Primary.svg';
import VisaImage from '../../../media/acceptedPayments/Visa_Blue.svg';
import Selection from '../../catalogue/manage-product/SelectionField';

const SPACE_BETWEEN = 'space-between';

const YEAR_INCREMENT = 20;

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const twentyYearsFromNow = currentYear + YEAR_INCREMENT; // Fetching 20 years from the current year
  return Array.from({ length: twentyYearsFromNow - currentYear }, (_, i) => String(i + currentYear));
};

const getMonthOptions = () => Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));

const StyledNumberDiv = styled('div')({
  height: '40px',
  width: '80%',
  '@media (max-width: 1315px)': { width: '70%' },
  '@media (max-width: 1100px)': { width: '65%' },
  '@media (max-width: 600px)': { width: 'auto' },
});

const SkeletonAnimationCSS = {
  backgroundColor: '#F0F0F0',
  animation: 'pulse 1.5s ease-in-out infinite',
  border: 'unset',
  '@keyframes pulse': {
    '0%': { opacity: 1.2 },
    '50%': { opacity: 0.4 },
    '100%': { opacity: 1.2 },
  },
};

const StyledTillPaymentErrorContainer = styled('div')({
  border: '1px solid #d32f2f',
  backgroundColor: '#ffebee',
  borderRadius: '8px',
  padding: '16px',
  maxWidth: '600px',
  margin: '16px auto',
});

const StyledLoadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  padding: '20px',
  gap: '10px',
});

/**
 * TillPaymentForm component renders a form for processing till payments.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isSubmitting - Indicates if the form is currently submitting.
 * @param {Function} props.handlePaymentFormSubmit - Function to handle form submission.
 * @param {string} [props.cardHolderName=''] - The name of the cardholder.
 * @param {string} [props.month=''] - The expiration month of the card.
 * @param {string} [props.year=''] - The expiration year of the card.
 * @param {Function} props.handleInput - Function to handle input changes.
 * @param {string|null} [props.cardType=null] - The type of the card (e.g., 'visa', 'mastercard').
 * @param {string} [props.cardHolderNameError=''] - Error message for cardholder name.
 * @param {string} [props.monthError=''] - Error message for expiration month.
 * @param {string} [props.yearError=''] - Error message for expiration year.
 * @param {string} [props.cvvError=''] - Error message for CVV.
 * @param {string} [props.cardNumberError=''] - Error message for card number.
 * @param {string} [props.errorMessage=''] - General error message.
 * @param {boolean} props.isPaymentJsLoading - Indicates if the payment JS is loading.
 * @param {boolean} props.isPaymentJsLoadFailed - Indicates if the payment JS failed to load.
 * @param {Object} props.paymentJs - The payment JS object.
 *
 * @returns {JSX.Element} The rendered component.
 */
const TillPaymentForm = ({
  isSubmitting,
  handlePaymentFormSubmit,
  cardHolderName = '',
  month = '',
  year = '',
  handleInput,
  cardType = null,
  cardHolderNameError = '',
  monthError = '',
  yearError = '',
  cvvError = '',
  cardNumberError = '',
  errorMessage = '',
  isPaymentJsLoading = true,
  isPaymentJsLoadFailed,
  paymentJs = null,
}) => {
  // If cardType is not set, show all payment logos from the accepted payments
  const showPaymentLogo = (type) => !cardType || cardType === type || !['visa', 'mastercard'].includes(cardType);

  const doesErrorExist = useMemo(
    () => cardHolderNameError || monthError || yearError || cvvError || cardNumberError,
    [cardHolderNameError, monthError, yearError, cvvError, cardNumberError],
  );

  if (!paymentJs) {
    return (
      <StyledLoadingContainer>
        <CircularProgress />
      </StyledLoadingContainer>
    );
  }

  if (isPaymentJsLoadFailed) {
    return (
      <StyledTillPaymentErrorContainer>
        <Typography variant="h6" color="error" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
          Payment Form Error
        </Typography>
        <Typography variant="body1" color="error" sx={{ marginBottom: '8px' }}>
          The payment form failed to load. Please refresh the page.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Ensure that ad blockers are not interfering with our payment processor, or try again with a more stable
          internet connection.
        </Typography>
      </StyledTillPaymentErrorContainer>
    );
  }

  return (
    <Grid container spacing={2} sx={{ textAlign: 'left' }}>
      <Grid size={{ xs: 12, sm: 12 }}>
        <Box>
          <InputLabel sx={{ py: 0.5, color: '#49454F' }}>Cardholder Name</InputLabel>
          {isPaymentJsLoading ? (
            <Box sx={{ height: '40px', ...SkeletonAnimationCSS }} />
          ) : (
            <Input
              fullWidth
              id="name"
              name="name"
              value={cardHolderName}
              onChange={(e) => handleInput(e, 'cardHolderName')}
              placeholder="e.g John Doe"
              size="small"
              disabled={isSubmitting}
              error={cardHolderNameError?.length > 0}
              helperText={cardHolderNameError}
              sx={{
                '& .Mui-error': { margin: '5px 0 0 0' },
                ...(isPaymentJsLoading ? SkeletonAnimationCSS : {}),
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid size={{ xs: 12, sm: 12 }}>
        <Box>
          <InputLabel sx={{ py: 0.5, color: '#49454F' }}>Card Number</InputLabel>
          <Box sx={{ display: 'flex', justifyContent: SPACE_BETWEEN, width: '100%', alignItems: 'center' }}>
            <StyledNumberDiv id="number_div" sx={isPaymentJsLoading ? SkeletonAnimationCSS : {}} />
            <Box
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginLeft: '10px', width: '20%' }}
            >
              {showPaymentLogo('mastercard') && (
                <Box
                  component="img"
                  sx={{
                    height: '30px',
                    width: 'auto',
                    maxWidth: '35px',
                    '@media (max-width: 600px)': { maxWidth: '23px' },
                  }}
                  alt="Mastercard"
                  src={MastercardImage}
                />
              )}
              {showPaymentLogo('visa') && (
                <Box
                  component="img"
                  sx={{
                    height: '30px',
                    width: 'auto',
                    maxWidth: '35px',
                    '@media (max-width: 600px)': { maxWidth: '23px' },
                  }}
                  alt="Visa"
                  src={VisaImage}
                />
              )}
            </Box>
          </Box>
          {cardNumberError?.length > 0 && (
            <Typography variant="caption" color="error">
              {cardNumberError}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid size={{ xs: 12, sm: 12 }}>
        <Grid container sx={{ display: 'flex', justifyContent: SPACE_BETWEEN }}>
          <Grid size={{ xs: 12, md: 8 }}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel sx={{ py: 1, color: '#49454F' }}>Expiry Date</FormLabel>
              <FormGroup row sx={{ width: '100%' }}>
                <Grid container spacing={1} sx={{ width: '100%', justifyContent: SPACE_BETWEEN }}>
                  <Grid size={{ xs: 5, md: 6 }}>
                    <Selection
                      size="small"
                      label="Month"
                      value={month}
                      onChange={(e) => handleInput(e, 'month')}
                      type="number"
                      disabled={isSubmitting}
                      error={monthError}
                    >
                      {getMonthOptions().map((monthItem) => (
                        <MenuItem key={monthItem} value={monthItem}>
                          {monthItem}
                        </MenuItem>
                      ))}
                    </Selection>
                  </Grid>
                  <Grid size={{ xs: 5, md: 6 }}>
                    <Selection
                      size="small"
                      label="Year"
                      value={year}
                      onChange={(e) => handleInput(e, 'year')}
                      type="number"
                      disabled={isSubmitting}
                      error={yearError}
                    >
                      {getYearOptions().map((yearItem) => (
                        <MenuItem key={yearItem} value={yearItem}>
                          {yearItem}
                        </MenuItem>
                      ))}
                    </Selection>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 4, md: 3 }}>
            <Box>
              <Box sx={{ py: 1, display: 'flex', alignItems: 'center' }}>
                <InputLabel sx={{ color: '#49454F' }}>CVV</InputLabel>
                <Tooltip title="CVV is the 3 digit number at the back of your card" placement="top">
                  <HelpIcon fontSize="small" color="primary" sx={{ fontSize: '16px', m: '0 5px' }} />
                </Tooltip>
              </Box>
              <div id="cvv_div" style={{ height: '40px', ...(isPaymentJsLoading ? SkeletonAnimationCSS : {}) }} />
              {cvvError?.length > 0 && (
                <Typography variant="caption" color="error">
                  Invalid CVV
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, sm: 12 }} sx={{ display: errorMessage ? 'block' : 'none' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPaymentJsLoading || isSubmitting || doesErrorExist}
          onClick={handlePaymentFormSubmit}
          sx={{
            width: '100%',
            mt: 2,
            py: 1.3,
            fontWeight: '700',
            display: 'flex',
            fontSize: '1rem',
          }}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Pay Now'}
        </Button>
      </Grid>
    </Grid>
  );
};

TillPaymentForm.propTypes = {
  cardType: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  handlePaymentFormSubmit: PropTypes.func.isRequired,
  cardHolderName: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  handleInput: PropTypes.func.isRequired,
  cardHolderNameError: PropTypes.string,
  monthError: PropTypes.string,
  yearError: PropTypes.string,
  cvvError: PropTypes.string,
  cardNumberError: PropTypes.string,
  errorMessage: PropTypes.string,
  isPaymentJsLoading: PropTypes.bool.isRequired,
  isPaymentJsLoadFailed: PropTypes.bool.isRequired,
  paymentJs: PropTypes.shape({}),
};

export default TillPaymentForm;
