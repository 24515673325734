import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { Paper, Typography, Button } from '@mui/material';

import { db } from '../../../../../firebase-config';
import Loading from '../../../../layout/loading';

const PREFIX = 'ToggleMessaging';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  minHeight: '60vh',
  margin: '40px',

  padding: '40px',
  width: '85%',
  maxWidth: '500px',
  '@media (max-width: 600px)': {
    padding: '30px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const ToggleMessaging = (props) => {
  const { handleModalClose, handleModalOpen, patientId, generalPatientInfo } = props;

  const [messagingOnline, setMessagingOnline] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const handleToggleMessaging = async () => {
    handleModalOpen('loading');
    try {
      await updateDoc(doc(db, 'conversations', `${patientId}:doctor`), { online: !messagingOnline });
      handleModalOpen('Result: Patient Chat Updated');
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact support.');
    }
  };

  useEffect(() => {
    const getDoctorConvo = async () => {
      const doctorConvo = (await getDoc(doc(db, 'conversations', `${patientId}:doctor`))).data();
      setMessagingOnline(doctorConvo?.online);
      setDataLoaded(true);
    };

    getDoctorConvo();
  }, [patientId]);

  if (!dataLoaded) {
    return <Loading />;
  }

  return (
    <StyledPaper data-testid="paper">
      <div>
        <Typography variant="h5" align="center" gutterBottom>
          Toggle Messaging
        </Typography>
      </div>
      <br />
      <div>
        <Typography variant="body1" textAlign="center" gutterBottom>
          {messagingOnline
            ? 'Turning off will prevent the patient from sending messages to their doctor.'
            : 'Turning on will allow the patient to send messages to their doctor.'}
        </Typography>
      </div>
      <div>
        <Button variant="contained" color="primary" className={classes.button} onClick={handleToggleMessaging}>
          {messagingOnline ? 'Turn Off' : 'Turn On'}
        </Button>
      </div>

      <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
        Cancel
      </Button>
    </StyledPaper>
  );
};

export default ToggleMessaging;
