import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Grid2 as Grid, Paper, Container, Typography, Button, Box, Divider } from '@mui/material';
import { doc, getDocs, collection, where, updateDoc, query, getDoc, writeBatch } from 'firebase/firestore';

import { db } from '../../../../../firebase-config';
import createEmailObj from '../../../../../functions/create-email-obj';
import { isBrandInList, SUPPORT_EMAIL_ADDRESS, PHARMACY } from '../../../../../utils/constants';

const PREFIX = 'GoLive';
const SUPPLIER = 'supplier';
const templateName = 'partnership_announcement';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  confirmButton: `${PREFIX}-confirmButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.confirmButton}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const handleType = ({ isSupplier, isPharmacy }) => {
  if (isSupplier) {
    return SUPPLIER;
  }
  if (isPharmacy) {
    return PHARMACY;
  }
  return '';
};

const GoLive = ({ handleModalClose, handleModalOpen, patientId, generalPatientInfo }) => {
  const [isLive, setIsLive] = useState(true);
  const [brandList, setBrandList] = useState([]);

  const orderRef = doc(db, 'orders', patientId);
  const accountType = handleType(generalPatientInfo);

  const handleConfirm = async () => {
    if (isLive) {
      return;
    }
    try {
      handleModalOpen('loading');
      const batch = writeBatch(db);
      if (brandList?.[0]) {
        const allProducts = await getDocs(query(collection(db, 'catalogue')));
        const productIds = allProducts.docs.reduce((acc, item) => {
          const { brand, suggested } = item.data() || {};
          if (!suggested && isBrandInList({ brandList, brand })) {
            return [...acc, item.id];
          }
          return acc;
        }, []);

        // Note: is to avoid the instance of overloading the firestore
        // Maybe changed if find a better way to handle in the future
        // eslint-disable-next-line no-restricted-syntax
        for await (const productId of productIds) {
          await updateDoc(doc(db, 'catalogue', productId), { suggested: true });
        }
      }

      batch.update(orderRef, {
        type: accountType,
      });

      // sends emails to the suppliers and support if a new pharmacy goes live
      if (accountType === PHARMACY) {
        const templateData = { partnerName: generalPatientInfo.name, partnerType: PHARMACY };
        const users = await getDocs(query(collection(db, 'orders'), where('type', '==', SUPPLIER)));

        batch.set(doc(collection(db, 'mail')), createEmailObj(SUPPORT_EMAIL_ADDRESS, templateName, templateData));
        users.forEach((item) => {
          const { email } = item.data() || {};
          if (!email) {
            return;
          }
          batch.set(doc(collection(db, 'mail')), createEmailObj(email, templateName, templateData));
        });
      }

      await batch.commit();
      handleModalOpen(`Result: The ${accountType} has been sent to live.`);
    } catch (error) {
      const sentryErrorId = await sentryCaptureException(error, {
        extra: { patientId, issueIn: `${accountType} going live` },
      });
      handleModalOpen(`Error: The ${accountType} could not be sent to live. Sentry Error ID: ${sentryErrorId}`);
    }
  };

  useEffect(() => {
    const getIsLive = async () => {
      const { type, brands } = (await getDoc(orderRef)).data() || {};
      setIsLive(type === accountType);
      setBrandList(brands || []);
    };
    getIsLive();
  }, [patientId]);

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box className={classes.box}>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
              <Divider
                sx={{
                  opacity: '0.6',
                }}
              />
            </Box>
          </Grid>
          <Grid>
            <Typography fontWeight="bold" textAlign="center" paddingTop="20px" paddingBottom="20px">
              {isLive
                ? `This ${accountType} is already live.`
                : `Are you sure you want to go live this ${accountType}?`}
            </Typography>
          </Grid>
          {!isLive && (
            <Box className={classes.confirmButton}>
              <Button variant="contained" color="primary" onClick={handleConfirm}>
                Confirm
              </Button>
            </Box>
          )}
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

GoLive.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  generalPatientInfo: PropTypes.shape({
    name: PropTypes.string,
    isSupplier: PropTypes.bool,
    isPharmacy: PropTypes.bool,
  }).isRequired,
};

export default GoLive;
