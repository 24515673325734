import React from 'react';
import { EditNote as EditNoteIcon } from '@mui/icons-material';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';

import {
  convertTimestampToString,
  CANDOR,
  SUPPORT_EMAIL_ADDRESS,
  SHIPMENT_TYPES,
} from '../../../../../utils/constants';
import { db } from '../../../../../firebase-config';
import { AuthContext } from '../../../../../auth-context';
import createEmailObj from '../../../../../functions/create-email-obj';
import { isPharmacist as getIsPharmacist } from '../../../../../utils/roles';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';

const { OUTSTANDING } = SHIPMENT_TYPES;

const NOTE_EMAIL_TEMPLATE = 'note_to_order';

const MAX_NOTE_LENGTH = 20;

const NOTE_MESSAGES = {
  NOTE_BLANK: 'Note should not be blank.',
  SUCCESS: 'Notification Sent',
  FAILURE: 'Notification failed, please try again',
};

const addNoteToOrder = async ({
  order,
  userId,
  note: originalNote,
  isPharmacist,
  setIsAddNoteLoading,
  handleModalClose,
  snackbar,
}) => {
  try {
    const note = originalNote.trim();
    if (!note) {
      throw new Error(NOTE_MESSAGES.NOTE_BLANK);
    }
    setIsAddNoteLoading(true);

    const date = Date.now();
    const batch = writeBatch(db);
    const mailRef = doc(collection(db, 'mail'));
    const from = isPharmacist ? order.pharmacyInfo.name : CANDOR;
    const trimmedNote = note.length > MAX_NOTE_LENGTH ? `${note.substring(0, MAX_NOTE_LENGTH)}...` : note;

    const noteObj = {
      senderId: userId,
      sent: date,
      text: note,
      from,
    };

    const mailContent = createEmailObj(
      isPharmacist ? SUPPORT_EMAIL_ADDRESS : order.pharmacyInfo.email,
      NOTE_EMAIL_TEMPLATE,
      {
        from: isPharmacist ? order.pharmacyInfo.name : CANDOR,
        orderDate: convertTimestampToString(order.updated),
        note: trimmedNote,
        orderName: order.name,
      },
    );

    const notes = [...(order.notes || []), noteObj];

    batch.update(doc(db, 'orders', order.pharmacyInfo.id, OUTSTANDING, order.id), { notes });

    // Send email notification
    batch.set(mailRef, mailContent);
    await batch.commit();
    snackbar(NOTE_MESSAGES.SUCCESS);
    handleModalClose();
  } catch (error) {
    sentryCaptureException(error, { extra: { order, supplierId: userId, issueIn: 'addNote' } });
    snackbar(NOTE_MESSAGES.FAILURE);
    setIsAddNoteLoading(false);
  }
};

const AddNoteModal = () => {
  const {
    user: { uid: userId },
    userType,
  } = React.useContext(AuthContext);
  const { snackbar } = usePharmacyContext();
  const { order, handleDialogue } = useOrderDetailsContext();
  const [note, setNote] = React.useState('');
  const [isAddNoteLoading, setIsAddNoteLoading] = React.useState(false);
  const isPharmacist = getIsPharmacist(userType);

  const handleModalClose = React.useCallback(() => {
    setIsAddNoteLoading(false);
    handleDialogue({ isOpen: false, content: null });
  }, [setIsAddNoteLoading, handleDialogue]);

  const handleAddNote = React.useCallback(async () => {
    if (!order) {
      return;
    }

    await addNoteToOrder({
      order,
      userId,
      note,
      isPharmacist,
      setIsAddNoteLoading,
      handleModalClose,
      snackbar,
    });
  }, [order, userId, note, isPharmacist, setIsAddNoteLoading, handleModalClose, snackbar]);

  return (
    <Stack direction="column" gap={2} width="50vw" maxWidth="100%">
      <Typography variant="h6" sx={{ mb: 2 }} color="primary">
        Contact {isPharmacist ? 'Support' : 'Pharmacy'}
      </Typography>

      <TextField
        value={note}
        onChange={(e) => setNote(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        multiline
        minRows={4}
        fullWidth
        variant="outlined"
      />

      <Stack direction="column" gap={2} alignItems="center" width="100%" justifyContent="flex-end">
        <Button
          fullWidth
          onClick={handleAddNote}
          disabled={!note.trim() || isAddNoteLoading}
          loading={isAddNoteLoading}
          variant="contained"
        >
          Send Notification
        </Button>
        <Button onClick={handleModalClose}>Cancel</Button>
      </Stack>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal
 */
export const AddNote = ({ openSecondaryModal }) => {
  const { userType } = React.useContext(AuthContext);

  const isPharmacist = getIsPharmacist(userType);

  const openModal = () => {
    openSecondaryModal(<AddNoteModal />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<EditNoteIcon />}>
        Contact {isPharmacist ? 'Support' : 'Pharmacy'}
      </Button>
    </MenuItem>
  );
};
