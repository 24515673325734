import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Typography, Button, Modal, Divider, Box, TextField } from '@mui/material';

const StyledPaper = styled(Paper)(() => ({
  margin: '10px 10px 40px 10px',
  padding: '30px',
  width: '100%',
}));

const StyledGridItem = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const AgreementGridContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'stretch',
}));

const ModalPaper = styled(Paper)(() => ({
  padding: '0px 30px 30px 30px',
  width: '90%',
  display: 'block',
  height: '85vh',
  overflow: 'auto',
  '@media (max-width: 600px)': {
    padding: '0px 20px 20px 20px',
    width: '100%',
  },
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#2AAFBB',
    borderRadius: '100px',
  },
}));

const ModalGrid = styled(Grid)(() => ({
  margin: 40,
  '@media (max-width: 600px)': {
    margin: 30,
  },
}));

const BackButtonGrid = styled(Grid)(() => ({
  backgroundColor: '#fff',
  position: 'sticky',
  top: 0,
  width: '100%',
  paddingTop: 10,
}));

const BackButton = styled(Button)(() => ({
  display: 'block',
  padding: 5,
  marginBottom: 10,
}));

const StyledButton = styled(Button)(() => ({
  display: 'block',
  width: 170,
  margin: '10px',
}));

const AgreementPresentation = (props) => {
  const {
    agreementRef,
    modalOpen,
    setModalOpen,
    acceptedAgreements,
    acceptAgreement,
    declineAgreement,
    accountContext,
    agreement,
    agreementTitle,
    signedName,
    setSignedName,
    isSubmitting,
  } = props;

  const date = new Date();
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const signedDate = date.toLocaleDateString('en-GB', options);
  const bannerButtonText = `View ${agreementRef.split('_')[1] === 'eula' ? 'License' : 'Payment'} Agreement`;

  const renderAgreement = (item) => {
    const key = item[0];
    const value = item[1];

    if (typeof value === 'string') return null;
    return (
      <React.Fragment key={key}>
        <Typography variant="h5" gutterBottom>
          {key}
        </Typography>
        <br />
        {Array.isArray(value) ? (
          <div>
            {value
              ?.sort((a, b) => b - a)
              .map((i) => (
                <Typography key={i} variant="body1" gutterBottom>
                  {i}
                  <br />
                  <br />
                </Typography>
              ))}
          </div>
        ) : (
          <>
            {Object.entries(value)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([k, val]) => (
                <div key={val?.toString()}>
                  <Typography variant="body1" fontWeight="bold" gutterBottom>
                    {k}
                  </Typography>
                  <br />
                  {val
                    ?.sort((a, b) => a - b)
                    .map((i) => (
                      <Typography key={i} variant="body1" gutterBottom>
                        {i}
                        <br />
                        <br />
                      </Typography>
                    ))}
                </div>
              ))}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      {accountContext && (
        <AgreementGridContainer size={{ sm: 6 }}>
          <StyledPaper>
            <Grid container spacing={2} justifyContent="center">
              <StyledGridItem size={{ sm: 12 }}>
                <Typography variant="h5" align="center" gutterBottom>
                  {agreementTitle.title}
                </Typography>
              </StyledGridItem>
              {!accountContext && (
                <StyledGridItem size={{ sm: 12 }}>
                  <Typography variant="body1" align="center" gutterBottom>
                    Please view and click accept or contact support@candor.org
                  </Typography>
                </StyledGridItem>
              )}
              <StyledGridItem size={{ sm: 12 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setModalOpen(true);
                    setSignedName('');
                  }}
                >
                  View Agreement
                </Button>
              </StyledGridItem>
            </Grid>
          </StyledPaper>
        </AgreementGridContainer>
      )}
      {!accountContext && (
        <Button variant="contained" onClick={() => setModalOpen(true)}>
          {bannerButtonText}
        </Button>
      )}
      <Modal open={modalOpen}>
        <>
          <ModalGrid container>
            <ModalPaper>
              <BackButtonGrid>
                <Box>
                  <BackButton
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    onClick={() => setModalOpen(false)}
                  >
                    Close
                  </BackButton>
                  <Divider
                    sx={{
                      opacity: '0.6',
                    }}
                  />
                </Box>
              </BackButtonGrid>
              <Grid container spacing={2} justifyContent="center">
                <Grid size={{ sm: 12 }}>
                  <br />
                  {agreementTitle.lastUpdated && (
                    <>
                      <Typography variant="body2" gutterBottom>
                        Last updated {agreementTitle.lastUpdated}
                      </Typography>
                      <br />
                    </>
                  )}
                  <Typography variant="h5" gutterBottom>
                    {agreementTitle.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {agreementTitle.subtitle}
                    <br />
                    <br />
                    {agreementTitle.party1}
                    <br />
                    {agreementTitle.party1_subtitle}
                    <br />
                    <br />
                  </Typography>
                  {agreementTitle.party2 && (
                    <Typography variant="body1" gutterBottom>
                      {agreementTitle.party2}
                      <br />
                      {agreementTitle.party2_subtitle}
                      <br />
                      <br />
                    </Typography>
                  )}
                </Grid>
                <Grid size={{ sm: 12 }}>
                  {agreement.map((item) => renderAgreement(item))}
                  {!acceptedAgreements?.[agreementRef.replace(/.*_/, '')] && (
                    <Box display="flex" flexDirection="column" width={480}>
                      <Box display="flex" alignItems="center">
                        <Typography marginRight="10px">Signed:</Typography>
                        <TextField
                          value={signedName}
                          onChange={(evt) => setSignedName(evt.target.value)}
                          slotProps={{
                            input: {
                              style: { fontFamily: 'Cedarville Cursive', fontSize: 30 },
                            },
                          }}
                        />
                      </Box>
                      <Box display="flex" alignItems="center" marginTop="10px">
                        <Typography marginRight="10px">Name:</Typography>
                        <Typography>{signedName}</Typography>
                      </Box>
                      <Box display="flex" alignItems="center" marginTop="10px">
                        <Typography marginRight="10px">Date:</Typography>
                        <Typography>{signedDate}</Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <StyledGridItem size={{ sm: 12 }}>
                  {!acceptedAgreements?.[agreementRef.replace(/.*_/, '')] && (
                    <>
                      <StyledButton disabled={isSubmitting} variant="contained" onClick={declineAgreement}>
                        Decline
                      </StyledButton>
                      <StyledButton
                        disabled={!signedName || isSubmitting}
                        variant="contained"
                        onClick={() => acceptAgreement()}
                      >
                        {isSubmitting ? 'Submitting...' : 'Accept'}
                      </StyledButton>
                    </>
                  )}
                </StyledGridItem>
              </Grid>
            </ModalPaper>
          </ModalGrid>
        </>
      </Modal>
    </>
  );
};

export default AgreementPresentation;
