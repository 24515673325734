import React from 'react';
import { styled } from '@mui/material/styles';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Grid2 as Grid, Container, CircularProgress, Paper, Typography, Button } from '@mui/material';

const PREFIX = 'ModalCard';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  gridItem: `${PREFIX}-gridItem`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
};

const StyledContainer = styled(Container)(() => ({
  height: '100%',
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '1rem',
    width: 'fit-content',
    minWidth: '300px',
    maxWidth: '800px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.cancelButton}`]: {
    position: 'absolute',
    right: -20,
    top: -10,
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
  },
}));

/**
 * @param {Object} props - Component props
 * @param {string} props.text - Text to display
 * @param {string} props.subtext - Subtext to display
 * @param {() => void} props.handleModalClose - Callback function to close modal dialogs
 * @param {boolean} [props.loadingComponent] - Flag indicating if the component is loading
 * @returns {JSX.Element} Modal component
 */
const ModalCard = ({ text, subtext, handleModalClose, loadingComponent = false }) => (
  <StyledContainer>
    <Grid container className={classes.gridContainer}>
      <Paper className={classes.paper}>
        <Grid className={classes.cancelButtonHolder}>
          <Button className={classes.cancelButton} onClick={handleModalClose}>
            <CancelRoundedIcon color="primary" />
          </Button>
        </Grid>
        <Grid container>
          {loadingComponent && (
            <Grid size={{ xs: 12 }} className={classes.gridItem}>
              <CircularProgress />
            </Grid>
          )}
          <Grid size={{ xs: 12 }} className={classes.gridItem}>
            <Typography variant="h5" align="center" gutterBottom>
              {text}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" align="center" gutterBottom>
              {subtext}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </StyledContainer>
);

export default ModalCard;
