import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { captureException as sentryCaptureException } from '@sentry/react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, collection, writeBatch, arrayUnion } from 'firebase/firestore';

import { db, storage } from '../../../../../firebase-config';
import { ModalCardOptions } from '../../../../layout';
import UploadDocumentSuccess from '../../../../patient-documents/documents/general/UploadDocumentSuccess';
import UploadPatientDocumentPresentation from '../../../../patient-documents/documents/general/UploadPatientDocumentPresentation';

const getCandorUploadFileUrl = ({ fileName, userId }) =>
  userId && fileName
    ? `gs://${import.meta.env.VITE_APP_FIREBASE_CONFIG_STORAGE_BUCKET_UNSCANNED}/patient_documents/${userId}/candor_uploads/${fileName}`
    : '';

const DELAY_TIME_TO_WRAP_UP_UPLOAD = 2000;

const UploadDocument = ({ generalPatientInfo, handleModalClose }) => {
  const fileNameArray = (generalPatientInfo?.candorUploads || []).map(({ fileName: name }) => name);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileNameWithoutExtension, setFileNameWithoutExtension] = useState('');
  const [fileFound, setFileFound] = useState(false);
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [exitUpload, setExitUpload] = useState(false);
  const [cancelUpload, setCancelUpload] = useState(false);
  const [isError, setIsError] = useState(false);

  const reset = () => {
    setFile(null);
    setFileName('');
    setFileNameWithoutExtension('');
    setDescription('');
    setIsLoading(false);
    setIsSubmitted(false);
    setExitUpload(false);
    setCancelUpload(false);
    setFileFound(false);
  };

  const fileOnChange = (e) => {
    setFileFound(fileNameArray.some((data) => data === e.target.files[0].name));
    setFileName(e.target.files[0].name);
    setFileNameWithoutExtension(e.target.files[0].name.replace(/\.[^/.]+$/, ''));
    setFile(e.target.files[0]);
    e.target.value = null; // resets file input
  };

  const fileNameOnChange = (name, value) => {
    setFileName(name);
    setFileNameWithoutExtension(value);
    setFileFound(fileNameArray.some((data) => data === name));
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const date = Date.now();
      const batch = writeBatch(db);
      batch.update(doc(db, 'patients', generalPatientInfo.id), {
        candorUploads: arrayUnion({ fileName, description: description.trim() }),
      });

      const storageRef = ref(storage, getCandorUploadFileUrl({ fileName, userId: generalPatientInfo.id }));

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        () => {},
        (err) => {
          sentryCaptureException(err, {
            extra: {
              issueIn: 'handleClick - uploadBytesResumable - on state_changed',
              patientId: generalPatientInfo.id,
            },
          });
          setIsError(true);
        },
        () => {
          setIsLoading(false);
          setIsSubmitted(true);
          // Put 2 second delay to ensure file is uploaded to existing storage before attempting to get download url.
          setTimeout(() => {
            getDownloadURL(ref(storage, `patient_documents/${generalPatientInfo.id}/candor_uploads/${fileName}`))
              .then(async (url) => {
                if (!url) {
                  return;
                }

                batch.set(doc(collection(db, 'patients', generalPatientInfo.id, 'activity')), {
                  fileName,
                  url,
                  author: 'System',
                  text: `Candor uploaded a document "${fileName}" with the description "${description.trim()}"`,
                  type: 'candor upload',
                  createdAt: date,
                  generalData: false,
                });
                await batch.commit();
              })
              .catch((err) => {
                // If file does not exist or is harmful, set progress to 0 and show an alert.
                sentryCaptureException(err, {
                  extra: { issueIn: 'handleClick - getDownloadURL', patientId: generalPatientInfo.id },
                });
                setIsError(true);
              });
          }, DELAY_TIME_TO_WRAP_UP_UPLOAD);
        },
      );
    } catch (error) {
      sentryCaptureException(error, {
        extra: { issueIn: 'handleClick - uploadBytesResumable', patientId: generalPatientInfo.id },
      });
      setIsLoading(false);
      setIsError(true);
    }
  };

  if (isSubmitted) {
    return (
      <UploadDocumentSuccess fileName={fileName} reset={reset} exitPage={handleModalClose} exitDescription="Exit" />
    );
  }

  return (
    <div style={{ maxWidth: 500, width: '100%', marginTop: '20vh' }}>
      <UploadPatientDocumentPresentation
        fileName={fileName}
        fileNameWithoutExtension={fileNameWithoutExtension}
        description={description}
        setDescription={setDescription}
        fileFound={fileFound}
        isLoading={isLoading}
        handleClick={handleClick}
        fileOnChange={fileOnChange}
        fileNameOnChange={fileNameOnChange}
        setExitUpload={setExitUpload}
        setCancelUpload={setCancelUpload}
        exitPage={handleModalClose}
        isError={isError}
      />
      <ModalCardOptions
        text="Are you sure you want to exit?"
        subtext="Pressing OK will exit this page and discard any changes you have made, do you wish to continue?"
        onClose={() => setExitUpload(false)}
        isOpen={exitUpload}
        handleProceed={handleModalClose}
      />
      <ModalCardOptions
        text="Are you sure you want to cancel your upload?"
        subtext="Pressing OK will reset any changes you have made so far, do you wish to continue?"
        onClose={() => setCancelUpload(false)}
        isOpen={cancelUpload}
        handleProceed={reset}
      />
    </div>
  );
};

UploadDocument.propTypes = {
  generalPatientInfo: PropTypes.shape({
    id: PropTypes.string,
    candorUploads: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }).isRequired,
  handleModalClose: PropTypes.func.isRequired,
};

export default UploadDocument;
