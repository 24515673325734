import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  Stack,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { doc, writeBatch } from 'firebase/firestore';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../../../firebase-config';
import { SHIPMENT_TYPES } from '../../../../../utils/constants';
import { ModalContainer } from '../../../../../components/layout';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { getTrackingCode, getUpdateTracking } from './helpers';

const { OUTSTANDING } = SHIPMENT_TYPES;

const PREFIX = 'TrackingSummaryModal';

const classes = {
  box: `${PREFIX}-box`,
  saveAllButton: `${PREFIX}-saveAllButton`,
  modalPaper: `${PREFIX}-modalPaper`,
  tableHeader: `${PREFIX}-tableHeader`,
  tableContainer: `${PREFIX}-tableContainer`,
};

const ModalPaper = styled(Paper)(() => ({
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80dvw',
  maxWidth: 1200,
  minWidth: 300,
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  maxHeight: '90vh',
  border: `1px solid #000`,

  [`& .${classes.tableHeader}`]: {
    backgroundColor: 'whitesmoke',
  },
  [`& .${classes.tableContainer}`]: {
    margin: '2rem auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      zIndex: 1,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },
}));

const TrackingSummaryModal = ({
  trackingList: mainTrackingList,
  setTrackingList,
  isSummaryModalOpen,
  setIsSummaryModalOpen,
  summarizedItems,
  pharmacyData,
}) => {
  const { isButtonDisabled: _, ...trackingList } = mainTrackingList || {}; // remove isButtonDisabled from mainTrackingList
  const { snackbar } = usePharmacyContext();
  const { user } = useAuthContext();
  const { uid: userId } = user || { uid: undefined };
  const [isSavingAllTracking, setIsSavingAllTracking] = useState(false);

  const modalOnClose = () => {
    setIsSummaryModalOpen(false);
  };

  const handleSave = async () => {
    setIsSavingAllTracking(true);
    try {
      const batch = writeBatch(db);
      Object.values(trackingList).forEach((ogShipment) => {
        const { newTracking: trackingCode, ...shipment } = ogShipment;
        const { payment: orderId } = shipment;
        const orderRef = doc(db, 'orders', userId, OUTSTANDING, orderId);
        const { UPSERT_FIRESTORE_TRACKING } = getUpdateTracking({ trackingCode, pharmacyData, shipment });
        batch.update(orderRef, UPSERT_FIRESTORE_TRACKING);
      });
      await batch.commit();
      setTrackingList({ isButtonDisabled: true });
      setIsSummaryModalOpen(false);
      setIsSavingAllTracking(false);
      snackbar(`Tracking codes updated successfully.`);
    } catch (error) {
      snackbar('Error updating tracking codes');
      sentryCaptureException(error, { extra: { user, issueIn: 'handleSave- TrackingSummaryModal' } });
      setIsSavingAllTracking(false);
    }
  };

  if (!isSummaryModalOpen) {
    return null;
  }

  return (
    <ModalContainer isOpen={isSummaryModalOpen} onClose={modalOnClose}>
      <ModalPaper>
        <Typography variant="h5" textAlign="center">
          Summary of Changes to Tracking Numbers
        </Typography>
        {isSavingAllTracking ? (
          <CircularProgress size={30} sx={{ my: 5 }} />
        ) : (
          <>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <colgroup>
                  <col width="50%" />
                  <col width="50%" />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>Customer/Product</TableCell>
                    <TableCell className={classes.tableHeader}>Tracking</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(trackingList).map((shipment) => {
                    const { name = '', newTracking = '', payment: orderId } = shipment || {};
                    const tracking = getTrackingCode(shipment);
                    return (
                      <TableRow key={orderId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" style={{ whiteSpace: 'pre', maxLines: '300px' }}>
                          <Typography variant="body1" fontWeight="500">
                            {name}
                          </Typography>
                          {Object.entries(summarizedItems[orderId] || {}).map(([item, qty]) => (
                            <Box key={item} display="flex" gap="0.5px" alignItems="start">
                              <Typography variant="body2" fontSize="small">
                                {item} <strong>x{qty}</strong>
                              </Typography>
                            </Box>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            spacing={1}
                            divider={tracking ? <ArrowRightIcon fontSize="small" /> : null}
                          >
                            {tracking && <Typography variant="body1">{tracking}</Typography>}
                            <Typography variant="body1">{newTracking}</Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button onClick={handleSave} variant="contained" size="large">
              Save All
            </Button>
            <Button onClick={modalOnClose} size="large" disabled={isSavingAllTracking}>
              Cancel
            </Button>
          </>
        )}
      </ModalPaper>
    </ModalContainer>
  );
};

TrackingSummaryModal.propTypes = {
  trackingList: PropTypes.shape({
    isButtonDisabled: PropTypes.bool,
    [PropTypes.string]: PropTypes.string,
  }).isRequired,
  setTrackingList: PropTypes.func.isRequired,
  isSummaryModalOpen: PropTypes.bool.isRequired,
  setIsSummaryModalOpen: PropTypes.func.isRequired,
  summarizedItems: PropTypes.objectOf(PropTypes.objectOf(PropTypes.number)).isRequired,
  pharmacyData: PropTypes.shape({
    id: PropTypes.string,
    tracking:
      PropTypes.string ||
      PropTypes.shape({
        trackingCode: PropTypes.string,
        shippedBy: PropTypes.string,
        createdAt: PropTypes.number,
        createdBy: PropTypes.string,
        updatedAt: PropTypes.number,
        updatedBy: PropTypes.string,
      }),
  }).isRequired,
};

export default TrackingSummaryModal;
