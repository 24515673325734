import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid2 as Grid, Paper, Container, Typography, Button, TextField, Box } from '@mui/material';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { AuthContext } from '../../../../auth-context';
import { isCandor } from '../../../../utils/roles';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'ExpandableFormElementPresentation';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  box: `${PREFIX}-box`,
  paper: `${PREFIX}-paper`,
  holder: `${PREFIX}-holder`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  input: `${PREFIX}-input`,
  openAdderButton: `${PREFIX}-openAdderButton`,
  button: `${PREFIX}-button`,
  removeButton: `${PREFIX}-removeButton`,
  checkedIcon: `${PREFIX}-checkedIcon`,
  addedCheckedIcon: `${PREFIX}-addedCheckedIcon`,
  candorPaper: `${PREFIX}-candorPaper`,
  candorHeading: `${PREFIX}-candorHeading`,
  candorSubheading: `${PREFIX}-candorSubheading`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
    alignItems: 'center',
  },

  [`& .${classes.accountGridContainer}`]: {
    height: 'auto',
  },

  [`& .${classes.box}`]: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '100%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.holder}`]: {
    margin: '20px auto',
    maxWidth: '400px',
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 10px',
    maxWidth: '300px',
    // Capitalize the
    '& .MuiFormLabel-root': {
      textTransform: 'capitalize',
    },
  },

  [`& .${classes.openAdderButton}`]: {
    margin: '30px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '140px',
    display: 'block',
  },

  [`& .${classes.removeButton}`]: {
    margin: '20px 5px 10px 0px',
    padding: '15px 10px',
    minWidth: 70,
  },

  [`& .${classes.checkedIcon}`]: {
    color: '#fff',
  },

  [`& .${classes.addedCheckedIcon}`]: {
    alignSelf: 'center',
  },

  [`& .${classes.candorPaper}`]: {
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(0 0 0 / 20%)',
    borderRadius: 'unset',
    padding: '0px 0 20px',
    width: '100%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.candorHeading}`]: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  [`& .${classes.candorSubheading}`]: {
    textAlign: 'left',
    fontSize: '13px',
    margin: '0 0px 10px 0px',
  },
}));

const ExpandableFormElementPresentation = (props) => {
  const {
    inputError,
    currentFormObject,
    handleFirstFieldChange,
    handleAddElement,
    handleRemoveElement,
    options,
    handleNextButton,
    simpleView,
  } = props;

  const { userType } = useContext(AuthContext);
  const buttonText = getFormsButtonText({ isLoading: false, isUpdateDetails: false });
  const [inputOne, inputTwo] = currentFormObject.inputs;

  const labelOne = inputOne === 'treatment' ? 'medication / treatment' : undefined;

  return (
    <StyledContainer maxWidth="lg">
      <Grid
        container
        className={isCandor(userType) ? classes.accountGridContainer : classes.gridContainer}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paper className={simpleView ? classes.candorPaper : classes.paper}>
          <Typography
            className={simpleView ? classes.candorHeading : classes.heading}
            variant={simpleView ? 'body1' : 'h5'}
            align="center"
            gutterBottom
          >
            {currentFormObject.headingText}
          </Typography>
          {currentFormObject.subheadingText && (
            <Typography
              className={simpleView ? classes.candorSubheading : classes.subheading}
              variant="body2"
              align="center"
              gutterBottom
            >
              {currentFormObject.subheadingText}
            </Typography>
          )}
          <>
            {simpleView ? (
              <ul style={{ padding: '0 20px' }}>
                {options.map((option, ind) => {
                  if (ind === 0) return null;
                  return (
                    <li key={option} style={{ textAlign: 'left', marginBottom: 5 }}>
                      {option}
                    </li>
                  );
                })}
              </ul>
            ) : (
              <>
                {options.map((option, ind) => (
                  <React.Fragment key={ind !== 0 ? option : 'new-option'}>
                    {ind === 0 && userType === 'patient' && (
                      <div>
                        <Box className={classes.box}>
                          <CheckCircleRoundedIcon className={classes.checkedIcon} />
                          <TextField
                            autoFocus
                            type="text"
                            variant="outlined"
                            multiline
                            className={classes.input}
                            onChange={handleFirstFieldChange}
                            value={option}
                            error={!(inputError === '')}
                            helperText={inputError}
                            fullWidth
                            slotProps={{
                              inputLabel: { required: false },
                              formHelperText: { className: classes.helperText },
                            }}
                          />
                          {userType === 'patient' && (
                            <Button
                              variant="outlined"
                              className={classes.removeButton}
                              disabled={!option}
                              color="primary"
                              onClick={() => handleAddElement(false)}
                            >
                              Save
                            </Button>
                          )}
                        </Box>
                      </div>
                    )}
                    {ind !== 0 && (
                      <Box className={classes.box}>
                        <CheckCircleRoundedIcon color="primary" className={classes.addedCheckedIcon} />
                        <TextField
                          disabled
                          type="text"
                          variant="outlined"
                          multiline
                          className={classes.input}
                          value={option}
                          label={labelOne}
                          fullWidth
                          required
                          slotProps={{
                            inputLabel: { required: false },
                          }}
                        />
                        {userType === 'patient' && (
                          <Button
                            variant="outlined"
                            className={classes.removeButton}
                            value={option}
                            onClick={(e) => handleRemoveElement(e.currentTarget.value)}
                            color="primary"
                          >
                            Remove
                          </Button>
                        )}
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </>
          {userType === 'patient' && (
            <Button
              disabled={options[0] !== ''}
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleNextButton}
            >
              {buttonText}
            </Button>
          )}
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default ExpandableFormElementPresentation;
