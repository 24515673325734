const UNKNOWN_NAME = 'Unknown Sender';
const CANDOR_FALLBACK_NAME = 'Candor Team';

const getNameFromCandorEmail = (email) => {
  // Exit if no email provided, or if more than 1 @ symbol return the full email
  if (!email || (email.match(/@/g) || []).length !== 1) {
    return email || UNKNOWN_NAME;
  }

  const [localPart, domain] = email.split('@');

  // Return the full email if the domain isn't exactly candor.org
  // Potentially a pharmacist, as they have `from: "candor", senderEmail: "pharmacist@localpharmacy.com"
  if (domain !== 'candor.org') {
    return email;
  }

  // If no local part of an email exists, return UNKNOWN_NAME
  if (!localPart) {
    return UNKNOWN_NAME;
  }

  const [firstName, lastName] = localPart.split('.') || [];
  // We know it's from a Candor Employee, lets default to CANDOR_FALLBACK_NAME
  if (!firstName || !lastName) {
    return CANDOR_FALLBACK_NAME;
  }

  // Finally we made it!!!
  return `${firstName.charAt(0).toUpperCase()}${firstName.substring(1)} ${lastName.charAt(0).toUpperCase()}`;
};

export default getNameFromCandorEmail;
