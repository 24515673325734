import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const PessariesInfo = () => (
  <Grid>
    <Typography variant="h6">Using medicinal cannabis pessaries</Typography>
    <br />
    <Typography variant="body1">
      Medicinal cannabis pessaries can provide localized relief and treatment effects for women with pelvic conditions,
      pain and bowel disorders. Pessaries may be used intermittently as needed, or as a regular medication.
    </Typography>
    <br />
    <Typography variant="h6">How to use a pessary</Typography>
    <br />
    <Typography variant="body1">
      1. Wash your hands thoroughly before handling the pessary.
      <br />
      2. Remove the pessary from its packaging.
      <br />
      3. Lie down on your back with your knees bent and legs apart.
      <br />
      4. Insert the pessary into the vagina as far as it will comfortably go, using your index finger. Lubricant can be
      used to assist.
      <br />
      5. It is advisable to remain lying down for 30 minutes after inserting the pessary to aid absorption and prevent
      leakage
      <br />
      6. Wash your hands again after insertion.
    </Typography>
    <br />
  </Grid>
);

export default PessariesInfo;
