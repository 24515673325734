import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Grid2 as Grid, Paper, Button, Container, Typography, Alert, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';

import { isCandor } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'VeteranCardFormElementPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  accountGrid: `${PREFIX}-accountGrid`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  input: `${PREFIX}-input`,
  form: `${PREFIX}-form`,
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  button: `${PREFIX}-button`,
  inputGrid: `${PREFIX}-inputGrid`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',

  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    flexWrap: 'nowrap',
    minHeight: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    marginBottom: '40px',
    marginTop: 20,
  },

  [`& .${classes.accountGrid}`]: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '100%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },

  [`& .${classes.label}`]: {
    margin: '10px 0px',
    padding: '10px',
    textAlign: 'center',
    width: '310px',
    [theme.breakpoints.down('xl')]: {
      width: '280px',
    },
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.inputGrid}`]: {
    padding: '10px 5px ',
  },
}));

const VeteranCardFormElementPresentation = ({
  currentFormObject,
  handleInput,
  handleChange,
  checkedOptions,
  isUpdateDetails,
  veteranCardData,
  expiryError,
  handleExpiryDate,
  firstNameError,
  lastNameError,
  validVeteranFields,
  fileNumberError,
  setFileNumberError,
}) => {
  const { userType } = useContext(AuthContext);
  const [expiryData, setExpiryData] = useState(null);

  useEffect(() => {
    setExpiryData(veteranCardData?.expiry ? moment(veteranCardData.expiry) : null);
  }, [veteranCardData]);

  const buttonText = getFormsButtonText({ isUpdateDetails, isLoading: false });

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={0} className={isCandor(userType) ? classes.accountGrid : classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} className={classes.container}>
            <Grid size={{ xs: 12 }}>
              <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
                Your Veterans Card Information
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }} className={classes.inputGrid}>
              <TextField
                label="First Name"
                value={veteranCardData?.firstName || ''}
                onChange={(e) => handleChange(e.target.value, 'firstName')}
                variant="outlined"
                fullWidth
                error={firstNameError}
              />
            </Grid>
            <Grid size={{ xs: 6 }} className={classes.inputGrid}>
              <TextField
                label="Last Name"
                value={veteranCardData?.lastName || ''}
                onChange={(e) => handleChange(e.target.value, 'lastName')}
                variant="outlined"
                fullWidth
                error={lastNameError}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} className={classes.inputGrid}>
              <TextField
                label="File No."
                value={veteranCardData?.fileNumber || ''}
                onChange={(e) => handleChange(e.target.value.replace(/\s+/g, ''), 'fileNumber')}
                variant="outlined"
                onBlur={(e) => {
                  if (e.target.value.replace(/\s+/g, '').length < 8) setFileNumberError(true);
                }}
                fullWidth
                error={fileNumberError}
                helperText={fileNumberError && 'A DVA File number should be eight or nine characters long'}
                slotProps={{
                  htmlInput: { minLength: 8, maxLength: 9 },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} className={classes.inputGrid}>
              <DatePicker
                className={classes.datePicker}
                label="Expiry Date"
                minDate={moment().startOf('month')}
                value={expiryData}
                format="MM/YY"
                onChange={(date) => handleExpiryDate(date)}
                views={['month', 'year']}
                slotProps={{
                  textField: {
                    error: expiryError,
                    fullWidth: true,
                  },
                }}
              />
            </Grid>

            <Grid className={classes.inputGrid} size={{ xs: 12 }}>
              {firstNameError && <Alert severity="error">Please enter a valid first name</Alert>}
              {lastNameError && <Alert severity="error">Please enter a valid last name</Alert>}
              {fileNumberError && (
                <Alert severity="error">
                  Invalid File No. A DVA File number should be eight or nine characters long
                </Alert>
              )}
              {expiryError && <Alert severity="error">Please enter valid expiry date</Alert>}
            </Grid>
          </Grid>
          <Button
            disabled={
              !validVeteranFields || (currentFormObject.selectOne && !Object.values(checkedOptions).includes(true))
            }
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleInput}
          >
            {buttonText}
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

VeteranCardFormElementPresentation.propTypes = {
  currentFormObject: PropTypes.shape({}).isRequired,
  handleInput: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  checkedOptions: PropTypes.shape({}),
  isUpdateDetails: PropTypes.bool,
  veteranCardData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fileNumber: PropTypes.string,
    expiry: PropTypes.string,
  }).isRequired,
  expiryError: PropTypes.bool.isRequired,
  handleExpiryDate: PropTypes.func.isRequired,
  firstNameError: PropTypes.bool.isRequired,
  lastNameError: PropTypes.bool.isRequired,
  validVeteranFields: PropTypes.bool.isRequired,
  fileNumberError: PropTypes.bool.isRequired,
  setFileNumberError: PropTypes.func.isRequired,
};

export default VeteranCardFormElementPresentation;
