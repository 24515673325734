import React from 'react';

import { OrderDetailsContext } from '../contexts/OrderDetailsContext';

export const useOrderDetailsContext = () => {
  const context = React.useContext(OrderDetailsContext);

  if (!context) {
    throw new Error('useOrderDetailsContext must be used within a OrderDetailsProvider');
  }

  if (!context.order) {
    throw new Error('OrderDetailsContext.order is null');
  }

  return {
    ...context,
    order: context.order,
  };
};
