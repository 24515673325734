import { styled } from '@mui/material/styles';
import React, { useEffect, useCallback } from 'react';
import { doc, collection, writeBatch } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';
import { Typography, Grid2 as Grid, CircularProgress, Button, TextField } from '@mui/material';

import { db } from '../../../../firebase-config';
import createEmailObj from '../../../../functions/create-email-obj';
import { SHIPMENT_TYPES, SUPPORT_ID } from '../../../../utils/constants';
import { usePharmacyContext } from '../../hooks/usePharmacyContext';
import { processData } from './processData';

/**
 * FIXME: This file needs major refactoring. For the most part it was moved from the
 * ScriptOnlySection.jsx file.
 *
 * @krochet94 - You are the main contributor to this file.
 *
 * The code is extremely hard to maintain and understand. It should be refactored.
 */

const PREFIX = 'ScriptOnlySection';

const classes = {
  gridItem: `${PREFIX}-gridItem`,
  scriptInput: `${PREFIX}-scriptInput`,
  gridItemMain: `${PREFIX}-gridItemMain`,
  circular: `${PREFIX}-circular`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.gridItem}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
  },

  [`& .${classes.scriptInput}`]: {
    width: 300,
    margin: '0px 10px',
  },

  [`& .${classes.gridItemMain}`]: {
    marginTop: 20,
  },

  [`&.${classes.circular}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const { COMPLETE } = SHIPMENT_TYPES;

const mailRef = collection(db, 'mail');

const EMAIL_TEMPLATES = {
  SCRIPT_ONLY: 'script_only_email',
  SCRIPT_NOTIFICATION: 'script_sent_notification',
  SCRIPT_TO_PHARMACY: 'paper_script_to_pharmacy',
};

const getActivityData = ({ updatedShipment, itemsList }) => ({
  createdAt: updatedShipment.updated,
  generalData: true,
  author: 'System',
  text: `Patient prescription for ${itemsList.join(' & ')} was sent to ${updatedShipment.pharmacyInfo.name}`,
});

const paperScriptEmailData = (prescriber, order, items) =>
  createEmailObj(prescriber, EMAIL_TEMPLATES.SCRIPT_TO_PHARMACY, {
    patientName: order.name,
    pharmacyName: order.pharmacyInfo.name,
    pharmacyAddress: order.pharmacyInfo.address,
    items,
  });

// TODO: refactor/merge the changes in the Script Only Issue after this was merged
// Remove the comment when finished
const processSendToPharmacy = async ({
  order,
  localPharmacyEmail,
  emailObj,
  setIsSendingToPharmacyLoading,
  items,
  snackbar,
}) => {
  try {
    setIsSendingToPharmacyLoading(true);
    const batch = writeBatch(db);
    const date = Date.now();
    const itemsList = Object.values(items);

    const patientPurchaseHistoryRef = doc(db, 'patients', order.user, 'purchase_history', order.payment);

    const completedOrderRef = doc(db, 'orders', SUPPORT_ID, 'completed', order.payment);
    const outstandingOrderRef = doc(db, 'orders', SUPPORT_ID, 'outstanding', order.payment);

    const patientRef = doc(db, 'patients', order.user);
    const patientActivityRef = doc(collection(db, 'patients', order.user, 'activity'));
    // Add a banner to the patient's dashboard
    const generalObj = { banner: { title: 'script sent to pharmacy' } };
    const updatedShipment = {
      ...order,
      updated: date, // Update the order to show the time it has been sent
      status: COMPLETE, // Make sure the order gets archived with the support
    };

    // Object that will be used to insert new data in the activity feed
    // Update the activity log in the patients chart
    const activityData = getActivityData({ updatedShipment, itemsList });

    // Update order pharmacy email from purchase history
    batch.update(patientPurchaseHistoryRef, {
      pharmacyInfo: { ...order.pharmacyInfo, email: localPharmacyEmail },
    });
    batch.set(completedOrderRef, updatedShipment);
    const scriptNotifRef = doc(mailRef);
    // Email the script to the pharmacy
    batch.set(scriptNotifRef, createEmailObj(localPharmacyEmail, EMAIL_TEMPLATES.SCRIPT_ONLY, emailObj));
    const scriptOnlyRef = doc(mailRef);
    // Let the patient know the script has been sent
    batch.set(scriptOnlyRef, createEmailObj(order.email, EMAIL_TEMPLATES.SCRIPT_NOTIFICATION));

    // old orders config. uncomment and fix if needed
    // if (order.prescribers) {
    //   order.prescribers.forEach((prescriber) => {
    //     batch.set(mailRef, paperScriptEmailData(prescriber, order, itemsList));
    //   });
    // }

    // Let the prescriber(s) know the paper script needs to be sent
    if (order?.scriptsArray.some((script) => script.prescriber)) {
      order.scriptsArray.forEach((script) => {
        if (script.prescriber) {
          const paperScriptRef = doc(mailRef);
          batch.set(paperScriptRef, paperScriptEmailData(script.prescriber, order, itemsList));
        }
      });
    }

    batch.set(patientActivityRef, activityData);
    batch.update(patientRef, generalObj);
    // Delete the order from the outstanding folder
    batch.delete(outstandingOrderRef);
    await batch.commit();
    snackbar('Order has been sent to the pharmacy');
  } catch (error) {
    sentryCaptureException(error, {
      extra: {
        issueIn: 'handleSendToPharmacyButton',
        order,
        user: 'support/director',
      },
    });
    snackbar(`Sorry something went wrong. Please try again. Error: ${error}`);
  } finally {
    setIsSendingToPharmacyLoading(false);
  }
};

/**
 * @param {Object} props - The props object
 * @param {Object} props.order - The order object
 */
export const OrderDetailsScriptOnlySection = ({ order }) => {
  const { catalogue, snackbar } = usePharmacyContext();
  const [localPharmacyEmail, setLocalPharmacyEmail] = React.useState('');
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [isSendingToPharmacyLoading, setIsSendingToPharmacyLoading] = React.useState(false);
  const [userDoc, setUserDoc] = React.useState(null);
  const [emailObj, setEmailObj] = React.useState({});
  const [items, setItems] = React.useState({});

  const handleSendToPharmacyButton = async () => {
    await processSendToPharmacy({
      items,
      order,
      emailObj,
      localPharmacyEmail,
      snackbar,
      setIsSendingToPharmacyLoading,
    });
  };

  // FIXME: This code is extremely hard to maintain and understand. It should be refactored.
  // FIXME: Missing hooks dependency
  const getData = useCallback(async () => {
    await processData({
      order,
      userDoc,
      catalogue,
      setItems,
      snackbar,
      setUserDoc,
      setEmailObj,
      setDataLoaded,
    });
    // WARNING: passing userDoc or userDoc.id will cause infinite fetching of file urls from gcp storage
    // FUTURE: Investigate the cause this behaviour.
  }, [order, snackbar, setUserDoc, setEmailObj, setItems, setDataLoaded]);

  useEffect(() => {
    setDataLoaded(false);
    setLocalPharmacyEmail('');
    setIsSendingToPharmacyLoading(false);
    setUserDoc(null);
    setEmailObj({});
    setItems({});
    getData();
  }, [getData]);

  if (!dataLoaded) {
    return (
      <StyledGrid size={{ xs: 12 }} className={classes.circular} my={1}>
        <CircularProgress />
      </StyledGrid>
    );
  }

  if (!Object.keys(order.scriptsArray || {}).length) {
    return (
      <Typography variant="h6" color="secondary" style={{ textAlign: 'center' }}>
        Scripts missing for this order
      </Typography>
    );
  }

  return (
    <StyledGrid className={classes.gridItemMain}>
      <Grid className={classes.gridItem} size={{ xs: 12 }}>
        <TextField
          placeholder="Pharmacy Email"
          className={classes.scriptInput}
          onChange={(e) => setLocalPharmacyEmail(e.target.value)}
          value={localPharmacyEmail || ''}
        />
      </Grid>
      <Grid className={classes.gridItem} size={{ xs: 12 }} mb={1}>
        <Button
          disabled={localPharmacyEmail === ''}
          variant="contained"
          color="primary"
          onClick={handleSendToPharmacyButton}
          loading={isSendingToPharmacyLoading}
        >
          Send To Pharmacy
        </Button>
      </Grid>
    </StyledGrid>
  );
};
