import React from 'react';
import { useParams } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { Button, Chip, Dialog, DialogContent, Menu, Stack, Typography } from '@mui/material';

import { AuthContext } from '../../../auth-context';
import { CANDOR, SHIPMENT_TYPES } from '../../../utils/constants';
import { convertMilisecondsToDate } from '../../../functions/date-conversion';
import { isDirector, isNurse, isPharmacist, isSupport } from '../../../utils/roles';
import { getShowTools } from '../helpers';
import { OrderDetailsProvider } from '../providers';
import { ItemsDetail } from '../components/ItemsDetail';
import { usePharmacyContext } from '../hooks/usePharmacyContext';
import { PharmacistSection } from '../components/PharmacistSection';
import { OrderDetailsNotes } from '../components/OrderDetailsNotes';
import { useOrderDetailsContext } from '../hooks/useOrderDetailsContext';
import { OrderDetailsNotesModal } from '../components/OrderDetailsNotesModal';
import { OrderDetailsScriptDetails } from '../components/OrderDetailsScriptDetails';
import { VerifyIdentityThroughPharmacy } from '../components/VerifyIdentityThroughPharmacy';
import { OrderDetailsScriptOnlySection } from '../components/OrderDetailsScriptOnlySection';
import { OrderDetailsToolsDropdownMenu } from '../components/OrderDetailsToolsDropdownMenu';

const { SCRIPT_ONLY, COMPLETE } = SHIPMENT_TYPES;

/**
 * This is a function that determines if the notes modal should be shown.
 * @param {Object} props - The props for the pharmacy order details page.
 * @param {Note[] | undefined} props.notes - The notes to display.
 * @param {import('../../../../functions/src/types/global.types').User | null} props.user - The user to display.
 * @param {boolean} props.userIsPharmacist - Whether the user is a pharmacist.
 * @returns {boolean} - Whether the notes modal should be shown.
 */
const shouldShowNotesModal = ({ notes, user, userIsPharmacist }) => {
  if (!user || !Array.isArray(notes) || !notes?.length) {
    return false;
  }

  const lastNote = notes[notes.length - 1] || {};
  const { from, read } = lastNote;

  /**
   * 1. If the last note is from Candor and the reader is a pharmacist and the message is not read yet, show the modal.
   * 2. If the last note is not from Candor and the reader is not a pharmacist and the message is not read yet, show the modal.
   */
  return userIsPharmacist ? from === CANDOR && !read?.[user.uid] : from !== CANDOR && !read?.[user.uid];
};

/**
 * This is a function that determines if the user verification section should be shown.
 * @param {Object} params
 * @param {boolean} params.userIsPharmacist - Whether the user is a pharmacist.
 * @param {Order} params.order - The order to display.
 * @returns {React.ReactNode} - The user verification section.
 */
const UserVerificationSection = ({ userIsPharmacist, order }) => {
  if (order.userVerified) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="body1" fontWeight={500} color="secondary">
        Patient ID Verification Pending
      </Typography>
      {userIsPharmacist && <VerifyIdentityThroughPharmacy />}
    </Stack>
  );
};

/**
 * This is the child component for the pharmacy order details page.
 * @param {Object} props - The props for the pharmacy order details page.
 * @param {Order} props.order - The order to display.
 * @param {boolean} props.hideTools - Whether to hide the tools.
 * @returns {React.ReactNode} - The pharmacy order details page.
 */
const PharmacyOrderDetailsPageChild = ({ order, hideTools = false }) => {
  const { user, userType } = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(/** @type {HTMLButtonElement | null} */ (null));
  const [isNotesModalOpen, setIsNotesModalOpen] = React.useState(false);
  const showTools = getShowTools({ shipment: order, userType, isHistorical: order.status === COMPLETE });

  const { catalogue } = usePharmacyContext();

  const userIsPharmacist = isPharmacist(userType);

  const openNotesModal = React.useCallback(() => {
    setIsNotesModalOpen(true);
  }, [setIsNotesModalOpen]);

  const closeNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  React.useEffect(() => {
    if (shouldShowNotesModal({ notes: order.notes, user, userIsPharmacist })) {
      openNotesModal();
    }
  }, [order, userType, userIsPharmacist, openNotesModal, user]);

  if (!order) {
    return null;
  }

  const isScriptOnly = order.status === SCRIPT_ONLY;
  const isHistorical = order.status === COMPLETE;

  const userIsDirector = isDirector(userType);
  const userIsSupport = isSupport(userType);
  const userIsNurse = isNurse(userType);

  const userIsSupportOrDirector = userIsSupport || userIsDirector;

  const canViewOrderDetails = userIsPharmacist || userIsSupport || userIsDirector || userIsNurse;

  return (
    <Stack direction="column" spacing={2} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', mb: 2 }}>
        {!hideTools && (
          <>
            <Button
              disabled={Object.values(showTools).every((show) => !show)}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              startIcon={<MenuIcon />}
            >
              Tools
            </Button>
            <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
              {Object.entries(showTools).map(([type, show]) => {
                if (!show) {
                  return null;
                }

                return <OrderDetailsToolsDropdownMenu type={type} key={type} onClose={() => setAnchorEl(null)} />;
              })}
            </Menu>
          </>
        )}

        {isScriptOnly && <Chip label="Script Only" color="secondary" sx={{ width: 'fit-content' }} />}
      </Stack>
      <Stack direction="column" spacing={3} pb={2}>
        {(!!canViewOrderDetails || !!order.orderPlaced) && (
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            justifyContent="space-between"
            alignItems="flex-start"
            gap={{ xs: 1, md: 2 }}
            mb={2}
          >
            {canViewOrderDetails && <ItemsDetail order={order} catalogue={catalogue} />}

            {order.orderPlaced && (
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', md: canViewOrderDetails ? 'flex-end' : 'flex-start' }}
                textAlign={{ xs: 'left', md: canViewOrderDetails ? 'right' : 'left' }}
              >
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Order Placed
                </Typography>
                <Typography variant="body1">
                  {convertMilisecondsToDate(parseInt(order.orderPlaced, 10), 'minutes')}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}

        <OrderDetailsNotes order={order} />
        <OrderDetailsScriptDetails order={order} scriptOnly={isScriptOnly} />

        {userIsPharmacist && !order.revertReason && <PharmacistSection />}

        {userIsSupportOrDirector && !isHistorical && isScriptOnly && <OrderDetailsScriptOnlySection order={order} />}

        <UserVerificationSection userIsPharmacist={userIsPharmacist} order={order} />
      </Stack>

      <Dialog open={isNotesModalOpen} onClose={closeNotesModal} sx={{ zIndex: 10000 }}>
        <DialogContent sx={{ width: 'fit-content', height: 'fit-content' }}>
          <OrderDetailsNotesModal order={order} closeNotesModal={closeNotesModal} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

/**
 * This is a wrapper component for the pharmacy order details page.
 * @param {Object} props - The props for the pharmacy order details page.
 * @param {boolean} [props.hideTools] - Whether to hide the tools.
 * @returns {React.ReactNode} - The pharmacy order details page.
 */
export const PharmacyOrderDetailsPageWithoutProvider = ({ hideTools = false }) => {
  const { order } = useOrderDetailsContext();

  if (!order) {
    return null;
  }

  return <PharmacyOrderDetailsPageChild order={order} hideTools={hideTools} />;
};

/**
 * This is the main page for the pharmacy order details page.
 * It is used in the pharmacy directory page and the pharmacy details page.
 * @param {Object} props - The parameters for the pharmacy order details page.
 * @param {Order | undefined} [props.order] - The order to display.
 * @param {boolean} [props.hideTools] - Whether to hide the tools.
 * @returns {React.ReactNode} - The pharmacy order details page.
 */
export const PharmacyOrderDetailsPage = ({ order, hideTools = false }) => {
  const { orderId } = useParams();
  const { pharmacy } = usePharmacyContext();

  const orderIdToUse = order?.id ?? orderId;

  return (
    <OrderDetailsProvider pharmacyId={pharmacy?.id} orderId={orderIdToUse} order={order}>
      <PharmacyOrderDetailsPageWithoutProvider hideTools={hideTools} />
    </OrderDetailsProvider>
  );
};
