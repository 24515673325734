import React from 'react';
import PropTypes from 'prop-types';

const ChatbotIcon = ({ width = '50px', height = '50px' }) => (
  <img src="/candi.webp" style={{ width, height }} alt="chatbot-icon" />
);

ChatbotIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default ChatbotIcon;
