import {
  Radio,
  Select,
  Button,
  MenuItem,
  TextField,
  FormLabel,
  InputLabel,
  RadioGroup,
  Typography,
  FormControl,
  Autocomplete,
  FormControlLabel,
  Stack,
} from '@mui/material';
import React from 'react';
import { httpsCallable } from 'firebase/functions';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { captureException as sentryCaptureException } from '@sentry/react';

import { functions } from '../../../../../firebase-config';
import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { useOrderDetailsContext } from '../../../hooks/useOrderDetailsContext';

const substituteItemFunc = httpsCallable(functions, 'substituteItem');

const NO_DIFFERENCE_THRESHOLD = 10;

const COLLECT_FROM_PATIENT_THRESHOLD = -10;

/**
 * TODO: Refactor this component
 * For the most past @keeganpotgieter just copied it over from
 * OrdersModal.ToolsDropDown.SubstituteItem. Some refactoring was done, but not
 * much.
 */

/**
 *
 * @param {Object} params - The parameters for the product display name
 * @param {string} params.item - The item name
 * @param {string} params.name - The product name
 * @returns {string} The product display name
 */
const getProductDisplayName = ({ item, name }) => `${name || 'Some Product'}${item ? ` - ${item}` : ''}`;

const processSubstitution = async ({
  selectedOption,
  order,
  priceDifference,
  itemToBeReplaced,
  substituteItem,
  setLoading,
  catalogue,
  itemToBeReplacedData,
  handleModalClose,
  snackbar,
}) => {
  try {
    if (!substituteItem) {
      throw new Error('No substitute item selected');
    }

    if (order.scriptsArray.some((script) => script?.substitute && !script?.substitute?.isPaid)) {
      throw new Error('There are still uncollected price differences. Please collect them first before proceeding.');
    }

    setLoading(true);
    // Replace the item to be replaced in the scriptsArray with the substitute item
    const newScriptsArray = order.scriptsArray.map((script) => {
      if (
        catalogue.find(
          (item) =>
            // Check for previously paid substituted items. This enables multiple substitutions
            ((script?.substitute?.isPaid && script?.substitute?.stripePrice) || script.priceRef) === item.stripePrice,
        ).item === itemToBeReplacedData.item
      ) {
        return {
          ...script,
          substitute: {
            isPaid: selectedOption !== 'collect',
            stripePrice: substituteItem.stripePrice,
            item: substituteItem.item,
            price: substituteItem.price,
            priceDifference,
          },
        };
      }

      return script;
    });

    const result = await substituteItemFunc({
      selectedOption,
      priceDifference,
      itemToBeReplaced,
      substituteItem,
      newScriptsArray,
      order,
      pharmacyId: order.pharmacyInfo.id || order.shipping.orderSelectedPharmacy,
    });

    if (result.error) {
      throw new Error(result.error);
    }

    handleModalClose();
  } catch (error) {
    sentryCaptureException(error, {
      extra: {
        order,
        substituteItem,
        selectedOption,
        itemToBeReplaced,
        issueIn: 'handleSubstitution item order',
      },
    });
    snackbar(
      `Sorry something went wrong. Please try again or contact support. Please print screen / copy the error message when contacting support. Error: ${error}`,
    );
  } finally {
    setLoading(false);
  }
};

const SubstituteItemModalContent = () => {
  const { order, handleDialogue } = useOrderDetailsContext();
  const { catalogue: fullCatalogue, snackbar } = usePharmacyContext();
  const [itemToBeReplaced, setItemToBeReplaced] = React.useState('');
  const [substituteItem, setSubstituteItem] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('noDiff');
  const [loading, setLoading] = React.useState(false);
  const [filteredCatalogue, setFilteredCatalogue] = React.useState([]);

  const handleModalClose = () => {
    handleDialogue({ isOpen: false, content: null });
  };

  // If the price difference is already paid, show the substituted item, else show the original item
  const items = React.useMemo(
    () =>
      order?.scriptsArray
        .filter((script) => !script.refunded)
        .map(
          (script) =>
            (script?.substitute && script?.substitute?.isPaid && script?.substitute?.item) ||
            filteredCatalogue.find((item) => item.stripePrice === script.priceRef)?.item,
        ),
    [order, filteredCatalogue],
  );

  // Get the data of the item to be replaced
  const itemToBeReplacedData = React.useMemo(
    () => filteredCatalogue.find((item) => item.item === itemToBeReplaced),
    [filteredCatalogue, itemToBeReplaced],
  );

  // Get the price difference between the selected item to be replaced and the substitute item. POSITIVE means refund, NEGATIVE means collect
  const priceDifference = (itemToBeReplacedData?.price || 0) - (substituteItem?.price || 0);

  // Remove duplicate items from the catalogue
  const uniqueCatalogue = React.useMemo(
    () =>
      Object.values(
        filteredCatalogue.reduce((acc, product) => ({ ...acc, [getProductDisplayName(product)]: product }), {}),
      )
        .filter((product) => product?.item?.length && itemToBeReplacedData?.overTheCounter === product?.overTheCounter)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [filteredCatalogue, itemToBeReplacedData],
  );

  const handleSubstitution = async () => {
    await processSubstitution({
      order,
      selectedOption,
      substituteItem,
      priceDifference,
      itemToBeReplaced,
      itemToBeReplacedData,
      catalogue: filteredCatalogue,
      snackbar,
      setLoading,
      handleModalClose,
    });
  };

  React.useEffect(() => {
    const catalogueForForm = Object.values(fullCatalogue).filter((product) => product.form === order.formName);
    setFilteredCatalogue(catalogueForForm);

    if (priceDifference > 0) {
      setSelectedOption('refund');
      return;
    }

    if (priceDifference < COLLECT_FROM_PATIENT_THRESHOLD) {
      setSelectedOption('collect');
      return;
    }

    setSelectedOption('noDiff');
  }, [order, fullCatalogue, priceDifference, setSelectedOption, setFilteredCatalogue]);

  return (
    <Stack direction="column" spacing={2} width={400} maxWidth="100%">
      <Typography variant="h6" sx={{ mb: 2 }} color="primary">
        Substitute Item
      </Typography>

      <FormControl fullWidth>
        <InputLabel>Item To Be Replaced</InputLabel>
        <Select
          disabled={items.some((item) => !item)}
          value={itemToBeReplaced}
          onChange={(e) => setItemToBeReplaced(e.target.value)}
          label="Item To Be Replaced"
        >
          {items.map((item, idx) => {
            // there could be multiple items with the same name, so we need to add an index to the key
            const key = `${item}-${idx}`;
            return (
              <MenuItem key={key} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Autocomplete
        fullWidth
        options={uniqueCatalogue}
        groupBy={(option) => option.form.trim().toUpperCase()}
        getOptionLabel={(option) => `${option.name}${option?.item ? ` - ${option.item} $${option.price}` : ''}`}
        onChange={(_, value) => {
          setSubstituteItem(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Substitute Item"
            margin="normal"
            variant="outlined"
            slotProps={{
              input: { ...params.InputProps, type: 'search' },
            }}
          />
        )}
      />
      <FormControl>
        {priceDifference < 0 && (
          <Typography variant="caption" textAlign="center" color="secondary">
            Substitute Item Costs More
          </Typography>
        )}
        {priceDifference > 0 && (
          <Typography variant="caption" textAlign="center" color="primary">
            Substitute Item Costs Less
          </Typography>
        )}
        <FormLabel sx={{ textAlign: 'center', mb: 2 }}>
          Price Difference: ${Math.abs(priceDifference).toFixed(2)}
        </FormLabel>
        <RadioGroup
          name="radio-buttons-group"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <FormControlLabel
            value="noDiff"
            control={<Radio disabled={-NO_DIFFERENCE_THRESHOLD > priceDifference} />}
            label={`No ${priceDifference > 0 ? 'Refund' : 'Collection'}`}
          />
          <FormControlLabel
            value="refund"
            control={<Radio disabled={priceDifference <= 0} />}
            label="Refund To Patient"
          />
          <FormControlLabel
            value="collect"
            control={<Radio disabled={priceDifference >= 0} />}
            label="Collect From Patient"
          />
        </RadioGroup>
      </FormControl>
      <Button
        disabled={loading || !itemToBeReplaced || !substituteItem}
        onClick={handleSubstitution}
        variant="contained"
        loading={loading}
      >
        Proceed
      </Button>
    </Stack>
  );
};

/**
 * @param {Object} props
 * @param {(content: React.ReactNode) => void} props.openSecondaryModal
 */
export const SubstituteItem = ({ openSecondaryModal }) => {
  const openModal = () => {
    openSecondaryModal(<SubstituteItemModalContent />);
  };

  return (
    <MenuItem onClick={openModal}>
      <Button style={{ textTransform: 'none' }} startIcon={<AutoModeIcon />}>
        Substitute Item
      </Button>
    </MenuItem>
  );
};
