import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { doc, updateDoc } from 'firebase/firestore';
import { Grid2 as Grid, Paper, Container, Typography, Button, TextField } from '@mui/material';

import { db } from '../../../../../firebase-config';

const PREFIX = 'ApplyCredit';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  gridItem: `${PREFIX}-gridItem`,
  paper: `${PREFIX}-paper`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.input}`]: {
    margin: '20px auto',
    width: '100px',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '220px',
    display: 'block',
  },

  [`& .${classes.backButton}`]: {
    margin: '60px auto 0px auto',
    width: '200px',
    display: 'block',
  },
}));

const ApplyCredit = (props) => {
  const { patientId, handleModalClose, handleModalOpen, generalPatientInfo } = props;
  const [accountCredit, setAccountCredit] = useState(generalPatientInfo.existingCreditBalance);

  const handleUpdateAccountCredit = async () => {
    try {
      await updateDoc(doc(db, 'patients', patientId), { existingCreditBalance: Number(accountCredit) || 0 });
      handleModalOpen(`Result: The patient now has $${accountCredit} credit on their account`);
    } catch (error) {
      handleModalOpen('Error: Something went wrong. Contact your team leader.');
    }
  };

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.gridItem}>
            <div>
              <Typography variant="h5" align="center" gutterBottom>
                Account Credit
              </Typography>
            </div>
            <br />
            <div>
              <Typography variant="body1" textAlign="center" gutterBottom>
                Add credit to the patient&apos;s account
              </Typography>
            </div>
            <TextField
              type="number"
              variant="outlined"
              label="Account Credit"
              className={classes.input}
              onChange={(e) => setAccountCredit(e.target.value)}
              value={accountCredit || 0}
            />
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleUpdateAccountCredit}
              >
                Update Account Credit
              </Button>
            </div>
          </Grid>
          <Button variant="text" color="primary" className={classes.backButton} onClick={handleModalClose}>
            Cancel
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default ApplyCredit;
