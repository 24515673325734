import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { captureException as sentryCaptureException } from '@sentry/react';

import MessagesPresentation from './messages-presentation';
import Loading from '../layout/loading';
import { AuthContext } from '../../auth-context';
import { USER_TYPES } from '../../utils/constants';
import createEmailObj from '../../functions/create-email-obj';
import { db, functions, storage } from '../../firebase-config';

const parseServerTimestamp = (timestamp) => {
  const isServerTimestamp = Boolean(timestamp?.seconds);

  if (isServerTimestamp) {
    return Math.round(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  }

  return new Date(timestamp || Date.now()).getTime();
};

const sortMessages = (messageA, messageB) => {
  const timestampA = parseServerTimestamp(messageA?.sent);
  const timestampB = parseServerTimestamp(messageB?.sent);
  return timestampB - timestampA;
};

const sendEmailToPharmacy = httpsCallable(functions, 'sendEmailToPharmacy');
const HUMAN_COMMAND = 'HUMAN';
const CANDI_COMMAND = 'CANDI';
const CANDI_EMAIL = 'candi-support@candor.org';

const MessagesContainer = ({ patientId, onClose }) => {
  const navigate = useNavigate();
  const history = useLocation();
  const { user, userType } = useContext(AuthContext);
  const messageBoxRef = useRef(null);
  const recipientId = patientId || history.pathname.split('/')[2];
  const [searchParams] = useSearchParams();
  const access = searchParams.get('access');
  const orderPatientId = searchParams.get('orderPatientId');
  const pharmacyId = searchParams.get('pharmacyId');

  const [progress, setProgress] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [lastMessage, setLastMessage] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [noPermission, setNoPermission] = useState(false);
  const [senderDetails, setSenderDetails] = useState(null);
  const [savedResponses, setSavedResponses] = useState([]);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [recipientDetails, setRecipientDetails] = useState(null);
  const [messagingAllowed, setMessagingAllowed] = useState(false);
  const [outsideRegularHours, setOutsideRegularHours] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [geminiErrorMessage, setGeminiErrorMessage] = useState('');
  const [chatbotTemplates, setChatbotTemplates] = useState({
    introduction: '',
    referToSupport: '',
  });
  const isAbleToUseChatbot = userType === USER_TYPES.PATIENT && recipientId === USER_TYPES.SUPPORT;
  // If the patient has not yet used the chatbot, isChatbotActive is set to true, unless support manually switches it off
  const isChatbotActive = (conversation?.isChatbotActive ?? !conversation?.hasChatbotIntroduced) && isAbleToUseChatbot;

  const TWO_WEEKS = 1209600000;

  const handleChange = (value) => {
    setMessageText(value);
  };

  const handleSenderId = useCallback(() => {
    if (!userType || !user) {
      return '';
    }
    if (userType === 'nurse' || userType === 'support' || userType === 'doctor') {
      return userType;
    }
    return user?.uid;
  }, [userType, user]);

  const handleRecipientNotification = async () => {
    if (recipientDetails) {
      const recipientParticipant = recipientId === conversation.participant1.id ? 'participant1' : 'participant2';
      const senderParticipant = handleSenderId() === conversation.participant1.id ? 'participant1' : 'participant2';
      const payload = {
        [`${recipientParticipant}.notifications`]: recipientDetails.notifications + 1,
        [`${recipientParticipant}.unreadNotifications`]: true,
        [`${senderParticipant}.notifications`]: 0,
        updated: Date.now(),
      };

      await updateDoc(doc(db, 'conversations', conversation?.id), payload);
      setRecipientDetails({
        ...recipientDetails,
        notifications: recipientDetails.notifications + 1,
      });
    }
  };

  const handleChatbotSuggestion = async () => {
    try {
      const { data } = await httpsCallable(functions, 'palmApi')({ patientId: recipientId });
      if (!data) {
        throw new Error('No data returned from palmApi');
      }
      setMessageText(data);
    } catch (error) {
      sentryCaptureException(error, { extra: { patientId: recipientId } });
    }
  };

  const customName = (name) => {
    const nameParts = name.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];
    if (name === 'Practice Nurse' || name === 'Candor Support') {
      return name;
    }
    return `${firstName} ${lastName.charAt(0)}`;
  };

  const handleName = useCallback((details = { name: '', firstName: '', lastName: '' }) => {
    if (!details || typeof details !== 'object' || Array.isArray(details)) {
      return '';
    }

    if (details?.name) {
      return details.name;
    }

    if (details?.firstName && details?.lastName) {
      return `${details.firstName} ${details.lastName}`;
    }

    return '';
  }, []);

  const handleMessagingWith = ({ chatConversation, senderDetails: senderDetailsMsg, userType: userTypeMsg }) => {
    if (chatConversation && senderDetailsMsg && userTypeMsg) {
      if (userTypeMsg === 'nurse' || userTypeMsg === 'support' || userTypeMsg === 'doctor') {
        const candorMessage = messages.find((item) => item.from === 'candor') || {};
        if (candorMessage.senderEmail !== senderDetailsMsg.email) {
          if (userTypeMsg === 'nurse' && senderDetailsMsg.name) {
            return { messageWith: `You are now messaging with ${customName(senderDetailsMsg.name)}` };
          }
          if (userTypeMsg === 'nurse' && !senderDetailsMsg.name) {
            return { messageWith: 'You are now messaging with the Practice Nurse' };
          }
          if (userTypeMsg === 'support' && senderDetailsMsg.name) {
            return { messageWith: `You are now messaging with ${customName(senderDetailsMsg.name)}` };
          }
          if (userTypeMsg === 'support' && !senderDetailsMsg.name) {
            return { messageWith: 'You are now messaging with Candor Support' };
          }
          return { messageWith: `You are now messaging with ${handleName(senderDetailsMsg)}` };
        }
        return {};
      }
    }
    return {};
  };

  const handleEmailObject = async () => {
    if (userType !== 'patient') {
      let fromDescription = 'the Candor support team';
      if (userType === 'nurse') fromDescription = 'the Candor practice nurse';
      if (userType === 'doctor') fromDescription = 'your medical practitioner';
      if (userType === 'pharmacist') fromDescription = 'our partner pharmacy';
      await addDoc(
        collection(db, 'mail'),
        createEmailObj(`${recipientDetails?.name} <${recipientDetails?.email}>`, 'message_from_candor', {
          fromDescription,
        }),
      );
    } else {
      const messageObj = { patientName: senderDetails?.name, patientEmail: senderDetails?.email };
      let messagePreviewCap;
      if (recipientDetails?.role === 'doctor') {
        messagePreviewCap = 20;
      } else if (recipientDetails?.role === 'nurse') {
        messageObj.practitionerEmail = senderDetails?.doctor || 'Unassigned';
        messagePreviewCap = 20;
      } else if (recipientDetails?.role === 'support') {
        messagePreviewCap = 200;
      } else {
        messagePreviewCap = 200;
      }
      messageObj.messageText =
        messageText.length > messagePreviewCap
          ? `${messageText.slice(0, messagePreviewCap)} ...`
          : (messageObj.messageText = `${messageText}`);

      if (recipientDetails?.role === 'pharmacist') {
        await sendEmailToPharmacy({ email: recipientDetails?.email, messageObj });
      } else if (recipientDetails?.role === 'nurse' || recipientDetails?.role === 'support') {
        const email = recipientDetails?.role === 'nurse' ? 'nurse@candor.org' : 'support@candor.org';
        messageObj.lastAgent = conversation?.participant2?.name;
        messageObj.recentMessages = Date.now() - (parseInt(conversation?.updated, 10) || 0) > TWO_WEEKS ? 'No' : 'Yes';
        await addDoc(collection(db, 'mail'), createEmailObj(email, 'message_from_patient', messageObj));
      } else {
        console.log('messaging ran');
        console.log(recipientDetails?.email);
        console.log(messageObj);
        await addDoc(
          collection(db, 'mail'),
          createEmailObj(recipientDetails?.email, 'message_from_patient', messageObj),
        );
      }
    }
  };

  const generateGeminiResponse = async (messageTextParams) => {
    try {
      const { data: geminiResponse } = await httpsCallable(
        functions,
        'geminiApi',
      )({ patientId: user?.uid, messageText: messageTextParams });

      if (geminiResponse?.errorMessage) {
        setGeminiErrorMessage(geminiResponse?.errorMessage);
        return;
      }

      const geminiPayload = {
        from: 'candor',
        sent: geminiResponse?.timestamp,
        text: `${!conversation?.hasChatbotIntroduced ? `${chatbotTemplates.introduction}\n\n` : ''}${geminiResponse?.message}`,
        senderEmail: CANDI_EMAIL,
      };

      if (!conversation?.hasChatbotIntroduced) {
        await updateDoc(doc(db, 'conversations', conversation?.id), {
          isChatbotActive: true,
          hasChatbotIntroduced: true,
        });
      }
      await addDoc(collection(db, 'conversations', conversation?.id, 'messages'), geminiPayload);
    } catch (error) {
      sentryCaptureException(error, {
        extra: {
          patientId: user?.uid,
        },
      });
    }
  };

  const handleGeminiStopSequence = async () => {
    try {
      const batch = writeBatch(db);
      const replyPayload = {
        from: 'candor',
        sent: Date.now(),
        text: chatbotTemplates.referToSupport,
        senderEmail: CANDI_EMAIL,
      };
      batch.set(doc(collection(db, 'conversations', conversation?.id, 'messages')), replyPayload);
      batch.update(doc(db, 'conversations', conversation?.id), { isChatbotActive: false, hasChatbotIntroduced: false });
      await batch.commit();
      handleRecipientNotification();
      handleEmailObject();
    } catch (error) {
      sentryCaptureException(error, {
        extra: {
          patientId: user?.uid,
        },
      });
    }
  };

  const handleSetChatbotToActive = async () => {
    try {
      const batch = writeBatch(db);
      const replyPayload = {
        from: 'candor',
        sent: Date.now(),
        text: chatbotTemplates.introduction,
        senderEmail: CANDI_EMAIL,
      };
      batch.set(doc(collection(db, 'conversations', conversation?.id, 'messages')), replyPayload);
      batch.update(doc(db, 'conversations', conversation?.id), { isChatbotActive: true, hasChatbotIntroduced: true });
      await batch.commit();
    } catch (error) {
      sentryCaptureException(error, {
        extra: {
          patientId: user?.uid,
        },
      });
    }
  };

  const handleSend = async (fileName, url = '') => {
    setMessageText('');
    if ((messageText || url) && conversation && senderDetails && !isSending) {
      setIsSending(true);

      const isFromPatient = userType === 'patient';

      const payload = {
        from: isFromPatient ? 'patient' : 'candor',
        sent: Date.now(),
        text: messageText || fileName,
        senderEmail: senderDetails.email,
        ...handleMessagingWith({ chatConversation: conversation, senderDetails, userType }),
      };

      if (url) {
        payload.url = url;
      }

      await addDoc(collection(db, 'conversations', conversation?.id, 'messages'), payload);

      if (messageBoxRef.current) {
        messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
      }

      const checkForChatCommands = (chatCommand) =>
        isAbleToUseChatbot && (messageText.trim().toUpperCase() === chatCommand || messageText.includes(chatCommand));

      const isStoppingChatbot = checkForChatCommands(HUMAN_COMMAND);
      const isReenablingChatbot = checkForChatCommands(CANDI_COMMAND);

      // If the user types HUMAN, we want to stop the chatbot and send a message to the support team
      if (isStoppingChatbot) {
        await handleGeminiStopSequence();
      } else if (isReenablingChatbot) {
        await handleSetChatbotToActive();
      } else if (isChatbotActive && messageText.trim()) {
        await generateGeminiResponse(messageText);
      } else {
        handleRecipientNotification();
        handleEmailObject();
      }
      setIsSending(false);

      // NOTE: HOTFIX: Moved to be the last action of the handleSend, a scrollTo animation should'nt
      // be allowed to cause our Notifications or Emails to not be sent.
      // Unsure if the below is the correct behaviour, seems to work. Noticed that screenHeight was
      // being used, as far as I'm aware the original author likely meant scrollHeight. Wouldn't have
      // ever worked in the first place. TODO: Delete this comment after this code's been refactored
      if (messageBoxRef.current) {
        messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
      }
    }
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProgress(1);
      const fileName = `${Date.now().toString()}_${file.name}`;
      // Upload file to unscanned bucket to be scanned by malware scanner.
      const storageRef = ref(
        storage,
        `gs://${import.meta.env.VITE_APP_FIREBASE_CONFIG_STORAGE_BUCKET_UNSCANNED}/patient_documents/${
          user?.uid
        }/chat_uploads/${fileName}`,
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(percent);
        },
        (err) => console.error(err),
        () => {
          // Put 2 second delay to ensure file is uploaded to existing storage before attempting to get download url.
          setTimeout(() => {
            getDownloadURL(ref(storage, `patient_documents/${user?.uid}/chat_uploads/${fileName}`))
              .then((url) => {
                if (url) {
                  handleSend(file.name, url);
                  setProgress(0);
                }
              })
              .catch((err) => {
                // If file does not exist or is harmful, set progress to 0 and show an alert.
                window.alert('File upload failed.');
                setProgress(0);
              });
          }, 2000);
        },
      );
    }
    // FIXME: This is a hacky way to clear the file input, just set it like this to bypass the eslint error
    // Please do fix it in the future when this component is refactored
    event.target.setAttribute('value', '');
  };

  // Check if the current time is outside of the 9am-5pm window local Brisbane time
  const isOutsideRegularHours = () => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentDay = now.getDay();
    return currentDay === 0 || currentDay === 6 || currentHour < 8 || currentHour > 17;
  };

  const getConversation = useCallback(async () => {
    try {
      let conversationId = '';
      switch (userType) {
        case 'patient':
          conversationId = `${handleSenderId()}:${recipientId}`;
          break;
        case 'pharmacist':
          if (orderPatientId !== null) {
            conversationId = `${orderPatientId}:${handleSenderId()}`;
          } else {
            conversationId = `${recipientId}:${handleSenderId()}`;
          }
          break;
        case 'doctor':
          if (access) {
            conversationId = `${recipientId}:${access}`;
          } else {
            conversationId = `${recipientId}:${userType}`;
          }
          break;
        case 'nurse':
          if (access) {
            conversationId = `${recipientId}:${access}`;
          } else {
            conversationId = `${recipientId}:${userType}`;
          }
          break;
        case 'director':
          if (orderPatientId && pharmacyId) {
            conversationId = `${orderPatientId}:${pharmacyId}`;
          } else {
            conversationId = `${recipientId}:${access}`;
          }
          break;
        default:
          conversationId = `${recipientId}:${userType}`;
          break;
      }
      const unsubscribeConversationDoc = onSnapshot(doc(db, 'conversations', conversationId), (snapshot) => {
        const conversationDoc = snapshot.data();
        const conversationData = { ...conversationDoc, id: snapshot.id };
        setConversation(conversationData);
      });
      return () => {
        unsubscribeConversationDoc();
      };
    } catch (error) {
      setNoPermission(String(error).includes('permissions'));
      setIsLoading(false);
      return () => {};
    }
  }, [recipientId, handleSenderId, userType, access, orderPatientId, pharmacyId]);

  const handleCollectionId = useCallback(() => {
    if (userType === 'pharmacist') return 'orders';
    if (userType === 'doctor') return 'doctors';
    return 'patients';
  }, [userType]);

  const getSenderDetails = useCallback(async () => {
    try {
      const details = (await getDoc(doc(db, handleCollectionId(), user?.uid))).data();

      setSenderDetails({
        ...details,
        id: user?.uid,
        notifications: 0,
        name: handleName(details),
        role: userType,
      });
    } catch (error) {
      console.error(error);
      sentryCaptureException(error, { extra: { userType } });
    }
  }, [user, userType, handleName, handleCollectionId]);

  const checkMessageAllowed = useCallback(() => {
    if (
      conversation !== null &&
      (userType === 'nurse' ||
        userType === 'doctor' ||
        conversation?.participant2?.id === 'nurse' ||
        conversation?.participant2?.id === 'doctor') &&
      !access
    ) {
      return setMessagingAllowed(conversation?.online);
    }

    if (access) {
      if (access === userType) {
        return setMessagingAllowed(conversation?.online);
      }

      return setMessagingAllowed(access === userType);
    }

    return setMessagingAllowed(true);
  }, [conversation, userType, access]);

  const handleSenderNotification = useCallback(
    async (conversationDoc) => {
      if (conversationDoc && senderDetails && userType) {
        const participant = userType === 'patient' ? 'participant1' : 'participant2';
        const payload = {
          [`${participant}.notifications`]: !access || userType === access ? 0 : participant.notifications,
          [`${participant}.unreadNotifications`]: !(userType === 'patient'),
        };

        if (userType !== 'patient') {
          payload[`${participant}.email`] = senderDetails.email;
          payload[`${participant}.name`] = senderDetails.name;
        }

        await updateDoc(doc(db, 'conversations', conversationDoc.id), payload);
      }
    },
    [access, senderDetails, userType],
  );

  const handleCancel = () => {
    if (access) navigate(-1);
    if (recipientDetails?.role === 'pharmacist') {
      navigate('/messages/list/pharmacists');
    } else if (recipientDetails?.role === 'patient') {
      navigate('/messages/list/patients');
    } else {
      navigate('/messages');
    }
  };

  const loadMoreMessages = useCallback(async () => {
    try {
      const messagesQuery = query(
        collection(db, 'conversations', conversation?.id, 'messages'),
        orderBy('sent', 'desc'),
        startAfter(lastMessage),
        limit(20),
      );
      const messageDocs = await getDocs(messagesQuery);
      const newMessages = [];
      messageDocs.forEach((message) => {
        newMessages.push({ ...message.data(), id: message.id });
      });
      if (newMessages.length) {
        setMessages((messagesPrevState) => [...messagesPrevState, ...newMessages].sort(sortMessages));
        setLastMessage(messageDocs.docs[messageDocs.docs.length - 1]);
      }
      setIsLoadMore(false);
    } catch (error) {
      console.log(error);
    }
  }, [lastMessage, conversation]);

  const handleScroll = useCallback(() => {
    const messageBox = messageBoxRef.current;
    if (messageBox) {
      if (messageBox.clientHeight + Math.abs(messageBox.scrollTop) >= messageBox.scrollHeight - 1 && !isLoadMore) {
        // Fetch more messages
        loadMoreMessages();
        setIsLoadMore(true);
      }
    }
  }, [isLoadMore, loadMoreMessages]);

  const getSavedResponses = useCallback(async () => {
    if (user) {
      const querySnapshot = await getDocs(collection(db, 'patients', user?.uid, 'cannedResponses'));
      const result = querySnapshot.docs.map((responseDoc) => responseDoc.data());
      const order = result.sort((a, b) => a.order - b.order);
      setSavedResponses(order);
    }
  }, [user]);

  const getChatbotTemplates = useCallback(async () => {
    if (!user) {
      return;
    }
    try {
      const docSnapshot = await getDoc(doc(db, 'public', 'chatbot_training_data'));
      const { introduction, referToSupport } = docSnapshot.data() || {};
      setChatbotTemplates({ introduction, referToSupport });
    } catch (error) {
      sentryCaptureException(error, { extra: { message: 'Error getting chatbot templates.' } });
    }
  }, [user]);

  const handleChatbotActiveSwitch = async (isActive) => {
    try {
      await updateDoc(doc(db, 'conversations', conversation?.id), { isChatbotActive: isActive });
    } catch (error) {
      sentryCaptureException(error, {
        extra: {
          patientId: user?.uid,
          issueIn: 'handleChatbotActiveSwitch',
          message: 'Error updating chatbot active status',
        },
      });
    }
  };

  useEffect(() => {
    if (conversation) {
      checkMessageAllowed();
    }
  }, [checkMessageAllowed, conversation]);

  useEffect(() => {
    let unsubscribeConversationDoc;

    const fetchData = async () => {
      try {
        // We need to handle the unsubscribe logic as we're using onSnapshot
        // FIXME: onSnapshot should be in a useEffect and not a useCallback
        unsubscribeConversationDoc = await getConversation();

        // Run other async functions concurrently
        await Promise.all([getSavedResponses(), getChatbotTemplates()]);
      } catch (error) {
        sentryCaptureException(error, {
          extra: { issueIn: 'MessagesContainer - fetchData' },
        });
      }
    };

    fetchData();

    return () => {
      if (unsubscribeConversationDoc) unsubscribeConversationDoc();
    };
  }, [getChatbotTemplates, getConversation, getSavedResponses]);

  useEffect(() => {
    getSenderDetails();
  }, [getSenderDetails]);

  useEffect(() => {
    // Return if conversation is null or undefined
    if (!conversation) {
      return;
    }

    const details =
      recipientId === conversation?.participant1?.id ? conversation?.participant1 : conversation?.participant2;

    // Return if details is null or undefined
    if (!details) {
      return;
    }

    let name = '';
    if (details?.name) {
      name = details.role === 'nurse' || details.role === 'support' ? customName(details.name) : details.name;
    } else if (!details.name && details.role === 'nurse') {
      name = 'Practice Nurse';
    } else if (!details.name && details.role === 'support') {
      name = 'Candor Support';
    }

    setRecipientDetails({ id: recipientId, ...details, name });
  }, [conversation, recipientId]);

  useEffect(() => {
    const messageBox = messageBoxRef.current;

    if (!isLoading && messageBox) {
      messageBox.addEventListener('scroll', handleScroll);
      return () => {
        messageBox.removeEventListener('scroll', handleScroll);
      };
    }

    return undefined;
  }, [isLoading, handleScroll]);

  useEffect(() => {
    if (conversation) {
      let unsubscribeMessages = null;
      setOutsideRegularHours(isOutsideRegularHours());
      const messagesQuery = query(
        collection(db, 'conversations', conversation?.id, 'messages'),
        orderBy('sent', 'desc'),
        limit(20),
      );
      unsubscribeMessages = onSnapshot(
        messagesQuery,
        (snapshot) => {
          const newMessages = [];
          snapshot.forEach((message) => {
            const messageDetails = message.data();
            const batch = writeBatch(db);
            if (!messageDetails.read && userType === 'patient' && messageDetails.from !== 'patient') {
              const docRef = doc(db, 'conversations', conversation?.id, 'messages', message.id);
              batch.update(docRef, { read: Date.now() });
            }
            if (!messageDetails.read && userType !== 'patient' && messageDetails.from === 'patient') {
              const docRef = doc(db, 'conversations', conversation?.id, 'messages', message.id);
              batch.update(docRef, { read: Date.now() });
            }
            newMessages.push({ ...message.data(), id: message.id });
          });
          // !access means its a patient so the handleSenderNotification will run
          // if the userType is not a patient, it will only run if the userType is the same as the access params
          // thus will fix the issue on nurses updating the chat when viewing the chat of doctors & patients
          if (!access || (userType !== 'patient' && userType === access)) {
            handleSenderNotification(conversation);
          }
          setMessages(newMessages.sort(sortMessages));
          setLastMessage(snapshot.docs[snapshot.docs.length - 1]);
          setIsLoading(false);
        },
        (error) => {
          setNoPermission(String(error).includes('permissions'));
          setIsLoading(false);
        },
      );
      return () => {
        unsubscribeMessages();
      };
    }

    return undefined;
  }, [conversation, userType, handleSenderNotification, access]);

  if (isLoading) return <Loading />;
  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center" height="95vh" maxHeight="100%">
      <MessagesPresentation
        cancel={handleCancel}
        messageBoxRef={messageBoxRef}
        recipientName={recipientDetails?.name || recipientDetails?.email}
        recipientId={recipientId}
        handleChange={handleChange}
        handleSend={handleSend}
        handleUpload={handleUpload}
        progress={progress}
        messageLog={messages}
        messageText={messageText}
        outsideRegularHours={outsideRegularHours}
        messagingAllowed={messagingAllowed}
        savedResponses={savedResponses}
        currentBatch={currentBatch}
        setCurrentBatch={setCurrentBatch}
        noPermission={noPermission}
        isSending={isSending}
        onClose={onClose}
        handleChatbotSuggestion={handleChatbotSuggestion}
        isChatbotActive={isChatbotActive}
        senderDetails={senderDetails}
        geminiErrorMessage={geminiErrorMessage}
        handleChatbotActiveSwitch={handleChatbotActiveSwitch}
        conversation={conversation}
      />
    </Box>
  );
};

export default MessagesContainer;
