import React, { useState } from 'react';
import { Grid2 as Grid, Button, TextField, Checkbox, Typography } from '@mui/material';

import { useOrderDetailsContext } from '../../hooks';
import { classes } from './styles';
import { BypassEscriptModal } from './BypassEscriptModal';
import { MIN_TOKEN_LENGTH, MAX_TOKEN_LENGTH, isToShowEscriptTokenComponent } from './helpers';

/**
 * Determines whether a button should be disabled based on specific conditions.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.repeatToken - The current repeat token as a string.
 * @param {Object} params.script - The script object containing a `repeatToken` property.
 * @param {boolean} params.isCheckboxChanged - A flag indicating whether the checkbox state has changed.
 * @returns {boolean} - Returns `true` if the button should be disabled, otherwise `false`.
 */
const getIsButtonDisabled = ({ repeatToken, script, isCheckboxChanged }) => {
  if (isCheckboxChanged) {
    return false;
  }

  return (
    !repeatToken ||
    repeatToken.length < MIN_TOKEN_LENGTH ||
    repeatToken.length > MAX_TOKEN_LENGTH ||
    repeatToken === script.repeatToken
  );
};

/**
 * EScriptTokenDisplay is a component that allows the user to input a repeat eScript token and save it.
 * @param {Object} props - The props object.
 * @param {Object} props.script - The script object.
 * @param {string} props.productName - The name of the product.
 * @param {boolean} props.isHistorical - Whether the script is historical.
 * @param {string} props.repeatToken - The current repeat token.
 * @param {React.Dispatch<React.SetStateAction<Record<string, string>>>} [props.setRepeatTokens] - The function to set the repeat tokens.
 * @param {({script, isCheckboxChanged}: {script: Object, isCheckboxChanged: boolean}) => Promise<void>} props.handleSaveScriptToken - The function to save the script token.
 * @returns {React.ReactNode} The EScriptTokenDisplay component.
 */
export const EScriptTokenDisplay = ({
  script,
  productName,
  isHistorical,
  repeatToken,
  setRepeatTokens,
  handleSaveScriptToken,
}) => {
  const { handleDialogue } = useOrderDetailsContext();
  const [isSavingScriptToken, setIsSavingScriptToken] = useState(false);
  const [isChecked, setIsChecked] = useState(!!script.manualBypassed);

  const isCheckboxChanged = isChecked !== !!script.manualBypassed;

  const processSave = async () => {
    setIsSavingScriptToken(true);
    handleDialogue({ isOpen: false, content: null });
    await handleSaveScriptToken?.({ script, isCheckboxChanged });
    setIsSavingScriptToken(false);
  };

  const onSave = async () => {
    if (!isCheckboxChanged) {
      await processSave();

      return;
    }

    handleDialogue({
      isOpen: true,
      content: (
        <BypassEscriptModal
          script={script}
          productName={productName}
          onClose={() => handleDialogue({ isOpen: false, content: null })}
          handleProceed={processSave}
          isLoading={isSavingScriptToken}
        />
      ),
    });
  };

  // Indicator for manual bypassed scripts in completed orders
  if (isChecked && isHistorical) {
    return (
      <Typography variant="body2" color="secondary" mt="0.5rem !important" mb="0.75rem !important">
        Dispenses remaining manually set to 0 by pharmacy.
      </Typography>
    );
  }

  if (!isToShowEscriptTokenComponent({ script, isHistorical })) {
    return null;
  }

  return (
    <Grid size={{ xs: 12 }} my={2}>
      <TextField
        label="Repeat eScript Token"
        size="small"
        value={repeatToken}
        onChange={(e) => setRepeatTokens?.((prev) => ({ ...prev, [script.priceRef]: e.target.value }))}
        disabled={isCheckboxChanged || script.manualBypassed}
      />
      <Button
        variant="contained"
        color="primary"
        loading={isSavingScriptToken}
        className={classes.saveButton}
        onClick={onSave}
        disabled={getIsButtonDisabled({ repeatToken, script, isCheckboxChanged })}
      >
        Save
      </Button>

      <div className={classes.eScriptCheckbox}>
        <Checkbox
          checked={isChecked}
          onChange={({ target }) => setIsChecked(target.checked)}
          disabled={isSavingScriptToken}
        />
        <span>No Dispenses Remaining</span>
      </div>
    </Grid>
  );
};
