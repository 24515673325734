import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Portal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useOrder } from '../hooks';
import { OrderDetailsContext } from '../contexts';

const Dialogue = ({ dialogueContent, isDialogueOpen, setIsDialogueOpen, attemptToAttachToDialogue }) => {
  const theme = useTheme();
  const element = document.getElementById('order-details-modal-container');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!dialogueContent) {
    return null;
  }

  if (element && attemptToAttachToDialogue && !isMobile) {
    return (
      <Portal container={element}>
        <Divider orientation="vertical" flexItem />
        <DialogContent id="findme" sx={{ flex: 1, px: 0 }}>
          {dialogueContent}
        </DialogContent>
      </Portal>
    );
  }

  return (
    <Dialog open={isDialogueOpen && !!dialogueContent} onClose={() => setIsDialogueOpen(false)}>
      <DialogContent id="findme2" sx={{ flex: 1 }}>
        {dialogueContent}
      </DialogContent>
    </Dialog>
  );
};

/**
 * @param {Object} props
 * @param {string | undefined} [props.pharmacyId]
 * @param {string | undefined} [props.orderId]
 * @param {Order | undefined} [props.order]
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode}
 */
export const OrderDetailsProvider = ({ pharmacyId, orderId, order: defaultOrder, children }) => {
  const navigate = useNavigate();
  const { pharmacyId: pharmacyIdFromParams, orderId: orderIdFromParams } = useParams();
  const [isDialogueOpen, setIsDialogueOpen] = React.useState(false);
  const [dialogueContent, setDialogueContent] = React.useState(null);
  const [attemptToAttachToDialogueInternal, setAttemptToAttachToDialogueInternal] = React.useState(false);

  const { order, loading, error, refreshOrder } = useOrder({
    pharmacyId: pharmacyId ?? pharmacyIdFromParams ?? '',
    orderId: orderId ?? orderIdFromParams ?? '',
    enabled: !defaultOrder,
  });

  const handleGoBack = () => {
    navigate('/pharmacies');
  };

  /**
   * @param {Object} props
   * @param {boolean} props.isOpen
   * @param {React.ReactNode} props.content
   * @param {boolean} [props.attemptToAttachToDialogue=false]
   */
  const handleDialogue = React.useCallback(({ isOpen, content, attemptToAttachToDialogue = false }) => {
    setIsDialogueOpen(isOpen);
    setDialogueContent(content);
    setAttemptToAttachToDialogueInternal(attemptToAttachToDialogue);
  }, []);

  const value = React.useMemo(
    () => ({
      order: defaultOrder ?? order,
      loading,
      error,
      handleDialogue,
      refreshOrder,
    }),
    [order, loading, error, handleDialogue, defaultOrder, refreshOrder],
  );

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%">
        <Typography>Error loading pharmacy</Typography>
      </Stack>
    );
  }

  if (!defaultOrder && !order) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100%" gap={2}>
        <Typography>Pharmacy not found</Typography>
        <Button variant="text" onClick={handleGoBack}>
          Go to pharmacy directory
        </Button>
      </Stack>
    );
  }

  return (
    <OrderDetailsContext.Provider value={value}>
      {children}
      <Dialogue
        isDialogueOpen={isDialogueOpen}
        setIsDialogueOpen={setIsDialogueOpen}
        attemptToAttachToDialogue={attemptToAttachToDialogueInternal}
        dialogueContent={dialogueContent}
      />
    </OrderDetailsContext.Provider>
  );
};
