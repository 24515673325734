import React from 'react';
import { Button } from '@mui/material';
import { LocalShipping } from '@mui/icons-material';

import { usePharmacyContext } from '../../../hooks/usePharmacyContext';
import { MassAddTrackingModal } from './MassAddTrackingModal';

export const MassAddTracking = () => {
  const { handleDialogue } = usePharmacyContext();

  const openModal = () => {
    handleDialogue({
      isOpen: true,
      content: <MassAddTrackingModal />,
    });
  };

  return (
    <Button startIcon={<LocalShipping />} onClick={openModal}>
      Bulk Add Tracking
    </Button>
  );
};
