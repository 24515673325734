import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { captureException as sentryCaptureException } from '@sentry/react';
import {
  Grid2 as Grid,
  Paper,
  Container,
  Typography,
  Button,
  Box,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { doc, getDocs, getDoc, collection } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import { AuthContext } from '../../../../../auth-context';
import { db, functions } from '../../../../../firebase-config';
import { consultPriceTranslator } from '../../../../../utils/form-translators';
import { isDirector as getIsDirector } from '../../../../../utils/roles';
import {
  capitalizeFirstLetterOfEachWord,
  TREATMENT_TYPES_INVERTED,
  STAR_TRACK,
  AUSPOST,
} from '../../../../../utils/constants';

const PREFIX = 'ApprovePharmacy';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  select: `${PREFIX}-select`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  confirmButton: `${PREFIX}-confirmButton`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    displayDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 40px 40px 40px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.select}`]: {
    margin: '20px 0px',
    maxWidth: '500px',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 10,
    paddingTop: 10,
    marginBotton: 20,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.confirmButton}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const createPharmacyAccount = httpsCallable(functions, 'createPharmacyAccount');

export const TableRowDetails = ({ label, value = '', children = null, defaultFont = false }) => (
  <TableRow sx={{ '& td': { border: 0, paddingBottom: 0 } }}>
    <TableCell>
      <Typography fontWeight={defaultFont ? 'regular' : 'bold'}>{label}:</Typography>
    </TableCell>
    <TableCell>
      {value && <Typography>{value}</Typography>}
      {children}
    </TableCell>
  </TableRow>
);

TableRowDetails.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  defaultFont: PropTypes.bool,
};

export const MultiSelection = ({
  isSubmit = false,
  selectedArray = [],
  onSelect,
  label,
  referenceArray = [],
  disabled = false,
}) => (
  <Box>
    <FormControl
      fullWidth
      size="small"
      style={{ margin: '20px 0px', maxWidth: '500px' }}
      error={isSubmit && !selectedArray?.length}
    >
      <InputLabel id={`${label}-selector-label`}>Select {capitalizeFirstLetterOfEachWord(label)}</InputLabel>
      <Select
        labelId={label}
        id={label}
        label={`Select ${capitalizeFirstLetterOfEachWord(label)}`}
        name={label}
        value={selectedArray}
        multiple
        fullWidth
        onChange={onSelect}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {referenceArray.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

MultiSelection.propTypes = {
  isSubmit: PropTypes.bool,
  selectedArray: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  referenceArray: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

const getShipmentProviderData = (treatments) => {
  if (!Array.isArray(treatments) || !treatments[0]) {
    return {};
  }
  return treatments.reduce((acc, treatment) => {
    if (!TREATMENT_TYPES_INVERTED[treatment]) {
      return acc;
    }
    if (treatment === 'medicinal cannabis') {
      // STAR_TRACK default for medicinal cannabis
      return { ...acc, [TREATMENT_TYPES_INVERTED[treatment]]: STAR_TRACK };
    }
    // AUSPOST default for all other treatments
    return { ...acc, [TREATMENT_TYPES_INVERTED[treatment]]: AUSPOST };
  }, {});
};

const ApprovePharmacy = ({ handleModalClose, handleModalOpen, generalPatientInfo }) => {
  const { userType } = useContext(AuthContext);
  const isDirector = getIsDirector(userType);
  const [promoCode, setPromoCode] = useState('');
  const [discountCodes, setDiscountCodes] = useState([]);
  const [promoCodeErr, setPromoCodeErr] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleConfirm = async () => {
    const { id: patientId } = generalPatientInfo || {};
    try {
      setIsSubmit(true);
      handleModalOpen('loading');
      const pharmacyData = (await getDoc(doc(db, 'patients', patientId))).data();
      const { isPharmacy, acceptedAgreements, ...alteredData } = pharmacyData || {};

      const payload = {
        ...alteredData,
        ordersAccepted: treatments,
        regional: false,
        software: 0,
        ...(promoCode && isDirector && { promoCode }),
        shipmentProvider: getShipmentProviderData(treatments),
        // some critical functions in the codebase use phone and state outside the shipping object
        // TODO: Remove this once we apply the fix in the statement above
        phone: pharmacyData?.shipping?.phone,
        state: pharmacyData?.shipping?.state,
      };

      const { data: createPharmacyAccountResponse = {} } = await createPharmacyAccount({ id: patientId, payload });
      if (createPharmacyAccountResponse?.error) {
        throw new Error(createPharmacyAccountResponse?.error);
      }

      handleModalOpen(`Result: ${createPharmacyAccountResponse?.message}`);
    } catch (error) {
      const sentryErrorId = await sentryCaptureException(error, {
        extra: { patientId, issueIn: 'pharmacy account approval' },
      });
      handleModalOpen(`Error: The pharmacy could not be approved. Sentry Error ID: ${sentryErrorId}`);
    }
  };

  const handlePromoCode = ({ target }) => {
    const value = target?.value || '';
    setPromoCodeErr(false);
    setPromoCode(value.toUpperCase());
    // find if value exist in discountCodes
    const codeExist = discountCodes.find((code) => code === value);

    if (!codeExist) {
      return;
    }
    setPromoCodeErr(true);
  };

  const onSelectTreatment = (event) => {
    const { value } = event.target;
    setTreatments(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    const getAccountInfo = async () => {
      const discountCodesSnap = await getDocs(collection(db, 'discount_codes'));
      const discountCodesArr = discountCodesSnap.docs.reduce((acc, item) => {
        const { promoCode: code } = item.data() || {};
        if (code) {
          return [...acc, code];
        }
        return acc;
      }, []);
      setDiscountCodes(discountCodesArr);
    };

    if (isDirector) {
      getAccountInfo();
    }
  }, [isDirector]);

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer} spacing={0}>
        <Paper className={classes.paper}>
          <Grid className={classes.backButtonGrid}>
            <Box>
              <Button className={classes.backButton} variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
              <Divider
                sx={{
                  opacity: '0.6',
                }}
              />
            </Box>
          </Grid>
          <Grid>
            <Typography fontWeight="bold" textAlign="center" paddingTop="20px">
              Account Information
            </Typography>
            <Box paddingTop="20px" paddingBottom="20px">
              <Typography textAlign="center">Select which treatments this pharmacy offers</Typography>
              <MultiSelection
                isSubmit={isSubmit}
                selectedArray={treatments}
                onSelect={onSelectTreatment}
                label="treatment"
                referenceArray={Object.keys(consultPriceTranslator || {})}
              />
              <TableContainer style={{ marginBottom: 20 }}>
                <Table aria-label="simple table">
                  <colgroup>
                    <col width="60%" />
                    <col width="40%" />
                  </colgroup>
                  <TableBody>
                    <TableRowDetails label="ABN" value={generalPatientInfo.abn} />
                    <TableRowDetails label="Email" value={generalPatientInfo.email} />
                    <TableRowDetails label="Name" value={generalPatientInfo.name} />
                    <TableRowDetails label="Legal Name" value={generalPatientInfo.legalName} />
                    <TableRowDetails label="Phone" value={generalPatientInfo.shipping?.phone} />
                    <TableRowDetails label="Postcode" value={generalPatientInfo.shipping?.postcode} />
                    <TableRowDetails label="Street" value={generalPatientInfo.shipping?.street} />
                    <TableRowDetails label="Suburb" value={generalPatientInfo.shipping?.suburb} />
                    <TableRowDetails label="State" value={generalPatientInfo.shipping?.state} />
                    <TableRowDetails label="Region" value={generalPatientInfo.region} />
                    <TableRowDetails label="Pickup" value={generalPatientInfo.pickup ? 'Yes' : 'No'} />
                    <TableRowDetails label="Account Name" value={generalPatientInfo.accountName} />
                    <TableRowDetails label="BSB" value={generalPatientInfo.bsb} />
                    <TableRowDetails label="Account Number" value={generalPatientInfo.accountNo} />
                    <TableRowDetails label="Contact Person" value={generalPatientInfo.contactPerson} />
                    <TableRowDetails label="Promo Code">
                      <TextField
                        label="Promo Code"
                        value={promoCode}
                        onChange={handlePromoCode}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={promoCodeErr}
                        helperText={promoCodeErr ? 'Promo Code Exists.' : ''}
                      />
                    </TableRowDetails>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Box className={classes.confirmButton}>
            <Button
              disabled={promoCodeErr || !treatments.length}
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

ApprovePharmacy.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  generalPatientInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    abn: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    legalName: PropTypes.string.isRequired,
    shipping: PropTypes.shape({
      phone: PropTypes.string,
      postcode: PropTypes.string,
      street: PropTypes.string,
      suburb: PropTypes.string,
      state: PropTypes.string,
    }),
    region: PropTypes.string,
    pickup: PropTypes.bool,
    accountName: PropTypes.string,
    bsb: PropTypes.string,
    accountNo: PropTypes.string,
    contactPerson: PropTypes.string,
  }).isRequired,
};

export default ApprovePharmacy;
