import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const DoxylamineInformation = () => (
  <Grid>
    <Typography variant="h6">About Doxylamine</Typography>
    <br />
    <Typography variant="body1">
      Doxylamine is an antihistamine used to help treat allergies, nausea and insomnia. It is a sedating antihistamine
      and can be used short term to help with sleep.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Doxylamine is taken 30 minutes before bed for sleep. It should be used for no more than 10 days at a time.
      <br />
      <br />
      It is recommended to avoid alcohol while using Doxylamine. Avoid driving or operating heavy machinery when using.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Doxylamine is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Sedation
      <br />
      - Drowsiness
      <br />
      - Memory impairment
      <br />
      - Dizziness
      <br />
      - Confusion
      <br />
      - Headache
      <br />
      - Dry mouth
      <br />
      - Blurred vision
      <br />
      - Constipation
      <br />
      <br />
      Infrequent (0.1 - 1%)
      <br />
      - Nausea
      <br />
      - Vomiting
      <br />
      - Diarrhoea
      <br />
      - Hypotension
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Leucopenia
      <br />
      - Agranulocytosis
      <br />
      - Haemolytic anaemia
      <br />
      - Allergic reactions
      <br />
      - Arrhythmias
      <br />
      - Dyskinesia
      <br />
      - Hallucinations
      <br />
      - Elevated liver enzymes
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Doxylamine including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Cetirizine when pregnant?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
    <br />
    <Typography variant="h6">Can I use Cetirizine when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes. However caution should be used as the mother may feel sedated.</Typography>
  </Grid>
);

export default DoxylamineInformation;
