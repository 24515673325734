import React from 'react';
import { Grid2 as Grid, Typography } from '@mui/material';

const ChocolateInfo = () => (
  <Grid>
    <Typography variant="h6">Using medicinal cannabis chocolate</Typography>
    <br />
    <Typography variant="body1">
      Chocolate should be eaten 1 to 2 hours before desired effects to manage symptoms. Dosages may be repeated every
      six hours or as needed.
      <br />
      <br />
      Titration is important, especially for new patients. Dosing schedules differ for each patient and should be
      discussed with your doctor. Doses should be increased gradually over time and the effect fully assessed before
      continuing to increase. Note that consuming too much cannabis can result in discomfort and should be avoided.
      <br />
      <br />
      Each piece of chocolate can be cut into smaller pieces to assist with titration.
    </Typography>
    <br />
  </Grid>
);

export default ChocolateInfo;
